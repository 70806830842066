/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import BadNoiseDetector from "./aquf/bad-noise-detector"
import { autorun, IReactionDisposer } from "mobx"
import logger from "@medaica/common/services/logging"

type NoiseQualityRecord = {
  unit: number
  record: number[]
}

class NoiseQualityRecorder {
  badNoiseDetector: BadNoiseDetector
  intervalHandler: NodeJS.Timeout
  intervalMs = 1000
  record: number[] = []
  reactionDisposer: IReactionDisposer | null = null

  constructor(badNoiseDetector: BadNoiseDetector) {
    this.badNoiseDetector = badNoiseDetector
  }

  getResult(): NoiseQualityRecord {
    return {
      unit: this.intervalMs,
      record: this.record,
    }
  }

  start(): void {
    let isBadNoiseDetected = false

    this.reactionDisposer = autorun(() => {
      if (this.badNoiseDetector.isBadNoiseDetected) {
        if (!isBadNoiseDetected) {
          logger.debug("NoiseQualityRecorder: Bad noise detected")
        }
        isBadNoiseDetected = true
      }
    })

    this.intervalHandler = setInterval(() => {
      logger.debug("NoiseQualityRecorder: Recording bad noise detected ", isBadNoiseDetected)
      this.record.push(isBadNoiseDetected ? 1 : 0)
      isBadNoiseDetected = this.badNoiseDetector.isBadNoiseDetected
      logger.debug("NoiseQualityRecorder: Resetting bad noise to ", isBadNoiseDetected)
    }, this.intervalMs)
  }

  stop(): void {
    this.badNoiseDetector.stop()
    if (this.reactionDisposer) {
      this.reactionDisposer()
    }
    clearInterval(this.intervalHandler)
  }
}

export default NoiseQualityRecorder
export type { NoiseQualityRecord }

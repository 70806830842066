/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

/* eslint-disable max-len */
import React, { ReactNode } from "react"

type Props = {
  iColor?: string
  [key: string]: ReactNode
}

const MedaicaLogo = ({ iColor = "#5CC3AD", ...props }: Props): React.ReactElement => {
  return (
    <svg {...props} x="0px" y="0px" viewBox="0 0 68.180003 13.8411">
      <g transform="translate(-4.48,-3.56)">
        <g style={{ fill: "currentColor" }}>
          <path d="M 15.85,9.23 C 15.52,8.95 15.07,8.81 14.5,8.81 c -0.38,0 -0.74,0.07 -1.09,0.21 -0.35,0.14 -0.66,0.34 -0.95,0.58 0.03,0.11 0.05,0.22 0.06,0.35 0.01,0.12 0.02,0.24 0.02,0.37 v 6.56 c -0.1,0.04 -0.23,0.08 -0.41,0.11 -0.18,0.03 -0.36,0.05 -0.55,0.05 -0.39,0 -0.69,-0.07 -0.88,-0.22 -0.19,-0.15 -0.29,-0.4 -0.29,-0.75 V 10.55 C 10.41,9.95 10.24,9.52 9.9,9.24 9.57,8.95 9.12,8.81 8.55,8.81 8.17,8.81 7.81,8.87 7.47,8.99 7.13,9.11 6.84,9.25 6.61,9.4 v 7.47 c -0.1,0.04 -0.23,0.08 -0.4,0.11 -0.17,0.03 -0.36,0.05 -0.56,0.05 -0.39,0 -0.69,-0.07 -0.88,-0.22 C 4.58,16.66 4.48,16.41 4.48,16.06 V 9.34 C 4.48,9.03 4.54,8.78 4.66,8.59 4.8,8.39 5,8.2 5.29,8.01 5.68,7.75 6.18,7.53 6.78,7.36 7.38,7.18 7.98,7.1 8.59,7.1 c 0.64,0 1.21,0.09 1.72,0.26 0.51,0.18 0.93,0.43 1.25,0.77 0.38,-0.3 0.83,-0.55 1.35,-0.74 0.52,-0.2 1.13,-0.3 1.82,-0.3 0.53,0 1.02,0.06 1.49,0.19 0.46,0.13 0.86,0.33 1.19,0.61 0.33,0.28 0.59,0.63 0.78,1.05 0.19,0.42 0.29,0.92 0.29,1.51 v 6.41 c -0.11,0.04 -0.25,0.08 -0.42,0.11 -0.17,0.03 -0.36,0.05 -0.56,0.05 -0.39,0 -0.69,-0.07 -0.88,-0.22 -0.19,-0.15 -0.29,-0.4 -0.29,-0.75 V 10.53 C 16.34,9.94 16.18,9.5 15.85,9.23 Z" />
          <path d="m 21.56,13.23 c 0.19,0.77 0.56,1.35 1.11,1.72 0.55,0.37 1.25,0.56 2.11,0.56 0.57,0 1.09,-0.09 1.57,-0.26 0.47,-0.18 0.85,-0.37 1.14,-0.57 0.38,0.22 0.57,0.52 0.57,0.92 0,0.23 -0.09,0.44 -0.26,0.64 -0.18,0.2 -0.42,0.37 -0.72,0.51 -0.31,0.14 -0.67,0.25 -1.08,0.34 -0.41,0.08 -0.86,0.12 -1.33,0.12 -0.79,0 -1.5,-0.11 -2.15,-0.33 -0.64,-0.22 -1.2,-0.54 -1.66,-0.98 -0.46,-0.43 -0.82,-0.97 -1.07,-1.61 -0.25,-0.64 -0.38,-1.38 -0.38,-2.22 0,-0.81 0.12,-1.53 0.37,-2.15 0.24,-0.62 0.58,-1.13 1,-1.55 0.42,-0.41 0.92,-0.73 1.49,-0.95 0.57,-0.22 1.18,-0.33 1.83,-0.33 0.65,0 1.25,0.11 1.78,0.32 0.54,0.21 1,0.5 1.38,0.88 0.39,0.37 0.69,0.82 0.9,1.33 0.21,0.52 0.32,1.08 0.32,1.69 0,0.34 -0.08,0.59 -0.24,0.74 -0.16,0.16 -0.4,0.26 -0.71,0.32 z m 2.52,-4.48 c -0.75,0 -1.37,0.25 -1.86,0.75 -0.5,0.5 -0.76,1.22 -0.78,2.16 l 5.01,-0.71 C 26.4,10.34 26.17,9.82 25.78,9.39 25.38,8.96 24.82,8.75 24.08,8.75 Z" />
          <path d="m 34.05,7.12 c 0.41,0 0.79,0.04 1.15,0.13 0.36,0.09 0.68,0.2 0.95,0.34 V 3.72 c 0.09,-0.03 0.23,-0.06 0.41,-0.1 0.18,-0.04 0.37,-0.06 0.57,-0.06 0.39,0 0.69,0.07 0.88,0.22 0.19,0.15 0.28,0.4 0.28,0.75 V 15.2 c 0,0.29 -0.06,0.52 -0.18,0.7 -0.12,0.18 -0.31,0.36 -0.57,0.52 -0.31,0.2 -0.74,0.39 -1.27,0.55 -0.54,0.16 -1.15,0.24 -1.84,0.24 -0.77,0 -1.48,-0.1 -2.12,-0.3 -0.64,-0.2 -1.18,-0.5 -1.63,-0.92 -0.45,-0.41 -0.79,-0.94 -1.04,-1.57 -0.24,-0.63 -0.37,-1.37 -0.37,-2.23 0,-0.88 0.13,-1.65 0.38,-2.29 C 29.9,9.26 30.24,8.73 30.68,8.31 31.11,7.9 31.62,7.59 32.2,7.39 32.77,7.22 33.39,7.12 34.05,7.12 Z m 2.12,2.26 C 35.94,9.23 35.67,9.1 35.36,8.99 35.05,8.88 34.7,8.83 34.32,8.83 c -0.41,0 -0.78,0.06 -1.13,0.18 -0.35,0.12 -0.65,0.32 -0.91,0.6 -0.26,0.28 -0.46,0.63 -0.61,1.06 -0.15,0.43 -0.22,0.95 -0.22,1.56 0,1.14 0.27,1.97 0.8,2.48 0.54,0.52 1.25,0.77 2.15,0.77 0.41,0 0.75,-0.04 1.03,-0.13 0.28,-0.09 0.53,-0.19 0.74,-0.32 z" />
          <path d="m 45.81,16.89 c 0.12,-0.12 0.25,-0.24 0.37,-0.37 0.43,-0.49 0.76,-0.9 1.11,-1.57 0.11,-0.2 0.19,-0.39 0.26,-0.57 V 10.55 C 47.55,9.37 47.18,8.5 46.43,7.95 45.68,7.4 44.68,7.13 43.42,7.13 c -0.54,0 -1.06,0.04 -1.55,0.13 -0.49,0.09 -0.92,0.23 -1.3,0.42 -0.24,0.11 -0.42,0.24 -0.54,0.39 -0.12,0.15 -0.17,0.34 -0.17,0.57 0,0.19 0.05,0.36 0.14,0.52 0.09,0.16 0.21,0.29 0.35,0.4 0.39,-0.19 0.84,-0.36 1.34,-0.51 0.5,-0.15 1.02,-0.22 1.57,-0.22 0.69,0 1.23,0.14 1.62,0.41 0.39,0.27 0.58,0.71 0.58,1.3 v 0.45 l -2.83,0.29 c -1.09,0.11 -1.92,0.4 -2.49,0.88 -0.58,0.48 -0.87,1.16 -0.87,2.06 0,1 0.37,1.76 1.11,2.28 0.74,0.52 1.77,0.77 3.08,0.77 0.73,0 1.38,-0.07 1.93,-0.22 0.14,-0.08 0.28,-0.12 0.42,-0.16 z m -2.36,-1.32 c -0.72,0 -1.25,-0.13 -1.59,-0.38 -0.34,-0.25 -0.51,-0.6 -0.51,-1.05 0,-0.43 0.16,-0.76 0.47,-0.97 0.31,-0.21 0.78,-0.35 1.41,-0.42 l 2.22,-0.22 v 2.59 c -0.15,0.11 -0.4,0.21 -0.75,0.31 -0.36,0.09 -0.77,0.14 -1.25,0.14 z" />
          <path d="m 60.73,8.85 c -0.43,0 -0.83,0.07 -1.2,0.21 -0.37,0.14 -0.68,0.36 -0.95,0.64 -0.26,0.29 -0.48,0.63 -0.63,1.05 -0.16,0.41 -0.23,0.89 -0.23,1.44 0,1.07 0.28,1.89 0.83,2.45 0.56,0.56 1.28,0.84 2.18,0.84 0.52,0 0.96,-0.07 1.32,-0.2 0.37,-0.14 0.69,-0.3 0.96,-0.49 0.18,0.11 0.32,0.24 0.43,0.39 0.11,0.15 0.16,0.33 0.16,0.55 0,0.42 -0.27,0.77 -0.82,1.06 -0.55,0.29 -1.27,0.43 -2.17,0.43 -0.75,0 -1.43,-0.1 -2.05,-0.31 -0.62,-0.2 -1.15,-0.52 -1.6,-0.94 -0.45,-0.42 -0.8,-0.95 -1.05,-1.58 -0.25,-0.63 -0.38,-1.37 -0.38,-2.21 0,-0.84 0.13,-1.58 0.4,-2.22 0.26,-0.64 0.62,-1.17 1.08,-1.59 0.45,-0.42 0.99,-0.74 1.6,-0.95 0.61,-0.21 1.26,-0.32 1.93,-0.32 0.45,0 0.85,0.04 1.21,0.12 0.36,0.08 0.67,0.19 0.93,0.34 0.26,0.14 0.46,0.31 0.6,0.49 0.14,0.18 0.21,0.38 0.21,0.58 0,0.19 -0.05,0.36 -0.15,0.52 -0.1,0.16 -0.23,0.27 -0.38,0.36 C 62.67,9.33 62.36,9.18 62.01,9.04 61.67,8.92 61.24,8.85 60.73,8.85 Z" />
          <path d="m 68.53,7.12 c 1.26,0 2.27,0.27 3.01,0.82 0.75,0.55 1.12,1.41 1.12,2.6 v 4.74 c 0,0.31 -0.07,0.55 -0.21,0.72 -0.14,0.17 -0.34,0.33 -0.58,0.48 -0.35,0.2 -0.81,0.38 -1.36,0.53 -0.56,0.15 -1.2,0.22 -1.93,0.22 -1.32,0 -2.34,-0.26 -3.08,-0.77 -0.74,-0.52 -1.11,-1.28 -1.11,-2.28 0,-0.9 0.29,-1.58 0.87,-2.06 0.58,-0.48 1.41,-0.77 2.49,-0.88 l 2.83,-0.29 V 10.5 C 70.58,9.9 70.39,9.47 70,9.2 69.61,8.93 69.07,8.79 68.38,8.79 67.84,8.79 67.31,8.86 66.81,9.01 66.31,9.16 65.86,9.33 65.47,9.52 65.33,9.41 65.22,9.28 65.12,9.12 65.02,8.96 64.98,8.79 64.98,8.6 c 0,-0.23 0.06,-0.42 0.17,-0.57 0.12,-0.15 0.3,-0.28 0.54,-0.39 0.38,-0.19 0.81,-0.33 1.3,-0.42 0.48,-0.06 0.99,-0.1 1.54,-0.1 z m 0.04,8.45 c 0.48,0 0.89,-0.05 1.24,-0.14 0.35,-0.09 0.6,-0.2 0.75,-0.31 v -2.59 l -2.22,0.22 c -0.62,0.07 -1.09,0.21 -1.41,0.42 -0.31,0.21 -0.47,0.53 -0.47,0.97 0,0.45 0.17,0.8 0.51,1.05 0.35,0.25 0.88,0.38 1.6,0.38 z" />
          <path d="M 56.04,16.76 C 55.75,16.43 55.46,16.1 55.2,15.69 54.85,15.14 54.6,14.68 54.36,13.96 54.13,13.28 54.11,12.79 54.08,12.21 L 54.07,11.94 C 54.05,11.63 54.05,11.22 54.04,10.81 54.03,10.4 54.03,9.97 54.01,9.68 53.96,8.94 53.5,8.2 52.88,8.07 52.69,8.03 52.29,8.02 52.04,8.16 51.83,8.28 51.6,8.54 51.38,9.03 c -0.3,0.68 -0.25,1.69 -0.16,2.69 v 0 c 0.1,1.08 0.36,2.01 0.76,2.77 0.41,0.77 0.89,1.41 1.45,1.9 0.33,0.29 0.76,0.51 1.23,0.74 1.51,0.73 1.49,-0.23 1.38,-0.37 z" />
          <circle className="letter" cx="52.66" cy="5.9099998" r="1.48" />
        </g>
        <g style={{ fill: iColor }}>
          <path d="M 50.96,14.49 C 50.77,14.12 50.43,12.98 50.33,11.82 50.23,10.61 50.37,9.38 50.53,9.03 50.74,8.55 50.82,8.38 51.02,8.16 51.07,8.1 50.84,8.06 50.79,8.05 50.05,8 49.48,8.85 49.42,9.67 c -0.02,0.29 -0.03,0.71 -0.03,1.13 -0.01,0.41 -0.01,0.82 -0.03,1.13 l -0.01,0.27 c -0.03,0.57 -0.05,1.06 -0.28,1.75 -0.24,0.72 -0.49,1.18 -0.84,1.73 -0.26,0.41 -0.56,0.75 -0.84,1.07 -0.12,0.13 -0.13,1.09 1.4,0.37 0.47,-0.22 0.9,-0.45 1.23,-0.74 0.45,-0.39 0.85,-0.89 1.2,-1.47 -0.1,-0.13 -0.18,-0.27 -0.26,-0.42 z" />
          <path d="m 50.35,5.98 c 0,-0.6 0.25,-1.15 0.75,-1.4 C 51.13,4.56 51.14,4.51 51.11,4.5 50.96,4.45 50.8,4.42 50.64,4.42 c -0.84,0 -1.52,0.68 -1.52,1.52 0,0.84 0.68,1.52 1.52,1.52 0.16,0 0.31,-0.02 0.45,-0.07 C 51.13,7.38 51.13,7.33 51.1,7.31 50.62,6.99 50.35,6.58 50.35,5.98 Z" />
        </g>
      </g>
    </svg>
  )
}

export default MedaicaLogo

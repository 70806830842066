/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement, useContext } from "react"
import Video from "@medaica/common/components/video"
import { observer } from "mobx-react-lite"
import { VolumeOffIcon } from "@heroicons/react/outline"
import VirtualExamContext from "views/exam/virtual-exam/virtual-exam-context"
import VideoContainer from "@medaica/common/views/exam/virtual-exam/exam-room/components/video-container"
import AudioMutedIcon from "@medaica/common/components/AudioMutedIcon"
import Target from "./target"
import { Box, Tooltip } from "@mui/material"
import { deviceIsM1 } from "@medaica/common/services/m1"
import RemoteParticipantStore from "@medaica/common/views/exam/virtual-exam/stores/av-store/remote-participant-store"
import PatientVirtualExamMediaDeviceStore from "views/exam/virtual-exam/views/exam-room/stores/patient-virtual-exam-media-device-store"
import ParticipantNameLabel from "@medaica/common/views/exam/virtual-exam/exam-room/components/participant-name-label"
import BadNoiseIndicator from "@medaica/common/views/exam/virtual-exam/components/bad-noise-indicator"

const StethoscopeInfo = observer(
  ({ mediaDeviceStore }: { mediaDeviceStore: PatientVirtualExamMediaDeviceStore }): ReactElement => {
    return (
      <div
        className="absolute bottom-2.5 right-2.5 text-white text-sm z-15 bg-black rounded-lg py-1 px-2.5
  bg-opacity-40 flex items-center"
      >
        {mediaDeviceStore.isM1Connected ? (
          <Tooltip title={mediaDeviceStore.m1Info?.label}>
            <div className="flex items-center">
              <div className="h-3 w-3 rounded-full bg-green-400 mr-2" />
              Stethoscope connected
            </div>
          </Tooltip>
        ) : (
          <>
            <div className="h-3 w-3 rounded-full bg-red-400 mr-2" />
            Stethoscope disconnected
          </>
        )}
      </div>
    )
  }
)

const MutedMessage = (): ReactElement => {
  return (
    <div
      className="absolute rounded-3xl text-white z-10 w-1/2 top-1/2 left-1/2 transform -translate-x-1/2
    -translate-y-1/2 flex flex-col justify-center bg-black bg-opacity-60 p-3 rounded-xl"
    >
      <VolumeOffIcon className="opacity-40" />
      <div className="text-xl text-center opacity-60 px-2">
        You have been muted while your healthcare provider reviews the recording.
      </div>
    </div>
  )
}

const MutedIndicator = () => {
  return (
    <Box
      sx={(theme) => ({
        position: "absolute",
        top: theme.spacing(0.5),
        left: theme.spacing(0.5),
        zIndex: 10,
      })}
    >
      <AudioMutedIcon />
    </Box>
  )
}

const RemoteParticipantVideo = observer(
  ({ remoteParticipant }: { remoteParticipant: RemoteParticipantStore }): ReactElement => {
    const { virtualExamStore } = useContext(VirtualExamContext)
    return (
      <Box
        sx={(theme) => ({
          position: "absolute",
          top: theme.spacing(1),
          right: theme.spacing(1),
          zIndex: 10,
          width: "160px",
          height: "200px",
          backgroundColor: "#F0F0FB",
        })}
        className="panel"
      >
        <Video
          videoTrack={remoteParticipant.videoTrack}
          audioTrack={remoteParticipant.audioTrack}
          isLocal={false}
          isMuted={remoteParticipant.videoIsMuted}
          overlay={
            <>
              {((remoteParticipant.auscultationAudioTrack && remoteParticipant.auscultationAudioIsMuted) ||
                (!remoteParticipant.auscultationAudioTrack && remoteParticipant.audioIsMuted)) && <MutedIndicator />}
              <ParticipantNameLabel name={virtualExamStore.healthcareProvider.displayName} />
            </>
          }
        />
      </Box>
    )
  }
)

const VideoSet = observer((): ReactElement => {
  const { avStore, virtualExamStore, mediaDeviceStore } = useContext(VirtualExamContext)

  return (
    <VideoContainer>
      {virtualExamStore.isReviewingAuscultation && <MutedMessage />}
      {avStore.remoteParticipant && <RemoteParticipantVideo remoteParticipant={avStore.remoteParticipant} />}
      <Video
        videoTrack={mediaDeviceStore.videoDevice?.track}
        audioTrack={mediaDeviceStore.audioDevice?.track}
        isLocal={true}
        isMuted={mediaDeviceStore.videoDeviceIsMuted}
      />
      {virtualExamStore.badNoiseDetector && deviceIsM1(mediaDeviceStore.audioDevice) && (
        <BadNoiseIndicator
          isVisible={virtualExamStore.badNoiseDetector.isBadNoiseDetected}
          isMicrophoneMuted={mediaDeviceStore.audioDeviceIsMuted}
        />
      )}
      <StethoscopeInfo mediaDeviceStore={mediaDeviceStore} />
      {virtualExamStore.isTargetEnabled && <Target />}
    </VideoContainer>
  )
})

export default VideoSet

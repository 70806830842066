/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement, useContext, useRef, useState } from "react"
import SelfInitiatedSelfExamContext from "views/exam/self-initiated-self-exam/self-initiated-self-exam-context"
import AudioPlayer from "@medaica/common/components/audio-player"
import moment from "moment"
import { Auscultation } from "@medaica/common/types"
import AuscultationPointImage from "@medaica/common/components/images/auscultation-point"
import useGlobalContext from "@medaica/common/hooks/global-context"
import { Button, Dialog, DialogActions, DialogContent } from "@mui/material"
import { buildAudioFileFromAuscultation } from "@medaica/common/services/factories"
import { WAFFileContext } from "@medaica/common/components/audio-player/web-audio-filters"

const AuscultationRow = ({ auscultation }: { auscultation: Auscultation }): ReactElement => {
  const { selfInitiatedSelfExamStore } = useContext(SelfInitiatedSelfExamContext)
  const { medaicaApiService } = useGlobalContext()
  const audioFileRef = useRef(buildAudioFileFromAuscultation(auscultation, medaicaApiService))
  const [confirmDialogIsOpen, setConfirmDialogIsOpen] = useState<boolean>(false)

  const handleDeleteButtonClicked = () => {
    setConfirmDialogIsOpen(true)
  }

  const handleDeleteConfirmedButtonClicked = async () => {
    setConfirmDialogIsOpen(false)
    await selfInitiatedSelfExamStore.deleteAuscultation(auscultation.id)
  }

  return (
    <>
      <Dialog open={confirmDialogIsOpen} onClose={() => setConfirmDialogIsOpen(false)}>
        <DialogContent>Are you sure you want to delete this recording?</DialogContent>
        <DialogActions>
          <Button color="inherit" onClick={() => setConfirmDialogIsOpen(false)}>
            Cancel
          </Button>
          <Button color="error" onClick={() => void handleDeleteConfirmedButtonClicked()}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <tr key={auscultation.id}>
        <td className="px-6 py-4 whitespace-nowrap text-gray-900">
          <AuscultationPointImage style={{ width: "62px" }} highlightedPoint={auscultation.auscultationPoint} />
        </td>
        <td className="px-6 py-4 whitespace-nowrap text-gray-500">{moment(auscultation.dateCreated).format("lll")}</td>
        <td className="px-6 py-4 whitespace-nowrap text-gray-500">
          <AudioPlayer
            showVolumeControl={false}
            audioFile={audioFileRef.current}
            filterContext={new WAFFileContext()}
          />
        </td>
        <td className="px-6 py-4 whitespace-nowrap text-right font-medium">
          <Button onClick={handleDeleteButtonClicked}>Delete</Button>
        </td>
      </tr>
    </>
  )
}

const AuscultationTable = ({
  auscultations,
  caption = "Auscultations",
}: {
  auscultations: Auscultation[]
  caption?: string
}): ReactElement => {
  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <caption className="bg-gray-50 px-6 py-3 text-left text-table-caption">{caption}</caption>
              <tbody className="bg-white divide-y divide-gray-200">
                {auscultations.map((auscultation) => (
                  <AuscultationRow key={auscultation.id} auscultation={auscultation} />
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AuscultationTable

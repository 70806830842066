/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement, useEffect } from "react"
import M1MediaDeviceStore from "@medaica/common/services/m1-media-device-store"
import { Alert, AlertTitle } from "@mui/material"
import { observer } from "mobx-react-lite"
import { logError } from "@medaica/common/services/util"

const StethoscopeDetector = observer(
  ({ mediaDeviceManager }: { mediaDeviceManager: M1MediaDeviceStore }): ReactElement => {
    useEffect(() => {
      const connectStethoscope = async () => {
        await mediaDeviceManager.start()
        if (mediaDeviceManager.isM1Available) {
          await mediaDeviceManager.connectToM1()
        }
      }
      connectStethoscope().catch((error) => logError(error))
    }, [mediaDeviceManager])

    return mediaDeviceManager.m1 ? (
      <></>
    ) : (
      <div className="w-full">
        <Alert
          severity="warning"
          sx={{
            "& .MuiAlert-message": {
              width: "100%",
            },
          }}
        >
          <AlertTitle>Stethoscope Not Detected</AlertTitle>

          <div className="text-base">
            <p>The Medaica M1 Stethoscope could not be detected. Please ensure that the stethoscope is plugged in.</p>
          </div>
        </Alert>
      </div>
    )
  }
)

export default StethoscopeDetector

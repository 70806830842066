/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import SelfInitiatedSelfExamStore from "views/exam/self-initiated-self-exam/stores/self-initiated-self-exam-store"
import { createContext } from "react"
import M1MediaDeviceStore from "@medaica/common/services/m1-media-device-store"

type SelfInitiatedSelfExamContextInterface = {
  selfInitiatedSelfExamStore: SelfInitiatedSelfExamStore
  mediaDeviceManager: M1MediaDeviceStore
}
const SelfInitiatedSelfExamContext = createContext<SelfInitiatedSelfExamContextInterface>(
  null as unknown as SelfInitiatedSelfExamContextInterface
)

export default SelfInitiatedSelfExamContext
export type { SelfInitiatedSelfExamContextInterface }

/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import firebase from "firebase"
import { Participant } from "views/exam/virtual-exam/virtual-exam-store"
import { Role } from "const"
import logger from "@medaica/common/services/logging"
import { logError } from "@medaica/common/services/util"

type ParticipantLogItem = {
  id: string
  name: string
  role: Role
  loggedIn: string
  loggedOut: string
}

enum ExamEntryRequestStatus {
  pending = "pending",
  approved = "approved",
  cancelled = "cancelled",
  denied = "denied",
}

type ExamEntryRequest = {
  name: string
  id: string
  status: ExamEntryRequestStatus
}

class FirebaseVirtualExamService {
  private readonly _virtualExamId: string
  private _participantRef: firebase.database.Reference | null = null

  constructor(virtualExamId: string) {
    this._virtualExamId = virtualExamId
  }

  get virtualExamIdRef(): firebase.database.Reference {
    return firebase.database().ref(`virtualExams/${this._virtualExamId}`)
  }

  get participantLogRef(): firebase.database.Reference {
    return this.virtualExamIdRef.child("participantLog")
  }

  get isM1ConnectedRef(): firebase.database.Reference {
    return this.virtualExamIdRef.child("isM1Connected")
  }

  get isReviewingAuscultationRef(): firebase.database.Reference {
    return this.virtualExamIdRef.child("isReviewingAuscultation")
  }

  get avRoomIdRef(): firebase.database.Reference {
    return this.virtualExamIdRef.child("avRoomId")
  }

  get examEntryRequestRef(): firebase.database.Reference {
    return this.virtualExamIdRef.child("examEntryRequest")
  }

  get auscultationRequestsRef(): firebase.database.Reference {
    return this.virtualExamIdRef.child("auscultationRequests")
  }

  get auscultationMicActivationRequestRef(): firebase.database.Reference {
    return this.virtualExamIdRef.child("auscultationMicActivationRequest")
  }

  get auscultationMicDeactivationRequestRef(): firebase.database.Reference {
    return this.virtualExamIdRef.child("auscultationMicDeactivationRequest")
  }

  get isTargetEnabledRef(): firebase.database.Reference {
    return this.virtualExamIdRef.child("isTargetEnabled")
  }

  get connectedRef(): firebase.database.Reference {
    return firebase.database().ref(".info/connected")
  }

  registerPresence(user: Participant): void {
    this.connectedRef.on("value", (snap) => {
      if (snap.val() === true) {
        this._participantRef = this.participantLogRef.push()
        void this._participantRef.set({
          id: user.id,
          name: user.name,
          role: user.role,
          loggedIn: new Date().toISOString(),
        })
        void this._participantRef.child("loggedOut").onDisconnect().set(new Date().toISOString())
      }
    })
  }

  deregisterPresence(): void {
    this._participantRef?.child("loggedOut").set(new Date().toISOString()).catch(logError)
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  dispose(): void {
    logger.debug("Disposing firebase virtual exam service")
    this.virtualExamIdRef.off()
    this.participantLogRef.off()
    this.isM1ConnectedRef.off()
    this.isReviewingAuscultationRef.off()
    this.avRoomIdRef.off()
    this.examEntryRequestRef.off()
    this.auscultationRequestsRef.off()
    this.connectedRef.off()
    this.auscultationMicActivationRequestRef.off()
    this.auscultationMicDeactivationRequestRef.off()
    this.isTargetEnabledRef.off()
  }
}

export default FirebaseVirtualExamService
export { ExamEntryRequestStatus }
export type { ParticipantLogItem, ExamEntryRequest }

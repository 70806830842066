/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import NoiseFilter from "./../noise-filter"
import { makeObservable, observable, runInAction } from "mobx"

class BadNoiseDetector {
  isRunning = false
  isBadNoiseDetected = false

  constructor(private readonly noiseFilter: NoiseFilter, private readonly checksPerSecond = 20) {
    makeObservable(this, {
      isRunning: observable,
      isBadNoiseDetected: observable,
    })
  }

  private startDetecting(): void {
    this.isRunning = true
    const checkIntervalInMilliseconds = 1000 / this.checksPerSecond
    let lastCheckTime = window.performance.now()

    const detect = () => {
      if (!this.isRunning) {
        return
      }

      requestAnimationFrame(detect)

      const currentTime = window.performance.now()
      const timeSinceLastCheck = currentTime - lastCheckTime

      if (timeSinceLastCheck > checkIntervalInMilliseconds) {
        lastCheckTime = currentTime - (timeSinceLastCheck % checkIntervalInMilliseconds)

        runInAction(() => {
          this.isBadNoiseDetected = this.noiseFilter.isBadNoise()
        })
      }
    }
    requestAnimationFrame(detect)
  }

  start(stream: MediaStream): void {
    if (this.isRunning) {
      throw new Error("badNoiseDetector is already running")
    }
    this.noiseFilter.connect(stream)
    this.startDetecting()
  }

  stop(): void {
    this.isRunning = false
    this.noiseFilter.disconnect()
  }
}

export default BadNoiseDetector

/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import { makeObservable, observable, runInAction } from "mobx"

class Timer {
  private readonly tick: number
  private interval: NodeJS.Timeout
  readonly duration: number
  elapsedTime = 0
  completed = false

  constructor(durationMs: number, tickMs: number) {
    makeObservable(this, {
      elapsedTime: observable,
      completed: observable,
    })
    this.duration = durationMs
    this.tick = tickMs
  }

  start(): void {
    this.interval = setInterval(() => {
      runInAction(() => {
        this.elapsedTime += this.tick
        if (this.elapsedTime >= this.duration) {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          clearInterval(this.interval)
          this.completed = true
        }
      })
    }, this.tick)
  }

  reset(): void {
    clearInterval(this.interval)
    runInAction(() => {
      this.elapsedTime = 0
      this.completed = false
    })
  }

  dispose(): void {
    clearInterval(this.interval)
  }
}

export default Timer

/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { HTMLProps, ReactElement, ReactNode } from "react"
import MedaicaLogo from "@medaica/common/components/medaica-logo"

const Layout = ({
  main,
  overlays,
  footer,
  ...props
}: {
  main: ReactNode
  overlays?: ReactNode
  footer?: ReactNode
} & HTMLProps<HTMLDivElement>): ReactElement => {
  return (
    <div className="inline-block h-screen v-screen bg-gray-100 flex flex-col" {...props}>
      <div className="mx-auto h-screen flex flex-col">
        <div className="my-5 flex">
          <MedaicaLogo className="text-primary-700 h-7" />
        </div>
        {overlays}
        <div className="flex">{main}</div>
        {footer && <div className="p-2 mt-2">{footer}</div>}
      </div>
    </div>
  )
}

export default Layout

/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import axios from "axios"
import { Event as SentryEvent, EventHint } from "@sentry/types"
import { captureException } from "@sentry/react"
import logger from "./logging"

const getAge = (birthDateIn: Date): string => {
  const birthDate = new Date(birthDateIn)
  const today = new Date()
  const monthDifference = (today.getFullYear() - birthDate.getFullYear()) * 12 + today.getMonth() - birthDate.getMonth()

  if (monthDifference === 1) {
    return "1 month old"
  } else if (monthDifference <= 24) {
    return `${monthDifference} months old`
  } else {
    const years = Math.floor(monthDifference / 12)
    return `${years} years old`
  }
}

const toMMSS = (duration: number | null | undefined): string => {
  //const sec_num = parseInt(duration, 10) // don't forget the second param
  if (duration == null) {
    duration = 0
  }
  const hours = Math.floor(duration / 3600)
  const minutes = Math.floor((duration - hours * 3600) / 60)
  const seconds = Math.round(duration - hours * 3600 - minutes * 60)

  let minutesStr = minutes.toString()
  if (minutes < 10) {
    minutesStr = "0" + minutesStr
  }

  let secondsStr = seconds.toString()
  if (seconds < 10) {
    secondsStr = "0" + secondsStr
  }

  return minutesStr + ":" + secondsStr
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/no-explicit-any
const logError = (exception: any, captureContext?: any, message?: string) => {
  if (exception) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    captureException(exception, captureContext)
    logger.error(message, exception, captureContext)
  }
}

const sentryBeforeSend = (event: SentryEvent, hint: EventHint | undefined): SentryEvent => {
  // We add some info to the error that gets logged to Sentry if it's an Axios error
  if (hint && hint.originalException && event.extra) {
    if (axios.isAxiosError(hint.originalException)) {
      event.extra.config = hint.originalException.config
      event.extra.request = hint.originalException.request
      event.extra.response = hint.originalException.response
    }
  }
  return event
}

const classNames = (...classes: Array<string | undefined>): string => {
  return classes.filter(Boolean).join(" ")
}

const sleep = (delay: number): Promise<void> => new Promise((r) => setTimeout(r, delay))

const putOnClipboard = async (value: string): Promise<void> => {
  try {
    await navigator.clipboard.writeText(value)
  } catch (err) {
    logError(err)
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
const mapReplacer = (key: any, value: any) => {
  if (value instanceof Map) {
    return {
      dataType: "Map",
      value: Array.from(value.entries()),
    }
  } else {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return value
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
const mapReviver = (key: any, value: any) => {
  if (typeof value === "object" && value !== null) {
    if (value.dataType === "Map") {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      return new Map(value.value)
    }
  }
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return value
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const dateTimeReviver = (key: any, value: any) => {
  if (typeof value == "string" && isIsoDateString(value)) {
    return new Date(value)
  }

  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return value
}

const moveCaretToEnd = (el: HTMLTextAreaElement): void => {
  const value = el.value
  el.value = ""
  el.value = value
}

const isoDateFormat = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*)?)((-(\d{2}):(\d{2})|Z)?)$/

function isIsoDateString(value: string): boolean {
  return value !== null && isoDateFormat.test(value)
}

const openInNewTab = (url: string): void => {
  const newWindow = window.open(url, "_blank", "noopener,noreferrer")
  if (newWindow) newWindow.opener = null
}

const getConfig = (value: string): string => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return process.env["REACT_APP_" + value]!
}

const getConfigNumber = (value: string): number => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return Number(process.env["REACT_APP_" + value]!)
}

const getConfigBool = (value: string): boolean => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const strValue = getConfig(value)
  switch (strValue) {
    case "true":
      return true
    case "false":
      return false
    default:
      return !!strValue
  }
}

const getCurrentUrlFullPath = () => {
  return `${window.location.pathname}${window.location.hash}${window.location.search}`
}

const mapExamType = (type: string): string => {
  if (type === "Live") {
    return "Virtual"
  } else if (type === "In Person") {
    return "In-Person"
  } else {
    return type
  }
}

export {
  toMMSS,
  sentryBeforeSend,
  classNames,
  sleep,
  putOnClipboard,
  mapReplacer,
  mapReviver,
  dateTimeReviver,
  getAge,
  moveCaretToEnd,
  logError,
  openInNewTab,
  getConfig,
  getConfigNumber,
  getConfigBool,
  getCurrentUrlFullPath,
  isIsoDateString,
  mapExamType,
}

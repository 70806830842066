/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement, ReactNode } from "react"

const ActionPanelActions = ({ children }: { children: ReactNode }): ReactElement => {
  return (
    <div className="mt-3 w-full">
      <div className="flex flex-col items-center">
        <div className="flex mt-8 justify-center gap-x-2 flex-row-reverse">{children}</div>
      </div>
    </div>
  )
}

export default ActionPanelActions

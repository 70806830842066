/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement } from "react"
import ContentMiddle from "@medaica/common/layouts/minimal-layout/content-middle"
import MinimalLayout from "@medaica/common/layouts/minimal-layout"
import Header from "@medaica/common/components/page/page-header"

const ExamCompleteView = (): ReactElement => {
  return (
    <MinimalLayout>
      <ContentMiddle>
        <Header title="This exam has been completed" />
      </ContentMiddle>
    </MinimalLayout>
  )
}

export default ExamCompleteView

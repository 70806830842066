/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

/* eslint-disable max-len */
import React, { ReactElement } from "react"

export const SmallError = (): ReactElement => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.2472 2.51662L17.4834 8.75277C18.1722 9.4416 18.1722 10.5584 17.4834 11.2472L11.2472 17.4834C10.5584 18.1722 9.4416 18.1722 8.75277 17.4834L2.51662 11.2472C1.82779 10.5584 1.82779 9.4416 2.51662 8.75277L8.75277 2.51662C9.4416 1.82779 10.5584 1.82779 11.2472 2.51662ZM11 13C11 13.5523 10.5523 14 10 14C9.44772 14 9 13.5523 9 13C9 12.4477 9.44772 12 10 12C10.5523 12 11 12.4477 11 13ZM9.98975 7H10.0101C10.621 7.0074 11.0826 7.59236 10.9876 8.24396L10.5599 10.4817C10.5192 10.7779 10.2816 11 10.0033 11H9.99653C9.71823 11 9.48066 10.7779 9.43993 10.4817L9.01229 8.24396C8.91726 7.59236 9.38562 7 9.98975 7Z"
      fill="#D61F1F"
    />
  </svg>
)

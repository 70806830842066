/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactNode } from "react"
import { Navigate, Outlet, RouteObject } from "react-router-dom"
import { withLayout } from "@medaica/common/services/routing"

// Layouts
import DashboardLayout from "@medaica/common/layouts/dashboard-layout"

// Misc
import SupportView from "@medaica/common/views/misc/support"
import PrivacyView from "@medaica/common/views/misc/privacy"
import ContactView from "@medaica/common/views/misc/contact"
import TermsView from "@medaica/common/views/misc/terms"
import HipaaView from "@medaica/common/views/misc/hipaa"

// Exams
import LiveExam from "views/exam/virtual-exam"
import RequestedSelfExam from "views/exam/requested-self-exam"
import SelfInitiatedSelfExam from "views/exam/self-initiated-self-exam"
import ExamListView from "views/exam/exam-list"
import ExamView from "views/exam/exam-details"
import LiveExamApp from "views/exam/virtual-exam-app-download"

// Profile
import StethoscopeView from "views/profile/stethoscope"

import ErrorBoundaryTests from "views/error-boundary-tests"
import { footerNavigation, Paths } from "const"

import StethoscopeIcon from "@medaica/common/components/icons/stethoscope-icon"
import LoggedOutView from "@medaica/common/views/auth/logged-out"
import LogOutView from "@medaica/common/views/auth/log-out"

import HomeIcon from "@medaica/common/components/icons/home-icon"
import CompatibilityTestView from "@medaica/common/views/compatibility-test"
import MuiTest from "muiTest"
import MinimalLayout from "@medaica/common/layouts/minimal-layout"
import PostAuthPipeline from "views/auth/post-auth-pipeline"

import useAuth from "services/auth"
import NotFoundView from "@medaica/common/views/errors/not-found"
import AuthCallback from "views/auth/auth-callback"
import { Auth } from "@medaica/common/services/auth"
import Holding from "views/holding"

/** This protect is temporary to prevent users from logging into the patient portal while we are not using it. */
const Protect = ({ useAuth, children }: { useAuth: Auth; children: ReactNode }) => {
  return (
    <MinimalLayout footerNavigation={footerNavigation}>
      <NotFoundView />
    </MinimalLayout>
  )
}

const baseProtect = (useAuth: Auth, ...children: RouteObject[]): RouteObject[] => {
  return children.map((child) => ({
    ...child,
    element: <Protect useAuth={useAuth}>{child.element}</Protect>,
  }))
}

const protect = (...children: RouteObject[]) => baseProtect(useAuth, ...children)

const userNavigation = [
  { name: "Your Profile", href: "#" },
  { name: "Stethoscope", href: Paths.profile_stethoscope },
  { name: "Sign out", href: Paths.logOut },
]

const dashboardLayout = (
  <DashboardLayout
    primaryNavigation={[
      {
        name: "Dashboard",
        href: Paths.home,
        icon: HomeIcon,
      },
      {
        name: "Exams",
        href: Paths.exams,
        icon: StethoscopeIcon,
      },
    ]}
    userNavigation={userNavigation}
    footerNavigation={footerNavigation}
  />
)

const protectedRoutes = [
  ...protect(
    withLayout(
      dashboardLayout,
      "/",
      {
        path: "/home",
        element: <Navigate to={Paths.home} replace={true} />,
      },
      {
        path: Paths.newSelfExam,
        element: <SelfInitiatedSelfExam />,
      },
      {
        path: `${Paths.exams}/:examId`,
        element: <ExamView />,
      },
      {
        path: `${Paths.exams}`,
        element: <ExamListView />,
      }
    ),
    withLayout(
      <MinimalLayout useHomeLink={false}>
        <Outlet />
      </MinimalLayout>,
      "/",
      {
        path: Paths.postAuthPipeline,
        element: <PostAuthPipeline />,
      }
    )
  ),
]

const unprotectedExamRoutes = [
  {
    path: Paths.profile_stethoscope,
    element: <StethoscopeView />,
  },
  {
    path: `${Paths.liveExam}/*`,
    element: <LiveExam />,
  },
  {
    path: "/liveexam",
    element: <Navigate to={Paths.liveExam} replace={true} />,
  },
  {
    path: Paths.liveExamApp,
    element: <LiveExamApp />,
  },
  {
    path: `${Paths.selfExam}/:selfExamRequestId/*`,
    element: <RequestedSelfExam />,
  },
]

const unprotectedRoutes = [
  withLayout(
    <MinimalLayout footerNavigation={footerNavigation}>
      <Outlet />
    </MinimalLayout>,
    "/",
    {
      path: Paths.home,
      element: <Holding />,
    },
    {
      path: Paths.loggedOut,
      element: <LoggedOutView useAuth={useAuth} />,
    },
    {
      path: Paths.support,
      element: <SupportView />,
    },
    {
      path: Paths.privacy,
      element: <PrivacyView />,
    },
    {
      path: Paths.contact,
      element: <ContactView />,
    },
    {
      path: Paths.terms,
      element: <TermsView />,
    },
    {
      path: Paths.hipaa,
      element: <HipaaView />,
    }
  ),
  {
    path: Paths.authCallback,
    element: <AuthCallback />,
  },
  {
    path: Paths.logOut,
    element: <LogOutView useAuth={useAuth} />,
  },
  {
    path: Paths.liveExamCompatibilityTest,
    element: <CompatibilityTestView />,
  },
  {
    path: "/error-boundary-tests",
    element: <ErrorBoundaryTests />,
  },
  {
    path: "mui-test",
    element: <MuiTest />,
  },
  ...unprotectedExamRoutes,
]

const routes = [
  ...unprotectedRoutes,
  ...protectedRoutes,
  {
    path: "*",
    element: (
      <MinimalLayout footerNavigation={footerNavigation}>
        <NotFoundView />
      </MinimalLayout>
    ),
  },
]

export default routes

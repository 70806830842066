/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement, useState } from "react"
import { Box, Container, Grid, Typography } from "@mui/material"
import { ConnectionFailed } from "./ConnectionFailed/ConnectionFailed"
import { ConnectionModal } from "./ConnectionModal/ConnectionModal"
import { ConnectionSuccess } from "./ConnectionSuccess/ConnectionSuccess"
import { Loading } from "../../../icons/Loading"
import { useAppStateContext } from "../../AppStateProvider/AppStateProvider"

export const Connectivity = (): ReactElement => {
  const { state } = useAppStateContext()
  const [isModalOpen, setIsModalOpen] = useState(false)

  const signalingGateway = state.preflightTest.signalingGatewayReachable ? "Reachable" : "Unreachable"
  const turnServers = state.preflightTest.turnServersReachable ? "Reachable" : "Unreachable"

  const connectionFailed =
    state.preflightTestFinished &&
    (state.preflightTest.error !== null ||
      !state.preflightTest.signalingGatewayReachable ||
      !state.preflightTest.turnServersReachable)

  return (
    <>
      <ConnectionModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        serviceStatuses={state.twilioStatus}
        signalingGateway={signalingGateway}
        turnServers={turnServers}
      />
      {
        /* If preflight test or bitrate test haven't completed,
        display loading screen otherwise, display connectivity results: */
        state.preflightTestInProgress || state.bitrateTestInProgress ? (
          <Container>
            <Grid
              container
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography component="h1" variant="h3" gutterBottom>
                Hang tight!
              </Typography>
              <Typography
                variant="body1"
                gutterBottom
                sx={{
                  width: "340px",
                  height: "48px",
                  textAlign: "center",
                }}
              >
                We're just finishing up your personalized results and creating your report.
              </Typography>
              <Box
                sx={{
                  animation: "spin 1.5s linear infinite",
                  display: "flex",
                  "@keyframes spin": {
                    "0%": { transform: "rotate(0deg)" },
                    "100%": { transform: "rotate(360deg)" },
                  },
                }}
              >
                <Loading />
              </Box>
            </Grid>
          </Container>
        ) : connectionFailed ? (
          <ConnectionFailed openModal={() => setIsModalOpen(true)} />
        ) : (
          <ConnectionSuccess openModal={() => setIsModalOpen(true)} />
        )
      }
    </>
  )
}

/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import { useState, useEffect } from "react"

export default function useDevices(): {
  audioInputDevices: Array<MediaDeviceInfo>
  videoInputDevices: Array<MediaDeviceInfo>
  audioOutputDevices: Array<MediaDeviceInfo>
} {
  const [devices, setDevices] = useState<MediaDeviceInfo[]>([])

  useEffect(() => {
    const getDevices = () => navigator.mediaDevices.enumerateDevices().then((newDevices) => setDevices(newDevices))
    // create a separate event listener callback function, so we can remove it later
    const handleDeviceChange = () => void getDevices()
    navigator.mediaDevices.addEventListener("devicechange", handleDeviceChange)
    void getDevices()

    return () => {
      navigator.mediaDevices.removeEventListener("devicechange", handleDeviceChange)
    }
  }, [])

  return {
    audioInputDevices: devices.filter((device) => device.kind === "audioinput"),
    videoInputDevices: devices.filter((device) => device.kind === "videoinput"),
    audioOutputDevices: devices.filter((device) => device.kind === "audiooutput"),
  }
}

/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement, useState } from "react"
import { Formik, Form } from "formik"
import * as Yup from "yup"
import TextInput from "@medaica/common/components/forms/text-input"
import useGlobalContext from "@medaica/common/hooks/global-context"
import { getConfigNumber, logError } from "@medaica/common/services/util"
import Header from "@medaica/common/components/page/page-header"
import { Alert, LoadingButton } from "@mui/lab"
import { Paths } from "const"
import Checkbox from "@medaica/common/components/forms/checkbox"
import { Link } from "react-router-dom"
import useAuth from "services/auth"
import { useSnackbar } from "notistack"

const OnboardForm = ({ onComplete }: { onComplete: () => void }): ReactElement => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const { medaicaApiService } = useGlobalContext()
  const { user } = useAuth()
  const { enqueueSnackbar } = useSnackbar()

  return (
    <div className="max-w-3xl">
      <Formik
        initialValues={{
          firstName: "",
          lastName: "",
          termsAndConditions: false,
          useOfData: false,
        }}
        validationSchema={Yup.object({
          firstName: Yup.string()
            .max(100, "First name cannot be more than 100 characters.")
            .required("First name is required."),
          lastName: Yup.string()
            .max(100, "Last name cannot be more than 100 characters.")
            .required("Last name is required."),
          termsAndConditions: Yup.boolean().oneOf([true], "You must agree to the terms and conditions."),
          useOfData: Yup.boolean().oneOf([true], "You must agree to the use of data terms."),
        })}
        onSubmit={async (values, { setSubmitting }) => {
          setErrorMessage(null)
          try {
            await medaicaApiService.patients.createPatient(
              user.id,
              user.auth0UserId,
              user.email,
              values.firstName,
              values.lastName,
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              getConfigNumber("TC_VERSION")
            )
            enqueueSnackbar("Your user profile has been updated.", {
              variant: "success",
            })
            onComplete()
          } catch (error) {
            setErrorMessage("There was an error submitting your information. Please try again later.")
            logError(error)
          } finally {
            setSubmitting(false)
          }
        }}
      >
        {({ errors, handleBlur, handleChange, isSubmitting, touched }) => (
          <Form>
            <Header
              title="Onboarding"
              summary="Before continuing to the Medaica Patient Portal, please complete the onboarding form."
            />
            <div className="space-y-8">
              <div>
                {!!errorMessage && (
                  <Alert sx={{ mt: 3 }} severity="error">
                    {errorMessage}
                  </Alert>
                )}
                <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                  <div className="sm:col-span-3">
                    <TextInput
                      label="First name"
                      name="firstName"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      autoComplete="given-name"
                      type="text"
                      errors={errors}
                      touched={touched}
                    />
                  </div>

                  <div className="sm:col-span-3">
                    <TextInput
                      label="Last name"
                      name="lastName"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      autoComplete="given-name"
                      type="text"
                      errors={errors}
                      touched={touched}
                    />
                  </div>
                </div>
              </div>

              <div className="mt-6">
                <fieldset>
                  <div className="mt-4 space-y-4">
                    <Checkbox
                      name="termsAndConditions"
                      label="Terms and Conditions"
                      description={
                        <>
                          I agree with the{" "}
                          <Link to={Paths.terms} className="link" target="_blank" rel="noopener noreferrer">
                            terms and conditions.
                          </Link>
                        </>
                      }
                      touched={touched}
                      errors={errors}
                    />

                    <Checkbox
                      name="useOfData"
                      label="Permissions and Use of Data"
                      description={
                        <>
                          I agree to comply with{" "}
                          <Link to={Paths.privacy} className="link" target="_blank" rel="noopener noreferrer">
                            specific requirements.
                          </Link>
                        </>
                      }
                      touched={touched}
                      errors={errors}
                    />
                  </div>
                </fieldset>
              </div>
            </div>

            <div className="flex justify-end mt-6 border-t pt-4">
              <LoadingButton loading={isSubmitting} type="submit" variant="contained">
                Continue
              </LoadingButton>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default OnboardForm

/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement, useContext, useEffect } from "react"
import RequestedSelfExamContext from "views/exam/requested-self-exam/requested-self-exam-context"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import { Alert, AlertTitle, Button } from "@mui/material"
import { observer } from "mobx-react-lite"
import { logError } from "@medaica/common/services/util"

const GettingStarted = observer((): ReactElement => {
  const { requestedSelfExamStore, mediaDeviceManager } = useContext(RequestedSelfExamContext)

  useEffect(() => {
    mediaDeviceManager.start().catch(logError)
  }, [mediaDeviceManager])

  useEffect(() => {
    if (mediaDeviceManager.isM1Available) {
      mediaDeviceManager.connectToM1().catch(logError)
    }
  }, [mediaDeviceManager, mediaDeviceManager.isM1Available])

  const nextButtonClicked = () => {
    requestedSelfExamStore.goToNextStep()
  }

  return (
    <div>
      <h2 className="page-header">Welcome to Your Self Exam</h2>
      <div className="mt-10">
        {requestedSelfExamStore.selfExamRequest.instructions && (
          <div className="panel mb-4 p-3">
            <h3 className="font-semibold text-lg text-gray-800">
              Notes from {requestedSelfExamStore.healthCareProvider.displayName}
            </h3>
            <p className="mt-2">{requestedSelfExamStore.selfExamRequest.instructions}</p>
          </div>
        )}
        <p>[Getting started instructions]</p>

        <div className="mt-4 flex justify-center w-full">
          {mediaDeviceManager.m1 ? (
            <Button variant="contained" endIcon={<ChevronRightIcon />} onClick={nextButtonClicked}>
              Let's Get Started
            </Button>
          ) : (
            <Alert
              severity="warning"
              sx={{
                "& .MuiAlert-message": {
                  width: "100%",
                },
              }}
            >
              <AlertTitle>Stethoscope Not Detected</AlertTitle>

              <div className="text-base">
                <p>
                  Your Medaica M1 Stethoscope could not be detected. Please ensure that your stethoscope is plugged in
                  and click <strong>Detect Stethoscope</strong>.
                </p>
              </div>
            </Alert>
          )}
        </div>
      </div>
    </div>
  )
})

export default GettingStarted

/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import { useAppStateContext } from "../../AppStateProvider/AppStateProvider"
import { Box, Button, Container, Grid, Typography } from "@mui/material"
import { ErrorIcon } from "../../../icons/ErrorIcon"
import Hello from "./Hello.png"
import React, { ReactElement } from "react"

export const GetStarted = (): ReactElement => {
  const { nextPane, state } = useAppStateContext()

  return (
    <Container>
      <Grid container alignItems="center" sx={{ justifyContent: "space-between" }}>
        <Grid item lg={5}>
          {state.appIsExpired ? (
            <>
              <Typography component="h1" variant="h3" gutterBottom>
                <ErrorIcon />
                App has expired.
              </Typography>

              <Typography variant="body1" gutterBottom>
                Please re-deploy the application and try again.
              </Typography>
            </>
          ) : (
            <>
              <Typography component="h1" variant="h3" gutterBottom>
                Let's get started.
              </Typography>

              <Typography variant="body1" gutterBottom>
                We're going to check whether your system is compatible with the Medaica virtual exam. We'll help you
                solve any incompatibilities that we find. First off, let's check if you've got a compatible browser.
              </Typography>
            </>
          )}

          <Box mt={3}>
            <Button
              variant="contained"
              color="primary"
              onClick={nextPane}
              style={{ marginBottom: "1em" }}
              disabled={state.appIsExpired}
            >
              Get started
            </Button>
          </Box>
        </Grid>

        <Grid item lg={5}>
          <img src={Hello} alt="Hello" style={{ width: "284px", height: "284px" }} />
          <Typography variant="body1" color="textSecondary" gutterBottom>
            <strong>Not sure about something?</strong> Skip that section for now, and your support administrator can
            help later.
          </Typography>
        </Grid>
      </Grid>
    </Container>
  )
}

/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement, ReactNode } from "react"
import { SpinnerCircular } from "spinners-react"
import MedaicaLogoAbbr from "@medaica/common/components/medaica-logo-abbr"
import useTailwindConfig from "@medaica/common/hooks/use-tailwind-config"

const LoadingWrapper = ({
  loading,
  minHeight = "84px",
  children,
}: {
  loading: boolean
  minHeight?: string
  children: ReactNode
}): ReactElement => {
  const twConfig = useTailwindConfig()

  return loading ? (
    <div className="relative h-full w-full m-4" style={{ minHeight }}>
      <div className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <div className="relative h-24 w-24">
          <MedaicaLogoAbbr className="absolute w-8 left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2" />
          <SpinnerCircular
            size="auto"
            thickness={88}
            speed={150}
            color={twConfig.theme.colors.primary.DEFAULT}
            secondaryColor="rgba(0, 0, 0, 0)"
          />
        </div>
      </div>
    </div>
  ) : (
    <>{children}</>
  )
}

export default LoadingWrapper

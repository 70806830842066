/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import { Container, Grid, Typography, Button, Paper, Box } from "@mui/material"
import { ErrorIcon } from "../../../../icons/ErrorIcon"
import { QualityScore } from "../Quality"
import { useAppStateContext } from "../../../AppStateProvider/AppStateProvider"
import { ViewIcon } from "../../../../icons/ViewIcon"
import { WarningIcon } from "../../../../icons/WarningIcon"
import React, { ReactElement } from "react"
import { styled } from "@mui/material/styles"

const PREFIX = "poorQuality"

const classes = {
  mainContainer: `${PREFIX}-mainContainer`,
  header: `${PREFIX}-header`,
  heading: `${PREFIX}-heading`,
  paper: `${PREFIX}-paper`,
  paperContainer: `${PREFIX}-paperContainer`,
  viewButton: `${PREFIX}-viewButton`,
  confirmationButtons: `${PREFIX}-confirmationButtons`,
}

const StyledContainer = styled(Container)(() => ({
  [`& .${classes.mainContainer}`]: {
    justifyContent: "space-between",
  },

  [`& .${classes.header}`]: (theme) => ({
    float: "left",
    [theme.breakpoints.down("md")]: {
      float: "initial",
    },
  }),

  [`& .${classes.heading}`]: {
    position: "relative",
  },

  [`& .${classes.paper}`]: {
    padding: "1.2em",
    maxWidth: "400px",
    borderRadius: "8px",
    "& li": {
      "&:not(:last-child)": {
        marginBottom: "1.5em",
      },
    },
  },

  [`& .${classes.paperContainer}`]: (theme) => ({
    float: "right",
    marginRight: "1em",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      float: "initial",
      justifyContent: "center",
      margin: "0 0 2.5em 0",
    },
  }),

  [`& .${classes.viewButton}`]: {
    marginTop: "2em",
    "& svg": {
      position: "relative",
      left: "-5px",
    },
  },

  [`& .${classes.confirmationButtons}`]: (theme) => ({
    clear: "left",
    [theme.breakpoints.down("md")]: {
      clear: "initial",
      marginBottom: "2em",
    },
  }),
}))

interface PoorQualityProps {
  quality: QualityScore | undefined
  openModal: () => void
}

export const PoorQuality = ({ quality, openModal }: PoorQualityProps): ReactElement => {
  const { nextPane } = useAppStateContext()

  return (
    <StyledContainer>
      <Grid container className={classes.mainContainer}>
        <Grid item lg={5} className={classes.header}>
          <Typography variant="h3" component="h1" gutterBottom className={classes.heading}>
            {quality === QualityScore.Suboptimal ? <WarningIcon /> : <ErrorIcon />}
            Quality {quality === QualityScore.Suboptimal ? "is okay" : "issues"}
          </Typography>

          <Typography variant="body1" gutterBottom>
            This the last step! Your expected audio and video quality is{" "}
            <strong>{quality === QualityScore.Suboptimal ? "suboptimal" : "degraded"} </strong>
            and overall performance {quality === QualityScore.Suboptimal ? "could be better" : "will be poor"}.
          </Typography>

          <Typography variant="body1" gutterBottom>
            <strong>Did you try all of the tips to improve quality?</strong>
          </Typography>

          <Box mt={3}>
            <Button variant="contained" color="primary" onClick={nextPane} style={{ marginRight: "1.5em" }}>
              Yes
            </Button>
            <Button color="primary" onClick={nextPane}>
              Skip for now
            </Button>
          </Box>
        </Grid>

        <Grid item lg={5} className={classes.paperContainer}>
          <Paper className={classes.paper}>
            <Typography variant="body1">
              <strong>Tips to improve quality and performance:</strong>
            </Typography>

            <ul>
              <li>
                <Typography variant="body1">
                  Close unused tabs and applications on your computer/device when using video.
                </Typography>
              </li>
              <li>
                <Typography variant="body1">Move closer to your router.</Typography>
              </li>
              <li>
                <Typography variant="body1">Turn off or disconnect from VPN.</Typography>
              </li>
              <li>
                <Typography variant="body1">Restart your computer or device.</Typography>
              </li>
            </ul>
          </Paper>

          <Button variant="outlined" onClick={openModal} className={classes.viewButton}>
            <ViewIcon />
            View detailed quality information
          </Button>
        </Grid>
      </Grid>
    </StyledContainer>
  )
}

/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement, useContext, useEffect, useRef } from "react"
import { observer } from "mobx-react-lite"
import VirtualExamContext from "views/exam/virtual-exam/virtual-exam-context"
import { reaction } from "mobx"
import { SnackbarKey, useSnackbar } from "notistack"

const Notifications = observer((): ReactElement => {
  const { avStore, virtualExamStore } = useContext(VirtualExamContext)
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const snackbarKeyRef = useRef<SnackbarKey>()

  useEffect(() => {
    return reaction(
      () => avStore.remoteParticipant,
      (connected) => {
        if (!connected) {
          enqueueSnackbar(`${virtualExamStore.healthcareProvider.displayName} has disconnected`)
        }
      }
    )
  }, [avStore.remoteParticipant, enqueueSnackbar, virtualExamStore.healthcareProvider.displayName])

  useEffect(() => {
    if (virtualExamStore.isRecording) {
      snackbarKeyRef.current = enqueueSnackbar("Please remain still and make as little noise as possible", {
        persist: true,
        preventDuplicate: true,
        anchorOrigin: {
          vertical: "top",
          horizontal: "center",
        },
        content: (key, message) => (
          <div id={key.toString()} className="bg-white py-2 px-3 -mt-4 panel" style={{ width: "480px" }}>
            <h3 className="font-semibold mb-1 text-gray-700">Recording in progress</h3>
            <p>{message}</p>
          </div>
        ),
      })
    } else {
      // In case snackbarKeyRef.current is undefined, closeSnackbar will close all open snackbars,
      // otherwise it will close the snackbar with the given key.
      closeSnackbar(snackbarKeyRef.current)
    }
  }, [closeSnackbar, enqueueSnackbar, virtualExamStore.isRecording])

  return <></>
})

export default Notifications

/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

enum Paths {
  home = "/",
  exams = "/exams",
  liveExam = "/live-exam",
  liveExamApp = "/live-exam-app",
  liveExamCompatibilityTest = "/live-exam-compatibility-test",
  selfExam = "/self-exam",
  newSelfExam = "/new-self-exam",
  signUp = "/sign-up",
  forgotPassword = "/forgot-password",
  updateProfile = "/update-profile",
  authCallback = "/auth-callback",
  postAuthPipeline = "/post-auth-pipeline",
  profile_stethoscope = "/profile/stethoscope",
  hipaa = "/hipaa",
  support = "/support",
  contact = "/contact",
  terms = "/terms",
  privacy = "/privacy",
  loggedOut = "/signed-out",
  logOut = "/sign-out",
}

const footerNavigation = [
  { name: "About Medaica", href: "https://www.medaica.com", newWindow: true },
  { name: "Support", href: Paths.support, newWindow: true },
  { name: "Contact", href: Paths.contact, newWindow: true },
  { name: "Terms", href: Paths.terms, newWindow: true },
  { name: "Privacy", href: Paths.privacy, newWindow: true },
  { name: "HIPAA", href: Paths.hipaa, newWindow: true },
]

export { Paths, footerNavigation }

/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import _ from "lodash"
import { auscultationMicSampleRateInHz } from "@medaica/common/const"

class NoiseFilter {
  analyser: AnalyserNode
  spectrum: Uint8Array
  private audioSourceNode: MediaStreamAudioSourceNode | null = null
  private readonly entryPoint: ChannelSplitterNode
  private readonly audioContext: AudioContext
  private noiseFrequencySplitPointPercentage = 32
  private noiseFrequencyNoiseFloor = 1

  isBadNoise(): boolean {
    this.analyser.getByteFrequencyData(this.spectrum)
    const frequencySplitPoint = Math.round((this.spectrum.length * this.noiseFrequencySplitPointPercentage) / 100)
    const highFrequencies = this.spectrum.slice(frequencySplitPoint, this.spectrum.length)
    const highFrequencyAvg = highFrequencies.reduce((a, b) => a + b, 0) / highFrequencies.length
    return highFrequencyAvg > this.noiseFrequencyNoiseFloor
  }

  constructor() {
    const audioContext = new (window.AudioContext || window["webkitAudioContext"])({
      sampleRate: auscultationMicSampleRateInHz,
    })
    const noiseSplitter = audioContext.createChannelSplitter(3)

    const masterGain = audioContext.createGain()
    masterGain.gain.value = 0

    const noiseAnalyser = audioContext.createAnalyser()
    noiseAnalyser.fftSize = 1024

    const noiseSpectrum = new Uint8Array(noiseAnalyser.frequencyBinCount)

    const brickWallFilters = _.range(5).map(() => {
      const biquadFilter = audioContext.createBiquadFilter()
      biquadFilter.type = "lowpass"
      biquadFilter.frequency.value = 1200
      biquadFilter.Q.value = 0.707
      return biquadFilter
    })

    const inputHighPassFilter = audioContext.createBiquadFilter()
    inputHighPassFilter.type = "highpass"
    inputHighPassFilter.frequency.value = 30 // Cutoff frequency in Hz
    inputHighPassFilter.Q.value = 0 // Controls the steepness of the roll-off

    noiseSplitter.connect(brickWallFilters[0], 0)

    for (let i = 0; i < brickWallFilters.length - 1; i++) {
      brickWallFilters[i].connect(brickWallFilters[i + 1])
    }

    brickWallFilters[brickWallFilters.length - 1]
      .connect(inputHighPassFilter)
      .connect(masterGain)
      .connect(audioContext.destination)

    noiseSplitter.connect(noiseAnalyser)

    this.audioContext = audioContext
    this.entryPoint = noiseSplitter
    this.spectrum = noiseSpectrum
    this.analyser = noiseAnalyser
  }

  connect = (stream: MediaStream): void => {
    this.audioSourceNode = this.audioContext.createMediaStreamSource(stream)
    this.audioSourceNode.connect(this.entryPoint)
  }

  disconnect = (): void => {
    this.audioSourceNode?.disconnect()
  }
}

export default NoiseFilter

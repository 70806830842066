/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement, useEffect, useState } from "react"
import useQuery from "@medaica/common/hooks/query"
import Button from "@mui/material/Button"
import { Auth } from "@medaica/common/services/auth"

const LoggedOutView = ({ useAuth }: { useAuth: Auth }): ReactElement => {
  const { login } = useAuth()
  const [title, setTitle] = useState("Signed Out")
  const [message, setMessage] = useState<string>()
  const reason = useQuery("reason")
  const returnTo = useQuery("returnTo")

  const signInButtonClicked = () => {
    // It is possible a returnTo value was set in the query prior to logging the user out. This might happen in the
    // case of being logged out for inactivity. We want to grab this value and update the appState with it, so on
    // login we can redirect to the page the user had been on.
    // const returnTo = getLoggedOutReturnTo()
    // removeLoggedOutReturnTo()
    void login(
      returnTo
        ? {
            appState: { returnTo },
          }
        : undefined
    )
  }

  useEffect(() => {
    switch (reason) {
      case "auth-error":
        setTitle("Error signing in")
        setMessage("There was an error signing in. Please try again.")
        break
      case "inactivity":
        setMessage("For security reasons, you've been signed out due to inactivity.")
        break
      default:
        setMessage("You've been safely signed out.")
    }
  }, [reason])

  return (
    <div className="w-80 mt-20">
      <div className="sm:mx-auto mb-8">
        <h2 className="text-center text-lg font-bold mb-4 text-gray-700">{title}</h2>
        <p className="text-center">{message}</p>
      </div>
      <Button variant="contained" fullWidth onClick={signInButtonClicked}>
        Sign back in
      </Button>
    </div>
  )
}

export default LoggedOutView

/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { createContext, useContext, useReducer, useEffect, useCallback, ReactNode, ReactElement } from "react"
import produce, { current } from "immer"
import { PreflightTestReport } from "twilio-video"
import UAParser from "ua-parser-js"
import usePreflightTest from "./usePreflightTest/usePreflightTest"
import useTwilioStatus from "./useTwilioStatus/useTwilioStatus"
import useBitrateTest from "./useBitrateTest/useBitrateTest"
import { VideoInputTest, MediaConnectionBitrateTest, AudioInputTest, AudioOutputTest } from "@twilio/rtc-diagnostics"
import { MediaPermissionsError } from "mic-check"
import AVStore from "@medaica/common/views/exam/virtual-exam/stores/av-store"
import Bowser from "bowser"

export enum ActivePane {
  GetStarted,
  BrowserTest,
  DeviceCheck,
  DeviceError,
  CameraTest,
  AudioTest,
  StethoscopeConnection,
  StethoscopeConnectionError,
  StethoscopeTest,
  Connectivity,
  Quality,
  Results,
}

export type TwilioAPIStatus = "operational" | "major_outage" | "partial_outage" | "degraded_performance"

export interface TwilioStatus {
  ["Group Rooms"]?: TwilioAPIStatus
  ["Go Rooms"]?: TwilioAPIStatus
  ["Peer-to-Peer Rooms"]?: TwilioAPIStatus
  ["Recordings"]?: TwilioAPIStatus
  ["Compositions"]?: TwilioAPIStatus
  ["Network Traversal Service"]?: TwilioAPIStatus
}

interface stateType {
  activePane: ActivePane
  videoGranted: boolean
  audioGranted: boolean
  deviceError: null | MediaPermissionsError
  stethoscopeConnectionError: boolean
  skipStethoscope: boolean
  systemError: null | Error
  preflightTest: {
    progress: string | null
    error: null | Error
    report: null | PreflightTestReport
    tokenError: null | Error
    signalingGatewayReachable: boolean
    turnServersReachable: boolean
  }
  twilioStatus: TwilioStatus | null
  twilioStatusError: null | Error
  videoInputTestReport: null | VideoInputTest.Report
  audioInputTestReport: null | AudioInputTest.Report
  audioOutputTestReport: null | AudioOutputTest.Report
  downButtonDisabled: boolean
  preflightTestInProgress: boolean
  preflightTestFinished: boolean
  bitrateTest: {
    bitrate: null | number
    report: null | MediaConnectionBitrateTest.Report
    error: null | Error
  }
  bitrateTestInProgress: boolean
  bitrateTestFinished: boolean
  appIsExpired: boolean
}

export type ACTIONTYPE =
  | { type: "set-active-pane"; newActivePane: ActivePane }
  | { type: "next-pane" }
  | { type: "previous-pane" }
  | { type: "set-devices"; devices: MediaDeviceInfo[] }
  | { type: "set-device-error"; error: MediaPermissionsError }
  | { type: "set-stethoscope-connection-error" }
  | { type: "skip-stethoscope" }
  | { type: "set-video-test-report"; report: VideoInputTest.Report }
  | { type: "set-audio-input-test-report"; report: AudioInputTest.Report }
  | { type: "set-audio-output-test-report"; report: AudioOutputTest.Report }
  | { type: "preflight-progress"; progress: string }
  | { type: "preflight-completed"; report: PreflightTestReport }
  | { type: "preflight-failed"; error: Error }
  | { type: "preflight-token-failed"; error: Error }
  | { type: "set-twilio-status"; statusObj: TwilioStatus }
  | { type: "set-twilio-status-error"; error: Error }
  | { type: "preflight-started" }
  | { type: "preflight-finished" }
  | { type: "set-bitrate"; bitrate: number }
  | { type: "set-bitrate-test-error"; error: Error }
  | { type: "set-bitrate-test-report"; report: MediaConnectionBitrateTest.Report }
  | { type: "bitrate-test-started" }
  | { type: "bitrate-test-finished" }
  | { type: "set-app-is-expired" }

export type AppStateContextType = {
  state: stateType
  dispatch: React.Dispatch<ACTIONTYPE>
  nextPane: () => void
  userAgentInfo: UAParser.IResult
  downloadFinalTestResults: () => void
}

export const initialState = {
  activePane: ActivePane.GetStarted,
  videoGranted: false,
  audioGranted: false,
  deviceError: null,
  systemError: null,
  stethoscopeConnectionError: false,
  skipStethoscope: true,
  preflightTest: {
    progress: null,
    report: null,
    error: null,
    tokenError: null,
    signalingGatewayReachable: false,
    turnServersReachable: false,
  },
  twilioStatus: null,
  twilioStatusError: null,
  videoInputTestReport: null,
  audioInputTestReport: null,
  audioOutputTestReport: null,
  downButtonDisabled: false,
  preflightTestInProgress: false,
  preflightTestFinished: false,
  bitrateTest: {
    bitrate: null,
    report: null,
    error: null,
  },
  bitrateTestInProgress: false,
  bitrateTestFinished: false,
  appIsExpired: false,
}

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const AppStateContext = createContext<AppStateContextType>(null!)

export const useAppStateContext = (): AppStateContextType => {
  const context = useContext(AppStateContext)
  if (!context) {
    throw new Error("useAppStateContext must be used within an AppStateProvider")
  }
  return context
}

// helper function for determining whether to disable the down arrow button:
export const isDownButtonDisabled = (currentState: stateType): boolean => {
  const {
    activePane,
    preflightTestInProgress,
    preflightTest,
    bitrateTestInProgress,
    audioInputTestReport,
    audioOutputTestReport,
    videoInputTestReport,
    appIsExpired,
  } = currentState

  const connectionFailedOrLoading =
    activePane === ActivePane.Connectivity &&
    (preflightTestInProgress ||
      bitrateTestInProgress ||
      preflightTest.error !== null ||
      preflightTest.tokenError !== null)

  const deviceTestErrors =
    !!audioInputTestReport?.errors.length ||
    !!audioOutputTestReport?.errors.length ||
    !!videoInputTestReport?.errors.length

  const onDeviceCheck = activePane === ActivePane.DeviceCheck || activePane === ActivePane.DeviceError
  const unsupportedBrowser = activePane === ActivePane.BrowserTest && !AVStore.isPlatformSupported()

  return (
    appIsExpired ||
    connectionFailedOrLoading ||
    !ActivePane[activePane + 1] ||
    deviceTestErrors ||
    onDeviceCheck ||
    unsupportedBrowser
  )
}

/**
 * This reducer function helps with the logic used to decide the activePane,
 * as well as which panes are to be shown to the user, and when.
 */

export const appStateReducer = produce((draft: stateType, action: ACTIONTYPE) => {
  switch (action.type) {
    case "set-active-pane":
      draft.activePane = action.newActivePane
      break

    case "next-pane":
      switch (draft.activePane) {
        // Making it such that we always do a system check, in case the camera or mic was disallowed for the browser
        // case ActivePane.GetStarted:
        //   if (draft.audioGranted && draft.videoGranted) {
        //     draft.activePane = ActivePane.SystemCheck;
        //   } else {
        //     draft.activePane = ActivePane.DeviceCheck;
        //   }
        //   break;
        case ActivePane.DeviceCheck:
          if (draft.deviceError) {
            draft.activePane = ActivePane.DeviceError
          } else {
            draft.activePane = ActivePane.CameraTest
          }
          break
        case ActivePane.AudioTest:
          if (draft.skipStethoscope) {
            draft.activePane = ActivePane.Connectivity
          } else {
            draft.activePane = ActivePane.StethoscopeConnection
          }
          break
        case ActivePane.StethoscopeConnection:
          if (draft.stethoscopeConnectionError) {
            draft.activePane = ActivePane.StethoscopeConnectionError
          } else {
            draft.activePane = ActivePane.StethoscopeTest
          }
          break
        default:
          draft.activePane++
          break
      }
      break

    case "previous-pane":
      switch (draft.activePane) {
        case ActivePane.CameraTest:
          draft.activePane = ActivePane.DeviceCheck
          break
        case ActivePane.Connectivity:
          if (draft.skipStethoscope) {
            draft.activePane = ActivePane.AudioTest
          } else {
            draft.activePane = ActivePane.StethoscopeTest
          }
          break
        default:
          draft.activePane--
          break
      }
      break

    case "set-devices":
      draft.audioGranted = action.devices.filter((d) => d.kind === "audioinput").every((d) => d.label)
      draft.videoGranted = action.devices.filter((d) => d.kind === "videoinput").every((d) => d.label)
      break

    case "set-device-error":
      draft.deviceError = action.error
      draft.activePane = ActivePane.DeviceError
      break

    case "set-stethoscope-connection-error":
      draft.stethoscopeConnectionError = true
      draft.activePane = ActivePane.StethoscopeConnectionError
      break

    case "skip-stethoscope":
      draft.skipStethoscope = true
      draft.activePane = ActivePane.CameraTest
      break

    case "preflight-progress":
      draft.preflightTest.progress = action.progress
      // Safari does not support RTCDtlsTransport, so we use 'peerConnectionConnected' to determine
      // if Signaling Gateway is reachable
      if (action.progress === "dtlsConnected" || action.progress === "peerConnectionConnected") {
        draft.preflightTest.signalingGatewayReachable = true
      }

      if (action.progress === "mediaAcquired") {
        draft.preflightTest.turnServersReachable = true
      }
      break

    case "preflight-completed":
      draft.preflightTest.report = action.report
      draft.preflightTest.progress = null
      break

    case "preflight-failed":
      draft.preflightTest.error = action.error
      draft.preflightTest.progress = null
      break

    case "preflight-token-failed":
      draft.preflightTest.tokenError = action.error
      break

    case "set-twilio-status":
      draft.twilioStatus = action.statusObj
      break

    case "set-twilio-status-error":
      draft.twilioStatusError = action.error
      break

    case "set-video-test-report":
      draft.videoInputTestReport = action.report
      break

    case "set-audio-input-test-report":
      draft.audioInputTestReport = action.report
      break

    case "set-audio-output-test-report":
      draft.audioOutputTestReport = action.report
      break

    case "preflight-started":
      draft.preflightTestInProgress = true
      draft.preflightTestFinished = false
      break

    case "preflight-finished":
      draft.preflightTestFinished = true
      draft.preflightTestInProgress = false
      break

    case "set-bitrate":
      draft.bitrateTest.bitrate = action.bitrate
      break

    case "set-bitrate-test-report":
      draft.bitrateTest.report = action.report
      break

    case "set-bitrate-test-error":
      draft.bitrateTest.error = action.error
      break

    case "bitrate-test-started":
      draft.bitrateTestInProgress = true
      draft.bitrateTestFinished = false
      break

    case "bitrate-test-finished":
      draft.bitrateTestFinished = true
      draft.bitrateTestInProgress = false
      break

    case "set-app-is-expired":
      draft.appIsExpired = true
  }

  const currentState = current(draft)

  draft.downButtonDisabled = isDownButtonDisabled(currentState)
})

export const userAgentParser = (): Bowser.Parser.ParsedResult => {
  return new UAParser().getResult() as Bowser.Parser.ParsedResult
}

export type AppStateProviderProps = {
  accessTokenProvider: () => Promise<string>
  userAgentParser: () => UAParser.IResult
  children: ReactNode
}

export const AppStateProvider = ({
  accessTokenProvider,
  userAgentParser,
  children,
}: AppStateProviderProps): ReactElement => {
  const [state, dispatch] = useReducer(appStateReducer, initialState)
  const { startPreflightTest } = usePreflightTest(dispatch)
  const { startBitrateTest } = useBitrateTest(dispatch)
  const { getTwilioStatus } = useTwilioStatus(dispatch)

  const userAgentInfo = userAgentParser()

  const downloadFinalTestResults = () => {
    const signalingGateway = state.preflightTest.signalingGatewayReachable ? "Reachable" : "Unreachable"
    const turnServers = state.preflightTest.turnServersReachable ? "Reachable" : "Unreachable"
    const maxBitrate = state.bitrateTest.report?.values ? Math.max(...state.bitrateTest.report.values) : 0

    const finalTestResults = {
      audioTestResults: { inputTest: state.audioInputTestReport, outputTest: state.audioOutputTestReport },
      bitrateTestResults: { maxBitrate, ...state.bitrateTest.report },
      browserInformation: userAgentInfo,
      connectivityResults: {
        twilioServices: { ...state.twilioStatus },
        signalingRegion: signalingGateway,
        TURN: turnServers,
      },
      preflightTestReport: { report: state.preflightTest.report, error: state.preflightTest.error?.message ?? null },
      videoTestResults: state.videoInputTestReport,
    }

    const link = document.createElement("a")
    link.download = "test_results.json"
    link.href = URL.createObjectURL(
      new Blob([JSON.stringify(finalTestResults, null, 2)], {
        type: "application/json",
      })
    )
    link.click()
  }

  const nextPane = useCallback(() => {
    switch (state.activePane) {
      case ActivePane.GetStarted:
        void startBitrateTest()
        void startPreflightTest()
        void getTwilioStatus()
        dispatch({ type: "next-pane" })
        break
      default:
        dispatch({ type: "next-pane" })
    }
  }, [startBitrateTest, startPreflightTest, getTwilioStatus, state, dispatch])

  useEffect(() => {
    void (async () => {
      void navigator.mediaDevices.enumerateDevices().then((devices) => dispatch({ type: "set-devices", devices }))

      try {
        await accessTokenProvider()
      } catch (error) {
        if (error.response?.data?.error?.message === "token server expired") {
          dispatch({ type: "set-app-is-expired" })
        }
      }
    })()
  }, [accessTokenProvider])

  return (
    // @ts-ignore
    <AppStateContext.Provider value={{ state, dispatch, nextPane, userAgentInfo, downloadFinalTestResults }}>
      {children}
    </AppStateContext.Provider>
  )
}

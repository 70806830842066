/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { useState, useEffect, useCallback, ReactElement } from "react"
import { MenuItem, Typography, FormControl, Select } from "@mui/material"
import { SmallError } from "../../../../icons/SmallError"
import useDevices from "../../../../hooks/useDevices/useDevices"
import { styled } from "@mui/material/styles"

const PREFIX = "audioDevice"

const classes = {
  form: `${PREFIX}-form`,
  deviceLabelContainer: `${PREFIX}-deviceLabelContainer`,
  deviceList: `${PREFIX}-deviceList`,
  error: `${PREFIX}-error`,
}

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")({
  [`& .${classes.form}`]: {
    margin: "0.5em 0 1em",
  },
  [`& .${classes.deviceLabelContainer}`]: {
    margin: "0.8em 0",
  },
  [`& .${classes.deviceList}`]: {
    height: "36px",
  },
  [`& .${classes.error}`]: {
    display: "flex",
    alignItems: "center",
    marginBottom: "1em",
    "& svg": {
      marginRight: "0.3em",
    },
  },
})

const labels = {
  audioinput: {
    audioLevelText: "Input level",
    headerText: "Microphone",
  },
  audiooutput: {
    audioLevelText: "Output level",
    headerText: "Speaker",
  },
}

interface AudioDeviceProps {
  disabled: boolean
  kind: "audioinput" | "audiooutput"
  onDeviceChange: (value: string) => void
  setDeviceError: (value: string) => void
  error?: string
}

export const AudioDevice = ({
  disabled,
  kind,
  onDeviceChange,
  setDeviceError,
  error,
}: AudioDeviceProps): ReactElement => {
  const devices = useDevices()
  const audioDevices = kind === "audiooutput" ? devices.audioOutputDevices : devices.audioInputDevices
  const [selectedDevice, setSelectedDevice] = useState("")
  const { headerText } = labels[kind]
  const noAudioRedirect = !Audio.prototype.setSinkId && kind === "audiooutput"

  const updateSelectedDevice = useCallback(
    (value: string) => {
      onDeviceChange(value)
      setSelectedDevice(value)
      setDeviceError("")
    },
    [onDeviceChange, setSelectedDevice, setDeviceError]
  )

  useEffect(() => {
    const hasSelectedDevice = audioDevices.some((device) => device.deviceId === selectedDevice)
    if (audioDevices.length && !hasSelectedDevice) {
      updateSelectedDevice(audioDevices[0].deviceId)
    }
  }, [audioDevices, devices, selectedDevice, updateSelectedDevice])

  return (
    <Root>
      <Typography variant="subtitle2">
        <strong>{headerText}</strong>
      </Typography>
      {noAudioRedirect && (
        <div className={classes.deviceLabelContainer}>
          <Typography variant="body1">System Default Audio Output</Typography>
        </div>
      )}
      {!noAudioRedirect && (
        <FormControl variant="outlined" disabled={disabled} className={classes.form} fullWidth>
          <Select
            value={selectedDevice}
            onChange={(e) => updateSelectedDevice(e.target.value)}
            className={classes.deviceList}
          >
            {audioDevices.map((device) => (
              <MenuItem value={device.deviceId} key={device.deviceId}>
                {device.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
      {error && (
        <div className={classes.error}>
          <SmallError />
          <Typography variant="subtitle2" color="error">
            {error === "No audio detected" ? "No audio detected." : "Unable to connect."}
          </Typography>
        </div>
      )}
    </Root>
  )
}

export default React.memo(AudioDevice)

/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import { Box, Button, Container, Grid, Typography } from "@mui/material"
import { ActivePane, useAppStateContext } from "../../AppStateProvider/AppStateProvider"
import { CheckMark } from "../../../icons/CheckMark"
import { DownloadIcon } from "../../../icons/DownloadIcon"
import { getQualityScore } from "../Quality/getQualityScore/getQualityScore"
import { QualityScore } from "../Quality/Quality"
import { SmallError } from "../../../icons/SmallError"
import SomeFailed from "./SomeFailed.png"
import TestsPassed from "./TestsPassed.png"
import React, { ReactElement } from "react"
import { useTheme } from "@mui/styles"

export const Results = (): ReactElement => {
  const { state, downloadFinalTestResults, dispatch } = useAppStateContext()
  const { totalQualityScore } = getQualityScore(state.preflightTest.report, state.bitrateTest.report)

  const testsPassed = totalQualityScore === QualityScore.Excellent || totalQualityScore === QualityScore.Good
  const qualityScore = QualityScore[totalQualityScore].toLowerCase()

  const theme = useTheme()

  const styles = {
    mainContainer: {
      justifyContent: "space-between",
    },

    header: {
      float: "left",
      [theme.breakpoints.down("md")]: {
        float: "initial",
        paddingBottom: "1em",
      },
    },

    buttonContainer: {
      display: "inline-flex",
      flexWrap: "wrap",
      gap: "1em",
      width: "100%",
      marginTop: "12px",
    },

    resultsList: {
      float: "right",
      [theme.breakpoints.down("md")]: {
        float: "initial",
      },
    },

    iconContainer: {
      display: "flex",
      alignItems: "center",
      "& svg": {
        margin: "0.2em 0.8em 0 0",
      },
    },

    downloadButton: {
      "& svg": {
        position: "relative",
        left: "-5px",
      },
    },

    restartButton: {
      backgroundColor: "#FFFFFF",
      borderColor: "#8891AA",
    },

    illustration: {
      marginTop: "7em",
    },

    hardwareButton: {
      marginRight: "1.5em",
    },

    gutterBottom: {
      marginBottom: "0em",
    },

    resultContainer: {
      marginTop: "1.5em",
      "&:not(:last-child)": {
        paddingBottom: "1.5em",
        borderBottom: "0.1em solid #8891AA",
      },
      [theme.breakpoints.down("md")]: {
        "&:last-child": {
          paddingBottom: "1.5em",
        },
      },
    },
  }

  return (
    <Container>
      <Grid container sx={styles.mainContainer}>
        <Grid item lg={5} sx={styles.header}>
          <Typography component="h1" variant="h3" gutterBottom>
            {testsPassed ? "All tests passed!" : "Some tests failed"}
          </Typography>

          {testsPassed ? (
            <Typography variant="body1" gutterBottom>
              As far as we can tell, your video should be working. If you're still experiencing issues, download your
              report results and send to your network administrator.
            </Typography>
          ) : (
            <Typography variant="body1" gutterBottom>
              <strong>One out of three </strong>
              tests failed – use this list to solve common video issues and restart the test. If you can’t easily solve
              the problem(s), download report results and send to your network administrator.
            </Typography>
          )}

          <Box sx={styles.buttonContainer}>
            <Button variant="contained" color="primary" sx={styles.downloadButton} onClick={downloadFinalTestResults}>
              <DownloadIcon />
              Download report results
            </Button>
            <Button variant="outlined" sx={styles.restartButton} onClick={() => window.location.reload()}>
              Restart test
            </Button>
          </Box>

          <img
            src={testsPassed ? TestsPassed : SomeFailed}
            alt={testsPassed ? "Success" : "Some Failed"}
            style={styles.illustration}
          />
        </Grid>

        <Grid item lg={5} sx={styles.resultsList}>
          <Box sx={styles.resultContainer} style={{ marginTop: 0 }}>
            <Box sx={styles.iconContainer}>
              <CheckMark />
              <Typography variant="h4" sx={styles.gutterBottom}>
                Device &amp; Network Setup
              </Typography>
            </Box>
            <Typography variant="body1" sx={styles.gutterBottom}>
              Audio and video successfully received from your hardware and browser.
            </Typography>

            <Box sx={styles.buttonContainer}>
              <Button
                variant="outlined"
                onClick={() => dispatch({ type: "set-active-pane", newActivePane: ActivePane.CameraTest })}
              >
                Review hardware
              </Button>
              <Button
                variant="outlined"
                onClick={() => dispatch({ type: "set-active-pane", newActivePane: ActivePane.BrowserTest })}
              >
                Review browser
              </Button>
            </Box>
          </Box>

          <Box sx={styles.resultContainer}>
            <Box sx={styles.iconContainer}>
              <CheckMark />
              <Typography variant="h4" sx={styles.gutterBottom}>
                Connectivity
              </Typography>
            </Box>
            <Typography variant="body1" sx={styles.gutterBottom}>
              All connections are working successfully.
            </Typography>
            <Box sx={styles.buttonContainer}>
              <Button
                variant="outlined"
                onClick={() => dispatch({ type: "set-active-pane", newActivePane: ActivePane.Connectivity })}
              >
                Review connectivity
              </Button>
            </Box>
          </Box>

          <Box sx={styles.resultContainer}>
            <Box sx={styles.iconContainer}>
              {testsPassed ? <CheckMark /> : <SmallError />}
              <Typography variant="h4" sx={styles.gutterBottom}>
                Quality &amp; Performance
              </Typography>
            </Box>

            {testsPassed ? (
              <Typography variant="body1" sx={styles.gutterBottom}>
                Awesome! Your expected call quality is <strong>{qualityScore}</strong> and overall performance looks
                {qualityScore === "excellent" ? " good" : " ok"}.
              </Typography>
            ) : (
              <Typography variant="body1" sx={styles.gutterBottom}>
                Your overall score is <strong>{qualityScore}</strong> which means that your connection isn't good enough
                to run video properly. Try out these tips and rerun the test.
              </Typography>
            )}

            <Box sx={styles.buttonContainer}>
              <Button
                variant="outlined"
                onClick={() => dispatch({ type: "set-active-pane", newActivePane: ActivePane.Quality })}
              >
                Review performance
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Container>
  )
}

/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement, useContext } from "react"
import SelfInitiatedSelfExamContext from "views/exam/self-initiated-self-exam/self-initiated-self-exam-context"
import { Form, Formik } from "formik"
import * as Yup from "yup"
import TextInput from "@medaica/common/components/forms/text-input"
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material"
import { LoadingButton } from "@mui/lab"
import { logError } from "@medaica/common/services/util"

const ShareExamModal = ({
  isOpen,
  onCloseRequested,
}: {
  isOpen: boolean
  onCloseRequested: () => void
}): ReactElement => {
  const { selfInitiatedSelfExamStore } = useContext(SelfInitiatedSelfExamContext)

  return selfInitiatedSelfExamStore.auscultations.length > 0 ? (
    <Dialog open={isOpen} onClose={onCloseRequested}>
      <div>
        <Formik
          initialValues={{
            email: "",
          }}
          validationSchema={Yup.object().shape({
            email: Yup.string().email("Email is invalid").required("Email is required"),
          })}
          onSubmit={(values, { setSubmitting }) => {
            // handle errors
            selfInitiatedSelfExamStore.shareExam(values.email).catch(logError)
            setSubmitting(false)
            onCloseRequested()
          }}
        >
          {({ errors, touched, handleBlur, handleChange, handleSubmit, isSubmitting }) => (
            <Form onSubmit={handleSubmit} className="space-y-6">
              <DialogTitle>Share Exam</DialogTitle>
              <DialogContent>
                <p className="mb-2">
                  Enter the email of the person with whom you would like to share the exam. They will receive an email
                  with a secure link to the exam.
                </p>
                <div>
                  <TextInput
                    label="Email"
                    name="email"
                    placeholder="Recipient's email"
                    hiddenLabel={true}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    autoComplete="email"
                    type="email"
                    errors={errors}
                    touched={touched}
                  />
                </div>
              </DialogContent>
              <DialogActions>
                <Button type="button" onClick={onCloseRequested}>
                  Cancel
                </Button>
                <LoadingButton type="submit" loading={isSubmitting}>
                  Share Exam
                </LoadingButton>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </div>
    </Dialog>
  ) : (
    <Dialog open={isOpen} onClose={onCloseRequested}>
      <DialogContent>You must add at least one recording to the exam before you can share it.</DialogContent>
      <DialogActions>
        <Button onClick={onCloseRequested}>Close</Button>
      </DialogActions>
    </Dialog>
  )
}

export default ShareExamModal

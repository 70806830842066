/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import { action, makeObservable, observable } from "mobx"

class UIStore {
  infoPanelIsVisible = true
  stethoscopeVideoTutorialIsVisible = false

  constructor() {
    makeObservable(this, {
      infoPanelIsVisible: observable,
      stethoscopeVideoTutorialIsVisible: observable,
      setHelpPanelIsVisible: action,
      setStethoscopeVideoTutorialIsVisible: action,
    })
  }

  setHelpPanelIsVisible(isVisible: boolean): void {
    this.infoPanelIsVisible = isVisible
  }

  setStethoscopeVideoTutorialIsVisible(isVisible: boolean): void {
    this.stethoscopeVideoTutorialIsVisible = isVisible
  }
}

export default UIStore

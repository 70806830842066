/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import MedaicaApiService from "./"
import { HealthcareProvider, Patient } from "@medaica/common/types"

class PatientsApi {
  private _apiService: MedaicaApiService

  constructor(apiService: MedaicaApiService) {
    this._apiService = apiService
  }

  async updatePatient(
    patientId: string,
    props: {
      firstName?: string
      lastName?: string
      tcVersion?: number
    }
  ): Promise<HealthcareProvider | undefined> {
    const apiClient = await this._apiService.apiClient()
    const response = await apiClient.patch<HealthcareProvider>(`/patients/${patientId}`, props)
    return response.data
  }

  async createPatient(
    id: string,
    auth0UserId: string,
    emailAddress: string,
    firstName: string,
    lastName: string,
    tcVersion: number
  ): Promise<Patient | undefined> {
    const apiClient = await this._apiService.apiClient(true)
    const response = await apiClient.post<Patient>("/patients", {
      auth0UserId,
      id,
      firstName,
      lastName,
      emailAddress,
      tcVersion,
    })
    return response.data
  }

  async getPatient(patientId: string): Promise<Patient> {
    const apiClient = await this._apiService.apiClient()
    const response = await apiClient.get<Patient>(`/patients/${patientId}`)
    return response.data
  }
}

export default PatientsApi

/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement, ReactNode, useEffect, useMemo, useState } from "react"
import SearchInput from "@medaica/common/components/table/search-input"
import LoadingTableBody from "./loading-table-body"
import MUIDataTable, {
  MUIDataTableBody,
  MUIDataTableOptions,
  MUIDataTableData,
  MUIDataTableProps,
  MUIDataTableColumn,
} from "mui-datatables"
import { createTheme, ThemeProvider } from "@mui/material/styles"
import theme from "@medaica/common/theme"
import { useSearchParams } from "react-router-dom"
import useQuery from "@medaica/common/hooks/query"
import Loading from "@medaica/common/components/loading"

const NoToolbar = () => {
  return <></>
}

const buildActionColumn = (
  name: string,
  contentsBuilder: (value: unknown) => ReactNode,
  label: string | undefined = ""
): MUIDataTableColumn => {
  return {
    name: name,
    label: label,
    options: {
      filter: true,
      setCellProps: () => ({ style: { width: "1px", whiteSpace: "nowrap" } }),
      sort: true,
      customHeadLabelRender: () => null,
      customBodyRender: (value: unknown) => {
        return <div className="flex justify-end">{contentsBuilder(value)}</div>
      },
    },
  }
}

const Table = ({
  data,
  columns,
  options,
  components,
  showToolbar = true,
  noRecordsMessage = "Sorry, no matching records found",
  noRecordsPanel,
  paging1 = false,
  onPageChanged,
  searchPlaceholder,
  ...tableProps
}: {
  data: MUIDataTableData[] | null
  columns: MUIDataTableColumn[]
  options?: MUIDataTableOptions
  components?: Record<symbol, ReactElement>
  showToolbar?: boolean
  noRecordsMessage?: string
  noRecordsPanel?: ReactNode
  paging1?: boolean
  onPageChanged?: (pageNumber: number) => void
  searchPlaceholder?: string
} & Omit<MUIDataTableProps, "data">): ReactElement => {
  const [paging, setPaging] = useState(false)
  const BodyComponent = useMemo(
    () => (props: MUIDataTableBody) => <LoadingTableBody loading={data == null} {...props} />,
    [data]
  )
  const [, setSearchParams] = useSearchParams()
  const pageNumber = useQuery("p")

  const handlePageChanged = (currentPage: number) => {
    setSearchParams((searchParams) => {
      searchParams.set("p", currentPage.toString())
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return searchParams
    })
  }

  const getMuiTheme = () =>
    createTheme(theme, {
      components: {
        //@ts-ignore
        MuiTableBody: {
          styleOverrides: {
            root: {
              opacity: paging ? 0.3 : 1,
            },
          },
        },
      },
    })

  useEffect(() => {
    setPaging(false)
  }, [data])

  if (!data) {
    return <Loading />
  }

  if (data.length === 0) {
    return <>{noRecordsPanel ?? <div style={{ textAlign: "center" }}>{noRecordsMessage}</div>}</>
  }

  return (
    <ThemeProvider theme={getMuiTheme}>
      <MUIDataTable
        data={data ?? []}
        columns={columns}
        options={{
          elevation: 0,
          viewColumns: false,
          filter: false,
          rowsPerPageOptions: [],
          // https://medaica.atlassian.net/browse/MD-279
          sort: false,
          search: false,
          searchAlwaysOpen: false,
          selectableRows: "none",
          page: (pageNumber ? parseInt(pageNumber) : 1) - 1,
          onChangePage: (pageNumber) => {
            setPaging(true)
            handlePageChanged(pageNumber + 1)
            if (onPageChanged) {
              onPageChanged(pageNumber + 1)
            }
          },
          customSearchRender: (searchText, handleSearch) => (
            <SearchInput placeholder={searchPlaceholder} handleSearch={handleSearch} />
          ),
          setTableProps: () => tableProps,
          ...options,
        }}
        components={{
          TableBody: BodyComponent,
          ...options,
          ...(!showToolbar && { TableToolbar: NoToolbar }),
        }}
        title={""}
      />
    </ThemeProvider>
  )
}

export default Table
export { buildActionColumn }

/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import { Box, Button, Container, Grid, Typography, Paper } from "@mui/material"
import { useAppStateContext } from "../../../AppStateProvider/AppStateProvider"
import React, { ReactElement } from "react"
import { styled } from "@mui/material/styles"

const PREFIX = "supportedBrowser"

const classes = {
  mainContainer: `${PREFIX}mainContainer`,
  paper: `${PREFIX}paper`,
  header: `${PREFIX}header`,
  okButton: `${PREFIX}okButton`,
  paperContainer: `${PREFIX}paperContainer`,
}

const Root = styled("div")(({ theme: Theme }) => ({
  [`& .${classes.mainContainer}`]: {
    justifyContent: "space-between",
  },

  [`& .${classes.paper}`]: (theme) => ({
    padding: "1.5em",
    borderRadius: "8px",
    width: "337px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  }),

  [`& .${classes.header}`]: (theme) => ({
    float: "left",
    [theme.breakpoints.down("md")]: {
      float: "initial",
    },
  }),

  [`& .${classes.okButton}`]: (theme) => ({
    clear: "left",
    [theme.breakpoints.down("md")]: {
      clear: "initial",
      marginBottom: "2em",
    },
  }),

  [`& .${classes.paperContainer}`]: (theme) => ({
    float: "right",
    marginRight: "1em",
    [theme.breakpoints.down("md")]: {
      float: "initial",
      display: "flex",
      justifyContent: "center",
      margin: "0 0 2.5em 0",
    },
  }),
}))

export const SupportedBrowser = (): ReactElement => {
  const { dispatch, userAgentInfo } = useAppStateContext()

  const browser = userAgentInfo.browser
  const operatingSystem = userAgentInfo.os

  return (
    <Root>
      <Container>
        <Grid container className={classes.mainContainer}>
          <Grid item lg={5} className={classes.header}>
            <Typography component="h1" variant="h3" gutterBottom>
              Browser supported
            </Typography>
            <Typography variant="body1" gutterBottom>
              Looks like your browser is good to go.
            </Typography>

            <Box mt={3}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => dispatch({ type: "next-pane" })}
                style={{ marginRight: "1.5em" }}
              >
                Ok
              </Button>
            </Box>
          </Grid>

          <Grid item lg={5} className={classes.paperContainer}>
            <Paper style={{ padding: "1.5em", borderRadius: "8px", width: "337px" }}>
              <Typography variant="body1">
                <strong>Browser: </strong>
                {browser.name} {browser.version}
              </Typography>
              <Typography variant="body1">
                <strong>Operating System: </strong>
                {operatingSystem.name} {operatingSystem.version}
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </Root>
  )
}

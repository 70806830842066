/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement } from "react"
import { Link } from "react-router-dom"
import { classNames } from "@medaica/common/services/util"
import useGlobalContext from "@medaica/common/hooks/global-context"
import { PrimaryNavigationItem } from "@medaica/common/types/ui"

const MenuItemGroup = ({ item, section }: { item: PrimaryNavigationItem; section: string | null }): ReactElement => {
  // const [isOpen, setIsOpen] = useState(false)
  // const toggleOpen = () => {
  //   setIsOpen(!isOpen)
  // }
  return (
    <>
      <div
        className={classNames(
          "flex leading-7 items-center flex p-2 rounded-md",
          item.href === section ? "text-primary-600" : "text-gray-600"
        )}
      >
        <item.icon className="mr-4 flex-shrink-0 h-7 w-7 stroke-icon" aria-hidden="true" />
        <div className="flex-grow cursor-default">{item.name}</div>
      </div>
      {item.children && (
        <ol className="space-y-1">
          <div className="ml-5 border-l border-gray-300">
            {item.children.map((item) => (
              <li key={item.name}>
                {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
                <Link key={item.name} to={item.href!}>
                  <div
                    className={classNames(
                      "flex leading-7 ml-1 items-center hover:text-primary-600 flex p-2 pl-7 rounded-md",
                      item.href === section ? "text-primary-600 bg-gray-200" : "text-gray-600"
                    )}
                  >
                    {item.name}
                  </div>
                </Link>
              </li>
            ))}
          </div>
        </ol>
      )}
    </>
  )
}

const MenuItem = ({ item, section }: { item: PrimaryNavigationItem; section: string | null }): ReactElement => {
  return (
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    <Link to={item.href!}>
      <div
        className={classNames(
          "flex items-center hover:text-primary-600 flex p-2 rounded-md",
          item.href === section ? "text-primary bg-gray-200" : "text-gray-600"
        )}
      >
        <item.icon
          className={classNames(item.href === section ? "text-primary" : "", "mr-4 flex-shrink-0 h-7 w-7 stroke-icon")}
          aria-hidden="true"
        />
        <div className="flex-grow">{item.name}</div>
      </div>
    </Link>
  )
}

const Sidebar = ({ navigation }: { navigation: PrimaryNavigationItem[] }): ReactElement => {
  const { section } = useGlobalContext()
  return (
    <div className="flex flex-col flex-grow pb-4 overflow-y-auto">
      <div className="mt-5 flex-1 flex flex-col">
        <nav className="flex-1 space-y-1">
          <ol>
            {navigation.map((item) => (
              <li key={item.name}>
                {item.children ? (
                  <MenuItemGroup item={item} section={section} />
                ) : (
                  <MenuItem item={item} section={section} />
                )}
              </li>
            ))}
          </ol>
        </nav>
      </div>
    </div>
  )
}

export default Sidebar
export type { PrimaryNavigationItem }

/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement, useEffect } from "react"
import useQuery from "@medaica/common/hooks/query"
import useNav from "@medaica/common/hooks/nav"
import Loading from "@medaica/common/components/loading"
import URI from "urijs"
import { LogoutOptions } from "@auth0/auth0-react"
import { useLocation } from "react-router-dom"

const AuthCallback = ({
  redirect,
  logout,
  loggedOutPath,
}: {
  redirect: string
  logout: (logoutOptions: LogoutOptions) => void
  loggedOutPath: string
}): ReactElement => {
  const error = useQuery("error")
  const navigate = useNav()
  const location = useLocation()

  useEffect(() => {
    if (error) {
      logout({
        returnTo: URI(window.location.href).pathname(loggedOutPath).search({ reason: "auth-error" }).valueOf(),
      })
    } else {
      navigate(redirect, { replace: true, state: { returnTo: location?.state?.returnTo } })
    }
  }, [redirect, error, navigate, logout, loggedOutPath, location?.state?.returnTo])

  return <Loading />
}

export default AuthCallback

/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import { action, computed, makeObservable, observable, runInAction } from "mobx"
import logger from "@medaica/common/services/logging"
import { createLocalVideoTrack, createLocalAudioTrack, LocalVideoTrack, LocalAudioTrack } from "twilio-video"
import AVStore from "@medaica/common/views/exam/virtual-exam/stores/av-store"
import { mainVideoHeight, mainVideoWidth } from "@medaica/common/const"

type AudioDevice = MediaDevice<LocalAudioTrack>
type VideoDevice = MediaDevice<LocalVideoTrack>

class MediaDevice<T extends LocalAudioTrack | LocalVideoTrack> {
  track: T
  id: string
  label: string
  muted = false

  constructor(track: T) {
    makeObservable(this, {
      setMuted: action,
      muted: observable,
    })
    this.track = track
    this.id = this.track.mediaStreamTrack.getSettings().deviceId ?? "undefined"
    this.label = this.track.mediaStreamTrack.label
  }

  get tracks(): T[] {
    return [this.track]
  }

  setMuted(muted: boolean): void {
    this.track.enable(!muted)
    this.muted = muted
  }

  dispose(): void {
    logger.debug("Disposing media device track")
    this.track.stop()
  }
}

class MediaDeviceStore {
  private readonly _preferredAudioDeviceId?: string | null
  private readonly _preferredVideoDeviceId?: string | null
  protected _audioDevice: AudioDevice | null = null
  protected _videoDevice: VideoDevice | null = null
  availableAudioDevices: MediaDeviceInfo[] = []
  availableVideoDevices: MediaDeviceInfo[] = []
  state: "started" | "stopped" = "stopped"
  audioDeviceIsMuted = false
  videoDeviceIsMuted = false
  disconnectedAudioDevice: AudioDevice | null = null
  disconnectedVideoDevice: VideoDevice | null = null

  constructor(preferredAudioDeviceId?: string | null, preferredVideoDeviceId?: string | null) {
    makeObservable<MediaDeviceStore, "_audioDevice" | "_videoDevice">(this, {
      _audioDevice: observable,
      _videoDevice: observable,
      availableAudioDevices: observable,
      availableVideoDevices: observable,
      connectToAudioDevice: action,
      connectToVideoDevice: action,
      audioDevice: computed,
      videoDevice: computed,
      dispose: action,
      start: action,
      stop: action,
      state: observable,
      audioDeviceIsMuted: observable,
      videoDeviceIsMuted: observable,
      setAudioDeviceMuted: action,
      setVideoDeviceMuted: action,
      setAudioDevice: action,
      setVideoDevice: action,
      disconnectedAudioDevice: observable,
      disconnectedVideoDevice: observable,
    })
    this._preferredAudioDeviceId = preferredAudioDeviceId
    this._preferredVideoDeviceId = preferredVideoDeviceId
  }

  setAudioDeviceMuted(muted: boolean): void {
    this._audioDevice?.setMuted(muted)
    this.audioDeviceIsMuted = muted
  }

  setVideoDeviceMuted(muted: boolean): void {
    this._videoDevice?.setMuted(muted)
    this.videoDeviceIsMuted = muted
  }

  get audioDevice(): MediaDevice<LocalAudioTrack> | null {
    return this._audioDevice
  }

  setAudioDevice(audioDevice: MediaDevice<LocalAudioTrack> | null): void {
    if (this._audioDevice === audioDevice) {
      return
    }
    if (this._audioDevice) {
      this.disconnectedAudioDevice = this._audioDevice
      this._audioDevice.dispose()
    }
    this._audioDevice = audioDevice
  }

  get videoDevice(): MediaDevice<LocalVideoTrack> | null {
    return this._videoDevice
  }

  setVideoDevice(videoDevice: MediaDevice<LocalVideoTrack> | null): void {
    if (this._videoDevice?.id === videoDevice?.id) {
      return
    }
    if (this._videoDevice) {
      this.disconnectedVideoDevice = this._videoDevice
      this._videoDevice.dispose()
    }
    this._videoDevice = videoDevice
  }

  private handleDeviceChanged = () => {
    void this.updateAvailableMediaDevices()
  }

  public stop(): void {
    logger.debug("Stopping MediaDeviceManager")
    navigator.mediaDevices.removeEventListener("devicechange", this.handleDeviceChanged)
    this.state = "stopped"
  }

  public async start(): Promise<void> {
    if (this.state !== "started") {
      logger.debug("Starting MediaDeviceManager")
      navigator.mediaDevices.addEventListener("devicechange", this.handleDeviceChanged)
      await runInAction(async () => {
        await this.updateAvailableMediaDevices()
        await this.connectToAudioDevice(this._preferredAudioDeviceId)
        await this.connectToVideoDevice(this._preferredVideoDeviceId)
      })
      this.state = "started"
    }
  }

  protected handleCurrentAudioDeviceUnavailable = (device: AudioDevice): void => {
    logger.debug("Existing audio device unavailable, connecting to a new one")
    runInAction(() => {
      this.disconnectedAudioDevice = device
    })
    void this.connectToAudioDevice()
  }

  protected handleCurrentVideoDeviceUnavailable = (device: VideoDevice): void => {
    logger.debug("Existing video device unavailable, connecting to a new one")
    runInAction(() => {
      this.disconnectedVideoDevice = device
    })
    void this.connectToVideoDevice()
  }

  public async updateAvailableMediaDevices(): Promise<void> {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: true,
        video: true,
      })
      // If we don't do this, sometimes it takes a long time to get garbage collected and the green light doesn't turn
      // off since we've got tracks lying around
      stream.getTracks().forEach((track) => {
        track.stop()
      })
    } catch (error) {
      logger.error("Error while getting media devices:", error)
      return
    }
    const deviceInfos = await navigator.mediaDevices.enumerateDevices()
    runInAction(() => {
      this.availableAudioDevices = deviceInfos.filter((device) => device.kind === "audioinput")
      this.availableVideoDevices = deviceInfos.filter((device) => device.kind === "videoinput")
    })
    logger.debug("Available audio devices")
    this.availableAudioDevices.forEach((device) => {
      logger.debug("\t", device.deviceId, device.label)
    })

    logger.debug("Available video devices")
    this.availableVideoDevices.forEach((device) => {
      logger.debug("\t", device.deviceId, device.label)
    })

    // If the video device we were using becomes unavailable, we connect to another one
    if (this.videoDevice) {
      if (!this.availableVideoDevices.some((device) => device.deviceId === this.videoDevice?.id)) {
        this.handleCurrentVideoDeviceUnavailable(this.videoDevice)
      }
    }

    if (this.audioDevice) {
      // If the audio device we were using becomes unavailable, we connect to another one
      if (!this.availableAudioDevices.some((device) => device.deviceId === this.audioDevice?.id)) {
        logger.debug("Existing audio device unavailable, connecting to a new one")
        void this.connectToAudioDevice()
      }
    }
  }

  /**
   * Connects to the specified audio device. If no device is specified, it connects to the default
   * @param audioDeviceId
   */
  async connectToAudioDevice(audioDeviceId?: string | null): Promise<void> {
    logger.debug("Connecting to audio device: ", audioDeviceId || "default")
    try {
      const localAudioTrack = await createLocalAudioTrack({
        name: AVStore.genTrackName("defaultAudio"),
        echoCancellation: true,
        autoGainControl: true,
        noiseSuppression: true,
        ...(audioDeviceId && { deviceId: audioDeviceId }),
      })
      const audioDevice = new MediaDevice(localAudioTrack)
      audioDevice.setMuted(this.audioDeviceIsMuted)
      this.setAudioDevice(audioDevice)
    } catch (error) {
      logger.error("An error occurred:", error)
    }
  }

  async connectToVideoDevice(videoDeviceId?: string | null): Promise<void> {
    logger.debug("Connecting to video device: ", videoDeviceId || "default")
    try {
      const localVideoTrack = await createLocalVideoTrack({
        name: AVStore.genTrackName("defaultVideo"),
        height: mainVideoHeight,
        width: mainVideoWidth,
        ...(videoDeviceId && { deviceId: videoDeviceId }),
      })
      const videoDevice = new MediaDevice(localVideoTrack)
      videoDevice.setMuted(this.videoDeviceIsMuted)
      this.setVideoDevice(videoDevice)
    } catch (error) {
      logger.error("An error occurred:", error)
    }
  }

  dispose(): void {
    logger.debug("Disposing MediaDeviceManager")
    this._audioDevice?.dispose()
    this._videoDevice?.dispose()
    this._videoDevice = null
    this._audioDevice = null
  }
}

export default MediaDeviceStore
export { MediaDevice }
export type { AudioDevice, VideoDevice }

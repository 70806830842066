/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import RequestedSelfExamStore from "views/exam/requested-self-exam/stores/requested-self-exam-store"
import React, { createContext, ReactElement, ReactNode, useEffect, useRef, useState } from "react"
import M1MediaDeviceStore from "@medaica/common/services/m1-media-device-store"
import { useLocation, useParams, useSearchParams } from "react-router-dom"
import useGlobalContext from "@medaica/common/hooks/global-context"
import { MedaicaApiAccessToken } from "@medaica/common/types"
import MedaicaApiService from "@medaica/common/services/medaica-api-service"
import { Paths } from "const"
import M1DeviceInfoUtil from "@medaica/common/services/m1-device-info-util"
import URI from "urijs"
import Loading from "@medaica/common/components/loading"
import useNav from "@medaica/common/hooks/nav"

type RequestedSelfExamContextInterface = {
  requestedSelfExamStore: RequestedSelfExamStore
  mediaDeviceManager: M1MediaDeviceStore
}

const RequestedSelfExamContext = createContext<RequestedSelfExamContextInterface>(
  null as unknown as RequestedSelfExamContextInterface
)

const RequestedSelfExamContextProvider = ({
  accessToken,
  children,
}: {
  accessToken: MedaicaApiAccessToken
  children: ReactNode
}): ReactElement => {
  const { selfExamRequestId } = useParams<{ selfExamRequestId: string }>()
  const [searchParams] = useSearchParams()
  const navigate = useNav()
  const location = useLocation()
  const { medaicaApiService } = useGlobalContext()
  const mediaDeviceManagerRef = useRef(new M1MediaDeviceStore(new M1DeviceInfoUtil()))
  const [requestedSelfExamStore, setRequestedSelfExamStore] = useState<RequestedSelfExamStore | null>(null)

  useEffect(() => {
    void (async () => {
      const medaicaApiService = new MedaicaApiService(() => {
        return new Promise<string>((resolve) => {
          if (new Date() < new Date(accessToken.expiresAt)) {
            resolve(accessToken.value)
          } else {
            navigate(Paths.selfExam)
          }
        })
      })

      const navigator = (segment: string) => {
        navigate(URI.joinPaths(segment).query(searchParams.toString()).valueOf())
      }

      const requestedSelfExamStore = new RequestedSelfExamStore(
        selfExamRequestId,
        navigator,
        medaicaApiService,
        mediaDeviceManagerRef.current
      )

      await requestedSelfExamStore.init()

      setRequestedSelfExamStore(requestedSelfExamStore)
    })()
  }, [searchParams, selfExamRequestId, location.search, medaicaApiService, accessToken, navigate])

  return requestedSelfExamStore ? (
    <RequestedSelfExamContext.Provider
      value={{
        requestedSelfExamStore,
        mediaDeviceManager: mediaDeviceManagerRef.current,
      }}
    >
      {children}
    </RequestedSelfExamContext.Provider>
  ) : (
    <Loading />
  )
}

export { RequestedSelfExamContextProvider }
export default RequestedSelfExamContext
export type { RequestedSelfExamContextInterface }

/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement } from "react"
import moment from "moment"
import { Auscultation } from "@medaica/common/types"
import AudioPlayer from "@medaica/common/components/audio-player"
import AuscultationPointImage from "@medaica/common/components/images/auscultation-point"
import useGlobalContext from "@medaica/common/hooks/global-context"
import { buildAudioFileFromAuscultation } from "@medaica/common/services/factories"
import { WAFFileContext } from "@medaica/common/components/audio-player/web-audio-filters"

const AuscultationTable = ({ auscultations }: { auscultations: Auscultation[] }): ReactElement => {
  const { medaicaApiService } = useGlobalContext()
  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="panel">
            <table className="min-w-full divide-y divide-gray-200">
              <caption className="bg-gray-50 px-6 py-3 text-left text-table-caption">Auscultations</caption>
              <tbody className="bg-white divide-y divide-gray-200">
                {auscultations.map((auscultation) => (
                  <tr key={auscultation.id}>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      <AuscultationPointImage
                        style={{ width: "62px" }}
                        highlightedPoint={auscultation.auscultationPoint}
                      />
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-gray-500">
                      {moment(auscultation.dateCreated).format("lll")}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-gray-500">
                      <AudioPlayer
                        filterContext={new WAFFileContext()}
                        date={auscultation.dateCreated}
                        audioFile={buildAudioFileFromAuscultation(auscultation, medaicaApiService)}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AuscultationTable

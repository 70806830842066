/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement } from "react"
import "./styles.css"

const Privacy = (): ReactElement => {
  return (
    <div className="main-centered">
      <h1 className="header h1-header">Privacy Policy</h1>
      <p>Updated August 1, 2023</p>
      <br />
      <p className="justified-text">
        <strong>Medaica Inc.</strong> ("we," "us," or "our") is committed to protecting the privacy and security of your
        Personal Identifiable Information (“PII”).
        <br />
        <br />
        PII refers to any information that can be used to identify an individual.
        <br />
        <br />
        This Privacy Policy describes how we collect, use, disclose, and safeguard the PII of users ("you" or "users")
        of our medical devices (“Devices”) and related services through our Devices, web and mobile applications,
        software applications delivered through third parties, and websites, including medaica.com (“Services”). By
        accessing or using our products and services, you agree to the terms of this Privacy Policy.
        <br />
        <br />
        BY SUBMITTING YOUR PERSONAL INFORMATION THROUGH OUR SERVICES, YOU ARE ACKNOWLEDGING THAT YOU HAVE READ AND AGREE
        TO THE TERMS OF THIS PRIVACY POLICY. IF YOU DO NOT AGREE, PLEASE DO NOT LOG INTO OR ACCESS OUR SERVICES AND DO
        NOT SUBMIT ANY INFORMATION TO US.
      </p>
      <h2 className="header h2-header">Information We Collect</h2>
      <p className="justified-text">
        We may collect the following types of personal information from patients or providers:
        <ol className="custom-ol">
          <li className="custom-li">
            PII: When you register to use or create a Medaica account, we may collect PII, such as your name, contact
            information (including ID, password, email address, mailing address, and phone number), date of birth, and
            demographic information. If you are a healthcare provider, we may also collect your National Provider
            Identifier (“NPI”).
          </li>
          <li className="custom-li">
            Medical Information: We may collect medical information, such as your health condition, medical history,
            prescriptions, and other health-related data. This information is collected to provide you with personalized
            medical advice and to improve our products and services.
          </li>
          <li className="custom-li">
            Usage Information: We may collect certain information through your use of our Devices and Services, such as
            but not limited to: auscultation data, Device ID, diagnosed condition, mobile device data, average heart
            rate, the location on the body where the recording was taken, local time, time zone and geographic location
            of data acquisition. We may also collect information about your interaction with our products and services,
            including device information, log files, and usage data. This information helps us understand how you use
            our products and improve their functionality and performance.
          </li>
          <li className="custom-li">
            Web Analytics Technologies: When you visit our Service or open our emails, we and our third-party service
            providers may collect information by automated means, such as but not limited to cookies and web server
            logs. The information collected includes IP address, browser characteristics, device IDs and
            characteristics, operating system version, language preferences, referring URLs, and information about the
            usage of our Service. You may be able to change browser settings to block and delete cookies when you access
            our Services through a web browser, however, if you do that, the Services may not function properly. This
            information is used to help provide additional functionality to the Services and/or help us analyze usage
            for research, marketing and development purposes. We will not collect PII without your permission.
          </li>
          <li className="custom-li">
            Payment Information: We use third-party payment processors to process payments made to us. When such
            payments are made through the Services, you may need to provide your shipping address and financial account
            information, such as a credit card number, to our third-party service providers. All information provided in
            connection payments to us is collected and stored by the third-party service provider, not by Medaica.
            Medaica may receive transaction identifiers and summary information, but we do not collect or store credit
            card or bank account numbers. Use of third-party payment processors to make payments to Medaica is governed
            by that third-party’s Terms of Use, Privacy Policy, and other applicable Terms. Please read those terms
            before using the third-party site and contact the third-party if you have questions. MEDAICA CANNOT BE HELD
            LIABLE FOR ANY LOSS DAMAGE, THEFT, OR OTHER ACTION ARISING FROM A THIRD-PARTY PAYMENT PROCESSOR’S FAILURE TO
            KEEP YOUR INFORMATION SECURE.
          </li>
          <li className="custom-li">
            De-identified, Anonymized and Pseudonymized Data: Medaica may compile and analyze any data we collect both
            on an aggregate basis and on a de-identified and pseudonymized basis to produce research and analyses.
            De-identified data and pseudonymized data cannot be used to identify you, but may contain markers that
            permit later reidentification by Medaica. Anonymized data cannot be used to identify you and is unable to be
            re-identified and is no longer considered PII. Medaica may share the research and analyses in aggregated and
            anonymized format with our aﬃliates, agents, Customers, Customers’ affiliates, and other healthcare research
            and services entities. Such research and analyses cannot be used to identify you personally and cannot be
            re-identified. Medaica may disclose aggregated, de-identiﬁed and/or pseudonymized information in order to
            describe our business and the Services to current and prospective business partners and Customers, and to
            other third parties for other lawful purposes. Medaica retains anonymized recording data from Devices. These
            These do not contain any associated PII.
          </li>
        </ol>
      </p>
      <h2 className="header h2-header">How We Use Your Information</h2>
      <p className="justified-text">
        We may use the collected information for the following purposes:
        <ol className="custom-ol">
          <li className="custom-li">
            Provision of Products and Services: We use your personal information to deliver our medical devices and
            related services to you, including monitoring your health, providing medical advice, and managing your
            account.
          </li>
          <li className="custom-li">
            Personalization: We may use your information to personalize your experience with our products and services,
            including tailoring recommendations, alerts, and notifications based on your health condition and
            preferences.
          </li>
          <li className="custom-li">
            Communication: We may use your information to communicate with you, respond to your inquiries, provide
            updates about our products and services, and send you important notices.
          </li>
          <li className="custom-li">
            Research and Development: We may use your information for research and development purposes, including
            improving our products, developing new features, and conducting data analysis to enhance medical
            understanding.
          </li>
          <li className="custom-li">
            Legal Compliance: We may use your information to comply with applicable laws, regulations, and legal
            processes, as well as enforce our terms and conditions and protect our rights, privacy, safety, or property.
          </li>
        </ol>
      </p>
      <h2 className="header h2-header">Information Sharing and Disclosure</h2>
      <p className="justified-text">
        We may share your personal information with the following parties:
        <ol className="custom-ol">
          <li className="custom-li">
            Service Providers: We may engage third-party service providers to perform various functions on our behalf,
            such as hosting services, data analysis, customer support, and marketing assistance. These service providers
            are authorized to access your personal information only as necessary to perform their services for us and
            are obligated to protect your information.
          </li>
          <li className="custom-li">
            Business Transfers: In the event of a merger, acquisition, sale, or transfer of assets, your personal
            information may be transferred as part of the transaction. We will notify you via email and/or prominent
            notice on our website of any change in ownership or use of your personal information, as well as any choices
            you may have regarding your information.
          </li>
          <li className="custom-li">
            Legal Requirements: We may disclose your personal information to comply with applicable laws, regulations,
            legal processes, or governmental requests, as well as to protect and defend our rights, privacy, safety, or
            property.
          </li>
          <li className="custom-li">
            HIPAA Compliance: Notwithstanding anything in this Privacy Policy to the contrary, to the extent we create,
            receive, maintain, or transmit (collectively, “Process”) “protected health information” (as such term is
            defined in 45 C.F.R 160.103) in providing the Service, we shall only use and disclose that information in
            accordance with the Health Insurance Portability and Accountability Act of 1996, as amended, and its
            implementing regulations (collectively, “HIPAA”). HIPAA also requires us to, among other things, apply
            reasonable and appropriate measures to safeguard the confidentiality, integrity, and availability of the
            protected health information we Process. For Patients: Under HIPAA, your healthcare provider is generally
            required to provide or make available to you a Notice of Privacy Practices (“NPP”). The NPP is intended to
            explain to you the ways in which your healthcare provider may use and share your protected health
            information and inform you about your health privacy rights. For more information about how your healthcare
            provider uses and shares your information, ask your healthcare provider for a copy of their NPP. MEDAICA IS
            NOT RESPONSIBLE FOR YOUR HEALTHCARE PROVIDER’S USE OR SHARING OF YOUR INFORMATION.
          </li>
        </ol>
      </p>
      <h2 className="header h2-header">Data Security and Retention</h2>
      <p className="justified-text">
        <ol className="custom-ol">
          <li className="custom-li">
            Security: We employ industry-standard security measures to protect your PII from unauthorized access,
            alteration, disclosure, or destruction. However, no method of transmission over the Internet or electronic
            storage is entirely secure, and we cannot guarantee absolute security.
          </li>
          <li className="custom-li">
            Retention of records: We retain your PII for as long as necessary to fulfill the purposes outlined in this
            Privacy Policy unless a longer retention period is required or permitted by law. We will securely delete or
            anonymize your information when it is no longer needed.
          </li>
          <li className="custom-li">
            Third parties: Once we have disclosed your Personal Information to third parties, we may not be able to
            access or control that information any longer, and we cannot compel those parties to delete or modify it. If
            you have specific requests for the deletion of your Personal Information, except as otherwise described in
            this Privacy Policy, please contact us.
          </li>
        </ol>
      </p>
      <h2 className="header h2-header">Your Choices and Rights</h2>
      <p className="justified-text">
        You have certain choices and rights regarding your personal information:
        <ol className="custom-ol">
          <li className="custom-li">
            Access and Correction: You can access and update your personal information by logging into your account or
            contacting us. We strive to ensure that your information is accurate and complete.
          </li>
          <li className="custom-li">
            Marketing Communications: You can opt out of receiving marketing communications from us by following the
            unsubscribe instructions provided in the communication or contacting us directly.
          </li>
          <li className="custom-li">
            Do Not Track: Our website and services do not respond to "Do Not Track" signals. However, you can adjust
            your browser settings to disable cookies or control how your browser handles tracking technologies.
          </li>
          <li className="custom-li">
            Legal Requirements: Please note that your rights to your information may be limited in certain circumstances
            due to local legal requirements. You also have the right to withdraw your consent to the collection of your
            information. However, if you choose to block, delete, or withhold certain information or withdraw your
            consent, it may affect our ability to provide certain features and functionalities of the Service to you.
          </li>
        </ol>
      </p>
      <h2 className="header h2-header">Children's Privacy</h2>
      <p className="justified-text">
        <ol className="custom-ol">
          <li className="custom-li">
            Our products and services are not directed to individuals under the age of 18. We do not knowingly collect
            personal information from children. If you believe that we may have collected personal information from a
            child without parental consent, please contact us, and we will promptly take appropriate action.
          </li>
        </ol>
      </p>
      <h2 className="header h2-header">Updates to this Privacy Policy</h2>
      <p className="justified-text">
        <ol className="custom-ol">
          <li className="custom-li">
            We may update this Privacy Policy periodically to reflect changes in our information practices. We will
            notify you of any material changes by posting the updated Privacy Policy on our website or through other
            communication channels. We encourage you to review this Privacy Policy regularly.
          </li>
          <li className="custom-li">
            Other languages: We may provide versions of our Privacy Policy in languages other than English. In the event
            of any discrepancy between the English version of this Privacy Policy and any translation of this Privacy
            Policy, the English version shall prevail.
          </li>
        </ol>
      </p>
      <h2 className="header h2-header">Contact Us</h2>
      <p className="justified-text">
        <ol className="custom-ol">
          <li className="custom-li">
            If you have any questions, concerns, inquiries, or requests regarding this Privacy Policy or our privacy
            practices, please contact us using the following information:
            <br />
            <br />
            <blockquote>
              Medaica, Inc.
              <br />
              170 S. Green Valley Parkway, Suite 300
              <br />
              Henderson
              <br />
              NV 89012
              <br />
              1888 MEDAICA (633 2422)
            </blockquote>
          </li>
        </ol>
        <br />
        Please note that this Privacy Policy applies solely to our products and services and does not cover the
        practices of third parties that we do not control.
      </p>
    </div>
  )
}

export default Privacy

/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement, useCallback, useEffect, useState } from "react"
import useGlobalContext from "@medaica/common/hooks/global-context"
import Switch, { Case } from "react-switch-case"
import LoadingWrapper from "@medaica/common/components/loading-wrapper"
import useAuth from "services/auth"
import { useLocation, useNavigate } from "react-router-dom"
import { Paths } from "const"
import AgreeTCsForm from "views/auth/post-auth-pipeline/agree-tcs"
import OnboardForm from "views/auth/post-auth-pipeline/onboard"
import { getConfigNumber } from "@medaica/common/services/util"

const PostAuthPipeline = (): ReactElement => {
  const { medaicaApiService } = useGlobalContext()
  const [view, setView] = useState<"onboard" | "agreeTCs" | null>(null)
  const { user } = useAuth()
  const location = useLocation()
  const navigate = useNavigate()

  const handleComplete = useCallback(() => {
    navigate((location?.state?.returnTo as string) || Paths.home, { replace: true })
  }, [location?.state?.returnTo, navigate])

  useEffect(() => {
    void (async () => {
      // If there is no medaica ID, this is the first time they've logged in, so we onboard them.
      if (user.id == null) {
        setView("onboard")
      } else {
        const patient = await medaicaApiService.patients.getPatient(user.id)
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        if (patient.tcVersion == null || patient.tcVersion < getConfigNumber("TC_VERSION")) {
          setView("agreeTCs")
        } else {
          handleComplete()
        }
      }
    })()
  }, [user, medaicaApiService, handleComplete])

  return (
    <LoadingWrapper loading={!view}>
      <Switch condition={view}>
        <Case value={"onboard"}>
          <OnboardForm onComplete={handleComplete} />
        </Case>
        <Case value={"agreeTCs"}>
          <AgreeTCsForm onComplete={handleComplete} />
        </Case>
      </Switch>
    </LoadingWrapper>
  )
}

export default PostAuthPipeline

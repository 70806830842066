/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import * as Sentry from "@sentry/react"
import { configureScope, setUser } from "@sentry/react"
import * as Integrations from "@sentry/tracing/dist/browser"
import { ExtraErrorData } from "@sentry/integrations"
import { getConfig, getConfigBool, sentryBeforeSend } from "@medaica/common/services/util"
import { useEffect } from "react"
import { Auth } from "@medaica/common/services/auth"

Sentry.init({
  dsn: getConfigBool("USE_SENTRY") ? getConfig("SENTRY_DSN") : undefined,
  autoSessionTracking: true,
  integrations: [
    new Integrations.BrowserTracing(),
    new ExtraErrorData(),
    // So sentry doesn't intercept console logs. If it does, we don't get line numbers.
    // todo only do this when not prod?
    new Sentry.Integrations.Breadcrumbs({
      console: false,
    }),
  ],
  environment: getConfig("SENTRY_ENVIRONMENT"),
  release: getConfig("COMMIT_REF"),
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
  beforeSend: sentryBeforeSend,
})

const useSentryConfiguration = (useAuth: Auth): void => {
  const { isLoading, userMaybe } = useAuth()

  // Sets user ID on Sentry context so the current user gets logged
  useEffect(() => {
    if (!isLoading) {
      if (userMaybe) {
        setUser({ id: userMaybe.id })
      } else {
        configureScope((scope) => scope.setUser(null))
      }
    }
  }, [isLoading, userMaybe])
}

export default useSentryConfiguration

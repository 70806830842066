/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import { QualityScore } from "../Quality"
import { PreflightTestReport } from "twilio-video"
import { MediaConnectionBitrateTest } from "@twilio/rtc-diagnostics"

export const getSingleQualityScore = (
  stat: number | undefined,
  goodThreshold: number,
  suboptimalThreshold: number,
  poorThreshold: number,
  descending = false
): QualityScore => {
  if (typeof stat === "undefined") {
    // We ignore values that are missing
    return QualityScore.Excellent
  }

  if (descending) {
    if (stat > goodThreshold) return QualityScore.Excellent
    if (stat > suboptimalThreshold) return QualityScore.Good
    if (stat > poorThreshold) return QualityScore.Suboptimal
    return QualityScore.Poor
  }

  if (stat >= poorThreshold) return QualityScore.Poor
  if (stat >= suboptimalThreshold) return QualityScore.Suboptimal
  if (stat >= goodThreshold) return QualityScore.Good
  return QualityScore.Excellent
}

export const formatNumber = (val: number | undefined): string | undefined => {
  return val?.toLocaleString(undefined, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  })
}

export const getQualityScore = (
  preflightTestReport: PreflightTestReport | null,
  bitrateTestReport: MediaConnectionBitrateTest.Report | null
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any => {
  const maxBitrate = bitrateTestReport?.values ? Math.max(...bitrateTestReport.values) : 0

  const latency = {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    average: formatNumber(preflightTestReport?.stats!.rtt!.average),
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    max: formatNumber(preflightTestReport?.stats!.rtt!.max),
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    qualityScore: getSingleQualityScore(preflightTestReport?.stats!.rtt!.average, 100, 250, 400),
  }

  const jitter = {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    average: formatNumber(preflightTestReport?.stats!.jitter!.average),
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    max: formatNumber(preflightTestReport?.stats!.jitter!.max),
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    qualityScore: getSingleQualityScore(preflightTestReport?.stats!.jitter!.average, 5, 10, 30),
  }

  const packetLoss = {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    average: formatNumber(preflightTestReport?.stats!.packetLoss!.average),
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    max: formatNumber(preflightTestReport?.stats!.packetLoss!.max),
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    qualityScore: getSingleQualityScore(preflightTestReport?.stats!.packetLoss!.average, 1, 3, 8),
  }

  const bitrate = {
    average: formatNumber(bitrateTestReport?.averageBitrate),
    max: formatNumber(maxBitrate),
    qualityScore: getSingleQualityScore(bitrateTestReport?.averageBitrate, 1000, 500, 150, true),
  }

  const totalQualityScore = Math.min(
    latency.qualityScore as number,
    jitter.qualityScore as number,
    packetLoss.qualityScore as number,
    bitrate.qualityScore as number
  ) as QualityScore

  return {
    latency,
    jitter,
    packetLoss,
    bitrate,
    totalQualityScore,
  }
}

/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement, ReactNode, useState } from "react"
import VideoControls from "@medaica/common/components/video-controls"
import Video from "@medaica/common/components/video"
import { FormControl, FormLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material"
import useQuery from "@medaica/common/hooks/query"
import SettingsButton from "@medaica/common/views/exam/virtual-exam/components/settings-button"
import MinimalLayout from "@medaica/common/layouts/minimal-layout"
import { observer } from "mobx-react-lite"
import MediaDeviceStore from "@medaica/common/services/media-device-store"
import PreferencesStore from "@medaica/common/views/exam/virtual-exam/stores/preferences-store"
import SettingsPanel from "@medaica/common/views/exam/virtual-exam/components/settings-panel"
import { mainVideoHeight, mainVideoWidth } from "@medaica/common/const"

const videoButtonClassName =
  "h-14 w-14 flex justify-center items-center border border-white rounded-full" +
  " text-white hover:bg-white hover:bg-opacity-20"
const videoButtonOffClassname =
  "h-14 w-14 flex justify-center items-center text-white border-red-600 rounded-full bg-red-600"

// Todo make sure at least one video and audio device
const LobbyView = observer(
  ({
    preferencesStore,
    mediaDeviceStore,
    children,
  }: {
    preferencesStore: PreferencesStore
    mediaDeviceStore: MediaDeviceStore
    children: ReactNode
  }): ReactElement => {
    const selectAltSteth = useQuery("select-alt-steth")
    const [altSteth, setAltSteth] = useState<string | null>(null)

    const handleStethoscopeInputChanged = (event: SelectChangeEvent<string>) => {
      setAltSteth(event.target.value)
    }

    return (
      <MinimalLayout>
        <div className="flex flex-col justify-center h-full">
          <div className="flex gap-x-8">
            <div
              className="panel flex relative h-full bg-gray-200"
              style={{
                height: `${mainVideoHeight}px`,
                width: `${mainVideoWidth}px`,
              }}
            >
              <Video
                isLocal={true}
                videoTrack={mediaDeviceStore.videoDevice?.track}
                audioTrack={mediaDeviceStore.audioDevice?.track}
              />
              <div
                className="absolute bottom-0 p-3 flex flex-row gap-x-4 w-full justify-center bg-black
                bg-opacity-30"
              >
                <VideoControls
                  buttonClassName={videoButtonClassName}
                  buttonOffClassName={videoButtonOffClassname}
                  showLabels={false}
                  mediaDeviceStore={mediaDeviceStore}
                />
                <SettingsButton buttonClassName={videoButtonClassName} showLabel={false}>
                  <SettingsPanel preferencesStore={preferencesStore} mediaDeviceStore={mediaDeviceStore} />
                </SettingsButton>
              </div>
            </div>
            <div
              style={{
                position: "relative",
                height: "540px",
                width: "430px",
              }}
            >
              {children}
              {selectAltSteth && (
                <div>
                  <FormControl size="small" fullWidth>
                    <FormLabel id="microphoneLabel">Stethoscope</FormLabel>
                    <Select labelId="microphoneLabel" onChange={handleStethoscopeInputChanged} value={altSteth ?? ""}>
                      {mediaDeviceStore.availableAudioDevices.map((device) => (
                        <MenuItem key={device.deviceId} value={device.deviceId}>
                          {device.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              )}
            </div>
          </div>
        </div>
      </MinimalLayout>
    )
  }
)

export default LobbyView

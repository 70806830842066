/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement, ReactNode, useEffect, useState } from "react"
import { useAppStateContext } from "../../../AppStateProvider/AppStateProvider"
import { Box, Button, Container, Grid, Paper, Typography } from "@mui/material"
import ErrorIllustration from "./ErrorIllustration.png"
import { ErrorIcon } from "../../../../icons/ErrorIcon"
import UAParser from "ua-parser-js"
import { styled } from "@mui/material/styles"
import { MediaPermissionsError, MediaPermissionsErrorType } from "mic-check"
import { InformationCircleIcon } from "@heroicons/react/outline"

const PREFIX = "permissionError"

const classes = {
  mainContainer: `${PREFIX}-mainContainer`,
  heading: `${PREFIX}-heading`,
  grantPermissions: `${PREFIX}-grantPermissions`,
  illustration: `${PREFIX}-illustration`,
  header: `${PREFIX}-header`,
  refreshButton: `${PREFIX}-refreshButton`,
  paperContainer: `${PREFIX}-paperContainer`,
  paper: `${PREFIX}-paper`,
}

const StyledContainer = styled(Container)(() => ({
  [`& .${classes.mainContainer}`]: {
    justifyContent: "space-between",
  },

  [`& .${classes.heading}`]: {
    position: "relative",
    marginTop: "20px",
  },

  [`& .${classes.grantPermissions}`]: {
    fontSize: "16px",
    paddingBottom: "16px",
  },

  /* The size of the image is explicitly stated here so that this content can properly be centered vertically
before the image is loaded.*/
  [`& .${classes.illustration}`]: (theme) => ({
    maxHeight: "174px",
    maxWidth: "337px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  }),

  [`& .${classes.header}`]: (theme) => ({
    float: "left",
    [theme.breakpoints.down("md")]: {
      float: "initial",
    },
  }),

  [`& .${classes.refreshButton}`]: (theme) => ({
    clear: "left",
    [theme.breakpoints.down("md")]: {
      clear: "initial",
      marginBottom: "2em",
    },
  }),

  [`& .${classes.paperContainer}`]: (theme) => ({
    float: "right",
    marginRight: "1em",
    [theme.breakpoints.down("md")]: {
      float: "initial",
      display: "flex",
      justifyContent: "center",
      margin: "0 0 2.5em 0",
    },
  }),

  [`& .${classes.paper}`]: {
    display: "inline-block",
    padding: "20px",
    borderRadius: "8px",
  },
}))

function getSettingsDataByOs(os: string, device: "microphone" | "camera") {
  return {
    "Mac OS": {
      name: "system preferences",
      link: `x-apple.systempreferences:com.apple.preference.security?${
        device === "microphone" ? "Privacy_Microphone" : "Privacy_Camera"
      }`,
    },
    Windows: {
      name: "operation system settings",
      link: `ms-settings:${device === "microphone" ? "privacy-microphone" : "privacy-webcam"}`,
    },
  }[os]
}

function getSystemDeniedContent(): { message: string; buttonText?: string; onButtonClicked: () => void } {
  const uaParser = new UAParser()
  // todo what if standalone app
  const appName = uaParser.getBrowser().name
  const osName = uaParser.getOS().name
  // @ts-ignore
  const osSettings = getSettingsDataByOs(osName as string, "camera")

  let settingsLocation
  let buttonText
  let onButtonClicked

  if (osSettings) {
    settingsLocation = osSettings.name
    buttonText = `Open ${osSettings.name}`
    onButtonClicked = () => window.open(osSettings.link, "_blank")
  } else {
    settingsLocation = "your operation system settings"
  }

  return {
    message: `Make sure that ${appName} has access to your microphone and camera in ${settingsLocation}.`,
    buttonText,
    onButtonClicked,
  }
}

export const getDeviceErrorPaneContent = (
  error: MediaPermissionsError
): {
  headline: string
  message: ReactNode
} => {
  if (error.type === MediaPermissionsErrorType.SystemPermissionDenied) {
    const systemDeniedContent = getSystemDeniedContent()
    return {
      headline: "Can't access your microphone or camera",
      message: (
        <>
          Either your microphone and/or camera are not connected or your operating system has blocked the browser from
          accessing them. To troubleshoot:
          <ol className="list-decimal list-inside mt-4 space-y-4">
            <li>If you have an external microphone or camera, make sure that it is plugged in.</li>
            <li>
              {systemDeniedContent.message}
              {systemDeniedContent.buttonText && (
                <Button
                  variant="outlined"
                  size="small"
                  color="primary"
                  className="mt-3 my-auto"
                  onClick={systemDeniedContent.onButtonClicked}
                >
                  {systemDeniedContent.buttonText}
                </Button>
              )}
            </li>
          </ol>
        </>
      ),
    }
  } else if (error.type === MediaPermissionsErrorType.UserPermissionDenied) {
    return {
      headline: "Permission denied",
      message: (
        <>
          A Medaica Virtual Exam requires access to your camera and microphone. Click the{" "}
          <InformationCircleIcon className="h-6 inline" /> icon in your browser's address bar to grant access to your
          camera and microphone.
        </>
      ),
    }
  } else if (error.type === MediaPermissionsErrorType.CouldNotStartVideoSource) {
    return {
      headline: "Can't start your camera or microphone",
      message:
        // eslint-disable-next-line max-len
        "Another application (for example, Zoom or Skype) or a website in another browser tab (for example, Google Meet or Messenger Video) might already be using your webcam. Please quit any other applications and tabs using your webcam.",
    }
  } else {
    return {
      headline: "Error acquiring media",
      message: `${error.name} ${error.message}`,
    }
  }
}

export const PermissionError = (): ReactElement => {
  const { state } = useAppStateContext()

  const [pageState, setPageState] = useState<{
    headline: string
    message: ReactNode
  } | null>(null)

  useEffect(() => {
    if (state.deviceError) {
      const pageState = getDeviceErrorPaneContent(state.deviceError)
      setPageState(pageState)
    }
  }, [state.deviceError])

  return (
    <StyledContainer>
      <Grid container className={classes.mainContainer}>
        <Grid item lg={5} className={classes.header}>
          <Typography variant="h3" component="h1" gutterBottom className={classes.heading}>
            <ErrorIcon />
            {pageState?.headline}
          </Typography>

          <Typography variant="body1" gutterBottom>
            {pageState?.message}
          </Typography>

          <Box mt={3}>
            <Typography variant="body1" gutterBottom>
              <strong>Try the solutions above and refresh this page.</strong>
            </Typography>

            <Box mt={1.5}>
              <Button
                variant="contained"
                style={{ marginRight: "1.5em" }}
                color="primary"
                onClick={() => window.location.reload()}
              >
                Refresh page
              </Button>
            </Box>
          </Box>
        </Grid>

        <Grid item lg={5} className={classes.paperContainer}>
          <Paper className={classes.paper}>
            <Typography variant="body1" className={classes.grantPermissions}>
              <strong>Permissions</strong>
            </Typography>
            <img src={ErrorIllustration} alt="Settings Illustration" className={classes.illustration} />
          </Paper>
        </Grid>
      </Grid>
    </StyledContainer>
  )
}

/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import { useEffect } from "react"

const useWarnBeforeUnload = (shouldInterruptUnload: () => boolean): void => {
  useEffect(() => {
    // We want to prevent the user from leaving the page before the uploads have completed
    const handleBeforeUnload = (event) => {
      if (shouldInterruptUnload()) {
        // noinspection JSDeprecatedSymbols
        const e = event || window.event
        e.preventDefault()
        if (e) {
          e.returnValue = ""
        }
        return ""
      }
    }

    window.addEventListener("beforeunload", handleBeforeUnload)

    // When the exam session unloads, we clean up all the resources
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload)
    }
  }, [shouldInterruptUnload])
}

export default useWarnBeforeUnload

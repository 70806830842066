/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import { useAppStateContext } from "../../../AppStateProvider/AppStateProvider"
import { Button, Container, Grid, Typography, Paper, Box } from "@mui/material"
import { DownloadIcon } from "../../../../icons/DownloadIcon"
import { ErrorIcon } from "../../../../icons/ErrorIcon"
import { ViewIcon } from "../../../../icons/ViewIcon"
import React, { ReactElement } from "react"
import { styled } from "@mui/material/styles"

const PREFIX = "connectionFailed"

const classes = {
  mainContainer: `${PREFIX}-mainContainer`,
  header: `${PREFIX}-header`,
  heading: `${PREFIX}-heading`,
  paperContainer: `${PREFIX}-paperContainer`,
  paper: `${PREFIX}-paper`,
  downloadButton: `${PREFIX}-downloadButton`,
  viewButton: `${PREFIX}-viewButton`,
}

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme: Theme }) => ({
  [`& .${classes.mainContainer}`]: {
    justifyContent: "space-between",
  },

  [`& .${classes.header}`]: (theme) => ({
    float: "left",
    [theme.breakpoints.down("md")]: {
      float: "initial",
    },
  }),

  [`& .${classes.heading}`]: {
    position: "relative",
  },

  [`& .${classes.paperContainer}`]: (theme) => ({
    float: "right",
    marginRight: "1em",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      float: "initial",
      justifyContent: "center",
      margin: "0 0 2.5em 0",
    },
  }),

  [`& .${classes.paper}`]: (theme) => ({
    padding: "1.5em",
    borderRadius: "8px",
    maxWidth: "400px",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  }),

  [`& .${classes.downloadButton}`]: {
    "& svg": {
      position: "relative",
      left: "-5px",
    },
  },

  [`& .${classes.viewButton}`]: {
    marginTop: "2em",
    "& svg": {
      position: "relative",
      left: "-5px",
    },
  },
}))

interface ConnectionFailedProps {
  openModal: () => void
}

export const ConnectionFailed = ({ openModal }: ConnectionFailedProps): ReactElement => {
  const { downloadFinalTestResults } = useAppStateContext()

  return (
    <Root>
      <Container>
        <Grid container className={classes.mainContainer}>
          <Grid item lg={5} className={classes.header}>
            <Typography component="h1" variant="h3" gutterBottom className={classes.heading}>
              <ErrorIcon />
              Connection failed
            </Typography>

            <Typography variant="body1" gutterBottom>
              It's possible that you're behind a firewall that will need to be updated by your network administrator.
            </Typography>

            <Typography variant="body1" gutterBottom>
              Download report results to send to your network admin, update your internet, and re-run this test.
            </Typography>

            <Box mt={3}>
              <Button
                variant="contained"
                color="primary"
                onClick={downloadFinalTestResults}
                className={classes.downloadButton}
              >
                <DownloadIcon />
                Download report results
              </Button>
            </Box>
          </Grid>

          <Grid item lg={5} className={classes.paperContainer}>
            <div className="flex flex-col items-center">
              <Paper className={classes.paper}>
                <Typography variant="body1" gutterBottom>
                  To conduct a video call, your internet needs to be able to communicate with Twilio's cloud.
                </Typography>

                <Typography variant="body1">
                  Learn{" "}
                  <a href="https://www.twilio.com/docs/video/ip-addresses" target="_blank" rel="noopener noreferrer">
                    how to configure your firewall correctly
                  </a>
                  .
                </Typography>
              </Paper>

              <Button
                variant="outlined"
                onClick={openModal}
                className="mt-4 text-black bg-white"
                sx={{
                  "& svg": {
                    position: "relative",
                    left: "-5px",
                  },
                }}
              >
                <ViewIcon />
                View detailed connection information
              </Button>
            </div>
          </Grid>
        </Grid>
      </Container>
    </Root>
  )
}

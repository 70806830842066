/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement } from "react"
import { Button } from "@mui/material"
import useAnonAuth from "views/exam/virtual-exam/hooks/anonAuth"
import URI from "urijs"
import ActionPanel from "@medaica/common/views/exam/virtual-exam/lobby/action-panel"
import ActionPanelTitle from "@medaica/common/views/exam/virtual-exam/lobby/action-panel-title"
import ActionPanelActions from "@medaica/common/views/exam/virtual-exam/lobby/action-panel-actions"

const Reentry = ({
  onEnterExam,
}: {
  onEnterExam: (patientName: string, virtualExamId: string) => void
}): ReactElement => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const virtualExamId = URI(window.location.href).segment(1)!
  const auth = useAnonAuth()

  return (
    <ActionPanel>
      <ActionPanelTitle>You have left the exam</ActionPanelTitle>
      <ActionPanelActions>
        <Button
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          onClick={() => onEnterExam(auth.getAccessToken(virtualExamId)!.userName, virtualExamId)}
          variant="contained"
        >
          Re-enter exam
        </Button>
      </ActionPanelActions>
    </ActionPanel>
  )
}

export default Reentry

/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import { Dispatch, useCallback, useRef } from "react"
import { testMediaConnectionBitrate, MediaConnectionBitrateTest } from "@twilio/rtc-diagnostics"
import { ACTIONTYPE } from "../AppStateProvider"
import useGlobalContext from "@medaica/common/hooks/global-context"
import { logError } from "@medaica/common/services/util"

const useBitrateTest = (dispatch: Dispatch<ACTIONTYPE>): { startBitrateTest: () => Promise<void> | undefined } => {
  const { medaicaApiService } = useGlobalContext()
  const bitrateTestRef = useRef<MediaConnectionBitrateTest>()

  const startBitrateTest = useCallback(async () => {
    //Don't start a new bitrate test if one is already running:
    if (bitrateTestRef.current) {
      return
    }

    dispatch({ type: "bitrate-test-started" })

    return medaicaApiService.twilio
      .getTwilioTurnCredentials()
      .then((response) => {
        const bitrateTest = testMediaConnectionBitrate({ iceServers: response.iceServers })

        bitrateTestRef.current = bitrateTest

        bitrateTest.on(MediaConnectionBitrateTest.Events.Bitrate, (bitrate) => {
          dispatch({ type: "set-bitrate", bitrate })
        })

        bitrateTest.on(MediaConnectionBitrateTest.Events.Error, (error) => {
          dispatch({ type: "set-bitrate-test-error", error })
          dispatch({ type: "bitrate-test-finished" })
        })

        bitrateTest.on(MediaConnectionBitrateTest.Events.End, (report) => {
          dispatch({ type: "set-bitrate-test-report", report })
          dispatch({ type: "bitrate-test-finished" })
        })

        setTimeout(() => {
          bitrateTest.stop()
        }, 15000)
      })
      .catch((error) => {
        logError(error, null, "Error running the bitrate test")
        dispatch({ type: "set-bitrate-test-error", error })
        dispatch({ type: "bitrate-test-finished" })
      })
  }, [dispatch, medaicaApiService])

  return { startBitrateTest }
}

export default useBitrateTest

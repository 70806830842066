/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import MedaicaApiService, { pagination, QuerySpec } from "./"
import { Page, PatientProfile, SelfExamRequest } from "@medaica/common/types"
import { instanceToPlain, plainToInstance } from "class-transformer"

class PatientProfilesApi {
  private _apiService: MedaicaApiService

  constructor(apiService: MedaicaApiService) {
    this._apiService = apiService
  }

  async getPatientProfile(patientProfileId: string): Promise<PatientProfile> {
    const apiClient = await this._apiService.apiClient()
    const response = await apiClient.get<PatientProfile>(`/patient-profiles/${patientProfileId}`)
    return plainToInstance(PatientProfile, response.data)
  }

  async getPatientProfiles(query: QuerySpec): Promise<Page<PatientProfile>> {
    const apiClient = await this._apiService.apiClient()
    const response = await apiClient.get(
      this._apiService.withQuery("/search/patient-profiles", {
        q: JSON.stringify(query),
      })
    )
    return {
      // @ts-ignore
      items: plainToInstance(PatientProfile, response.data),
      ...pagination(response),
    }
  }

  async updatePatientProfile(patientProfile: PatientProfile): Promise<PatientProfile> {
    const apiClient = await this._apiService.apiClient()
    const response = await apiClient.patch(`/patient-profiles/${patientProfile.id}`, instanceToPlain(patientProfile))
    return plainToInstance(PatientProfile, response.data)
  }

  async createPatientProfile(patientProfile: PatientProfile): Promise<PatientProfile> {
    const apiClient = await this._apiService.apiClient()
    const response = await apiClient.post("/patient-profiles", instanceToPlain(patientProfile))
    return plainToInstance(PatientProfile, response.data)
  }

  async createSelfExamRequest(
    healthcareProviderId: string,
    patientProfileId: string,
    examDate: Date,
    instructions: string,
    auscultationPoints: string[]
  ): Promise<SelfExamRequest> {
    const apiClient = await this._apiService.apiClient()
    const response = await apiClient.post<SelfExamRequest>(`/patient-profiles/${patientProfileId}/self-exam-requests`, {
      healthcareProviderId,
      examDate,
      instructions,
      auscultationPoints,
    })
    return plainToInstance(SelfExamRequest, response.data)
  }
}

export default PatientProfilesApi

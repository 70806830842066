/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

module.exports = {
  // corePlugins: {
  //   preflight: false,
  // },
  purge: ["./src/**/*.{js,jsx,ts,tsx}", "../common/**/*.{js,jsx,ts,tsx}", "./public/index.html"],
  darkMode: false,
  theme: {
    extend: {
      fontFamily: {
        sane: [
          "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Fira Sans', 'Droid Sans', " +
            "'Helvetica Neue', sans-serif;",
        ],
        //sans: ["inter", "sans-serif"],
      },
      strokeWidth: {
        icon: "1.4",
      },
      colors: {
        primary: {
          DEFAULT: "#3B3AEE",
          50: "#FFFFFF",
          100: "#F6F6FE",
          125: "#EEEEFE",
          150: "#D7D7FB",
          200: "#CFCFFB",
          300: "#9898F6",
          400: "#6A69F2",
          500: "#3B3AEE",
          600: "#1513E2",
          700: "#100FB3",
          800: "#0C0B84",
          900: "#080755",
        },
        secondary: "#76E6CF",
      },
      maxWidth: {
        "main-centered": "63rem",
      },
    },
  },
  variants: {
    extend: {
      opacity: ["disabled"],
      borderColor: ["disabled"],
      outline: ["focus"],
      backgroundColor: ["disabled"],
      cursor: ["disabled"],
      ringWidth: ["hover"],
      ringColor: ["hover"],
    },
  },
  plugins: [require("@tailwindcss/forms")],
}

/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React from "react"
import LoggingErrorBoundary from "@medaica/common/components/logging-error-boundary"

const Inner = ({ children }) => {
  const raiseError = () => {
    throw Error("normal error from inner")
  }

  const raisePromiseException = async () => {
    throw Error("unhandled promise from inner")
  }
  return (
    <div>
      <button onClick={raiseError}>raise error from inner</button>
      <br />
      <button onClick={raisePromiseException}>raise unhandled promise exception from inner</button>
      {children}
    </div>
  )
}

const InnerFallback = () => {
  return <div>oh no an error</div>
}

const Outest = () => {
  const raiseError = () => {
    throw Error("normal error from outest")
  }

  const raisePromiseException = async () => {
    throw Error("unhandled promise from outest")
  }
  return (
    <div>
      <button onClick={raiseError}>raise error</button>
      <br />
      <button onClick={raisePromiseException}>raise unhandled promise exception</button>
      <LoggingErrorBoundary FallbackComponent={InnerFallback}>
        <Inner />
      </LoggingErrorBoundary>
    </div>
  )
}

export default Outest

/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import { Container, Typography, Grid, Button, Box } from "@mui/material"
import { useAppStateContext } from "../../../AppStateProvider/AppStateProvider"
import { QualityScore } from "../Quality"
import Excellent from "./Excellent.png"
import { ViewIcon } from "../../../../icons/ViewIcon"
import React, { ReactElement } from "react"
import { styled } from "@mui/material/styles"

const PREFIX = "excellentQuality"

const classes = {
  mainContainer: `${PREFIX}-mainContainer`,
  header: `${PREFIX}-header`,
  illustrationContainer: `${PREFIX}-illustrationContainer`,
  illustration: `${PREFIX}-illustration`,
  viewButton: `${PREFIX}-viewButton`,
  finishButton: `${PREFIX}-finishButton`,
}

const StyledContainer = styled(Container)(() => ({
  [`& .${classes.mainContainer}`]: {
    justifyContent: "space-between",
  },

  [`& .${classes.header}`]: (theme) => ({
    float: "left",
    [theme.breakpoints.down("md")]: {
      float: "initial",
    },
  }),

  [`& .${classes.illustrationContainer}`]: (theme) => ({
    float: "right",
    marginRight: "1em",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      float: "initial",
      justifyContent: "center",
      margin: "0 0 2.5em 0",
    },
  }),

  /*
    The size of the image is explicitly stated here so that this content can properly be centered vertically
    before the image is loaded.
  */
  [`& .${classes.illustration}`]: {
    width: "245px",
    height: "200px",
  },

  [`& .${classes.viewButton}`]: {
    marginTop: "2em",
    "& svg": {
      position: "relative",
      left: "-5px",
    },
  },

  [`& .${classes.finishButton}`]: (theme) => ({
    clear: "left",
    [theme.breakpoints.down("md")]: {
      clear: "initial",
      marginBottom: "2em",
    },
  }),
}))

interface ExcellentQualityProps {
  quality: QualityScore
  openModal: () => void
}

export const ExcellentQuality = ({ quality, openModal }: ExcellentQualityProps): ReactElement => {
  const { nextPane } = useAppStateContext()

  const qualityScore = quality === QualityScore.Excellent ? "excellent" : "good"

  return (
    <StyledContainer>
      <Grid container className={classes.mainContainer}>
        <Grid item lg={5} className={classes.header}>
          <Typography component="h1" variant="h3" gutterBottom>
            Expected quality is {qualityScore}
          </Typography>

          <Typography variant="body1" gutterBottom>
            This is the last step! Your expected audio and video quality is <strong>{qualityScore}</strong> and overall
            performance looks {qualityScore === "excellent" ? "good" : "ok"}.
          </Typography>

          <Box mt={3}>
            <Button variant="contained" color="primary" onClick={nextPane}>
              Finish up!
            </Button>
          </Box>
        </Grid>

        <Grid item lg={5} className={classes.illustrationContainer}>
          <img src={Excellent} alt="Success" className={classes.illustration} />
          <Button variant="outlined" onClick={openModal} className={classes.viewButton}>
            <ViewIcon />
            View detailed quality information
          </Button>
        </Grid>
      </Grid>
    </StyledContainer>
  )
}

/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement, useState, useMemo, useCallback } from "react"
import type { ReactNode } from "react"
import MedaicaApiService from "@medaica/common/services/medaica-api-service"
import Loading from "@medaica/common/components/loading"
import { Auth } from "@medaica/common/services/auth"
import { getCurrentUrlFullPath } from "@medaica/common/services/util"

type GlobalContextInterface = {
  section: string | null
  setSection: (section: string) => void
  pathToHome: string
  setCurrentPage: (section: string, path: string) => void
  currentPage: { section: string; path: string }
  readonly medaicaApiService: MedaicaApiService
}

const GlobalContext = React.createContext<GlobalContextInterface>(null as unknown as GlobalContextInterface)

const GlobalContextProvider = ({
  pathToHome,
  useAuth,
  children,
}: {
  pathToHome: string
  useAuth: Auth
  children: ReactNode
}): ReactElement => {
  const { login, isAuthenticated, isLoading, getAccessToken } = useAuth()

  const [section, setSection] = useState<string | null>(null)

  const [currentPage, _setCurrentPage] = useState<{ section: string; path: string }>({
    section: "dashboard",
    path: "/",
  })

  const setCurrentPage = useCallback((section: string, path: string) => _setCurrentPage({ section, path }), [])

  const medaicaApiService = useMemo(() => {
    return !isLoading
      ? new MedaicaApiService(async () => {
          if (isAuthenticated) {
            return await getAccessToken()
          } else {
            void login({
              appState: { returnTo: getCurrentUrlFullPath() },
            })
          }
        })
      : null
  }, [isLoading, getAccessToken, isAuthenticated, login])

  return medaicaApiService ? (
    <GlobalContext.Provider
      value={{
        section,
        setSection,
        currentPage,
        setCurrentPage,
        pathToHome,
        medaicaApiService,
      }}
    >
      {children}
    </GlobalContext.Provider>
  ) : (
    <Loading />
  )
}

export { GlobalContextProvider, GlobalContext }
export type { GlobalContextInterface }

/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement, useEffect, useState } from "react"
import { classNames } from "@medaica/common/services/util"

export const minVol = -35

const VolumeMeterBar = ({ volume, threshold, color }: { volume: number; threshold: number; color: string }) => (
  <div className={classNames("rounded-full w-8 h-1.5", volume > threshold ? color : "bg-gray-300")} />
)

const VolumeMeter = ({
  volumeProvider,
  pollingInterval = 100,
  on,
  inVideo = false,
}: {
  volumeProvider: (() => number) | null
  pollingInterval?: number
  on: boolean
  inVideo?: boolean
}): ReactElement => {
  const [volume, setVolume] = useState(0)

  useEffect(() => {
    let intervalId: number | NodeJS.Timer
    if (on && volumeProvider) {
      intervalId = setInterval(() => setVolume(volumeProvider()), pollingInterval)
    } else {
      setVolume(0)
    }

    return () => clearInterval(intervalId as number)
  }, [volumeProvider, on, pollingInterval])

  const bars = [
    { threshold: 0, color: "bg-red-500" },
    { threshold: -5, color: "bg-red-500" },
    { threshold: -10, color: "bg-green-500" },
    { threshold: -15, color: "bg-green-500" },
    { threshold: -20, color: "bg-green-500" },
    { threshold: -25, color: "bg-green-500" },
    { threshold: -30, color: "bg-green-500" },
    { threshold: -35, color: "bg-green-500" },
  ]

  return (
    <div className="flex flex-col">
      {!inVideo && (
        <div className="flex flex-col space-y-0.5 items-center">
          {bars.map((bar) => (
            <VolumeMeterBar key={bar.threshold} volume={volume} threshold={bar.threshold} color={bar.color} />
          ))}
        </div>
      )}

      {inVideo && (
        <div className="flex flex-col space-y-0.5 items-center p-1">
          {bars.map((bar) => (
            <VolumeMeterBar key={bar.threshold} volume={volume} threshold={bar.threshold} color={bar.color} />
          ))}
        </div>
      )}
    </div>
  )
}

export default VolumeMeter

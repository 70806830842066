/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement, ReactNode } from "react"
import { Box, IconButton, Typography } from "@mui/material"
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"
import { useNavigate } from "react-router-dom"

const PageHeader = ({
  title,
  subtitle,
  summary,
  actions,
  backButton = false,
}: {
  title?: string
  subtitle?: ReactNode
  summary?: ReactNode
  actions?: ReactNode
  children?: ReactNode
  backButton?: boolean
}): ReactElement => {
  const navigate = useNavigate()

  return (
    <Box mt={1} mb={4} ml="14px">
      <Box sx={{ display: "flex" }}>
        <Box sx={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            {backButton && (
              <IconButton
                size="small"
                sx={{ marginRight: "5px", marginLeft: "-14px" }}
                aria-label="Back button"
                onClick={() => navigate(-1)}
              >
                <ChevronLeftIcon color="primary" />
              </IconButton>
            )}
            <Typography
              variant="h1"
              sx={{ lineHeight: "32px", fontSize: "21px", fontWeight: "medium", fontColor: "text.primary" }}
            >
              {title}
            </Typography>
          </Box>
          <Box mt={0.5}>{subtitle}</Box>
        </Box>
        {actions && <Box sx={{ display: "flex", columnGap: "10px" }}>{actions}</Box>}
      </Box>
      {summary && <Box mt={2}>{summary}</Box>}
    </Box>
  )
}

export default PageHeader

/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement, useEffect, useState } from "react"
import Header from "@medaica/common/components/page/page-header"
import useGlobalContext from "@medaica/common/hooks/global-context"
import { Exam } from "@medaica/common/types"
import { useParams } from "react-router-dom"
import { Paths } from "const"
import Loading from "@medaica/common/components/loading"
import moment from "moment"
import AuscultationTable from "views/exam/exam-details/components/auscultation-table"

const ExamView = (): ReactElement => {
  const { examId } = useParams<{ examId: string }>()
  const [exam, setExam] = useState<Exam | null>(null)
  const [examTitle, setExamTitle] = useState<string>()
  const { medaicaApiService, setCurrentPage } = useGlobalContext()

  useEffect(() => {
    setCurrentPage("exam", Paths.exams)
  }, [setCurrentPage])

  useEffect(() => {
    const fetchData = async () => {
      const exam = await medaicaApiService.exams.getExamById(examId, ["auscultations"])
      setExamTitle(moment(exam.dateCreated).format("lll"))
      setExam(exam)
    }
    void fetchData()
  }, [medaicaApiService, examId])

  return exam ? (
    <div className="main-centered">
      <Header title={examTitle} />
      <div className="flex space-x-5">
        <div className="flex-grow">
          <AuscultationTable auscultations={exam.auscultations} />
        </div>
      </div>
    </div>
  ) : (
    <Loading />
  )
}

export default ExamView

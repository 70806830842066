/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement, useEffect, useRef, useState } from "react"
import Header from "@medaica/common/components/page/page-header"
import VolumeMeter, { minVol } from "@medaica/common/components/volume-meter"
import { observer } from "mobx-react-lite"
import Phonocardiogram, { PhonocardiogramHandle } from "@medaica/common/components/phonocardiogram"
import AuscultationWaveformProvider from "@medaica/common/services/auscultation-waveform-provider"
import { deviceIsM1, M1 } from "@medaica/common/services/m1"
import M1MediaDeviceStore from "@medaica/common/services/m1-media-device-store"
import M1DeviceInfoUtil from "@medaica/common/services/m1-device-info-util"
import StethoscopeDetector from "@medaica/common/components/stethoscope-detector"
import { classNames } from "@medaica/common/services/util"
import Button from "@mui/material/Button"

const PatientView = observer((): ReactElement => {
  const [mediaDeviceManager] = useState(() => new M1MediaDeviceStore(new M1DeviceInfoUtil()))
  const [waveformProvider, setWaveformProvider] = useState<AuscultationWaveformProvider | null>(null)
  const [device, setDevice] = useState<M1 | null>(null)
  const phonocardiogramHandle = useRef<PhonocardiogramHandle>(null)
  const [auscultationMicOn, setAuscultationMicOn] = useState(true)
  const [roomMicOn, setRoomMicOn] = useState(true)

  useEffect(() => {
    if (mediaDeviceManager.m1) {
      mediaDeviceManager.m1.enableRoomMicOnLocalSpeakers()
      mediaDeviceManager.m1.enableAuscultationMicOnLocalSpeakers()
    }
  }, [mediaDeviceManager.m1])

  useEffect(() => {
    if (mediaDeviceManager.m1) {
      setDevice(mediaDeviceManager.m1)
      const waveformGenerator = new AuscultationWaveformProvider(mediaDeviceManager.m1.ausVolAnalyser)
      setWaveformProvider(waveformGenerator)
    } else {
      setWaveformProvider(null)
      phonocardiogramHandle.current?.reset()
    }
  }, [mediaDeviceManager.m1])

  const toggleAuscultationMic = () => {
    if (deviceIsM1(mediaDeviceManager.m1)) {
      if (auscultationMicOn) {
        mediaDeviceManager.m1.disableAuscultationMicOnLocalSpeakers()
      } else {
        mediaDeviceManager.m1.enableAuscultationMicOnLocalSpeakers()
      }
    }
    setAuscultationMicOn(!auscultationMicOn)
  }

  const toggleRoomMic = () => {
    if (deviceIsM1(mediaDeviceManager.m1)) {
      if (roomMicOn) {
        mediaDeviceManager.m1.disableRoomMicOnLocalSpeakers()
      } else {
        mediaDeviceManager.m1.enableRoomMicOnLocalSpeakers()
      }
    }
    setRoomMicOn(!roomMicOn)
  }

  return (
    <div className="main-centered">
      <Header title="Stethoscope" />
      <div className="panel">
        <div className="panel-content">
          <div className="md:col-span-2">
            <h3 className="panel-title">Device Information</h3>

            <div className="mt-4">
              <StethoscopeDetector mediaDeviceManager={mediaDeviceManager} />
            </div>

            <div
              className={classNames(
                "grid grid-cols-3 gap-4 mt-6",
                deviceIsM1(mediaDeviceManager.m1) ? "opacity-100" : "opacity-40"
              )}
            >
              <div className="border-gray-300 border rounded-md p-4">
                <h4 className="font-bold mb-4">Device Specs</h4>
                <dl className="space-y-4">
                  <div>
                    <dt className="font-medium">Device type:</dt>
                    <dd>{device ? "Medaica M1" : "Unknown"}</dd>
                  </div>
                  <div>
                    <dt className="font-medium">Device label:</dt>
                    <dd>{device ? device.label : "Unknown"}</dd>
                  </div>
                </dl>
              </div>
              <div className="border-gray-300 border rounded-md p-4">
                <h4 className="font-bold mb-3">Auscultation Microphone</h4>
                <Button onClick={toggleAuscultationMic} variant="outlined">
                  {auscultationMicOn ? "Turn off" : "Turn on"}
                </Button>
                <p className="mb-2">The phonocardiogram presents a visualization of heart sounds.</p>
                <p className="mb-4">
                  To test that your device is working correctly, tap the drum of the device and observe whether the
                  phonocardiogram registers the tap.
                </p>
                <div className="mt-2 border-gray-300 border bg-gray-100">
                  <Phonocardiogram
                    ref={phonocardiogramHandle}
                    className="w-full"
                    shouldDraw={true}
                    waveformProvider={waveformProvider ? () => waveformProvider.getWaveform() : null}
                  />
                </div>
              </div>
              <div className="border-gray-300 border rounded-md p-4 flex flex-col">
                <div>
                  <h4 className="font-bold mb-3">Room Microphone</h4>
                  <Button onClick={toggleRoomMic} variant="outlined">
                    {roomMicOn ? "Turn off" : "Turn on"}
                  </Button>
                  <p className="mb-2">The room volume meter reflects the volume of the ambient noise.</p>
                  <p className="mb-4">
                    To test that your device is working correctly, talk at a normal volume and observe whether the
                    volume meter registers the noise.
                  </p>
                </div>
                <div className="mt-2 flex flex-grow justify-center items-center">
                  <div className="inline-block border-gray-300 h-auto border rounded-sm p-2">
                    <VolumeMeter
                      on={true}
                      volumeProvider={() =>
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
                        deviceIsM1(mediaDeviceManager.m1) ? mediaDeviceManager.m1.getVolume() : minVol
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
})

export default PatientView

/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import { autorun, makeObservable, observable, runInAction } from "mobx"
import { M1 } from "@medaica/common/services/m1"
import MediaDeviceStore from "@medaica/common/services/media-device-store"
import M1DeviceInfoUtil from "@medaica/common/services/m1-device-info-util"
import logger from "@medaica/common/services/logging"

class PatientVirtualExamMediaDeviceStore extends MediaDeviceStore {
  // If true, when the stethoscope is plugged in we will automatically connect to it, otherwise we won't.
  private readonly _connectToM1IfAvailable
  // This is the audio device which was connected before the M1 was connected to, so that when we disconnect the M1,
  // we know which device to reconnect to
  //private _fallbackAudioDeviceId?: string
  isM1Connected = false
  auscultationTrack: MediaStreamTrack
  private _m1DeviceInfoUtil: M1DeviceInfoUtil

  constructor(
    automaticallyActivateM1IfAvailable: boolean,
    m1DeviceInfoUtil: M1DeviceInfoUtil,
    preferredAudioDeviceId?: string | null,
    preferredVideoDeviceId?: string | null
  ) {
    super(preferredAudioDeviceId, preferredVideoDeviceId)
    this._m1DeviceInfoUtil = m1DeviceInfoUtil
    makeObservable(this, {
      isM1Connected: observable,
    })
    // todo we're not doing anything with this
    this._connectToM1IfAvailable = automaticallyActivateM1IfAvailable

    // We listen for the M1 to be plugged in
    autorun(() => {
      this.isM1Connected = this._m1DeviceInfoUtil.deviceInfosIncludeM1(this.availableAudioDevices)
    })
  }

  set m1DeviceInfoUtil(m1DeviceInfoUtil: M1DeviceInfoUtil) {
    this._m1DeviceInfoUtil = m1DeviceInfoUtil
    const m1IsAvailable = this._m1DeviceInfoUtil.deviceInfosIncludeM1(this.availableAudioDevices)
    runInAction(() => {
      this.isM1Connected = m1IsAvailable
    })
  }

  // todo get rid of this
  public async disconnectFromM1ForRecording(): Promise<void> {
    await this.connectToAudioDevice()
  }

  get m1Info(): { deviceId: string; label: string } | null {
    const m1MediaDeviceInfo = this._m1DeviceInfoUtil.findM1MediaDeviceInfo(this.availableAudioDevices)
    return m1MediaDeviceInfo
      ? {
          deviceId: m1MediaDeviceInfo.deviceId,
          label: m1MediaDeviceInfo.label,
        }
      : null
  }

  public async connectToM1(withAuscultationMicActive: boolean): Promise<M1 | null> {
    const m1MediaDeviceInfo = this._m1DeviceInfoUtil.findM1MediaDeviceInfo(this.availableAudioDevices)
    if (!m1MediaDeviceInfo) {
      throw new Error("M1 is not connected")
    }
    const constraints = {
      audio: {
        deviceId: { exact: m1MediaDeviceInfo.deviceId },
        channelCount: 2,
        ...(withAuscultationMicActive && {
          echoCancellation: false,
          autoGainControl: false,
          noiseSuppression: false,
        }),
      },
    }
    try {
      const mediaStream = await navigator.mediaDevices.getUserMedia(constraints)
      const m1 = new M1(mediaStream, m1MediaDeviceInfo.deviceId, m1MediaDeviceInfo.label)
      logger.debug("connecting to M1, recording mode", withAuscultationMicActive)
      runInAction(() => {
        this.setAudioDevice(m1)
      })
      return m1
    } catch (error) {
      logger.error("Error while connecting to M1:", error)
      this.isM1Connected = false
      return null
    }
  }

  private isAudioDeviceAvailable(deviceId?: string | null): deviceId is string {
    if (deviceId === null) {
      return false
    }
    return this.availableAudioDevices.some((deviceInfo) => deviceInfo.deviceId === deviceId)
  }

  private async connectToStandardAudioDeviceOrM1(audioDeviceId: string) {
    const deviceInfo = this.availableAudioDevices.find((device) => device.deviceId === audioDeviceId)
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    if (this._m1DeviceInfoUtil.isM1DeviceInfo(deviceInfo!)) {
      await this.connectToM1(false)
    } else {
      await super.connectToAudioDevice(audioDeviceId)
    }
  }

  async connectToDefaultAudioDevice(): Promise<void> {
    await super.connectToAudioDevice()
  }

  override async connectToAudioDevice(audioDeviceId?: string | null): Promise<void> {
    if (this.isAudioDeviceAvailable(audioDeviceId)) {
      logger.debug("Connecting to audio device", audioDeviceId)
      await this.connectToStandardAudioDeviceOrM1(audioDeviceId)
      return
    }

    // If we get here it means we haven't connected to a fallback or selected device, so we'll just connect to the
    // default device
    logger.debug("Connecting to default audio device")
    await super.connectToAudioDevice()
  }
}

export default PatientVirtualExamMediaDeviceStore

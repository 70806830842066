/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import { ExcellentQuality } from "./ExcellentQuality/ExcellentQuality"
import { PoorQuality } from "./PoorQuality/PoorQuality"
import { getQualityScore } from "./getQualityScore/getQualityScore"
import { useAppStateContext } from "../../AppStateProvider/AppStateProvider"
import { QualityModal } from "./QualityModal/QualityModal"
import React, { ReactElement, useState } from "react"

export enum QualityScore {
  Poor,
  Suboptimal,
  Good,
  Excellent,
}

export const Quality = (): ReactElement => {
  const { state } = useAppStateContext()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const { totalQualityScore, latency, jitter, packetLoss, bitrate } = getQualityScore(
    state.preflightTest.report,
    state.bitrateTest.report
  )

  return (
    <>
      <QualityModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        jitter={jitter}
        latency={latency}
        packetLoss={packetLoss}
        bitrate={bitrate}
      />
      {totalQualityScore === QualityScore.Good || totalQualityScore === QualityScore.Excellent ? (
        <ExcellentQuality quality={totalQualityScore} openModal={() => setIsModalOpen(true)} />
      ) : (
        <PoorQuality quality={totalQualityScore} openModal={() => setIsModalOpen(true)} />
      )}
    </>
  )
}

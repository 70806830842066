/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import { makeAutoObservable, runInAction } from "mobx"
import logger from "@medaica/common/services/logging"

import { M1 } from "./m1"
import M1DeviceInfoUtil from "./m1-device-info-util"

class M1MediaDeviceStore {
  private m1DeviceInfoUtil: M1DeviceInfoUtil
  isM1Available = false
  m1: M1 | null = null
  mediaStream: MediaStream | null = null

  constructor(m1DeviceInfoUtil: M1DeviceInfoUtil) {
    makeAutoObservable(this)
    this.m1DeviceInfoUtil = m1DeviceInfoUtil
  }

  async start(): Promise<void> {
    navigator.mediaDevices.ondevicechange = async () => {
      await this.updateAudioDevices()
    }
    await this.updateAudioDevices()
  }

  private getAudioDevices = async (): Promise<MediaDeviceInfo[]> => {
    try {
      const mediaStream = await navigator.mediaDevices.getUserMedia({
        audio: true,
      })
      mediaStream.getTracks().forEach((track) => track.stop())
      const deviceInfos = await navigator.mediaDevices.enumerateDevices()
      return deviceInfos.filter((device) => device.kind === "audioinput")
    } catch (error) {
      console.error("Error while getting audio devices:", error)
      return []
    }
  }

  private updateAudioDevices = async (): Promise<void> => {
    const availableAudioDevices = await this.getAudioDevices()

    runInAction(() => {
      this.isM1Available = this.m1DeviceInfoUtil.deviceInfosIncludeM1(availableAudioDevices)
    })
  }

  public async connectToM1(audioTrackConstraints?: MediaTrackConstraints): Promise<M1 | null> {
    if (this.m1) {
      this.m1 = null
    }

    const availableAudioDevices = await this.getAudioDevices()
    const m1MediaDeviceInfo = this.m1DeviceInfoUtil.findM1MediaDeviceInfo(availableAudioDevices)

    if (!m1MediaDeviceInfo) {
      throw new Error("M1 is not connected")
    }

    const constraints = {
      audio: {
        deviceId: { exact: m1MediaDeviceInfo.deviceId },
        channelCount: 2,
        echoCancellation: false,
        autoGainControl: false,
        noiseSuppression: false,
        ...audioTrackConstraints,
      },
    }
    try {
      this.mediaStream = await navigator.mediaDevices.getUserMedia(constraints)
      runInAction(() => {
        this.m1 = new M1(this.mediaStream, m1MediaDeviceInfo.deviceId, m1MediaDeviceInfo.label, false)
      })
      return this.m1
    } catch (error) {
      logger.error("Error while connecting to M1:", error)
      this.m1 = null
      return this.m1
    }
  }

  dispose(): void {
    this.mediaStream?.getTracks().forEach((track) => track.stop())
    this.m1?.dispose()
  }
}

export default M1MediaDeviceStore

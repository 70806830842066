/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import * as logger from "loglevel"

// This will override the log level set by the env var. Thus, if we're in production and we want to see debug logs,
// we load the app with ?log-level=debug
const logLevel = new URLSearchParams(window.location.search).get("log-level")
logger.setLevel(((logLevel ?? process.env.REACT_APP_LOG_LEVEL) as logger.LogLevelDesc) || "silent")

export default logger

/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import { action, computed, makeObservable, observable } from "mobx"
import { NavigateFunction } from "react-router-dom"

class UIStore<View> {
  private _view: View
  private readonly _navigator: NavigateFunction
  private readonly _pathToDashboard: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  viewArgs: any

  constructor(navigator: NavigateFunction, initialView: View, pathToDashboard: string) {
    makeObservable<UIStore<View>, "_view">(this, {
      _view: observable,
      viewArgs: observable,
      view: computed,
      navigate: action,
    })
    this._view = initialView
    this._navigator = navigator
    this._pathToDashboard = pathToDashboard
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
  navigate(view: View, args?: any): void {
    this._view = view
    this.viewArgs = args
  }

  get view(): View {
    return this._view
  }

  returnToDashboard = (): void => {
    this._navigator(this._pathToDashboard, { replace: true })
  }
}

export default UIStore

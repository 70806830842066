/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement, useState } from "react"
import { Formik, Form } from "formik"
import * as Yup from "yup"
import useGlobalContext from "@medaica/common/hooks/global-context"
import { getConfigNumber, logError } from "@medaica/common/services/util"
import Header from "@medaica/common/components/page/page-header"
import { Alert, LoadingButton } from "@mui/lab"
import { Paths } from "const"
import Checkbox from "@medaica/common/components/forms/checkbox"
import { Link } from "react-router-dom"
import useAuth from "services/auth"
import { useSnackbar } from "notistack"

const AgreeTCsForm = ({ onComplete }: { onComplete: () => void }): ReactElement => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const { medaicaApiService } = useGlobalContext()
  const { user } = useAuth()
  const { enqueueSnackbar } = useSnackbar()

  return (
    <div className="max-w-3xl">
      <Formik
        initialValues={{
          termsAndConditions: false,
        }}
        validationSchema={Yup.object({
          termsAndConditions: Yup.boolean().oneOf([true], "You must agree to the terms and conditions."),
        })}
        onSubmit={async (values, { setSubmitting }) => {
          setErrorMessage(null)
          try {
            await medaicaApiService.patients.updatePatient(user.id, {
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              tcVersion: getConfigNumber("TC_VERSION"),
            })
            enqueueSnackbar("Your user profile has been updated.", {
              variant: "success",
            })
            onComplete()
          } catch (error) {
            setErrorMessage("There was an error submitting your information. Please try again later.")
            logError(error)
          } finally {
            setSubmitting(false)
          }
        }}
      >
        {({ errors, handleBlur, handleChange, isSubmitting, touched }) => (
          <Form>
            <Header
              title="New Terms and Conditions"
              summary="We have updated our terms and conditions. Please indicate your agreement with them by
              clicking the checkbox below."
            />
            <div className="space-y-8">
              <div>
                {!!errorMessage && (
                  <Alert sx={{ mt: 3 }} severity="error">
                    {errorMessage}
                  </Alert>
                )}
              </div>
              <div className="mt-6">
                <fieldset>
                  <div className="mt-4 space-y-4">
                    <Checkbox
                      name="termsAndConditions"
                      label="Terms and Conditions"
                      description={
                        <>
                          I agree with the{" "}
                          <Link to={Paths.terms} className="link" target="_blank" rel="noopener noreferrer">
                            terms and conditions.
                          </Link>
                        </>
                      }
                      touched={touched}
                      errors={errors}
                    />
                  </div>
                </fieldset>
              </div>
            </div>

            <div className="flex justify-end mt-6 border-t pt-4">
              <LoadingButton loading={isSubmitting} type="submit" variant="contained">
                Continue
              </LoadingButton>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default AgreeTCsForm

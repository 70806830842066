/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement, ReactNode } from "react"
import { mainVideoHeight, mainVideoWidth } from "@medaica/common/const"

const VideoContainer = ({ children }: { children: ReactNode }): ReactElement => {
  return (
    <div
      className="panel flex relative bg-gray-200"
      style={{ width: `${mainVideoWidth}px`, height: `${mainVideoHeight}px` }}
    >
      {children}
    </div>
  )
}

export default VideoContainer

/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import _ from "lodash"
import { AuscultationPoint } from "@medaica/common/types"

const InvitationType = {
  PATIENT_TO_HEALTHCARE_PROVIDER: "patientToHealthcareProvider",
  HEALTHCARE_PROVIDER_TO_PATIENT: "healthcareProviderToPatient",
}

const Events = {
  RECORD_CREATED: "recordCreated",
  CREATE_RECORDING_WIZARD_CANCELLED: "createRecordingWizardCancelled",
}

enum Role {
  HealthcareProvider = "healthcare-provider",
  Patient = "patient",
}

enum Sex {
  male = "male",
  female = "female",
}

enum ExamType {
  Virtual = "Live", // We need to keep "Live" since the API uses it
  SelfProviderInitiated = "Self - Provider Initiated",
  SelfPatientInitiated = "Self - Patient Initiated",
  InPerson = "In Person",
}

enum AuscultationPointType {
  heart,
  lungs,
  other,
}

const auscultationPoints = {
  heart: _.range(1, 5).map((num) => {
    return {
      label: `h${num}`,
      longLabel: `Heart ${num}`,
      description: `Heart auscultation point ${num}`,
      type: AuscultationPointType.heart,
    } as AuscultationPoint
  }),

  lungs: _.range(1, 5).map((num) => {
    return {
      label: `l${num}`,
      longLabel: `Lung ${num}`,
      description: `Lung auscultation point ${num}`,
      type: AuscultationPointType.lungs,
    } as AuscultationPoint
  }),

  other: {
    label: "other",
    longLabel: "Other",
    description: "Custom auscultation point",
    type: AuscultationPointType.other,
  },

  getByLabel: (label: string): AuscultationPoint => {
    const auscultationPoint =
      auscultationPoints.heart.find((auscultationPoint) => auscultationPoint.label === label) ??
      auscultationPoints.lungs.find((auscultationPoint) => auscultationPoint.label === label) ??
      auscultationPoints.other
    if (!auscultationPoint) {
      throw new Error(`Invalid auscultation point label ${label}`)
    }
    return auscultationPoint
  },
}

enum DiagnosisResult {
  ERROR = -1,
  INSUFFICIENT_DATA = 0,
  NORMAL = 1,
  ABNORMAL = 2,
}

const mainVideoHeight = 625
const mainVideoWidth = 500

const auscultationMicSampleRateInHz = 16_000

const maxCharactersInNote = 10_000

const genericSystemErrorMessage =
  "The application has encountered an unexpected error. Our technical staff have been" +
  " automatically notified and will be looking into it."

export {
  ExamType,
  InvitationType,
  Events,
  Role,
  Sex,
  AuscultationPointType,
  auscultationPoints,
  DiagnosisResult,
  mainVideoWidth,
  mainVideoHeight,
  maxCharactersInNote,
  auscultationMicSampleRateInHz,
  genericSystemErrorMessage,
}

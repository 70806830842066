/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement } from "react"
import { AppStateProvider, userAgentParser } from "./components/AppStateProvider/AppStateProvider"
import { MainContent } from "./components/MainContent/MainContent"
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles"
import theme from "@medaica/common/theme"
import useGlobalContext from "@medaica/common/hooks/global-context"

const CompatibilityTestView = (): ReactElement => {
  const { medaicaApiService } = useGlobalContext()

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <AppStateProvider
          accessTokenProvider={() => medaicaApiService.twilio.getCompatibilityTestAccessToken()}
          userAgentParser={userAgentParser}
        >
          <div className="h-screen overflow-hidden relative bg-gray-100">
            <MainContent />
          </div>
        </AppStateProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  )
}

export default CompatibilityTestView

/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement, useEffect, useState } from "react"
import { Box, CircularProgress, Typography } from "@mui/material"

const Denied = ({ handleTimeoutComplete }: { handleTimeoutComplete: () => void }): ReactElement => {
  const [progress, setProgress] = useState(0)
  useEffect(() => {
    const timer = setInterval(() => {
      if (progress === 30) {
        handleTimeoutComplete()
      } else {
        setProgress((prevProgress) => prevProgress + 1)
      }
    }, 1000)
    return () => {
      clearInterval(timer)
    }
  }, [handleTimeoutComplete, progress])
  return (
    <>
      <h3 className="text-2xl">You may not enter the exam</h3>
      <p className="mt-6 mb-14">The exam host denied your request to join. You may try again in 30 seconds.</p>
      <Box sx={{ position: "relative", display: "inline-flex" }}>
        <CircularProgress variant="determinate" value={progress * 3.333} />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography component="div" color="text.secondary">
            {30 - progress}
          </Typography>
        </Box>
      </Box>
    </>
  )
}

export default Denied

/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

/* eslint-disable max-len */
import React, { ReactElement } from "react"

export const ErrorIcon = (): ReactElement => (
  <div style={{ display: "inline-block", marginRight: "10px" }}>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.7461 1.52332L22.4767 10.2539C23.4411 11.2183 23.4411 12.7818 22.4767 13.7462L13.7461 22.4768C12.7818 23.4411 11.2182 23.4411 10.2539 22.4768L1.52327 13.7462C0.55891 12.7818 0.55891 11.2183 1.52327 10.2539L10.2539 1.52332C11.2182 0.55896 12.7818 0.55896 13.7461 1.52332ZM13.4 16.2C13.4 16.9732 12.7732 17.6 12 17.6C11.2268 17.6 10.6 16.9732 10.6 16.2C10.6 15.4269 11.2268 14.8 12 14.8C12.7732 14.8 13.4 15.4269 13.4 16.2ZM11.9856 7.80005H12.0142C12.8694 7.81042 13.5156 8.62935 13.3826 9.54159L12.7839 12.6744C12.7269 13.0891 12.3943 13.4 12.0047 13.4H11.9951C11.6055 13.4 11.2729 13.0891 11.2159 12.6744L10.6172 9.54159C10.4842 8.62935 11.1399 7.80005 11.9856 7.80005Z"
        fill="#D61F1F"
      />
    </svg>
  </div>
)

/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import axios, { AxiosInstance, AxiosError, AxiosResponse } from "axios"
import URI from "urijs"
import { getConfig, isIsoDateString } from "@medaica/common/services/util"
import HealthcareProvidersApi from "./HealthcareProvidersApi"
import SelfExamRequestsApi from "./SelfExamRequestsApi"
import VirtualExamsApi from "./VirtualExamsApi"
import FirebaseApi from "./FirebaseApi"
import TwilioApi from "./TwilioApi"
import ExamsApi from "./ExamsApi"
import PatientProfilesApi from "./PatientProfilesApi"
import AuscultationsApi from "./AuscultationsApi"
import EmsApi from "./EmsApi"
import HealthcareProviderInvitationsApi from "./HealthcareProviderInvitationsApi"
import PatientsApi from "./PatientsApi"
import moment from "moment"

// Recurses through a json object and converts any string that looks like a date into a Date object
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const convertDateStringsToDates = (body: unknown): any => {
  if (body === null || body === undefined || typeof body !== "object") return body

  for (const key of Object.keys(body)) {
    const value = body[key]
    if (typeof value === "string" && isIsoDateString(value)) body[key] = moment(value).toDate()
    else if (typeof value === "object") convertDateStringsToDates(value)
  }
}

type Pagination = {
  pageNumber: number
  pageSize: number
  totalCount: number
}

const pagination = (response: AxiosResponse): Pagination => {
  return response.headers["x-total-count"]
    ? {
        pageNumber: parseInt(response.headers["x-page-number"] as string),
        pageSize: parseInt(response.headers["x-page-size"] as string),
        totalCount: parseInt(response.headers["x-total-count"] as string),
      }
    : ({} as Pagination)
}

type QuerySpecField = { field: string; op?: string; value: string | number | null | Date }
type QuerySpecFilter = QuerySpecField[] | { or: QuerySpecFilter } | { and: QuerySpecFilter } | { not: QuerySpecFilter }

/**
 * Specify child objects to eager load. E.g. if you want a House eager loaded with House.rooms and
 *  House.livingRoom.chairs, you would specify ["rooms", "livingRoom.chairs"]
 *
 *  Note that although the API uses snake case, we specify the properties in camel case and it is converted to snake
 *   case on the backend.
 */
type JoinedLoads = string[]

type QuerySpec = {
  filters?: QuerySpecFilter
  sort?: { field: string; direction: "asc" | "desc" }[]
  pagination?: { pageNumber: number; pageSize: number }
  joinedLoads?: JoinedLoads
}

enum MedaicaApiErrorType {
  INTERNAL_SERVER_ERROR = 1,
  INVALID_LIVE_EXAM,
  EXPIRED_ACCESS_CODE,
  EXPIRED_SESSION,
  EXAM_EXISTS,
  UNAUTHORIZED,
  SIGNATURE_HAS_EXPIRED,
  RESOURCE_DOES_NOT_EXIST,
  INVALID_DATA,
  DUPLICATE_PATIENT_PROFILE,
  PASSWORD_TOO_SHORT,
  PASSWORD_TOO_COMMON,
  PASSWORD_CONTAINS_USER_INFORMATION,
  PASSWORD_SAME_AS_PREVIOUS,
  USER_ALREADY_EXISTS,
  AUTH0_UNKNOWN_ERROR,
  INVALID_HEALTHCARE_PROVIDER_INVITATION,
  HCP_SHOULD_REFRESH_DATA_ERROR,
  INVALID_EMAIL,
  AUSCULTATION_RECORDING_FAILED,
}

interface MedaicaApiError extends AxiosError {
  type: MedaicaApiErrorType
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/no-explicit-any
const isMedaicaApiError = (payload: any): boolean => {
  return typeof payload === "object" && payload.isMedaicaApiError === true
}

class MedaicaApiService {
  private readonly accessTokenProvider: () => Promise<string | undefined>

  constructor(accessTokenProvider: () => Promise<string | undefined>) {
    this.accessTokenProvider = accessTokenProvider
  }

  public async apiClient(withAccessToken = true): Promise<AxiosInstance> {
    const client = axios.create({
      baseURL: MedaicaApiService.apiUrl(),
      responseType: "json",
      headers: {
        "Content-Type": "application/json",
        ...(withAccessToken && {
          Authorization: `Bearer ${await this.accessTokenProvider()}`,
        }),
      },
    })
    client.interceptors.response.use((originalResponse) => {
      convertDateStringsToDates(originalResponse.data)
      return originalResponse
    })
    client.interceptors.response.use(
      (res) => res,
      (err) => {
        const error = (err as AxiosError<{ error: MedaicaApiError }>).response?.data?.error

        if (typeof error === "object" && MedaicaApiErrorType[error.type]) {
          err["type"] = MedaicaApiErrorType[error.type]
          err["isMedaicaApiError"] = true
        }
        throw err
      }
    )
    return client
  }

  public static apiUrl = (): string => {
    const uri = new URI("/api/v1")
    return uri.absoluteTo(getConfig("MEDAICA_SERVICE_URL")).valueOf()
  }

  public withQuery(path: string, queries: Record<string, string | number | boolean | undefined | null>): string {
    const uri = new URI(path)
    uri.setSearch(queries)
    return uri.valueOf()
  }

  healthcareProviders = new HealthcareProvidersApi(this)
  healthcareProviderInvitations = new HealthcareProviderInvitationsApi(this)
  selfExamRequests = new SelfExamRequestsApi(this)
  virtualExams = new VirtualExamsApi(this)
  firebase = new FirebaseApi(this)
  twilio = new TwilioApi(this)
  exams = new ExamsApi(this)
  patientProfiles = new PatientProfilesApi(this)
  auscultations = new AuscultationsApi(this)
  ems = new EmsApi(this)
  patients = new PatientsApi(this)
}

export default MedaicaApiService
export { isMedaicaApiError, MedaicaApiErrorType, pagination }
export type { QuerySpec, JoinedLoads }

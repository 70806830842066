/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement, useEffect } from "react"
import { Link, useLocation } from "react-router-dom"
import { logError } from "@medaica/common/services/util"
import ContentMiddle from "@medaica/common/layouts/minimal-layout/content-middle"
import Header from "@medaica/common/components/page/page-header"

const NotFound = (): ReactElement => {
  const location = useLocation()
  useEffect(() => {
    logError(new Error("404"), { location })
  }, [location])
  return (
    <ContentMiddle>
      <Header title="404 Error" subtitle="Page not found." />
      <p className="mt-3 text-base text-gray-500">Sorry, we couldn't find the page you were looking for.</p>
      <Link to="/" className="mt-7 link font-medium text-base">
        Go back home <span aria-hidden="true">&rarr;</span>
      </Link>
    </ContentMiddle>
  )
}

export default NotFound

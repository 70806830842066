/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

/* eslint-disable max-len */
import React, { ReactNode } from "react"

type Props = {
  iColor?: string
  [key: string]: ReactNode
}

const MedaicaLogoAbbr = ({ iColor = "#3B3AEE", ...props }: Props): React.ReactElement => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="svg236"
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 8.7422113 12.981101"
      {...props}
    >
      <g transform="translate(-5.0219886,-4.77)" id="g140">
        <g id="g130">
          <g id="g121">
            <path
              id="path69"
              d="M 8.64,14.84 C 8.45,14.47 8.11,13.33 8.01,12.17 7.91,10.96 8.05,9.73 8.21,9.38 8.42,8.9 8.5,8.73 8.7,8.51 8.75,8.45 8.52,8.41 8.47,8.4 7.73,8.35 7.16,9.2 7.1,10.02 7.08,10.31 7.07,10.73 7.07,11.15 7.06,11.56 7.06,11.97 7.04,12.28 L 7.03,12.55 C 7,13.12 6.98,13.61 6.75,14.3 6.51,15.02 6.26,15.48 5.91,16.03 5.65,16.44 5.35,16.78 5.07,17.1 4.95,17.23 4.94,18.19 6.47,17.47 6.94,17.25 7.37,17.02 7.7,16.73 8.15,16.34 8.55,15.84 8.9,15.26 8.8,15.13 8.72,14.99 8.64,14.84 Z"
              style={{ fill: "#5CC3AD" }}
            />
            <g id="g73">
              <path
                id="path71"
                d="m 13.72,17.11 c -0.29,-0.33 -0.58,-0.66 -0.84,-1.07 -0.35,-0.55 -0.6,-1.01 -0.84,-1.73 -0.23,-0.68 -0.25,-1.17 -0.28,-1.75 L 11.75,12.29 C 11.73,11.98 11.73,11.57 11.72,11.16 11.71,10.75 11.71,10.32 11.69,10.03 11.64,9.29 11.18,8.55 10.56,8.42 10.37,8.38 9.97,8.37 9.72,8.51 9.51,8.63 9.28,8.89 9.06,9.38 8.76,10.06 8.81,11.07 8.9,12.07 v 0 c 0.1,1.08 0.36,2.01 0.76,2.77 0.41,0.77 0.89,1.41 1.45,1.9 0.33,0.29 0.76,0.51 1.23,0.74 1.51,0.73 1.49,-0.23 1.38,-0.37 z"
                style={{ fill: iColor }}
              />
            </g>
            <circle r="1.48" id="circle75" cy="6.2600002" cx="10.34" style={{ fill: iColor }} />
            <path
              id="path77"
              d="M 8.03,6.33 C 8.03,5.73 8.28,5.18 8.78,4.93 8.81,4.91 8.82,4.86 8.79,4.85 8.64,4.8 8.48,4.77 8.32,4.77 7.48,4.77 6.8,5.45 6.8,6.29 6.8,7.13 7.48,7.81 8.32,7.81 8.48,7.81 8.63,7.79 8.77,7.74 8.81,7.73 8.81,7.68 8.78,7.66 8.3,7.34 8.03,6.93 8.03,6.33 Z"
              style={{ fill: "#5CC3AD" }}
            />
            <path id="path79" d="M 8.45,8.58" className="st1" />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default MedaicaLogoAbbr

/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement, useContext, useEffect, useRef } from "react"
import Layout from "@medaica/common/views/exam/virtual-exam/layout"
import Video from "views/exam/virtual-exam/views/exam-room/components/video"
import PageControls from "views/exam/virtual-exam/views/exam-room/components/page-controls"
import Notifications from "views/exam/virtual-exam/views/exam-room/components/notifications"
import Loading from "@medaica/common/components/loading"
import VirtualExamContext from "views/exam/virtual-exam/virtual-exam-context"
import { useNavigate } from "react-router-dom"
import VirtualExamUIStore from "views/exam/virtual-exam/views/exam-room/stores/ui-store"
import VirtualExamStore from "views/exam/virtual-exam/views/exam-room/stores/virtual-exam-store"
import MedaicaApiService, { isMedaicaApiError, MedaicaApiErrorType } from "@medaica/common/services/medaica-api-service"
import FirebaseLiveExamService from "@medaica/common/views/exam/virtual-exam/exam-room/services/firebase-virtual-exam-service"
import useAnonAuth from "views/exam/virtual-exam/hooks/anonAuth"
import URI from "urijs"
import { Paths } from "const"
import FirebaseClient from "@medaica/common/services/firebase-client"
import { logError } from "@medaica/common/services/util"
import { observer } from "mobx-react-lite"
import AudioTest from "views/exam/virtual-exam/views/exam-room/components/audio-test"
import AVStore from "@medaica/common/views/exam/virtual-exam/stores/av-store"
import { useSnackbar } from "notistack"

const LiveExamView = observer((): ReactElement => {
  const { uiStore, mediaDeviceStore, setVirtualExamStore, virtualExamStoreMaybe, setAVStore, avStoreMaybe } =
    useContext(VirtualExamContext)
  const navigate = useNavigate()
  const { deleteAccessToken, getAccessToken } = useAnonAuth()
  const avStoreRef = useRef(new AVStore(mediaDeviceStore))
  const { closeSnackbar } = useSnackbar()

  useEffect(() => {
    let virtualExamStore: VirtualExamStore

    // We construct the virtualExamStore's dependencies, then create the exam store. Once this process
    // is complete, we update the state of the exam store and switch from loading to the LiveExamView
    const buildExamStore = async () => {
      try {
        const virtualExamId = URI(window.location.href).segment(1)
        if (!virtualExamId) {
          throw new Error(`URL does not contain exam id ${window.location.href}`)
        }

        const accessToken = getAccessToken(virtualExamId)

        if (!accessToken) {
          uiStore.navigate("lobby")
          return
        }

        if (accessToken.liveExamId !== virtualExamId) {
          uiStore.navigate("lobby")
        }

        const medaicaApiService = new MedaicaApiService(() => {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-return
          return new Promise<string>((resolve) => {
            if (new Date() < new Date(accessToken.expiresAt)) {
              resolve(accessToken.value)
            } else {
              navigate(Paths.liveExam)
            }
          })
        })

        const firebaseAccessToken = await medaicaApiService.firebase.getFirebaseAccessTokenForAuthorizedParticipant(
          virtualExamId
        )
        await FirebaseClient.instance.authenticate(firebaseAccessToken.value)
        const firebaseService = new FirebaseLiveExamService(virtualExamId)

        virtualExamStore = new VirtualExamStore(
          accessToken.userId,
          virtualExamId,
          firebaseService,
          medaicaApiService,
          mediaDeviceStore,
          avStoreRef.current
        )

        uiStore.virtualExam = new VirtualExamUIStore()

        await virtualExamStore.load(accessToken.userName)

        setVirtualExamStore(virtualExamStore)
        setAVStore(avStoreRef.current)
      } catch (error) {
        if (isMedaicaApiError(error)) {
          if (error.type === MedaicaApiErrorType.SIGNATURE_HAS_EXPIRED) {
            navigate(Paths.liveExam)
            return
          }
        }
        virtualExamStore?.dispose()
        avStoreRef.current.dispose()
        logError(error)
        const virtualExamId = URI(window.location.href).segment(1)
        if (virtualExamId) {
          deleteAccessToken(virtualExamId)
        }
        uiStore.navigate("lobby", { redirectReason: "entryError" })
      }
    }

    void buildExamStore()

    const currentAVStoreRef = avStoreRef.current

    return () => {
      setVirtualExamStore(null)
      setAVStore(null)
      currentAVStoreRef.dispose()
      virtualExamStore?.dispose()
      // In case we're connected to the M1, we need to disconnect from it when we leave the exam room
      mediaDeviceStore.connectToDefaultAudioDevice().catch(logError)
    }
  }, [deleteAccessToken, getAccessToken, navigate, uiStore, mediaDeviceStore, setVirtualExamStore, setAVStore])

  return virtualExamStoreMaybe && avStoreMaybe ? (
    <Layout
      overlays={<Notifications />}
      main={
        <div className="flex justify-center w-full">
          <Video />
          <AudioTest />
        </div>
      }
      footer={<PageControls onExitEvent={() => closeSnackbar()} />}
    />
  ) : (
    <Loading />
  )
})

export default LiveExamView

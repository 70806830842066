/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement, ReactNode, useEffect, useState } from "react"
import { ErrorMessage as FormikErrorMessage, Field, FormikErrors, FormikTouched, FormikValues, getIn } from "formik"
import { classNames } from "@medaica/common/services/util"

type Props = {
  name: string
  label: string
  description: ReactNode
  errors: FormikErrors<FormikValues>
  touched: FormikTouched<FormikValues>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  props?: any
}

const Checkbox = ({ name, label, description, errors, touched, ...props }: Props): ReactElement => {
  const [hasError, setHasError] = useState(false)

  useEffect(() => {
    setHasError(!!getIn(errors, name) && !!getIn(touched, name))
  }, [errors, touched, name])

  return (
    <div className="relative flex items-start">
      <div className="flex items-center mt-1">
        <Field
          {...props}
          name={name}
          type="checkbox"
          className={classNames("checkbox", hasError ? "checkbox-error" : "")}
          aria-invalid={hasError}
          aria-describedby={hasError ? `${name}-errorMessage` : `${name}-description`}
        />
      </div>
      <div className="ml-3">
        <label htmlFor={name}>{label}</label>
        {description && (
          <p id={`${name}-description`} className="mt-1 text-gray-500">
            {description}
          </p>
        )}
        <FormikErrorMessage name={name}>
          {(msg) => (
            <span className="mt-2 text-error" id={`${name}-errorMessage`}>
              {msg}
            </span>
          )}
        </FormikErrorMessage>
      </div>
    </div>
  )
}

export default Checkbox
export type { Props }

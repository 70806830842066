/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { HTMLProps, ReactElement, useEffect, useState } from "react"
import { ErrorMessage as FormikErrorMessage, Field, FormikErrors, FormikTouched, FormikValues, getIn } from "formik"
import { classNames } from "@medaica/common/services/util"
import { ExclamationCircleIcon } from "@heroicons/react/solid"

type Props = {
  label: string
  helpText?: string
  hiddenLabel?: boolean
  name: string
  errors: FormikErrors<FormikValues>
  touched: FormikTouched<FormikValues>
} & HTMLProps<HTMLInputElement>

const TextInput = ({
  label,
  helpText,
  hiddenLabel = false,
  name,
  errors,
  touched,
  className,
  ...props
}: Props): ReactElement => {
  const [hasError, setHasError] = useState(false)

  useEffect(() => {
    setHasError(!!getIn(errors, name) && !!getIn(touched, name))
  }, [errors, touched, name])

  return (
    <>
      <label htmlFor={name} className={"block font-base text-gray-700" + (hiddenLabel ? " sr-only" : "")}>
        {label}
      </label>
      <div className="mt-1 relative">
        <Field
          name={name}
          className={classNames("block w-full input", className, hasError ? "input-error" : "")}
          aria-invalid={hasError}
          aria-describedby={hasError ? name + "-errorMessage" : helpText ? name + "-description" : null}
          {...props}
        />
        {hasError && (
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
          </div>
        )}
      </div>
      <FormikErrorMessage name={name}>
        {(msg) => (
          <div className="mt-1 text-error" id={name + "-errorMessage"}>
            {msg}
          </div>
        )}
      </FormikErrorMessage>
      {helpText && (
        <p id={`${name}-description`} className="mt-2 text-gray-500">
          {helpText}
        </p>
      )}
    </>
  )
}

export default TextInput

/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { useRef, useEffect } from "react"
import { alpha } from "@mui/material"
import { styled } from "@mui/material/styles"

const PREFIX = "progressBar"

const classes = {
  container: `${PREFIX}-container`,
  progress: `${PREFIX}-progress`,
  background: `${PREFIX}-background`,
}

const Root = styled("div")({
  [`&.${classes.container}`]: {
    position: "relative",
    margin: "0 0.2em 0",
    height: "4px",
    "& div": {
      position: "absolute",
      top: 0,
      bottom: 0,
      left: 0,
    },
  },
  [`& .${classes.progress}`]: {
    background: "#14B053",
    right: "100%",
  },
  [`& .${classes.background}`]: {
    right: 0,
    background: alpha("#E1E3EA", 0.2),
  },
})

interface ProgressBarProps {
  duration: number
  position: number
  style?: { [key: string]: string }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const ProgressBar = ({ duration, position, style }: ProgressBarProps) => {
  const progressBarRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const el = progressBarRef.current
    if (el) {
      window.requestAnimationFrame(() => {
        // We set these values asynchronously so that the browser can recognize the change in the 'right' value.
        // Without this, the progress bar would instantly snap to the designated position.
        el.style.transition = `right ${duration}s linear`
        el.style.right = `${Math.max(0, 100 - position)}%`
      })
    }
  }, [duration, position])

  return (
    <Root className={classes.container} style={{ ...style }}>
      <div className={classes.progress} ref={progressBarRef} data-testid="progressBar" />
      <div className={classes.background} />
    </Root>
  )
}

export default ProgressBar

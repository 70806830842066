/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement } from "react"
import { FailureReason } from "@medaica/common/components/compatibility-check"
import MinimalLayout from "@medaica/common/layouts/minimal-layout"
import CompatibilityCheckFailureMessage from "@medaica/common/components/compatibility-check-failure-message"

const CompatibilityCheckFailure = ({ failureReason }: { failureReason: FailureReason }): ReactElement => {
  return (
    <MinimalLayout>
      <div className="w-3/6 mx-auto">
        <h2 className="text-3xl font-bold mb-6">Compatibility Error</h2>
        <CompatibilityCheckFailureMessage failureReason={failureReason} />
      </div>
    </MinimalLayout>
  )
}

export default CompatibilityCheckFailure

/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement, useContext, useEffect, useState } from "react"
import Header from "@medaica/common/components/page/page-header"
import { Paths } from "const"
import ShareIcon from "@mui/icons-material/Share"
import SelfInitiatedSelfExamContext from "views/exam/self-initiated-self-exam/self-initiated-self-exam-context"
import AuscultationTable from "views/exam/self-initiated-self-exam/components/auscultation-table"
import AddAuscultationDialog from "views/exam/self-initiated-self-exam/components/add-auscultation-dialog"
import ShareExamDialog from "views/exam/self-initiated-self-exam/components/share-exam-dialog"
import useGlobalContext from "@medaica/common/hooks/global-context"
import { AuscultationPoint } from "@medaica/common/types"
import { observer } from "mobx-react-lite"
import { Button } from "@mui/material"
import AddAuscultationButton from "views/exam/self-initiated-self-exam/components/add-auscultation-button"

const SelfInitiatedSelfExamView = observer((): ReactElement => {
  const { setCurrentPage } = useGlobalContext()
  const { selfInitiatedSelfExamStore, mediaDeviceManager } = useContext(SelfInitiatedSelfExamContext)
  const [auscultationModalIsOpen, setAuscultationModalIsOpen] = useState(false)
  const [shareExamModalIsOpen, setShareExamModalIsOpen] = useState(false)
  const [auscultationPoint, setAuscultationPoint] = useState<AuscultationPoint | null>(null)

  useEffect(() => {
    setCurrentPage("exams", Paths.exams)
  }, [setCurrentPage])

  // We want to warn the user before leaving the page if the uploads have not completed
  //useBeforeUnload(() => selfInitiatedSelfExamStore.isBusy)

  useEffect(() => {
    return () => {
      mediaDeviceManager.dispose()
    }
  }, [mediaDeviceManager, selfInitiatedSelfExamStore])

  const handleAuscultationPointSelected = (auscultationPoint: AuscultationPoint) => {
    setAuscultationPoint(auscultationPoint)
    setAuscultationModalIsOpen(true)
  }

  const shareExamButtonClicked = () => {
    setShareExamModalIsOpen(true)
  }

  return (
    <>
      <ShareExamDialog isOpen={shareExamModalIsOpen} onCloseRequested={() => setShareExamModalIsOpen(false)} />
      {/* We create a new Dialog for each every time we set it to open instead of just updating the open prop */}
      {auscultationModalIsOpen ? (
        <AddAuscultationDialog
          onClose={() => setAuscultationModalIsOpen(false)}
          auscultationPoint={auscultationPoint}
        />
      ) : null}
      <div className="main-centered">
        <Header
          title="New Exam"
          actions={
            <>
              <Button startIcon={<ShareIcon />} variant="outlined" onClick={shareExamButtonClicked}>
                Share Exam
              </Button>
              <AddAuscultationButton
                label="New Recording"
                variant="outlined"
                onAuscultationPointSelected={handleAuscultationPointSelected}
              />
            </>
          }
        />
        <div>
          {selfInitiatedSelfExamStore.auscultations.length > 0 ? (
            <AuscultationTable caption="Recordings" auscultations={selfInitiatedSelfExamStore.auscultations} />
          ) : (
            <div className="panel">
              <div className="my-16 mx-auto w-1/2 text-center">
                <h3 className="text-xl font-semibold">You don't have any recordings yet.</h3>
                <p className="mt-3 mb-8">
                  An exam requires at least one recording. Click <strong>New Recording</strong> to create a your first
                  recording.
                </p>
                <AddAuscultationButton
                  label="New Recording"
                  variant="contained"
                  onAuscultationPointSelected={handleAuscultationPointSelected}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )
})

export default SelfInitiatedSelfExamView

/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement, useContext, useRef, useState } from "react"
import { Button } from "@mui/material"
import VirtualExamContext from "views/exam/virtual-exam/virtual-exam-context"
import { M1 } from "@medaica/common/services/m1"

const AudioTest = (): ReactElement => {
  const { mediaDeviceStore, avStore } = useContext(VirtualExamContext)
  const m1Ref = useRef<M1 | null>(null)
  const [show, setShow] = useState(false)

  // @ts-ignore
  global.startAudioTest = () => {
    setShow(true)
  }

  // @ts-ignore
  global.stopAudioTest = () => {
    setShow(false)
  }

  const publishAuscultationTrack = async () => {
    const m1 = await mediaDeviceStore.connectToM1(true)
    if (!m1) {
      return
    }
    m1Ref.current = m1
    await avStore.publishLocalAudioTrack(m1.auscultationAudioTrack)
  }
  const unpublishAuscultationTrack = () => {
    if (m1Ref.current?.auscultationAudioTrack) {
      avStore.unpublishLocalAudioTrack(m1Ref.current.auscultationAudioTrack)
    }
  }

  const disconnectFromM1ForRecording = async () => {
    await mediaDeviceStore.disconnectFromM1ForRecording()
  }

  const connectToM1ForNotRecording = async () => {
    await mediaDeviceStore.connectToM1(false)
  }
  return show ? (
    <ul>
      <li>
        <Button onClick={() => void publishAuscultationTrack()}>Connect to M1 for auscultation</Button>
      </li>
      <li>
        <Button onClick={() => void connectToM1ForNotRecording()}>Connect to M1 for simple audio</Button>
      </li>
      <li>
        <Button onClick={() => void unpublishAuscultationTrack()}>Unpublish auscultation track</Button>
      </li>
      <li>
        <Button onClick={() => void disconnectFromM1ForRecording()}>Disconnect from M1 for auscultation</Button>
      </li>
    </ul>
  ) : (
    <></>
  )
}

export default AudioTest

/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement, useCallback, useEffect, useState } from "react"
import { Link } from "react-router-dom"
import useGlobalContext from "@medaica/common/hooks/global-context"
import Header from "@medaica/common/components/page/page-header"
import { Paths } from "const"
import { Exam } from "@medaica/common/types"
import Table, { buildActionColumn } from "@medaica/common/components/table/table"
import moment from "moment"
import useAuth from "services/auth"
import useQuery from "@medaica/common/hooks/query"

const ExamListView = (): ReactElement => {
  const { setCurrentPage, medaicaApiService } = useGlobalContext()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [exams, setExams] = useState<any[] | null>(null)
  const { user } = useAuth()
  const [count, setCount] = useState<number>()
  const pageNumber = useQuery("p")

  useEffect(() => {
    setCurrentPage("exams", Paths.exams)
  }, [setCurrentPage])

  const fetchData = useCallback(
    async (pageNumber: number): Promise<void> => {
      const page = await medaicaApiService.exams.getExams({
        filters: [{ field: "patientId", value: user.id }],
        sort: [{ field: "dateCreated", direction: "asc" }],
        pagination: { pageNumber: pageNumber, pageSize: 10 },
      })
      setCount(page.totalCount)
      setExams(
        page.items.map((exam) => {
          return {
            date: moment(exam.dateCreated).format("lll"),
            provider: exam.healthcareProvider?.displayName,
            type: exam.type,
            actions: exam,
          }
        })
      )
    },
    [medaicaApiService, user]
  )

  useEffect(() => {
    void fetchData(pageNumber ? parseInt(pageNumber) : 1)
  }, [fetchData, pageNumber])

  return (
    <div className="main-centered">
      <Header title="Exams" />
      <Table
        data={exams}
        title="Exams list"
        options={{
          serverSide: true,
          count: count,
        }}
        columns={[
          {
            label: "Date",
            name: "date",
            options: {
              filter: true,
              sort: true,
            },
          },
          {
            label: "Provider",
            name: "provider",
            options: {
              filter: true,
              sort: true,
            },
          },
          {
            name: "type",
            label: "Type",
            options: {
              sort: true,
            },
          },
          buildActionColumn("actions", (value: Exam) => (
            <Link to={`${Paths.exams}/${value.id}`} className="link">
              <button className="link">View Details</button>
            </Link>
          )),
        ]}
      />
    </div>
  )
}

export default ExamListView

/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import { ExclamationIcon, CheckCircleIcon, InformationCircleIcon, XCircleIcon } from "@heroicons/react/solid"
import React, { ForwardedRef, forwardRef, HTMLProps, ReactElement, ReactNode } from "react"

enum Mode {
  success,
  error,
  warning,
  info,
}

const Alert = forwardRef(
  (
    { mode, title, children, ...rest }: { mode: Mode; title?: string; children: ReactNode } & HTMLProps<HTMLDivElement>,
    ref: ForwardedRef<HTMLDivElement>
  ): ReactElement => {
    const config = {
      [Mode.success]: {
        className: "alert-success",
        color: "green",
        icon: CheckCircleIcon,
      },
      [Mode.error]: {
        className: "alert-error",
        color: "red",
        icon: XCircleIcon,
      },
      [Mode.warning]: {
        className: "alert-warning",
        color: "yellow",
        icon: ExclamationIcon,
      },
      [Mode.info]: {
        className: "alert-info",
        color: "blue",
        icon: InformationCircleIcon,
      },
    }[mode]

    return (
      <div ref={ref} {...rest}>
        <div className={`${config.className} bg-${config.color}-50 border-l-4 border-${config.color}-400 p-4`}>
          <div className="flex">
            <div className="flex-shrink-0 mt-px">
              <config.icon className={`h-6 w-6 text-${config.color}-600`} aria-hidden="true" />
            </div>
            <div className="ml-3">
              {title && <h3 className={`font-semibold mb-2 text-${config.color}-700`}>{title}</h3>}
              {children && <div className={`text-${config.color}-700`}>{children}</div>}
            </div>
          </div>
        </div>
      </div>
    )
  }
)

export default Alert
export { Mode }

/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import { DialogTitle } from "@mui/material"

import React, { ReactElement, ReactNode } from "react"

const PageDialogTitle = ({ children }: { children: ReactNode }): ReactElement => {
  return (
    <DialogTitle {...({ component: "div" } as never)} sx={{ paddingTop: "24px", paddingX: "24px" }}>
      <h1 className="font-semibold text-2xl mb-4">{children}</h1>
    </DialogTitle>
  )
}

export default PageDialogTitle

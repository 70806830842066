/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { useCallback } from "react"
import { ACTIONTYPE, TwilioStatus, TwilioAPIStatus } from "../AppStateProvider"
import axios from "axios"

const BASE_URL = "https://status.twilio.com/api/v2/components.json"

const useTwilioStatus = (dispatch: React.Dispatch<ACTIONTYPE>): { getTwilioStatus: () => Promise<void> } => {
  const getTwilioStatus = useCallback(() => {
    return axios(BASE_URL)
      .then((response) => {
        const statusObj: TwilioStatus = {}

        const ALLOWED_COMPONENTS = [
          "Group Rooms",
          "Peer-to-Peer Rooms",
          "Compositions",
          "Recordings",
          "Network Traversal Service",
          "Go Rooms",
        ]

        response.data.components.forEach(({ name, status }: { name: keyof TwilioStatus; status: TwilioAPIStatus }) => {
          if (ALLOWED_COMPONENTS.includes(name)) {
            statusObj[name] = status
          }
        })

        dispatch({ type: "set-twilio-status", statusObj })
      })

      .catch((error) => {
        dispatch({ type: "set-twilio-status-error", error })
      })
  }, [dispatch])

  return { getTwilioStatus } as const
}

export default useTwilioStatus

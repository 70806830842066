/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement, useCallback, useContext, useEffect, useRef, useState } from "react"
import FirebaseLiveExamService, {
  ExamEntryRequest,
  ExamEntryRequestStatus,
} from "@medaica/common/views/exam/virtual-exam/exam-room/services/firebase-virtual-exam-service"
import LobbyBase from "@medaica/common/views/exam/virtual-exam/lobby"
import Reentry from "views/exam/virtual-exam/views/lobby/components/reentry"
import InitialEntryExamKnown from "views/exam/virtual-exam/views/lobby/components/initial-entry-exam-known"
import WaitingForAdmission from "views/exam/virtual-exam/views/lobby/components/waiting-for-admission"
import useAnonAuth from "views/exam/virtual-exam/hooks/anonAuth"
import InitialEntryExamUnknownPanel from "views/exam/virtual-exam/views/lobby/components/initial-entry-exam-unknown"
import URI from "urijs"
import useGlobalContext from "@medaica/common/hooks/global-context"
import FirebaseClient from "@medaica/common/services/firebase-client"
import VirtualExamContext from "views/exam/virtual-exam/virtual-exam-context"
import Denied from "views/exam/virtual-exam/views/lobby/components/denied"
import { Paths } from "const"

// todo get segment
const Lobby = (): ReactElement => {
  const [mode, setMode] = useState<"default" | "waitingForAdmission" | "deniedEntry">("default")
  const modeRef = useRef<"default" | "waitingForAdmission" | "deniedEntry">("default")
  modeRef.current = mode
  const { medaicaApiService } = useGlobalContext()
  const [lobbyMode, setLobbyMode] = useState<"reentry" | "initialEntry-examKnown" | "initialEntry-examUnknown">(
    "initialEntry-examUnknown"
  )
  const firebaseServiceRef = useRef<FirebaseLiveExamService>()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const auth = useAnonAuth()
  const { preferencesStore, uiStore, mediaDeviceStore } = useContext(VirtualExamContext)
  const [name, setName] = useState<string>("")

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const liveExamId = URI(window.location.href).segment(1)!
    if (liveExamId) {
      const token = auth.getAccessToken(liveExamId)
      setLobbyMode(token ? "reentry" : "initialEntry-examKnown")
    } else {
      setLobbyMode("initialEntry-examUnknown")
    }
  }, [auth])

  useEffect(() => {
    const currentFirebaseServiceRef = firebaseServiceRef.current
    return () => {
      if (currentFirebaseServiceRef) {
        //  todo if currentFirebaseServiceRef gets garbage collected, the listeners may not get disposed. Don't fix
        //  for now since we'll probably move to Redis
        currentFirebaseServiceRef.dispose()
      }
    }
  }, [])

  const setNewAccessToken = useCallback(
    async (liveExamId: string, patientName: string) => {
      const liveExamAccessToken = await medaicaApiService.virtualExams.createVirtualExamAccessToken(
        liveExamId,
        patientName
      )
      auth.storeAccessToken(liveExamAccessToken)
    },
    [auth, medaicaApiService.virtualExams]
  )

  const getFirebaseService = async (liveExamId: string): Promise<FirebaseLiveExamService> => {
    if (firebaseServiceRef.current) {
      return firebaseServiceRef.current
    } else {
      const firebaseAccessToken = await medaicaApiService.firebase.getFirebaseAccessTokenForPotentialParticipant(
        liveExamId
      )
      await FirebaseClient.instance.authenticate(firebaseAccessToken.value)
      firebaseServiceRef.current = new FirebaseLiveExamService(liveExamId)
      void firebaseServiceRef.current.examEntryRequestRef.onDisconnect().set(ExamEntryRequestStatus.cancelled)
      return firebaseServiceRef.current
    }
  }

  const attachEntryRequestChangedListener = (
    firebaseService: FirebaseLiveExamService,
    name: string,
    virtualExamId: string
  ): void => {
    firebaseService.examEntryRequestRef.off()
    firebaseService.examEntryRequestRef.on("value", async (snapshot) => {
      if (modeRef.current !== "waitingForAdmission") {
        return
      }
      const examEntryRequest = snapshot.val() as ExamEntryRequest
      if (examEntryRequest?.status === ExamEntryRequestStatus.approved) {
        await setNewAccessToken(virtualExamId, name)
        window.history.replaceState(null, "", `${Paths.liveExam}/${virtualExamId}`)
        uiStore.navigate("exam")
      } else if (examEntryRequest?.status === ExamEntryRequestStatus.denied) {
        setMode("deniedEntry")
      }
    })
  }

  const handleExamEntryRequestMade = async (patientName: string, virtualExamId: string): Promise<void> => {
    setMode("waitingForAdmission")
    setName(patientName)
    const firebaseService = await getFirebaseService(virtualExamId)
    attachEntryRequestChangedListener(firebaseService, patientName, virtualExamId)
    await firebaseService.examEntryRequestRef.set({
      name: patientName,
      status: ExamEntryRequestStatus.pending,
    })
  }

  const getLobby = (lobbyMode) => {
    switch (lobbyMode) {
      case "reentry":
        return (
          <Reentry
            onEnterExam={(patientName: string, virtualExamId: string) =>
              void handleExamEntryRequestMade(patientName, virtualExamId)
            }
          />
        )
      case "initialEntry-examKnown":
        return (
          <InitialEntryExamKnown
            onEnterExam={(patientName: string, virtualExamId: string) =>
              void handleExamEntryRequestMade(patientName, virtualExamId)
            }
            name={name}
          />
        )
      default:
        return (
          <InitialEntryExamUnknownPanel
            onEnterExam={(patientName: string, virtualExamId: string) =>
              void handleExamEntryRequestMade(patientName, virtualExamId)
            }
          />
        )
    }
  }

  const getView = (mode, lobbyMode) => {
    switch (mode) {
      case "deniedEntry":
        return <Denied handleTimeoutComplete={() => setMode("default")} />
      case "waitingForAdmission":
        return <WaitingForAdmission />
      default:
        return getLobby(lobbyMode)
    }
  }

  return (
    <LobbyBase preferencesStore={preferencesStore} mediaDeviceStore={mediaDeviceStore}>
      <div className="flex h-full flex-col justify-center w-full items-center p-10">{getView(mode, lobbyMode)}</div>
    </LobbyBase>
  )
}

export default Lobby

/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement } from "react"
import { Box, Typography } from "@mui/material"

const ParticipantNameLabel = ({ name }: { name: string }): ReactElement => {
  return (
    <Box
      sx={(theme) => ({
        position: "absolute",
        display: "flex",
        alignItems: "center",
        bottom: theme.spacing(0.5),
        left: theme.spacing(0.5),
        color: "white",
        zIndex: 10,
        backgroundColor: "rgba(0, 0, 0, 0.4)",
        borderRadius: theme.shape.borderRadius / 2,
        padding: theme.spacing(0.35, 1),
      })}
    >
      <Typography variant="body2">{name}</Typography>
    </Box>
  )
}

export default ParticipantNameLabel

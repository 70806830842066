/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import { AnyError, AuscultationPoint } from "@medaica/common/types"
import PatientVirtualExamMediaDeviceStore from "views/exam/virtual-exam/views/exam-room/stores/patient-virtual-exam-media-device-store"
import { logError } from "@medaica/common/services/util"
import { action, autorun, makeObservable, observable } from "mobx"
import Recorder from "@medaica/common/services/recorder"
import VirtualExamStore from "./virtual-exam-store"
import { deviceIsM1 } from "@medaica/common/services/m1"
import AVStore from "@medaica/common/views/exam/virtual-exam/stores/av-store"
import AuscultationRequestDataStore from "views/exam/virtual-exam/views/exam-room/stores/auscultation-request-data-store"
import NoiseQualityRecorder, {
  NoiseQualityRecord,
} from "@medaica/common/components/audio-player/noise-quality-recorder"
import BadNoiseDetector from "@medaica/common/components/audio-player/aquf/bad-noise-detector"
import NoiseFilter from "@medaica/common/components/audio-player/noise-filter"

enum ErrorType {
  HealthcareProviderDisconnected,
  DeviceDisconnected,
}

type RecordingCreatedEventHandler = (
  auscultationRequest: AuscultationRequest,
  recording: Blob,
  deviceLabel: string,
  noiseQualityRecord: NoiseQualityRecord
) => Promise<void>

class AuscultationRequest {
  private recorder: Recorder
  readonly id: string
  readonly auscultationPoint: AuscultationPoint
  private readonly mediaDeviceStore: PatientVirtualExamMediaDeviceStore
  private readonly virtualExamStore: VirtualExamStore
  private readonly avStore: AVStore
  private readonly dataStore: AuscultationRequestDataStore
  private readonly onRecordingCreated: RecordingCreatedEventHandler
  state: "recording" | "complete" | "cancelled" = "recording"
  private noiseQualityRecorder: NoiseQualityRecorder

  constructor({
    dataStore,
    id,
    auscultationPoint,
    mediaDeviceStore,
    virtualExamStore,
    avStore,
    onRecordingCreated,
  }: {
    dataStore: AuscultationRequestDataStore
    id: string
    auscultationPoint: AuscultationPoint
    onRecordingCreated: RecordingCreatedEventHandler
    mediaDeviceStore: PatientVirtualExamMediaDeviceStore
    virtualExamStore: VirtualExamStore
    avStore: AVStore
  }) {
    makeObservable(this, {
      state: observable,
      handleError: action,
    })
    this.id = id
    this.auscultationPoint = auscultationPoint
    this.dataStore = dataStore
    this.mediaDeviceStore = mediaDeviceStore
    this.virtualExamStore = virtualExamStore
    this.avStore = avStore
    this.onRecordingCreated = onRecordingCreated

    autorun(() => {
      if (!deviceIsM1(this.mediaDeviceStore.audioDevice) && this.state === "recording") {
        this.handleError(new Error(ErrorType[ErrorType.DeviceDisconnected]))
      }
      if (!this.avStore.remoteParticipant?.connected && this.state === "recording") {
        this.handleError(new Error(ErrorType[ErrorType.HealthcareProviderDisconnected]))
      }
      if (this.dataStore.recordingCancelRequest) {
        this.recorder?.cancel()
        this.noiseQualityRecorder.stop()
        this.state = "cancelled"
      }
      if (this.dataStore.recordingStopRequest) {
        this.recorder?.stop()
        this.noiseQualityRecorder.stop()
        this.state = "complete"
      }
    })
  }

  async startRecording(): Promise<void> {
    await this.virtualExamStore.activateAuscultationMic()
    if (!deviceIsM1(this.mediaDeviceStore.audioDevice)) {
      throw new Error("Device not M1")
    }
    const m1 = this.mediaDeviceStore.audioDevice

    const badNoiseDetector = new BadNoiseDetector(new NoiseFilter())
    this.noiseQualityRecorder = new NoiseQualityRecorder(badNoiseDetector)

    this.recorder = new Recorder()

    this.recorder.onRecordingComplete = (recording) => {
      this.noiseQualityRecorder.stop()
      void this.onRecordingCreated(this, recording, m1.label, this.noiseQualityRecorder.getResult())
    }

    this.recorder.onError = (error: AnyError) => {
      this.noiseQualityRecorder.stop()
      this.handleError(error)
    }

    badNoiseDetector.start(m1.recordingStream)
    this.noiseQualityRecorder.start()
    await this.recorder.start(m1.recordingStream)
  }

  handleError(error: AnyError): void {
    logError(error)
    this.state = "cancelled"
    this.dataStore.setError(error["message"] || error)
    this.recorder?.cancel()
    this.noiseQualityRecorder.stop()
  }
}

export default AuscultationRequest

/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import MedaicaApiService from "@medaica/common/services/medaica-api-service"
import VirtualExamStoreBase from "@medaica/common/views/exam/virtual-exam/virtual-exam-store"
import { action, autorun, computed, makeObservable, observable, reaction, runInAction, when } from "mobx"
import PatientVirtualExamMediaDeviceStore from "views/exam/virtual-exam/views/exam-room/stores/patient-virtual-exam-media-device-store"
import AVStore from "@medaica/common/views/exam/virtual-exam/stores/av-store"
import AuscultationRequest from "views/exam/virtual-exam/views/exam-room/stores/auscultation-request"
import _ from "lodash"
import logger from "@medaica/common/services/logging"
import { auscultationPoints, Role } from "@medaica/common/const"
import FirebaseVirtualExamService, {
  ExamEntryRequestStatus,
} from "@medaica/common/views/exam/virtual-exam/exam-room/services/firebase-virtual-exam-service"
import { handleChildAdded } from "@medaica/common/services/firebase-client"
import firebase from "firebase"
import { deviceIsM1, M1 } from "@medaica/common/services/m1"
import AuscultationRequestDataStore from "views/exam/virtual-exam/views/exam-room/stores/auscultation-request-data-store"
import BadNoiseDetector from "@medaica/common/components/audio-player/aquf/bad-noise-detector"
import { NoiseQualityRecord } from "@medaica/common/components/audio-player/noise-quality-recorder"
import NoiseFilter from "@medaica/common/components/audio-player/noise-filter"
import { logError } from "@medaica/common/services/util"

/**
 * This is the root store for the virtual exam. It manages most of the state and coordinates just about everything.
 *  Unless there's a good reason for doing so, pretty much all actions should by initiated by calling methods on
 *   this store.
 */
class VirtualExamStore extends VirtualExamStoreBase {
  protected _disposers: (() => void)[] = []
  private readonly _mediaDeviceStore: PatientVirtualExamMediaDeviceStore
  private readonly _avStore: AVStore
  private _fallbackDeviceId: string | undefined
  // We use this to store the user's preference for muting the audio device, so that when we automatically unmute
  // after an HCP auscultation review we restore the user's preference.
  private audioDeviceMutedByUser = false
  auscultationRequests: AuscultationRequest[] = []
  inListeningMode = false
  badNoiseDetector: BadNoiseDetector | null = null
  isTargetEnabled = false

  constructor(
    id: string,
    virtualExamId: string,
    firebaseLiveExamService: FirebaseVirtualExamService,
    medaicaApiService: MedaicaApiService,
    mediaDeviceStore: PatientVirtualExamMediaDeviceStore,
    avStore: AVStore
  ) {
    super(id, virtualExamId, firebaseLiveExamService, medaicaApiService)
    makeObservable(this, {
      auscultationRequests: observable,
      isRecording: computed,
      inListeningMode: observable,
      badNoiseDetector: observable,
      activateAuscultationMic: action,
      isTargetEnabled: observable,
    })
    this._mediaDeviceStore = mediaDeviceStore
    this._avStore = avStore

    this._firebaseVirtualExamService.examEntryRequestRef.onDisconnect().set(ExamEntryRequestStatus.cancelled)

    autorun(() => {
      this._firebaseVirtualExamService.isM1ConnectedRef.set(this._mediaDeviceStore.isM1Connected)
      if (!this._avStore.remoteParticipant?.connected) {
        this.inListeningMode = false
      }
      this._mediaDeviceStore.setAudioDeviceMuted(this.isReviewingAuscultation || this.audioDeviceMutedByUser)
      if (!deviceIsM1(this._mediaDeviceStore.audioDevice)) {
        this.inListeningMode = false
        this.badNoiseDetector?.stop()
        this.badNoiseDetector = null
      }
      if (this.inListeningMode && this.badNoiseDetector) {
        avStore.localDataTrack?.send(
          JSON.stringify({
            type: "badNoiseDetected",
            detail: this.badNoiseDetector.isBadNoiseDetected,
          })
        )
      }
    })
  }

  /**
   * This method performs all initial setup.
   */
  async load(userName: string): Promise<void> {
    const virtualExam = await this._getVirtualExam()

    this.accessCode = virtualExam.accessCode
    this.patientProfile = virtualExam.patientProfile
    this.healthcareProvider = virtualExam.healthcareProvider
    this.examId = virtualExam.examId

    this.listenForAVManagerReady()
    this.listenForIsReviewingAuscultationChanged()
    this.listenForAuscultationRequestAdded()
    this.listenForAuscultationMicActivationRequest()
    this.listenForAuscultationMicDeactivationRequest()
    this.listenForProviderDisconnected()
    this.listenForTargetEnabled()

    this.registerPresence({
      id: this._userId,
      name: userName,
      role: Role.Patient,
    })
  }

  private listenForAuscultationRequestAdded() {
    handleChildAdded(
      this._firebaseVirtualExamService.auscultationRequestsRef as firebase.database.Reference,
      (snapshot) => {
        const auscultationRequestData = snapshot.val()
        logger.debug("New auscultation request", auscultationRequestData)

        runInAction(() => {
          // I'm not sure how this could happen, but this handles the case in which the previous auscultation was
          // not completed.
          const lastAuscultationRequest = _.last(this.auscultationRequests)
          if (lastAuscultationRequest?.state === "recording") {
            lastAuscultationRequest.handleError(new Error("Concurrent Auscultation Error"))
          }

          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          const auscultationId = snapshot.key!
          const auscultationPoint = auscultationPoints.getByLabel(auscultationRequestData.auscultationPoint as string)

          const auscultationRequest = new AuscultationRequest({
            dataStore: new AuscultationRequestDataStore(snapshot.ref),
            id: auscultationId,
            auscultationPoint,
            mediaDeviceStore: this._mediaDeviceStore,
            virtualExamStore: this,
            avStore: this._avStore,
            onRecordingCreated: (...args) => this.addAuscultation(...args),
          })
          auscultationRequest
            .startRecording()
            .then(() => {
              this.auscultationRequests.unshift(auscultationRequest)
            })
            .catch((error) => {
              auscultationRequest.handleError(error)
              this.deactivateAuscultationMic().catch(logError)
            })
        })
      }
    )
  }

  private listenForIsReviewingAuscultationChanged() {
    this._firebaseVirtualExamService.isReviewingAuscultationRef.on(
      "value",
      action((snapshot) => {
        this.isReviewingAuscultation = snapshot.val() as boolean
      })
    )
  }

  private listenForTargetEnabled() {
    this._firebaseVirtualExamService.isTargetEnabledRef.on(
      "value",
      action((snapshot) => {
        this.isTargetEnabled = snapshot.val() as boolean
      })
    )
  }

  private listenForAuscultationMicActivationRequest() {
    handleChildAdded(
      this._firebaseVirtualExamService.auscultationMicActivationRequestRef as firebase.database.Reference,
      () => {
        if (this._mediaDeviceStore.isM1Connected) {
          this.activateAuscultationMic().catch(logError)
        }
      }
    )
  }

  private listenForAuscultationMicDeactivationRequest() {
    handleChildAdded(
      this._firebaseVirtualExamService.auscultationMicDeactivationRequestRef as firebase.database.Reference,
      () => {
        if (this._mediaDeviceStore.isM1Connected) {
          this.deactivateAuscultationMic().catch(logError)
        }
      }
    )
  }

  private listenForProviderDisconnected() {
    reaction(
      () => this._avStore.remoteParticipant?.connected,
      (connected) => {
        if (!connected) {
          // In case the provider disconnected while the auscultation mic was active, we need to make sure it gets
          // set to inactive
          if (deviceIsM1(this._mediaDeviceStore.audioDevice)) {
            this._mediaDeviceStore.connectToAudioDevice().catch(logError)
          }
        }
      }
    )
  }

  private disableM1OnLocalSpeakers(m1: M1): void {
    m1.disableAuscultationMicOnLocalSpeakers()
    m1.disableRoomMicOnLocalSpeakers()
  }

  async activateAuscultationMic(): Promise<void> {
    if (!this._mediaDeviceStore.isM1Connected) {
      throw new Error("M1 is not connected")
    }

    if (this._avStore.localAudioTracksInclude("m1AuscMicAudio")) {
      return
    }

    this._fallbackDeviceId = this._mediaDeviceStore.audioDevice?.id
    const m1 = await this._mediaDeviceStore.connectToM1(true)
    if (!m1) {
      throw new Error("M1 is not connected")
    }
    this.disableM1OnLocalSpeakers(m1)

    await runInAction(async () => {
      this.badNoiseDetector = new BadNoiseDetector(new NoiseFilter())
      this.badNoiseDetector.start(m1.recordingStream)

      await this._avStore.publishLocalAudioTrack(m1.auscultationAudioTrack)

      this.inListeningMode = true
    })
  }

  async deactivateAuscultationMic(): Promise<void> {
    await runInAction(async () => {
      this.badNoiseDetector?.stop()
      this.badNoiseDetector = null
      this.inListeningMode = false
      await this._mediaDeviceStore.connectToAudioDevice(this._fallbackDeviceId)
    })
  }

  public listenForAVManagerReady(): void {
    this._disposers.push(
      when(
        () => !!this.avRoomName,
        () => {
          void (async () => {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const avRoomName = this.avRoomName!
            const token = await this._medaicaApiService.twilio.getTwilioRoomToken(avRoomName)
            await this._avStore.joinRoom(token.value, avRoomName)
          })()
        }
      )
    )
  }

  get isRecording(): boolean {
    return this.auscultationRequests.length > 0 && this.auscultationRequests[0].state === "recording"
  }

  async addAuscultation(
    auscultationRequest: AuscultationRequest,
    data: Blob,
    deviceLabel: string,
    noiseQualityRecord: NoiseQualityRecord
  ): Promise<void> {
    const examId = await this.getOrCreateExam()

    const uploadProgressCallback = (progress: number) => {
      this._firebaseVirtualExamService.virtualExamIdRef
        .child(`/auscultationRequests/${auscultationRequest.id}/uploadProgress`)
        .set(progress)
    }
    try {
      await this._medaicaApiService.exams.addAuscultationToExam({
        examId,
        auscultationPoint: auscultationRequest.auscultationPoint,
        recording: data,
        noiseQualityRecord,
        deviceLabel: deviceLabel,
        uploadProgressCallback,
        virtualExamInfo: {
          virtualExamId: this.virtualExamId,
          auscultationRequestId: auscultationRequest.id,
        },
      })
    } catch (error) {
      logError(error)
    }
  }

  cancelExamEntryRequest(): void {
    this._firebaseVirtualExamService.examEntryRequestRef.set(null)
  }

  setAudioDeviceMutedByUser(muted: boolean): void {
    this.audioDeviceMutedByUser = muted
    this._mediaDeviceStore.setAudioDeviceMuted(muted)
  }

  setVideoDeviceMutedByUser(muted: boolean): void {
    this._mediaDeviceStore.setVideoDeviceMuted(muted)
  }

  dispose(): void {
    this.cancelExamEntryRequest()
    this.badNoiseDetector?.stop()
    this._disposers.forEach((disposer) => disposer())
    super.dispose()
  }
}

export default VirtualExamStore

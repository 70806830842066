/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import { LiveExamAnonAccessToken } from "@medaica/common/types"
import { useCallback } from "react"
import { dateTimeReviver } from "@medaica/common/services/util"

const liveExamAnonAccessTokenKey = "liveExamAnonAccessToken"

function useAnonAuth(): {
  getAccessToken: (liveExamId: string) => LiveExamAnonAccessToken | undefined
  deleteAccessToken: (liveExamId: string) => void
  storeAccessToken: (accessToken: LiveExamAnonAccessToken) => void
  isAuthenticated: (liveExamId: string) => boolean
} {
  const isAuthenticated = (liveExamId: string): boolean => {
    const token = getAccessToken(liveExamId)
    if (!token) {
      return false
    }
    return new Date() < token.expiresAt
  }

  const storeAccessToken = (accessToken: LiveExamAnonAccessToken): void => {
    localStorage.setItem(`${liveExamAnonAccessTokenKey}-${accessToken.liveExamId}`, JSON.stringify(accessToken))
  }

  const deleteAccessToken = useCallback((liveExamId: string): void => {
    localStorage.removeItem(`${liveExamAnonAccessTokenKey}-${liveExamId}`)
  }, [])

  const getAccessToken = useCallback((liveExamId: string): LiveExamAnonAccessToken | undefined => {
    const accessToken = localStorage.getItem(`${liveExamAnonAccessTokenKey}-${liveExamId}`)
    if (!accessToken) {
      return undefined
    }
    return JSON.parse(accessToken, dateTimeReviver) as LiveExamAnonAccessToken
  }, [])

  // const redirectOnAuthenticationExpiration = () => {
  //   const token = storage.getLiveExamAnonAccessToken()
  //   // set timer
  // }

  return {
    getAccessToken,
    storeAccessToken,
    isAuthenticated,
    deleteAccessToken,
  }
}

export default useAnonAuth

/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import { mapReplacer, mapReviver } from "./util"

type StoredAccessTokenData = {
  value: string
  expiresAt: Date
  userId: string
}

/**
 * This class handles the local storage of access tokens. It is effectively a queue, which stores a fixed
 *  number of access tokens (FIFO). We store these tokens so the user doesn't have to request a new one every time
 *   they access the page.
 */
class AccessTokenStorage {
  private readonly _storageKey: string

  constructor(storageKey: string) {
    this._storageKey = storageKey
  }

  private static readonly _maxSize = 20

  get(key: string): StoredAccessTokenData | null {
    const data = localStorage.getItem(this._storageKey)
    if (!data) {
      return null
    }
    const tokens = JSON.parse(data, mapReviver) as Map<string, StoredAccessTokenData>
    return tokens.get(key) ?? null
  }

  // todo check if we're dumping after 20
  set(key: string, accessTokenData: StoredAccessTokenData): void {
    const data = localStorage.getItem(this._storageKey)
    const tokens: Map<string, StoredAccessTokenData> = data
      ? (JSON.parse(data, mapReviver) as Map<string, StoredAccessTokenData>)
      : new Map<string, StoredAccessTokenData>()
    // We cap the list at 20 items
    if (tokens.size === AccessTokenStorage._maxSize) {
      tokens.delete(tokens.keys()[AccessTokenStorage._maxSize - 1] as string)
    }
    tokens.set(key, accessTokenData)
    localStorage.setItem(this._storageKey, JSON.stringify(tokens, mapReplacer))
  }
}

export default AccessTokenStorage

/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement, useRef } from "react"
import SelfInitiatedSelfExamView from "views/exam/self-initiated-self-exam/exam"
import SelfInitiatedSelfExamStore from "views/exam/self-initiated-self-exam/stores/self-initiated-self-exam-store"
import useGlobalContext from "@medaica/common/hooks/global-context"
import SelfInitiatedSelfExamContext from "views/exam/self-initiated-self-exam/self-initiated-self-exam-context"
import M1MediaDeviceStore from "@medaica/common/services/m1-media-device-store"
import M1DeviceInfoUtil from "@medaica/common/services/m1-device-info-util"
import useAuth from "services/auth"

const Index = (): ReactElement => {
  const { medaicaApiService } = useGlobalContext()
  const mediaDeviceManagerRef = useRef(new M1MediaDeviceStore(new M1DeviceInfoUtil()))
  const { user } = useAuth()
  const selfInitiatedSelfExamStore = useRef(
    new SelfInitiatedSelfExamStore(user.id, medaicaApiService, mediaDeviceManagerRef.current)
  )

  return (
    <SelfInitiatedSelfExamContext.Provider
      value={{
        mediaDeviceManager: mediaDeviceManagerRef.current,
        selfInitiatedSelfExamStore: selfInitiatedSelfExamStore.current,
      }}
    >
      <SelfInitiatedSelfExamView />
    </SelfInitiatedSelfExamContext.Provider>
  )
}

export default Index

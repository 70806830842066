/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import { ClickAwayListener, Fade, Popper, PopperProps } from "@mui/material"
import React, { ReactElement, useState } from "react"
//import { styled } from "@mui/material/styles"
import { bindPopper, PopupState } from "material-ui-popup-state/hooks"
import DialogCloseButton from "@medaica/common/components/dialog/dialog-close-button"
import PopupContainer from "@medaica/common/components/popup/popup-container"

const PREFIX = "popup"

const classes = {
  popoverRoot: `${PREFIX}-popoverRoot`,
  popper: `${PREFIX}-popper`,
  arrow: `${PREFIX}-arrow`,
}

//const boxShadow = "0 1.2px 3.6px 0px rgb(0 0 0 / 0.11), 0 6.4px 14.4px 0px rgb(0 0 0 / 0.13)"

type Props = {
  arrow?: boolean
  arrowWidth?: string
  closeButton?: boolean
  onClose?: () => void
  children: ReactElement
  popupState: PopupState
}

const Popup = ({
  arrow = true,
  arrowWidth = "1.5em",
  closeButton = true,
  onClose = () => null,
  popupState,
  children,
  placement,
  modifiers,
  ...rest
}: Props & Omit<PopperProps, "open">): ReactElement => {
  // const StyledPopper = styled(Popper)(() => {
  //   const arrowWidthFloat = parseFloat(arrowWidth)
  //   const unit = arrowWidth.replace(arrowWidthFloat.toString(), "")
  //   const arrowHeight = `${arrowWidthFloat / Math.sqrt(2)}${unit}`
  //
  //   const color = "white"
  //   return {
  //     [`& .${classes.popoverRoot}`]: {
  //       backgroundColor: color,
  //       maxWidth: 1000,
  //     },
  //     // content: {
  //     //   padding: "4px",
  //     // },
  //     [`&.${classes.popper}`]: {
  //       zIndex: 2000,
  //       '&[data-popper-placement*="bottom"] $arrow': {
  //         top: 0,
  //         left: 0,
  //         marginTop: `-${arrowHeight}`, //"-0.71em",
  //         marginLeft: "100px",
  //         "&::before": {
  //           transformOrigin: "0 100%",
  //         },
  //       },
  //       '&[data-popper-placement*="top"] $arrow': {
  //         bottom: 0,
  //         left: 0,
  //         //marginBottom: "-0.71em",
  //         marginBottom: `-${arrowHeight}`,
  //         "&::before": {
  //           transformOrigin: "100% 0",
  //         },
  //       },
  //       '&[data-popper-placement*="right"] $arrow': {
  //         left: 0,
  //         //marginLeft: "-0.71em",
  //         marginLeft: `-${arrowHeight}`,
  //         height: arrowWidth,
  //         width: arrowHeight,
  //         "&::before": {
  //           transformOrigin: "100% 100%",
  //         },
  //       },
  //       '&[data-popper-placement*="left"] $arrow': {
  //         right: 0,
  //         //marginRight: "-0.71em",
  //         marginRight: arrowHeight,
  //         height: arrowWidth,
  //         width: arrowHeight,
  //         // height: "1em",
  //         // width: "0.71em",
  //         "&::before": {
  //           transformOrigin: "0 0",
  //         },
  //       },
  //     },
  //     [`& .${classes.arrow}`]: {
  //       overflow: "hidden",
  //       position: "absolute",
  //       width: arrowWidth,
  //       height: arrowHeight,
  //       // width: "1em",
  //       // height: "0.71em" /* = width / sqrt(2) = (length of the hypotenuse) */,
  //       boxSizing: "border-box",
  //       "&::before": {
  //         content: '""',
  //         margin: "auto",
  //         display: "block",
  //         width: "100%",
  //         height: "100%",
  //         boxShadow: boxShadow,
  //         backgroundColor: "white",
  //         transform: "rotate(45deg)",
  //       },
  //     },
  //   }
  // })

  const [arrowRef, setArrowRef] = useState<HTMLElement | null>(null)

  const handleOnClose = () => {
    popupState.close()
    onClose()
  }

  const defaultModifiers = [
    {
      name: "preventOverflow",
      options: {
        enabled: true,
        boundary: "window",
      },
    },
    ...(arrow
      ? [
          {
            name: "arrow",
            options: {
              enabled: arrow,
              element: arrowRef,
            },
          },
          {
            name: "offset",
            options: {
              offset: [0, 10],
            },
          },
        ]
      : [
          {
            name: "offset",
            options: {
              offset: [0, 5],
            },
          },
        ]),
  ]

  return (
    <Popper
      {...bindPopper(popupState)}
      placement={placement}
      transition
      className={classes.popper}
      // @ts-ignore
      modifiers={[...defaultModifiers, ...(modifiers ? modifiers : [])]}
      {...rest}
    >
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={350}>
          <div>
            {arrow && <span className={classes.arrow} ref={setArrowRef} />}
            <ClickAwayListener onClickAway={handleOnClose}>
              <div>
                <PopupContainer>
                  {closeButton && <DialogCloseButton onClick={handleOnClose} />}
                  {children}
                </PopupContainer>
              </div>
            </ClickAwayListener>
          </div>
        </Fade>
      )}
    </Popper>
  )
}

export default Popup

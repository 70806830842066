/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement } from "react"
import { MUIDataTableBody, TableBody, TableBodyCell, TableBodyRow } from "mui-datatables"
import { Skeleton, TableBody as MuiTableBody } from "@mui/material"
import useTailwindConfig from "@medaica/common/hooks/use-tailwind-config"
import _ from "lodash"

const LoadingTableBody = ({
  loading,
  options,
  columns,
  rows = 3,
  paging = false,
  ...others
}: { rows?: number; loading: boolean; paging?: boolean } & MUIDataTableBody): ReactElement => {
  const twConfig = useTailwindConfig()

  return loading ? (
    <MuiTableBody>
      {_.range(0, rows).map((_, rowIndex) => (
        <TableBodyRow key={rowIndex} options={options}>
          {columns.map((_, colIndex) => (
            <TableBodyCell key={`${rowIndex}-${colIndex}`} options={options} colIndex={0} rowIndex={0}>
              <Skeleton
                sx={{
                  backgroundColor: twConfig.theme.colors.gray["100"],
                }}
              />
            </TableBodyCell>
          ))}
        </TableBodyRow>
      ))}
    </MuiTableBody>
  ) : (
    <TableBody
      options={{
        ...options,
        setTableProps: () => {
          return {
            style: { opacity: 0.2 },
          }
        },
      }}
      columns={columns}
      {...others}
    />
  )
}

export default LoadingTableBody

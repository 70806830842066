/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import { SmallError } from "../../icons/SmallError"
import { Typography } from "@mui/material"
import Button from "@mui/material/Button"
import MuiSnackbar from "@mui/material/Snackbar"
import React, { ReactElement } from "react"
import { styled } from "@mui/material/styles"

const PREFIX = "snackbar"

const classes = {
  container: `${PREFIX}-container`,
  contentContainer: `${PREFIX}-contentContainer`,
  iconContainer: `${PREFIX}-iconContainer`,
  headline: `${PREFIX}-headline`,
}

const StyledMuiSnackbar = styled(MuiSnackbar)(() => ({
  [`& .${classes.container}`]: (theme) => ({
    display: "flex",
    justifyContent: "space-between",
    width: "400px",
    minHeight: "50px",
    background: "white",
    padding: "1em",
    borderRadius: "3px",
    boxShadow: "0 12px 24px 4px rgba(40,42,43,0.2)",
    borderLeft: "4px solid #D61F1F",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginLeft: "0.5em",
    },
  }),

  [`& .${classes.contentContainer}`]: {
    display: "flex",
    lineHeight: 1.8,
  },

  [`& .${classes.iconContainer}`]: {
    display: "flex",
    padding: "0 1.3em 0 0.3em",
    transform: "translateY(3px)",
  },

  [`& .${classes.headline}`]: {
    fontWeight: "bold",
  },
}))

interface SnackbarProps {
  open: boolean
}

/**
 * This Snackbar component is used to handle an edge case where a user grants their browser
 * permission to access their camera and/or microphone, and then later revokes this permission.
 * When this occurs, this Snackbar will display an error asking the user to reset their device permissions,
 * and refresh the app.
 */
export const Snackbar = ({ open }: SnackbarProps): ReactElement => {
  return (
    <StyledMuiSnackbar
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={open}
      autoHideDuration={110000}
    >
      <div className={classes.container}>
        <div className={classes.contentContainer}>
          <div className={classes.iconContainer}>
            <SmallError />
          </div>
          <div>
            <Typography variant="body1" className={classes.headline}>
              Device change: unable to connect -
            </Typography>
            <Typography variant="body1">
              {" "}
              please reset your browser/OS permissions (which may be in your "Settings") and refresh this page.
            </Typography>
            <Button style={{ marginTop: "1em" }} variant="outlined" onClick={() => window.location.reload()}>
              Refresh page
            </Button>
          </div>
        </div>
      </div>
    </StyledMuiSnackbar>
  )
}

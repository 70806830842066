/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement } from "react"
import { CircularProgress } from "@mui/material"

const WaitingForAdmission = (): ReactElement => {
  return (
    <>
      <h3 className="text-2xl">Asking to be let in...</h3>
      <p className="mt-6 mb-14">You'll join the exam when your healthcare provider lets you in</p>
      <CircularProgress size="3.6em" />
    </>
  )
}

export default WaitingForAdmission

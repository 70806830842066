/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import MedaicaApiService from "services/medaica-api-service"
import { pagination, QuerySpec } from "./"
import { HealthcareProvider, HealthcareProviderOverview, Page } from "@medaica/common/types"
import { plainToInstance } from "class-transformer"

class HealthcareProvidersApi {
  private _apiService: MedaicaApiService

  constructor(apiService: MedaicaApiService) {
    this._apiService = apiService
  }

  async getHealthcareProvider(healthcareProviderId: string): Promise<HealthcareProvider> {
    const apiClient = await this._apiService.apiClient()
    const response = await apiClient.get<HealthcareProvider>(`healthcare-providers/${healthcareProviderId}`)
    return response.data
  }
  async getHealthcareProviders(query: QuerySpec): Promise<Page<HealthcareProvider>> {
    const apiClient = await this._apiService.apiClient()
    const response = await apiClient.get(
      this._apiService.withQuery("/search/healthcare-providers", {
        q: JSON.stringify(query),
      })
    )
    return {
      // @ts-ignore
      items: plainToInstance(HealthcareProvider, response.data),
      ...pagination(response),
    }
  }

  async getHealthcareProvidersOverview(query: QuerySpec): Promise<Page<HealthcareProviderOverview>> {
    const apiClient = await this._apiService.apiClient()
    const response = await apiClient.get(
      this._apiService.withQuery("/healthcare-providers-overview", {
        q: JSON.stringify(query),
      })
    )
    return {
      // @ts-ignore
      items: plainToInstance(HealthcareProviderOverview, response.data),
      ...pagination(response),
    }
  }

  async updateHealthcareProvider(
    healthcareProviderId: string,
    props: {
      firstName?: string
      lastName?: string
      displayName?: string
      tcVersion?: number
    }
  ): Promise<HealthcareProvider | undefined> {
    const apiClient = await this._apiService.apiClient()
    const response = await apiClient.patch<HealthcareProvider>(`/healthcare-providers/${healthcareProviderId}`, props)
    return response.data
  }

  async updateHealthcareProviderPassword(password: string, healthcareProviderId: string): Promise<void> {
    const apiClient = await this._apiService.apiClient()
    await apiClient.patch(`/healthcare-providers/${healthcareProviderId}/password`, {
      password,
    })
  }

  async updateHealthcareProviderEmailAddress(emailAddress: string, healthcareProviderId: string): Promise<void> {
    const apiClient = await this._apiService.apiClient()
    await apiClient.put(`/healthcare-providers/${healthcareProviderId}/email-address`, {
      emailAddress,
    })
  }

  async getHealthcareProviderEmailAddress(healthcareProviderId: string): Promise<string> {
    const apiClient = await this._apiService.apiClient()
    const response = await apiClient.get<string>(`healthcare-providers/${healthcareProviderId}/email-address`)
    return response.data
  }

  async createHealthcareProvider(
    invitationId: string,
    password: string,
    firstName: string,
    lastName: string,
    displayName: string,
    tcVersion: number
  ): Promise<void> {
    const apiClient = await this._apiService.apiClient(false)
    await apiClient.post<{
      error: string
    }>(`/healthcare-providers`, {
      invitationId,
      password,
      firstName,
      lastName,
      displayName,
      tcVersion,
    })
  }
}

export default HealthcareProvidersApi

/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement, useContext, useState } from "react"
import SelfInitiatedSelfExamContext from "views/exam/self-initiated-self-exam/self-initiated-self-exam-context"
import { AuscultationPoint } from "@medaica/common/types"
import SelfAuscultationView, { RecordingInfo } from "components/CreateSelfAuscultationView"
import { Button, DialogContent, LinearProgress } from "@mui/material"
import DialogCloseButton from "@medaica/common/components/dialog/dialog-close-button"
import PageDialog from "@medaica/common/components/dialog/page-dialog"
import PageDialogTitle from "@medaica/common/components/dialog/page-dialog-title"
import PageDialogActions from "@medaica/common/components/dialog/page-dialog-actions"
import { useSnackbar } from "notistack"
import UndismissableDialog from "@medaica/common/components/dialog/undismissable-dialog"

const AddAuscultationDialog = ({
  onClose,
  auscultationPoint,
}: {
  onClose: () => void
  auscultationPoint: AuscultationPoint | null
}): ReactElement => {
  const { selfInitiatedSelfExamStore, mediaDeviceManager } = useContext(SelfInitiatedSelfExamContext)
  const [recordingInfo, setRecordingInfo] = useState<RecordingInfo | null>(null)
  const { enqueueSnackbar } = useSnackbar()
  const [isUploading, setIsUploading] = useState(false)
  const [uploadProgress, setUploadProgress] = useState(0)

  const acceptRecordingButtonClicked = async () => {
    if (recordingInfo) {
      setIsUploading(true)

      const uploadProgressCallback = (progress: number) => {
        setUploadProgress(progress)
      }

      await selfInitiatedSelfExamStore.addAuscultation(
        {
          data: recordingInfo.data,
          mimeType: recordingInfo.mimeType,
          deviceLabel: recordingInfo.deviceLabel,
        },
        recordingInfo.auscultationPoint,
        uploadProgressCallback
      )

      if (onClose) {
        onClose()
      }

      enqueueSnackbar("Recording successfully created")
    }
  }

  return (
    <>
      <UndismissableDialog open={isUploading}>
        <DialogContent>
          <div style={{ minWidth: "20rem" }}>
            <LinearProgress variant="determinate" value={uploadProgress} />
            <div className="mt-2 text-center text-sm">Uploading...</div>
          </div>
        </DialogContent>
      </UndismissableDialog>
      <PageDialog dismissable={false} open={true} onClose={onClose}>
        {auscultationPoint &&
          ((auscultationPoint) => (
            <>
              <PageDialogTitle>
                <DialogCloseButton onClick={onClose} />
                New Recording
              </PageDialogTitle>
              <DialogContent>
                <div className="overflow-hidden">
                  <SelfAuscultationView
                    auscultationPoint={auscultationPoint}
                    onRecordingMade={(recordingInfo) => setRecordingInfo(recordingInfo)}
                    mediaDeviceManager={mediaDeviceManager}
                    acceptRecordingButtonTitle="Accept Recording"
                  />
                </div>
              </DialogContent>
              <PageDialogActions>
                <Button variant="outlined" onClick={onClose}>
                  Cancel
                </Button>
                <Button
                  type="submit"
                  onClick={() => void acceptRecordingButtonClicked()}
                  variant="contained"
                  disabled={!recordingInfo || isUploading}
                >
                  Accept Recording
                </Button>
              </PageDialogActions>
            </>
          ))(auscultationPoint)}
      </PageDialog>
    </>
  )
}

export default AddAuscultationDialog

/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import { Typography, Table, TableHead, TableBody, TableRow, TableCell, Link } from "@mui/material"
import React, { ReactElement } from "react"
import { styled } from "@mui/material/styles"

const PREFIX = "supportedList"

const classes = {
  tableHead: `${PREFIX}-tableHead`,
  table: `${PREFIX}-table`,
}

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")({
  [`& .${classes.tableHead}`]: {
    background: "#F4F4F6",
  },
  [`& .${classes.table}`]: {
    "& td": {
      padding: "0.9em",
    },
  },
})

export const SupportedList = (): ReactElement => {
  return (
    <Root>
      <Table className={classes.table}>
        <TableHead className={classes.tableHead}>
          <TableRow>
            <TableCell>
              <Typography variant="body1">
                <strong>Supported browsers</strong>
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>
              <Typography variant="body1">
                <Link
                  color="inherit"
                  underline="always"
                  target="_blank"
                  rel="noopener"
                  href="https://www.google.com/chrome/"
                >
                  Chrome
                </Link>
              </Typography>
            </TableCell>
          </TableRow>
          {/*<TableRow>*/}
          {/*  <TableCell>*/}
          {/*    <Typography variant="body1">*/}
          {/*      <Link*/}
          {/*        color="inherit"*/}
          {/*        underline="always"*/}
          {/*        target="_blank"*/}
          {/*        rel="noopener"*/}
          {/*        href="https://www.mozilla.org/en-US/firefox/new/"*/}
          {/*      >*/}
          {/*        Firefox*/}
          {/*      </Link>*/}
          {/*    </Typography>*/}
          {/*  </TableCell>*/}
          {/*</TableRow>*/}
          {/*<TableRow>*/}
          {/*  <TableCell>*/}
          {/*    <Typography variant="body1">*/}
          {/*      <Link*/}
          {/*        color="inherit"*/}
          {/*        underline="always"*/}
          {/*        target="_blank"*/}
          {/*        rel="noopener"*/}
          {/*        href="https://support.apple.com/downloads/safari"*/}
          {/*      >*/}
          {/*        Safari*/}
          {/*      </Link>*/}
          {/*    </Typography>*/}
          {/*  </TableCell>*/}
          {/*</TableRow>*/}
          <TableRow>
            <TableCell style={{ borderBottom: "none" }}>
              <Typography variant="body1">
                <Link
                  color="inherit"
                  underline="always"
                  target="_blank"
                  rel="noopener"
                  href="https://www.microsoft.com/en-us/edge"
                >
                  Edge
                </Link>
              </Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Root>
  )
}

/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement, useCallback, useEffect, useRef, useState } from "react"
import ProgressCircle from "@medaica/common/components/progress-circle"
import { observer } from "mobx-react-lite"
import AuscultationPointImage from "@medaica/common/components/images/auscultation-point"
import { getConfigNumber, logError } from "@medaica/common/services/util"
import AudioPlayer, { AudioFile, RemoteAudioFile } from "@medaica/common/components/audio-player"
import { AnyError, Auscultation, AuscultationPoint } from "@medaica/common/types"
import VolumeMeter from "@medaica/common/components/volume-meter"
import Phonocardiogram, { PhonocardiogramHandle } from "@medaica/common/components/phonocardiogram"
import AuscultationWaveformProvider from "@medaica/common/services/auscultation-waveform-provider"
import Timer from "@medaica/common/services/timer"
import useGlobalContext from "@medaica/common/hooks/global-context"
import M1MediaDeviceStore from "@medaica/common/services/m1-media-device-store"
import { Accordion, Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material"
import MuiAccordionSummary, { AccordionSummaryProps } from "@mui/material/AccordionSummary"
import MuiAccordionDetails from "@mui/material/AccordionDetails"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import StethoscopeDetector from "@medaica/common/components/stethoscope-detector"
import resolveConfig from "tailwindcss/resolveConfig"
import tailwindConfig from /* preval */ "@medaica/common/tailwind.config.js"
import { styled } from "@mui/styles"
import Recorder from "@medaica/common/services/recorder"
import { buildAuscultationFileName, buildAudioFileFromAuscultation } from "@medaica/common/services/factories"
import { WAFFileContext } from "@medaica/common/components/audio-player/web-audio-filters"

type RecordingInfo = {
  mimeType: string
  deviceLabel: string
  auscultationPoint: AuscultationPoint
  data: Blob
}

const twConfig = resolveConfig(tailwindConfig)

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary expandIcon={<ExpandMoreIcon />} {...props} />
))(() => ({
  backgroundColor: twConfig.theme.colors.gray["100"],
}))

const AccordionDetails = styled(MuiAccordionDetails)(() => ({
  borderTopWidth: "1px",
}))

const CreateSelfAuscultationView = observer(
  ({
    onRecordingMade,
    auscultationPoint,
    existingAuscultation,
    mediaDeviceManager,
    acceptRecordingButtonTitle,
  }: {
    onRecordingMade: (recordingInfo: RecordingInfo) => void
    auscultationPoint: AuscultationPoint
    existingAuscultation?: Auscultation | null
    mediaDeviceManager: M1MediaDeviceStore
    acceptRecordingButtonTitle: string
  }): ReactElement => {
    const { medaicaApiService } = useGlobalContext()
    const [recordingInfo, setRecordingInfo] = useState<RecordingInfo | null>(null)
    const [audioFile, setAudioFile] = useState<AudioFile | RemoteAudioFile | null>(
      existingAuscultation ? buildAudioFileFromAuscultation(existingAuscultation, medaicaApiService) : null
    )
    // If the AuscultationRequest already has an auscultation, we open up to the review pane first
    const [currentStep, setCurrentStep] = useState(existingAuscultation ? 2 : 1)
    const [waveformProvider, setWaveformProvider] = useState<AuscultationWaveformProvider | null>()
    const [roomMicVolumeProvider, setRoomMicVolumeProvider] = useState<(() => number) | null>(null)
    const [isRecording, setIsRecording] = useState(false)
    const phonocardiogramHandle = useRef<PhonocardiogramHandle>(null)
    const timerRef = useRef(new Timer(getConfigNumber("REQUESTED_SELF_EXAM_RECORDING_DURATION") * 1000, 100))
    const mediaRecorderRef = useRef<Recorder | null>(null)
    const [error, setError] = useState<string>()

    const cancelRecording = useCallback(() => {
      setIsRecording(false)
      mediaRecorderRef.current?.cancel()
      timerRef.current.reset()
    }, [])

    const handleRerecordButtonClicked = () => {
      cancelRecording()
      // todo pause current player
      setCurrentStep(1)
    }

    const handleRecordingError = (error: AnyError) => {
      timerRef.current.reset()
      setIsRecording(false)
      setError(
        "There was a system error during recording. It has been logged and our team will be looking into it." +
          " Please try your recording again."
      )
      logError(error)
    }

    const startRecordingButtonClicked = async () => {
      if (!mediaDeviceManager.isM1Available) {
        return
      }

      const m1 = await mediaDeviceManager.connectToM1()

      if (!m1) {
        handleRecordingError(new Error("M1 is not available"))
        return
      }

      setIsRecording(true)

      const mediaRecorder = new Recorder()
      const deviceLabel = m1.label

      mediaRecorder.onRecordingComplete = (recording, mimeType) => {
        try {
          const recordingInfo = {
            mimeType: mimeType,
            auscultationPoint: auscultationPoint,
            deviceLabel: deviceLabel,
            data: recording,
          }

          setRecordingInfo(recordingInfo)
          onRecordingMade(recordingInfo)
          setAudioFile({
            filename: buildAuscultationFileName(auscultationPoint, new Date(), mimeType),
            data: recording,
          })

          setIsRecording(false)
          timerRef.current.reset()
          setCurrentStep(2)
        } catch (error) {
          handleRecordingError(error)
        }
      }

      mediaRecorder.onError = (error) => {
        handleRecordingError(error)
      }

      mediaRecorderRef.current = mediaRecorder
      await mediaRecorder.start(m1.recordingStream)
      timerRef.current.start()
    }

    useEffect(() => {
      if (mediaDeviceManager.m1) {
        if (currentStep === 1) {
          mediaDeviceManager.m1.enableAuscultationMicOnLocalSpeakers()
        } else {
          mediaDeviceManager.m1.disableAuscultationMicOnLocalSpeakers()
        }
      }
    }, [mediaDeviceManager.m1, currentStep])

    useEffect(() => {
      mediaDeviceManager.start().catch(logError)
    }, [mediaDeviceManager])

    useEffect(() => {
      if (mediaDeviceManager.isM1Available) {
        mediaDeviceManager.connectToM1().catch(logError)
      }
    }, [cancelRecording, mediaDeviceManager, mediaDeviceManager.isM1Available])

    useEffect(() => {
      if (mediaDeviceManager.m1) {
        const m1 = mediaDeviceManager.m1
        const waveformProvider = new AuscultationWaveformProvider(m1.ausVolAnalyser)
        setWaveformProvider(waveformProvider)
        const volumeProvider = () => {
          return () => m1.getVolume()
        }
        setRoomMicVolumeProvider(volumeProvider)
      } else {
        setWaveformProvider(null)
        setRoomMicVolumeProvider(null)
        phonocardiogramHandle.current?.reset()
      }
    }, [mediaDeviceManager.m1])

    useEffect(() => {
      if (timerRef.current.completed && mediaRecorderRef.current && mediaRecorderRef.current.state === "recording") {
        mediaRecorderRef.current.stop()
      }
    }, [timerRef.current.completed])

    useEffect(() => {
      const timer = timerRef.current
      return () => {
        cancelRecording()
        timer.dispose()
        mediaDeviceManager.dispose()
      }
    }, [cancelRecording, mediaDeviceManager])

    return (
      <>
        <Dialog open={!!error}>
          <DialogTitle>Error</DialogTitle>
          <DialogContent>{error}</DialogContent>
          <DialogActions>
            <Button onClick={() => setError(undefined)}>Close</Button>
          </DialogActions>
        </Dialog>
        <p className="mt-5">Follow the instructions below to create a recording for your doctor to review.</p>
        <div className="mt-10 panel">
          <div>
            <Accordion disableGutters expanded={currentStep === 1} onChange={() => setCurrentStep(1)}>
              <AccordionSummary aria-controls="panel2a-content" id="panel2a-header">
                Step 1: Create Recording
              </AccordionSummary>
              <AccordionDetails>
                <ol className="mt-2 ml-5 list-decimal list-outside space-y-1">
                  <li>Position the Medaica M1 Stethoscope on your body in the position shown below.</li>
                  <li>
                    Once you have positioned the Medaica M1 Stethoscope, press <strong>Start Recording</strong> to begin
                    recording.
                  </li>
                  <li>While recording do not talk and keep all sound to a minimum.</li>
                </ol>

                <div className="mt-4">
                  <StethoscopeDetector mediaDeviceManager={mediaDeviceManager} />
                </div>

                {mediaDeviceManager.m1 && (
                  <div className="my-7 flex-col panel bg-gray-100 p-3">
                    <div className="grid grid-cols-3 grid-rows-2 gap-3">
                      <div
                        className="relative flex bg-white rounded-md p-1 justify-center items-center border"
                        style={{ gridRowStart: 1, gridRowEnd: 3 }}
                      >
                        <AuscultationPointImage
                          style={{ width: "100%", height: "100%" }}
                          highlightedPoint={auscultationPoint}
                        />
                      </div>
                      <div
                        className="relative bg-white rounded-md flex items-center justify-center border"
                        style={{ gridRowStart: 1, gridRowEnd: 3 }}
                      >
                        <div className="w-36">
                          <ProgressCircle timer={timerRef.current} />
                        </div>
                      </div>
                      <div className="bg-black rounded-md border relative">
                        <div className="absolute text-xs text-white top-1 left-2">Stethoscope sound</div>
                        <Phonocardiogram
                          className="w-full rounded-md"
                          shouldDraw={true}
                          waveformProvider={waveformProvider ? () => waveformProvider.getWaveform() : null}
                          ref={phonocardiogramHandle}
                        />
                      </div>
                      <div className="bg-white rounded-md flex justify-center items-center border relative">
                        <div className="absolute text-xs top-1 left-2">Room sound</div>
                        <VolumeMeter on={true} volumeProvider={roomMicVolumeProvider} />
                      </div>
                    </div>

                    <div className="mt-5 mb-2 flex justify-center">
                      {!isRecording ? (
                        <Button
                          disabled={!mediaDeviceManager.m1}
                          onClick={() => void startRecordingButtonClicked()}
                          variant="contained"
                        >
                          Start Recording
                        </Button>
                      ) : (
                        <Button variant="outlined" onClick={cancelRecording}>
                          Cancel Recording
                        </Button>
                      )}
                    </div>
                  </div>
                )}
              </AccordionDetails>
            </Accordion>

            <Accordion disableGutters expanded={currentStep === 2} onChange={() => setCurrentStep(2)}>
              <AccordionSummary
                expandIcon={recordingInfo ? <ExpandMoreIcon /> : null}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                Step 2: Review Recording
              </AccordionSummary>
              <AccordionDetails>
                <p>
                  Please review the recording. If you would like to re-record it, click <strong>Re-record</strong>.
                  Otherwise, click <strong>{acceptRecordingButtonTitle}</strong>.
                </p>
                <div className="mt-8 flex justify-center">
                  <div className="w-80 p-4 bg-gray-50 panel">
                    <AudioPlayer filterContext={new WAFFileContext()} audioFile={audioFile} />
                  </div>
                </div>
                <div className="mt-5 flex justify-center">
                  <Button variant="outlined" onClick={handleRerecordButtonClicked}>
                    Re-record
                  </Button>
                </div>
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
      </>
    )
  }
)

export default CreateSelfAuscultationView
export { Timer }
export type { RecordingInfo }

/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement, useRef, useState } from "react"
import Exam from "./views/exam-room"
import Lobby from "./views/lobby"
import { VirtualExamContextProvider } from "views/exam/virtual-exam/virtual-exam-context"
import Switch, { Case, Default } from "react-switch-case"
import { useNavigate } from "react-router-dom"
import UIStore from "./stores/ui-store"
import { Paths } from "const"
import { observer } from "mobx-react-lite"
import CompatibilityCheckFailure from "@medaica/common/views/exam/virtual-exam/CompatibilityCheckFailure"
import CompatibilityCheck, { FailureReason } from "@medaica/common/components/compatibility-check"
import { ExamType } from "@medaica/common/const"

const Router = observer((): ReactElement => {
  const navigate = useNavigate()
  const uiStore = useRef(new UIStore(navigate, "lobby", Paths.home))
  const [compatibilityCheck, setCompatibilityCheck] = useState<{
    status: "inProgress" | "passed" | "failed"
    failureReason?: FailureReason
  }>({ status: "inProgress" })

  const handleCompatibilityCheckComplete = (passed: boolean, failureReason: FailureReason) => {
    setCompatibilityCheck(passed ? { status: "passed" } : { status: "failed", failureReason: failureReason })
  }

  return (
    <Switch condition={compatibilityCheck.status}>
      <Case value="failed">
        {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
        <CompatibilityCheckFailure failureReason={compatibilityCheck.failureReason!} />
      </Case>
      <Case value="passed">
        <VirtualExamContextProvider uiStore={uiStore.current}>
          <Switch condition={uiStore.current.view}>
            <Case value="exam">
              <Exam />
            </Case>
            <Default>
              <Lobby />
            </Default>
          </Switch>
        </VirtualExamContextProvider>
      </Case>
      <Default>
        <CompatibilityCheck examType={ExamType.Virtual} onComplete={handleCompatibilityCheckComplete} />
      </Default>
    </Switch>
  )
})

export default Router

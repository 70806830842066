/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import firebase from "firebase"
import { makeObservable, observable, runInAction } from "mobx"
import { AnyError } from "@medaica/common/types"

class AuscultationRequestDataStore {
  private errorRef: firebase.database.Reference
  recordingCancelRequest = false
  recordingStopRequest = false

  constructor(ref: firebase.database.Reference) {
    makeObservable(this, {
      recordingCancelRequest: observable,
      recordingStopRequest: observable,
    })

    this.errorRef = ref.child("error")

    ref.child("recordingCancelRequest").on("value", (snapshot) => {
      if (snapshot.val()) {
        runInAction(() => {
          this.recordingCancelRequest = true
        })
      }
    })

    ref.child("recordingStopRequest").on("value", (snapshot) => {
      if (snapshot.val()) {
        runInAction(() => {
          this.recordingStopRequest = true
        })
      }
    })
  }

  setError(error: AnyError): void {
    void this.errorRef.set(error["message"] || error)
  }
}

export default AuscultationRequestDataStore

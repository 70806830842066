/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import { action, makeObservable, observable, runInAction } from "mobx"
import logger from "@medaica/common/services/logging"
import { RemoteTrackPublication, RemoteAudioTrack, RemoteVideoTrack, RemoteParticipant } from "twilio-video"
import { TrackName } from "./index"

class RemoteParticipantStore {
  videoTrack: RemoteVideoTrack | null
  audioTrack: RemoteAudioTrack | null
  auscultationAudioTrack: RemoteAudioTrack | null
  audioIsMuted = false
  auscultationAudioIsMuted = false
  videoIsMuted = false
  participant: RemoteParticipant
  reconnecting: boolean
  connected = true

  constructor(participant: RemoteParticipant) {
    makeObservable(this, {
      videoTrack: observable,
      audioTrack: observable,
      auscultationAudioTrack: observable,
      setReconnecting: action,
      reconnecting: observable,
      connected: observable,
      audioIsMuted: observable,
      auscultationAudioIsMuted: observable,
      videoIsMuted: observable,
    })

    this.participant = participant

    participant.tracks.forEach((publication: RemoteTrackPublication) => {
      this.handleTrackPublished(publication)
    })

    participant.on("disconnected", () => {
      this.connected = false
    })

    // Handle theTrackPublications that will be published by the Participant later.
    participant.on("trackPublished", (publication) => {
      this.handleTrackPublished(publication)
    })

    participant.on("trackUnpublished", (publication) => {
      if (publication.track && publication.track.kind !== "data") {
        publication.track.detach()
      }
    })
  }

  private isTrackName(trackName: string, prefix: TrackName): boolean {
    return trackName.startsWith(prefix)
  }

  private _setTrack(track: RemoteVideoTrack | RemoteAudioTrack): void {
    runInAction(() => {
      if (track.kind === "audio") {
        if (this.isTrackName(track.name, "m1AuscMicAudio")) {
          this.auscultationAudioTrack = track
          this.auscultationAudioIsMuted = !track.isEnabled
        } else {
          this.audioTrack = track
          this.audioIsMuted = !track.isEnabled
        }

        track.on("disabled", () => {
          if (this.isTrackName(track.name, "m1AuscMicAudio")) {
            this.auscultationAudioIsMuted = true
          } else {
            this.audioIsMuted = true
          }
        })

        track.on("enabled", () => {
          if (this.isTrackName(track.name, "m1AuscMicAudio")) {
            this.auscultationAudioIsMuted = false
          } else {
            this.audioIsMuted = false
          }
        })
      } else {
        this.videoTrack = track
        this.videoIsMuted = !track.isEnabled

        track.on("disabled", () => {
          this.videoIsMuted = true
        })

        track.on("enabled", () => {
          this.videoIsMuted = false
        })
      }
    })
  }

  private _unsetTrack(track: RemoteVideoTrack | RemoteAudioTrack): void {
    runInAction(() => {
      if (track.kind === "audio") {
        if (this.isTrackName(track.name, "m1AuscMicAudio")) {
          this.auscultationAudioTrack = null
        } else {
          this.audioTrack = null
        }
      } else {
        this.videoTrack = null
      }
    })
  }

  private handleTrackPublished(publication: RemoteTrackPublication) {
    logger.debug("Handling remote track received", publication)
    if (publication.track && publication.track.kind !== "data") {
      this._setTrack(publication.track)
    }

    publication.on("subscribed", (track) => {
      if (track && track.kind !== "data") {
        this._setTrack(track)
      }
    })

    publication.on("unsubscribed", (track) => {
      if (track && track.kind !== "data") {
        this._unsetTrack(track)
      }
    })
  }

  setReconnecting(reconnecting: boolean): void {
    this.reconnecting = reconnecting
  }
}

export default RemoteParticipantStore

/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement } from "react"
import { Alert, Button } from "@mui/material"
import TWAlert, { Mode } from "@medaica/common/components/alert"
// import red from "@mui/material/colors/red"
// import grey from "@mui/material/colors/grey"

const MuiTest = (): ReactElement => {
  return (
    <div>
      {/*{(() => {*/}
      {/*  const boxes = []*/}
      {/*  for (const r in red) {*/}
      {/*    // @ts-ignore*/}
      {/*    boxes.push(<div style={{ height: "20px", width: "300px", backgroundColor: grey[r] }}>{r}</div>)*/}
      {/*    if (r in twConfig.theme.colors.gray) {*/}
      {/*      boxes.push(*/}
      {/*        // @ts-ignore*/}
      {/* eslint-disable-next-line max-len */}
      {/*        <div style={{ height: "20px", width: "300px", backgroundColor: twConfig.theme.colors.gray[r] }}>{r}</div>*/}
      {/*      )*/}
      {/*    }*/}
      {/*  }*/}
      {/*  return <>{boxes}</>*/}
      {/*})()}*/}
      <Button variant="contained">Contained</Button>
      <button className="button-md button-primary">Contained Tailwind</button>
      <Button variant="contained" color="secondary">
        Contained
      </Button>
      <Alert severity="error">This is an error alert — check it out!</Alert>
      <TWAlert className="mb-4" mode={Mode.error}>
        This is an error alert — check it out!
      </TWAlert>
      <Alert severity="warning">This is a warning alert — check it out!</Alert>
      <TWAlert className="mb-4" mode={Mode.warning}>
        This is an error alert — check it out!
      </TWAlert>
      <Alert severity="info">This is an info alert — check it out!</Alert>
      <TWAlert className="mb-4" mode={Mode.info}>
        This is an error alert — check it out!
      </TWAlert>
      <Alert severity="success">This is a success alert — check it out!</Alert>
      <TWAlert className="mb-4" mode={Mode.success}>
        This is an error alert — check it out!
      </TWAlert>
    </div>
  )
}

export default MuiTest

/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement, useEffect, useRef } from "react"
import { alpha, Box } from "@mui/material"
import StethoscopeIcon from "@medaica/common/components/icons/stethoscope-icon"

const BadNoiseIndicator = ({
  isVisible,
  isMicrophoneMuted,
}: {
  isVisible: boolean
  isMicrophoneMuted: boolean
}): ReactElement => {
  const hideTimeout = useRef<NodeJS.Timeout | null>(null)
  const [isHidden, setIsHidden] = React.useState(false)

  useEffect(() => {
    if (isMicrophoneMuted) {
      setIsHidden(true)
    }
    // If it's visible, and we want to hide it, we delay the hiding in case it becomes visible again.
    // This keeps it from flickering.
    else if (!isVisible && !isHidden) {
      hideTimeout.current = setTimeout(() => {
        setIsHidden(true)
      }, 100)
    } else if (isVisible) {
      if (hideTimeout.current) {
        clearInterval(hideTimeout.current)
      }
      setIsHidden(false)
    }
  }, [isHidden, isMicrophoneMuted, isVisible])

  return isHidden ? (
    <></>
  ) : (
    <Box
      sx={(theme) => ({
        position: "absolute",
        display: "flex",
        alignItems: "center",
        top: theme.spacing(1),
        left: theme.spacing(1.1),
        zIndex: 10,
        borderRadius: theme.shape.borderRadius,
        backgroundColor: alpha(theme.palette.error.light, 0.8),
        padding: theme.spacing(0.75),
        color: "#fff",
        columnGap: "4px",
      })}
    >
      <StethoscopeIcon height="15px" width="15px" />
    </Box>
  )
}

export default BadNoiseIndicator

/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement } from "react"
import { Navigate, Outlet, Route, Routes } from "react-router-dom"
import GettingStarted from "views/exam/requested-self-exam/views/exam/views/getting-started"
import Auscultation from "views/exam/requested-self-exam/views/exam/views/auscultation"
import Review from "views/exam/requested-self-exam/views/exam/views/review"
import URI from "urijs"
import { SelfExamRequestAuscultationRequest } from "@medaica/common/types"

const Container = () => {
  return (
    <div className="p-12 w-full">
      <Outlet />
    </div>
  )
}

const Router = ({
  auscultationRequests,
  query,
}: {
  auscultationRequests: SelfExamRequestAuscultationRequest[]
  query: string
}): ReactElement => {
  return (
    <Routes>
      <Route path="/*" element={<Container />}>
        <Route path="getting-started" element={<GettingStarted />} />
        {auscultationRequests.map((auscultationRequest, index) => {
          return (
            <Route
              key={index}
              path={(index + 1).toString()}
              element={<Auscultation key={index} step={index + 1} auscultationRequest={auscultationRequest} />}
            />
          )
        })}
        <Route path="review" element={<Review step={auscultationRequests.length + 2} />} />
        <Route index element={<Navigate to={URI("getting-started").query(query).valueOf()} />} />
      </Route>
    </Routes>
  )
}

export default Router

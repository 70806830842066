/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

const videoInputDeviceIdKey = "videoInputDeviceId"
const audioInputDeviceIdKey = "audioInputDeviceId"

class PreferencesStore {
  get videoDeviceId(): string | null {
    return localStorage.getItem(videoInputDeviceIdKey)
  }

  setVideoDeviceId(id: string | undefined): void {
    if (id) {
      localStorage.setItem(videoInputDeviceIdKey, id)
    } else {
      localStorage.removeItem(videoInputDeviceIdKey)
    }
  }

  get audioDeviceId(): string | null {
    return localStorage.getItem(audioInputDeviceIdKey)
  }

  setAudioDeviceId(id: string | undefined): void {
    if (id) {
      localStorage.setItem(audioInputDeviceIdKey, id)
    } else {
      localStorage.removeItem(audioInputDeviceIdKey)
    }
  }
}

export default PreferencesStore

/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement } from "react"
import { Link } from "react-router-dom"
import { NavigationItem } from "@medaica/common/types/ui"

const LinkList = ({
  links,
  ulClassname,
  liClassname,
  aClassname,
}: {
  links: NavigationItem[]
  ulClassname?: string
  liClassname?: string
  aClassname?: string
}): ReactElement => {
  return (
    <ul className={ulClassname}>
      {links.map((item) => (
        <li key={item.name} className={liClassname}>
          {item.href?.startsWith("http") ? (
            <a href={item.href} className={aClassname} {...(item.newWindow && { target: "_blank" })}>
              {item.name}
            </a>
          ) : item.href ? (
            <Link to={item.href} className={aClassname} {...(item.newWindow && { target: "_blank" })}>
              {item.name}
            </Link>
          ) : (
            <span>{item.name}</span>
          )}
        </li>
      ))}
    </ul>
  )
}

export default LinkList

/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement } from "react"
import { LogoutIcon } from "@heroicons/react/outline"

const ExitButton = ({ onExit }: { onExit: () => void }): ReactElement => {
  return (
    <button onClick={onExit}>
      <div className="video-button bg-red-500">
        <LogoutIcon className="h-6 stroke-2" stroke={"white"} />
      </div>
      <div className="font-sm text-gray-500">Exit</div>
    </button>
  )
}

export default ExitButton

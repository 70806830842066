/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { createContext, ReactElement, ReactNode, useEffect, useRef, useState } from "react"
import PreferencesStore from "@medaica/common/views/exam/virtual-exam/stores/preferences-store"
import UIStore from "./stores/ui-store"
import AVStore from "@medaica/common/views/exam/virtual-exam/stores/av-store"
import { observer } from "mobx-react-lite"
import PatientVirtualExamMediaDeviceStore from "views/exam/virtual-exam/views/exam-room/stores/patient-virtual-exam-media-device-store"
import M1DeviceInfoUtil from "@medaica/common/services/m1-device-info-util"
import VirtualExamStore from "views/exam/virtual-exam/views/exam-room/stores/virtual-exam-store"
import { logError } from "@medaica/common/services/util"

type VirtualExamContextInterface = {
  preferencesStore: PreferencesStore
  mediaDeviceStore: PatientVirtualExamMediaDeviceStore
  uiStore: UIStore
  setAVStore: (avStore: AVStore | null) => void
  avStoreMaybe: AVStore | null
  avStore: AVStore
  setVirtualExamStore: (virtualExamStore: VirtualExamStore | null) => void
  virtualExamStoreMaybe: VirtualExamStore | null
  virtualExamStore: VirtualExamStore
}

const VirtualExamContext = createContext<VirtualExamContextInterface>(null as unknown as VirtualExamContextInterface)

const VirtualExamContextProvider = observer(
  ({ uiStore, children }: { uiStore: UIStore; children: ReactNode }): ReactElement => {
    const preferencesStoreRef = useRef(new PreferencesStore())
    const mediaDeviceStoreRef = useRef(
      new PatientVirtualExamMediaDeviceStore(
        false,
        new M1DeviceInfoUtil(),
        preferencesStoreRef.current.audioDeviceId,
        preferencesStoreRef.current.videoDeviceId
      )
    )

    // This will be set when the virtual exam room is initialized
    const [virtualExamStore, setVirtualExamStore] = useState<VirtualExamStore | null>(null)
    // This will be set when the virtual exam room is initialized
    const [avStore, setAVStore] = useState<AVStore | null>(null)

    // We make sure that once we leave the context, we dispose of all resources.
    // In particular, we want to stop the media streams so that any indicator that the streams are still active is
    // turned off, e.g., the little green light by a laptop's webcam.
    useEffect(() => {
      mediaDeviceStoreRef.current.start().catch(logError)
      const currentMediaDeviceManager = mediaDeviceStoreRef.current
      return () => {
        currentMediaDeviceManager.dispose()
      }
    }, [])

    return (
      <VirtualExamContext.Provider
        value={{
          preferencesStore: preferencesStoreRef.current,
          mediaDeviceStore: mediaDeviceStoreRef.current,
          setAVStore,
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          avStore: avStore!,
          avStoreMaybe: avStore,
          uiStore,
          setVirtualExamStore,
          virtualExamStoreMaybe: virtualExamStore,
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          virtualExamStore: virtualExamStore!,
        }}
      >
        {children}
      </VirtualExamContext.Provider>
    )
  }
)

export { VirtualExamContextProvider }
export default VirtualExamContext

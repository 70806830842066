/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import { AUDIO_LEVEL_THRESHOLD } from "../constants"
import React, { ReactElement, useContext } from "react"
import { render, RenderResult } from "@testing-library/react"
import { ThemeProvider } from "@mui/material/styles"
import theme from "@medaica/common/theme"
import {
  AppStateProvider,
  userAgentParser as validUserAgentParser,
} from "../components/AppStateProvider/AppStateProvider"
import { GlobalContextProvider } from "@medaica/common/contexts/global-context"
import { AuthContextInterface, User } from "services/auth"
import { BrowserRouter } from "react-router-dom"
import UAParser from "ua-parser-js"

export const getAudioLevelPercentage = (level: number): number => {
  return (level * 100) / AUDIO_LEVEL_THRESHOLD // 0 to 100
}

export function getStandardDeviation(values: number[]): number {
  // Same method used in client sdks
  // https://github.com/twilio/twilio-client.js/blob/master/lib/twilio/statsMonitor.ts#L88

  if (values.length <= 0) {
    return 0
  }

  const valueAverage: number =
    values.reduce((partialSum: number, value: number) => partialSum + value, 0) / values.length

  const diffSquared: number[] = values.map((value: number) => Math.pow(value - valueAverage, 2))

  const stdDev: number = Math.sqrt(
    diffSquared.reduce((partialSum: number, value: number) => partialSum + value, 0) / diffSquared.length
  )

  return round(stdDev)
}

export const round = (num: number, decimals = 2): number =>
  Math.round((num + Number.EPSILON) * 10 ** decimals) / 10 ** decimals

const setUpMediaDevices = (): void => {
  Object.defineProperty(window.navigator, "mediaDevices", {
    writable: true,
    value: {
      getUserMedia: () => jest.fn(),
      enumerateDevices: jest.fn().mockReturnValue(Promise.resolve([])),
      addEventListener: jest.fn(),
    },
  })
}

export const invalidAccessTokenProvider = (): Promise<string> => {
  // eslint-disable-next-line no-throw-literal
  throw {
    response: {
      data: {
        error: {
          message: "token server expired",
        },
      },
    },
  }
}
const validAccessTokenProvider = () => Promise.resolve("valid-access-token")

export const renderInContext = (
  ui: ReactElement,
  {
    accessTokenProvider = validAccessTokenProvider,
    userAgentParser = validUserAgentParser,
  }: { accessTokenProvider?: () => Promise<string>; userAgentParser?: () => UAParser.IResult } = {}
): RenderResult => {
  const AuthContext = React.createContext<AuthContextInterface<User | undefined>>(
    {} as AuthContextInterface<User | undefined>
  )
  setUpMediaDevices()

  const useAuth = (): AuthContextInterface<User> =>
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
    useContext(AuthContext) as AuthContextInterface<User>
  return render(
    <BrowserRouter>
      <GlobalContextProvider pathToHome={""} useAuth={useAuth}>
        <ThemeProvider theme={theme}>
          <AppStateProvider accessTokenProvider={accessTokenProvider} userAgentParser={userAgentParser}>
            {ui}
          </AppStateProvider>
        </ThemeProvider>
      </GlobalContextProvider>
    </BrowserRouter>
  )
}

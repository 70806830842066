/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement } from "react"
import Dialog, { DialogProps } from "@mui/material/Dialog"

const PageDialog = ({
  dismissable = true,
  ...dialogProps
}: {
  dismissable?: boolean
} & DialogProps): ReactElement => {
  return (
    <Dialog
      {...dialogProps}
      {...(!dismissable && {
        disableEscapeKeyDown: true,
        onClose: (event, reason) => {
          if (reason !== "backdropClick") {
            if (dialogProps.onClose) {
              dialogProps.onClose(event, reason)
            }
          }
        },
      })}
    />
  )
}

export default PageDialog

/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import MedaicaApiService from "services/medaica-api-service"
import { HealthcareProviderInvitation } from "@medaica/common/types"
import { plainToInstance } from "class-transformer"

class HealthcareProvidersApi {
  private _apiService: MedaicaApiService

  constructor(apiService: MedaicaApiService) {
    this._apiService = apiService
  }

  async getHealthcareProviderInvitation(invitationId: string): Promise<HealthcareProviderInvitation> {
    const apiClient = await this._apiService.apiClient(false)
    const response = await apiClient.get<HealthcareProviderInvitation>(
      `/healthcare-provider-invitations/${invitationId}`
    )
    return plainToInstance(HealthcareProviderInvitation, response.data)
  }
}

export default HealthcareProvidersApi

/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement, useContext, useState } from "react"
import SelfAuscultationView, { RecordingInfo } from "components/CreateSelfAuscultationView"
import { SelfExamRequestAuscultationRequest } from "@medaica/common/types"
import RequestedSelfExamContext from "views/exam/requested-self-exam/requested-self-exam-context"
import { Button } from "@mui/material"
import { ChevronLeft as ChevronLeftIcon, ChevronRight as ChevronRightIcon } from "@mui/icons-material"
import { logError } from "@medaica/common/services/util"

const Auscultation = ({
  step,
  auscultationRequest,
}: {
  step: number
  auscultationRequest: SelfExamRequestAuscultationRequest
}): ReactElement => {
  const { requestedSelfExamStore, mediaDeviceManager } = useContext(RequestedSelfExamContext)
  const [recordingInfo, setRecordingInfo] = useState<RecordingInfo | null>(null)

  const nextStepButtonClicked = () => {
    if (recordingInfo) {
      requestedSelfExamStore
        .addOrUpdateAuscultation(
          {
            data: recordingInfo.data,
            mimeType: recordingInfo.mimeType,
            deviceLabel: recordingInfo.deviceLabel,
          },
          auscultationRequest
        )
        .catch(logError)
    }

    requestedSelfExamStore.goToNextStep()
  }

  return (
    <>
      <h2 className="text-2xl header-color font-semibold">Recording Position {step}</h2>
      <SelfAuscultationView
        onRecordingMade={(recordingInfo) => {
          setRecordingInfo(recordingInfo)
        }}
        auscultationPoint={auscultationRequest.auscultationPoint}
        existingAuscultation={auscultationRequest.auscultation}
        mediaDeviceManager={mediaDeviceManager}
        acceptRecordingButtonTitle="Next Step"
      />
      <div className="mt-5 flex justify-between">
        <Button
          color="neutral"
          onClick={() => requestedSelfExamStore.goToPreviousStep()}
          startIcon={<ChevronLeftIcon />}
        >
          Back
        </Button>
        <Button
          onClick={nextStepButtonClicked}
          disabled={!auscultationRequest.auscultation && !recordingInfo}
          endIcon={<ChevronRightIcon />}
        >
          Next Step
        </Button>
      </div>
    </>
  )
}

export default Auscultation

/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement, useState } from "react"
import { AuscultationPoint } from "@medaica/common/types"
import { Button, Popover } from "@mui/material"
import AddCircleIcon from "@mui/icons-material/AddCircleOutline"
import AuscultationPointMenu from "@medaica/common/components/auscultation-point-menu"

const AddAuscultationButton = ({
  label,
  onAuscultationPointSelected,
  variant,
}: {
  label: string
  onAuscultationPointSelected: (auscultationPoint: AuscultationPoint) => void
  variant: "outlined" | "contained"
}): ReactElement => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const open = Boolean(anchorEl)
  const id = open ? "simple-popover" : undefined

  const close = () => {
    setAnchorEl(null)
  }

  const handleAuscultationPointSelected = (auscultationPoint: AuscultationPoint): void => {
    close()
    onAuscultationPointSelected(auscultationPoint)
  }

  return (
    <>
      <Button startIcon={<AddCircleIcon />} variant={variant} onClick={(event) => setAnchorEl(event.currentTarget)}>
        {label}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={close}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <div className="p-2">
          <h6>Select the Recording Position</h6>
          <div className="p-2">
            <AuscultationPointMenu boxSize="5em" onAuscultationPointSelected={handleAuscultationPointSelected} />
          </div>
        </div>
      </Popover>
    </>
  )
}

export default AddAuscultationButton

/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import { DiagnosticError, testVideoInputDevice, VideoInputTest } from "@twilio/rtc-diagnostics"
import { useAppStateContext } from "../../../AppStateProvider/AppStateProvider"
import { useState, useRef, useCallback } from "react"

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useCameraTest = (): any => {
  const { dispatch } = useAppStateContext()
  const [videoTest, setVideoTest] = useState<VideoInputTest>()
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const videoElementRef = useRef<HTMLVideoElement>(null!)
  const [videoTestError, setVideoTestError] = useState<DiagnosticError>()

  const stopVideoTest = useCallback(() => {
    setVideoTest(undefined)
    videoTest?.stop()
  }, [videoTest])

  const startVideoTest = useCallback(
    (deviceId: string) => {
      stopVideoTest()
      const test = testVideoInputDevice({ element: videoElementRef.current, deviceId })
      setVideoTest(test)
      test.on(VideoInputTest.Events.Error, (err) => setVideoTestError(err))
      test.on(VideoInputTest.Events.End, (report) => dispatch({ type: "set-video-test-report", report }))
    },
    [stopVideoTest, dispatch]
  )

  return { startVideoTest, stopVideoTest, videoElementRef, videoTest, videoTestError } as const
}

/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement, ReactNode, useContext } from "react"
import { AuthContextInterface, User, AuthContextProvider as BaseAuthProvider } from "@medaica/common/services/auth"
import { User as Auth0User } from "@auth0/auth0-react"

const AuthContext = React.createContext<AuthContextInterface<User | undefined>>(
  {} as AuthContextInterface<User | undefined>
)

const useAuth = (): AuthContextInterface<User> =>
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
  useContext(AuthContext) as AuthContextInterface<User>

const AuthProvider = ({ children }: { children: ReactNode }): ReactElement => {
  return (
    <BaseAuthProvider userBuilder={(baseUser: User | undefined, _: Auth0User) => baseUser} context={AuthContext}>
      {children}
    </BaseAuthProvider>
  )
}

export { AuthProvider }
export default useAuth

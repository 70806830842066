/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import { buildStyles, CircularProgressbarWithChildren } from "react-circular-progressbar"
import { classNames, toMMSS } from "@medaica/common/services/util"
import React, { ReactElement, useEffect, useRef, useState } from "react"
import Timer from "@medaica/common/services/timer"
import { observer } from "mobx-react-lite"
import resolveConfig from "tailwindcss/resolveConfig"
import tailwindConfig from /* preval */ "@medaica/common/tailwind.config.js"

const ProgressCircle = observer(
  ({
    timer,
    pathColor,
    trailColor,
    strokeWidth = 5,
    counterClockwise = false,
  }: {
    timer: Timer
    pathColor?: string
    trailColor?: string
    strokeWidth?: number
    counterClockwise?: boolean
  }): ReactElement => {
    const [value, setValue] = useState(0)
    const maxValue = timer.duration / 1000
    const twConfig = resolveConfig(tailwindConfig)
    const clockElRef = useRef<HTMLDivElement>(null)

    if (!pathColor) {
      pathColor = twConfig.theme.colors.primary.DEFAULT
    }

    if (!trailColor) {
      trailColor = twConfig.theme.colors.gray["300"]
    }

    useEffect(() => {
      setValue(timer.elapsedTime / 1000)
    }, [timer.elapsedTime])

    // useEffect(() => {
    //   if (clockElRef.current) {
    //     fitty(clockElRef.current)
    //   }
    // }, [])

    return (
      <CircularProgressbarWithChildren
        styles={buildStyles({
          // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
          strokeLinecap: "butt",

          // Text size
          textSize: "16px",

          pathTransition: value === 0 ? "none" : "stroke-dashoffset 0.5s ease 0s",

          // Colors
          pathColor: pathColor,
          trailColor: trailColor,
        })}
        strokeWidth={strokeWidth}
        value={value}
        // We subtract whatever the pathTransition is so it looks like the progress ends at the right time
        maxValue={maxValue - 0.5}
        counterClockwise={counterClockwise}
      >
        <div
          ref={clockElRef}
          className={classNames("font-bold text-3xl")}
          style={{
            fontFamily: "Roboto Mono, monospace",
            color: timer.elapsedTime === 0 ? trailColor : pathColor,
          }}
        >
          {toMMSS(maxValue - Math.floor(value))}
        </div>
      </CircularProgressbarWithChildren>
    )
  }
)

export default ProgressCircle

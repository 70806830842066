/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import logger from "@medaica/common/services/logging"
import { auscultationMicSampleRateInHz } from "@medaica/common/const"
import { computed, makeObservable } from "mobx"
import { getConfigNumber } from "@medaica/common/services/util"
import { getEnumKeyByValue } from "@medaica/common/utils/misc"

enum WAFFilterType {
  wideband = "1",
  lowband = "2",
  highband = "3",
  raw = "4",
}

interface FilterConfiguration {
  [key: string]: {
    lowpassKey: string
    highpassKey: string
  }
}

const FILTER_CONFIGURATION: FilterConfiguration = {
  wideband: {
    lowpassKey: "WIDE_BAND_FILTER_LOWPASS_FREQUENCY",
    highpassKey: "WIDE_BAND_FILTER_HIGHPASS_FREQUENCY",
  },
  lowband: {
    lowpassKey: "LOW_BAND_FILTER_LOWPASS_FREQUENCY",
    highpassKey: "LOW_BAND_FILTER_HIGHPASS_FREQUENCY",
  },
  highband: {
    lowpassKey: "HIGH_BAND_FILTER_LOWPASS_FREQUENCY",
    highpassKey: "HIGH_BAND_FILTER_HIGHPASS_FREQUENCY",
  },
}

type DynamicCompressorParameters = {
  threshold: number
  knee: number
  ratio: number
  attackTimeInMilliseconds: number
  releaseTimeInMilliseconds: number
}

abstract class WAFContext extends EventTarget {
  protected abstract gain: number
  protected abstract maxGain: number
  protected abstract enhancedAudio: boolean
  filterType = WAFFilterType.raw
  playbackGain: GainNode
  compressor: DynamicsCompressorNode
  playbackAudioContext: AudioContext | null = null

  protected WIDE_BAND_FILTER_HIGHPASS_FREQUENCY: number
  protected WIDE_BAND_FILTER_LOWPASS_FREQUENCY: number
  protected LOW_BAND_FILTER_HIGHPASS_FREQUENCY: number
  protected LOW_BAND_FILTER_LOWPASS_FREQUENCY: number
  protected HIGH_BAND_FILTER_HIGHPASS_FREQUENCY: number
  protected HIGH_BAND_FILTER_LOWPASS_FREQUENCY: number

  protected WIDE_BAND_FILTER_COMPRESSOR_THRESHOLD: number
  protected WIDE_BAND_FILTER_COMPRESSOR_KNEE: number
  protected WIDE_BAND_FILTER_COMPRESSOR_RATIO: number
  protected WIDE_BAND_FILTER_COMPRESSOR_ATTACK: number
  protected WIDE_BAND_FILTER_COMPRESSOR_RELEASE: number

  protected LOW_BAND_FILTER_COMPRESSOR_THRESHOLD: number
  protected LOW_BAND_FILTER_COMPRESSOR_KNEE: number
  protected LOW_BAND_FILTER_COMPRESSOR_RATIO: number
  protected LOW_BAND_FILTER_COMPRESSOR_ATTACK: number
  protected LOW_BAND_FILTER_COMPRESSOR_RELEASE: number

  protected HIGH_BAND_FILTER_COMPRESSOR_THRESHOLD: number
  protected HIGH_BAND_FILTER_COMPRESSOR_KNEE: number
  protected HIGH_BAND_FILTER_COMPRESSOR_RATIO: number
  protected HIGH_BAND_FILTER_COMPRESSOR_ATTACK: number
  protected HIGH_BAND_FILTER_COMPRESSOR_RELEASE: number

  protected RAW_COMPRESSOR_THRESHOLD: number
  protected RAW_COMPRESSOR_KNEE: number
  protected RAW_COMPRESSOR_RATIO: number
  protected RAW_COMPRESSOR_ATTACK: number
  protected RAW_COMPRESSOR_RELEASE: number

  abstract dispose(): void

  constructor() {
    super()
    this.setDefaultFilterValues()

    // @ts-ignore
    window.filtersObj = this
  }

  public setDefaultFilterValues(): void {
    this.WIDE_BAND_FILTER_HIGHPASS_FREQUENCY = getConfigNumber("WIDE_BAND_FILTER_HIGHPASS_FREQUENCY")
    this.WIDE_BAND_FILTER_LOWPASS_FREQUENCY = getConfigNumber("WIDE_BAND_FILTER_LOWPASS_FREQUENCY")
    this.LOW_BAND_FILTER_HIGHPASS_FREQUENCY = getConfigNumber("LOW_BAND_FILTER_HIGHPASS_FREQUENCY")
    this.LOW_BAND_FILTER_LOWPASS_FREQUENCY = getConfigNumber("LOW_BAND_FILTER_LOWPASS_FREQUENCY")
    this.HIGH_BAND_FILTER_HIGHPASS_FREQUENCY = getConfigNumber("HIGH_BAND_FILTER_HIGHPASS_FREQUENCY")
    this.HIGH_BAND_FILTER_LOWPASS_FREQUENCY = getConfigNumber("HIGH_BAND_FILTER_LOWPASS_FREQUENCY")

    this.WIDE_BAND_FILTER_COMPRESSOR_THRESHOLD = getConfigNumber("WIDE_BAND_FILTER_COMPRESSOR_THRESHOLD")
    this.WIDE_BAND_FILTER_COMPRESSOR_KNEE = getConfigNumber("WIDE_BAND_FILTER_COMPRESSOR_KNEE")
    this.WIDE_BAND_FILTER_COMPRESSOR_RATIO = getConfigNumber("WIDE_BAND_FILTER_COMPRESSOR_RATIO")
    this.WIDE_BAND_FILTER_COMPRESSOR_ATTACK = getConfigNumber("WIDE_BAND_FILTER_COMPRESSOR_ATTACK")
    this.WIDE_BAND_FILTER_COMPRESSOR_RELEASE = getConfigNumber("WIDE_BAND_FILTER_COMPRESSOR_RELEASE")

    this.LOW_BAND_FILTER_COMPRESSOR_THRESHOLD = getConfigNumber("LOW_BAND_FILTER_COMPRESSOR_THRESHOLD")
    this.LOW_BAND_FILTER_COMPRESSOR_KNEE = getConfigNumber("LOW_BAND_FILTER_COMPRESSOR_KNEE")
    this.LOW_BAND_FILTER_COMPRESSOR_RATIO = getConfigNumber("LOW_BAND_FILTER_COMPRESSOR_RATIO")
    this.LOW_BAND_FILTER_COMPRESSOR_ATTACK = getConfigNumber("LOW_BAND_FILTER_COMPRESSOR_ATTACK")
    this.LOW_BAND_FILTER_COMPRESSOR_RELEASE = getConfigNumber("LOW_BAND_FILTER_COMPRESSOR_RELEASE")

    this.HIGH_BAND_FILTER_COMPRESSOR_THRESHOLD = getConfigNumber("HIGH_BAND_FILTER_COMPRESSOR_THRESHOLD")
    this.HIGH_BAND_FILTER_COMPRESSOR_KNEE = getConfigNumber("HIGH_BAND_FILTER_COMPRESSOR_KNEE")
    this.HIGH_BAND_FILTER_COMPRESSOR_RATIO = getConfigNumber("HIGH_BAND_FILTER_COMPRESSOR_RATIO")
    this.HIGH_BAND_FILTER_COMPRESSOR_ATTACK = getConfigNumber("HIGH_BAND_FILTER_COMPRESSOR_ATTACK")
    this.HIGH_BAND_FILTER_COMPRESSOR_RELEASE = getConfigNumber("HIGH_BAND_FILTER_COMPRESSOR_RELEASE")

    this.RAW_COMPRESSOR_THRESHOLD = getConfigNumber("RAW_COMPRESSOR_THRESHOLD")
    this.RAW_COMPRESSOR_KNEE = getConfigNumber("RAW_COMPRESSOR_KNEE")
    this.RAW_COMPRESSOR_RATIO = getConfigNumber("RAW_COMPRESSOR_RATIO")
    this.RAW_COMPRESSOR_ATTACK = getConfigNumber("RAW_COMPRESSOR_ATTACK")
    this.RAW_COMPRESSOR_RELEASE = getConfigNumber("RAW_COMPRESSOR_RELEASE")
  }

  public toggleAudioEnhancement(value: boolean): void {
    this.enhancedAudio = value
    this.apply(this.filterType)
  }

  public setFilter(lowValue: number | null, highValue: number | null, type: string | null = null): void {
    let filterConfiguration = FILTER_CONFIGURATION[getEnumKeyByValue(WAFFilterType, this.filterType)]
    if (type !== null) {
      filterConfiguration = FILTER_CONFIGURATION[type]
    }

    if (!filterConfiguration) {
      logger.error("Invalid filter type used")
      return
    }

    const { lowpassKey, highpassKey } = filterConfiguration

    const setValue = (value: number | null, key: string): void => {
      if (value === null) {
        return
      }

      if (value < 0 || value > 20000) {
        logger.log(`Value must be between 0 and 20000, ${value} is invalid`)
        return
      }

      logger.log(`Setting ${key} to ${value} - default is ${getConfigNumber(key)}`)

      this[key] = value

      this.apply(type !== null ? WAFFilterType[type as keyof typeof WAFFilterType] : this.filterType)
    }

    setValue(lowValue, highpassKey) // Intentionally inverted, low value frequency up to which block
    setValue(highValue, lowpassKey) // Similarly, high value is where we make the upper cut
  }

  get gainMultiplier(): number {
    return this.maxGain / 100
  }

  protected getLowpassBrickwallFilter(audioContext: AudioContext, cutoffFrequency: number): BiquadFilterNode[] {
    // Create a series of BiquadFilterNodes for the brickwall filter
    const biquadFilters: BiquadFilterNode[] = []

    const numFilters = 5
    const Q = 0.707 // Q factor for each filter

    for (let i = 0; i < numFilters; i++) {
      const biquadFilter: BiquadFilterNode = audioContext.createBiquadFilter()
      biquadFilter.type = "lowpass"
      biquadFilter.frequency.value = cutoffFrequency
      biquadFilter.Q.value = Q
      biquadFilters.push(biquadFilter)
    }

    return biquadFilters
  }

  public abstract apply(filterNumber: string): void

  protected applyGain(): void {
    logger.debug(`Applying gain ${this.gain}`)
    this.playbackGain?.gain.setValueAtTime(this.gain, this.playbackAudioContext?.currentTime ?? 0)
  }

  /**
   * @param volume A value between 0 and 100
   */
  setVolume(volume: number): void {
    this.gain = volume * this.gainMultiplier
    this.applyGain()
  }

  get volume(): number {
    return this.gain / this.gainMultiplier
  }

  private applyDynamicCompression(filter: BiquadFilterNode, audioContext: AudioContext): void {
    logger.debug("enhanced audio", this.enhancedAudio)
    if (this.enhancedAudio) {
      logger.debug("low - enhanced audio")
      filter.connect(this.compressor)
      this.compressor.connect(audioContext.destination)
    } else {
      logger.debug("low - normal audio")
      filter.connect(audioContext.destination)
    }
  }

  applyBandpassFilter(
    playbackAudioContext: AudioContext,
    playbackGain: GainNode,
    lowCutFrequency: number,
    highCutFrequency: number
  ): void {
    const biquadFilters: BiquadFilterNode[] = this.getLowpassBrickwallFilter(playbackAudioContext, lowCutFrequency)

    for (let i = 0; i < biquadFilters.length - 1; i++) {
      biquadFilters[i].connect(biquadFilters[i + 1])
    }

    const highPassFilter = playbackAudioContext.createBiquadFilter()
    highPassFilter.type = "highpass"
    highPassFilter.frequency.value = highCutFrequency // Cutoff frequency in Hz
    highPassFilter.Q.value = 0.707 // Q factor

    playbackGain.connect(biquadFilters[0])
    biquadFilters[biquadFilters.length - 1].connect(highPassFilter)

    this.applyDynamicCompression(highPassFilter, playbackAudioContext)
  }

  private getDynamicCompressorParameters(filterType: WAFFilterType): DynamicCompressorParameters | null {
    logger.debug("DynamicCompressor - filterType", filterType)
    switch (filterType) {
      case WAFFilterType.wideband:
        return {
          threshold: this.WIDE_BAND_FILTER_COMPRESSOR_THRESHOLD,
          knee: this.WIDE_BAND_FILTER_COMPRESSOR_KNEE,
          ratio: this.WIDE_BAND_FILTER_COMPRESSOR_RATIO,
          attackTimeInMilliseconds: this.WIDE_BAND_FILTER_COMPRESSOR_ATTACK,
          releaseTimeInMilliseconds: this.WIDE_BAND_FILTER_COMPRESSOR_RELEASE,
        }
      case WAFFilterType.lowband:
        return {
          threshold: this.LOW_BAND_FILTER_COMPRESSOR_THRESHOLD,
          knee: this.LOW_BAND_FILTER_COMPRESSOR_KNEE,
          ratio: this.LOW_BAND_FILTER_COMPRESSOR_RATIO,
          attackTimeInMilliseconds: this.LOW_BAND_FILTER_COMPRESSOR_ATTACK,
          releaseTimeInMilliseconds: this.LOW_BAND_FILTER_COMPRESSOR_RELEASE,
        }
      case WAFFilterType.highband:
        return {
          threshold: this.HIGH_BAND_FILTER_COMPRESSOR_THRESHOLD,
          knee: this.HIGH_BAND_FILTER_COMPRESSOR_KNEE,
          ratio: this.HIGH_BAND_FILTER_COMPRESSOR_RATIO,
          attackTimeInMilliseconds: this.HIGH_BAND_FILTER_COMPRESSOR_ATTACK,
          releaseTimeInMilliseconds: this.HIGH_BAND_FILTER_COMPRESSOR_RELEASE,
        }
      case WAFFilterType.raw:
        return null // Return null for raw filter
    }
  }

  public applyDynamicCompressorParameters(filter: WAFFilterType, playbackAudioContext: AudioContext): void {
    const compressorParameters = this.getDynamicCompressorParameters(filter)

    if (compressorParameters) {
      const { threshold, knee, ratio, attackTimeInMilliseconds, releaseTimeInMilliseconds } = compressorParameters
      this.compressor.threshold.setValueAtTime(threshold, playbackAudioContext.currentTime)
      this.compressor.knee.setValueAtTime(knee, playbackAudioContext.currentTime)
      this.compressor.ratio.setValueAtTime(ratio, playbackAudioContext.currentTime)
      this.compressor.attack.setValueAtTime(attackTimeInMilliseconds, playbackAudioContext.currentTime)
      this.compressor.release.setValueAtTime(releaseTimeInMilliseconds, playbackAudioContext.currentTime)
    }
  }

  public applyFilter(filterType: WAFFilterType, audioContext: AudioContext): void {
    console.log("DynamicCompressor - filterType", filterType)
    switch (filterType) {
      case WAFFilterType.wideband:
        this.applyBandpassFilter(
          audioContext,
          this.playbackGain,
          this.WIDE_BAND_FILTER_LOWPASS_FREQUENCY,
          this.WIDE_BAND_FILTER_HIGHPASS_FREQUENCY
        )
        break
      case WAFFilterType.lowband:
        this.applyBandpassFilter(
          audioContext,
          this.playbackGain,
          this.LOW_BAND_FILTER_LOWPASS_FREQUENCY,
          this.LOW_BAND_FILTER_HIGHPASS_FREQUENCY
        )
        break
      case WAFFilterType.highband:
        this.applyBandpassFilter(
          audioContext,
          this.playbackGain,
          this.HIGH_BAND_FILTER_LOWPASS_FREQUENCY,
          this.HIGH_BAND_FILTER_HIGHPASS_FREQUENCY
        )
        break
      case WAFFilterType.raw:
        logger.debug("raw - stream")
        this.playbackGain.connect(audioContext.destination)
        break
    }
  }
}

/**
 * This class is used to play audio using a MediaStream.
 */
class WAFStreamContext extends WAFContext {
  protected gain = 1
  protected maxGain = 20
  protected sampleRate = auscultationMicSampleRateInHz
  private _stream: MediaStream | null
  private _previousContext: AudioContext | null
  private _previousSource: MediaStreamAudioSourceNode | null
  protected enhancedAudio: boolean

  // In order to implement the WAFContext interface, we need to return a promise. Hence, this is an async function,
  // despite the fact that there is no internal await.
  // eslint-disable-next-line @typescript-eslint/require-await
  async play(stream: MediaStream): Promise<void> {
    this._stream = stream

    this.apply(this.filterType)
  }

  public toggleAudioEnhancement(value: boolean): void {
    super.toggleAudioEnhancement(value)
  }

  isActive(): boolean {
    return !!this._stream && this._stream.active
  }

  apply(filterType: WAFFilterType): void {
    if (!this._stream) {
      return
    }
    this.filterType = filterType

    if (this.playbackGain) {
      logger.debug("disconnecting filter - gain")
      this.playbackGain.disconnect()
    }

    if (this.compressor) {
      logger.debug("disconnecting filter - compressor")
      this.compressor.disconnect()
    }

    this.playbackAudioContext = new (window.AudioContext || window["webkitAudioContext"])({
      sampleRate: this.sampleRate,
    })
    this._previousContext = this.playbackAudioContext
    this.playbackGain = this.playbackAudioContext.createGain()
    this.compressor = this.playbackAudioContext.createDynamicsCompressor()

    const sourceNode = this.playbackAudioContext.createMediaStreamSource(this._stream)
    this._previousSource = sourceNode
    sourceNode.connect(this.playbackGain)

    this.applyDynamicCompressorParameters(this.filterType, this.playbackAudioContext)
    this.applyFilter(this.filterType, this.playbackAudioContext)
    this.applyGain()
  }

  dispose = (): void => {
    this._previousContext?.destination.disconnect()
    if (this._previousContext?.state !== "closed") {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      this._previousContext?.close()
    }
    this._previousSource?.disconnect()
  }
}

/**
 * This class is used to play audio using an ArrayBuffer or Blob.
 */
class WAFFileContext extends WAFContext {
  protected gain = 1
  protected maxGain = 5
  private _audioData: ArrayBuffer
  private _sourceNode: AudioBufferSourceNode | null
  protected enhancedAudio: boolean

  constructor() {
    super()

    makeObservable(this, {
      state: computed,
    })
  }

  get state(): AudioContextState {
    if (!this.playbackAudioContext) {
      return "suspended"
    }
    return this.playbackAudioContext.state
  }

  private handleEnded = async () => {
    await this.playbackAudioContext?.close()
    this.dispatchEvent(new Event("ended"))
  }

  private initializePlaybackContext(): void {
    this.playbackAudioContext = new (window.AudioContext || window["webkitAudioContext"])({
      sampleRate: auscultationMicSampleRateInHz,
    })
    this.playbackGain = this.playbackAudioContext.createGain()
    this.compressor = this.playbackAudioContext.createDynamicsCompressor()
  }

  private async initializeBufferSource(): Promise<void> {
    if (!this.playbackAudioContext) {
      return
    }
    this._sourceNode = this.playbackAudioContext.createBufferSource()
    this._sourceNode.onended = this.handleEnded
    this._sourceNode.buffer = await this.playbackAudioContext.decodeAudioData(this._audioData.slice(0))
    this._sourceNode.connect(this.playbackGain)
  }

  private async loadAudioDataFromBlob(blob: Blob, audioElement: HTMLAudioElement | null): Promise<ArrayBuffer> {
    const url = URL.createObjectURL(blob)
    if (audioElement !== null) {
      audioElement.src = url
    }

    const response = await fetch(url)
    return await response.arrayBuffer()
  }

  private initializeMediaElementSource(audioElement: HTMLAudioElement): void {
    if (!this.playbackAudioContext) {
      return
    }

    // Check if audioElement is already connected to a MediaElementSourceNode
    const sourceNode = this.playbackAudioContext?.createMediaElementSource(audioElement)
    sourceNode?.disconnect()
    sourceNode?.connect(this.playbackGain).connect(this.playbackAudioContext.destination)
  }

  async setSource(audioData: ArrayBuffer | Blob, audioElement: HTMLAudioElement): Promise<void> {
    if (audioData instanceof ArrayBuffer) {
      this._audioData = audioData
    } else if (audioData instanceof Blob) {
      this._audioData = await this.loadAudioDataFromBlob(audioData, audioElement)
    }

    this.initializePlaybackContext()

    if (audioElement == null) {
      await this.initializeBufferSource()
    } else {
      this.initializeMediaElementSource(audioElement)
    }
  }

  apply(filterType: WAFFilterType): void {
    if (!this.playbackAudioContext) {
      return
    }

    this.filterType = filterType

    logger.debug(`Setting filter to ${filterType}`)

    if (this.playbackGain) {
      logger.debug("disconnecting file - gain")
      this.playbackGain.disconnect()
    }

    if (this.compressor) {
      logger.debug("disconnecting file - compressor")
      this.compressor.disconnect()
    }

    this.applyDynamicCompressorParameters(this.filterType, this.playbackAudioContext)
    this.applyFilter(this.filterType, this.playbackAudioContext)
    this.applyGain()
  }

  async play(isWavesurfer: boolean): Promise<void> {
    if (!this._audioData) {
      throw new Error("No audio data to play")
    }

    if (this.playbackAudioContext?.state === "suspended") {
      logger.debug("Resuming playback")
      await this.playbackAudioContext?.resume()
      return
    }

    if (!isWavesurfer) {
      this._sourceNode?.start()
    }
  }

  async pause(): Promise<void> {
    if (this.playbackAudioContext?.state === "running") {
      await this.playbackAudioContext?.suspend()
    }
  }

  async stop(): Promise<void> {
    if (this.playbackAudioContext?.state === "running" || this.playbackAudioContext?.state === "suspended") {
      await this.playbackAudioContext?.close()
    }
  }

  get currentTime(): number {
    if (!this.playbackAudioContext) {
      return 0
    }
    return this.playbackAudioContext.currentTime
  }

  get currentFilterType(): WAFFilterType {
    return this.filterType
  }

  async dispose(): Promise<void> {
    if (this.playbackAudioContext?.state !== "closed") {
      await this.playbackAudioContext?.close()
    }
  }
}

export { WAFFileContext, WAFStreamContext, WAFContext, WAFFilterType }

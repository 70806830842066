/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import { Box, Typography } from "@mui/material"
import React, { ReactElement } from "react"
import { Link } from "react-router-dom"

const Holding = (): ReactElement => {
  return (
    <Box mt={2} style={{ width: "300px", textAlign: "center" }}>
      <Typography variant="h4" component="h1">
        Medaica Patient Portal
      </Typography>
      <Box mt={10}>
        If you have received an invitation to join a Medaica virtual exam,{" "}
        <Link style={{ textDecoration: "underline" }} to="/live-exam">
          enter here
        </Link>
        .
      </Box>
    </Box>
  )
}

export default Holding

/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import MedaicaApiService from "@medaica/common/services/medaica-api-service"
import { Role } from "@medaica/common/const"
import { action, makeObservable, observable } from "mobx"
import { HealthcareProvider, PatientProfile, VirtualExam } from "@medaica/common/types"
import FirebaseVirtualExamService, {
  ExamEntryRequest,
  ParticipantLogItem,
} from "views/exam/virtual-exam/exam-room/services/firebase-virtual-exam-service"
import { logError } from "@medaica/common/services/util"

type Participant = {
  id: string
  name: string
  role: Role
}

abstract class VirtualExamStore {
  protected _firebaseVirtualExamService: FirebaseVirtualExamService
  protected _medaicaApiService: MedaicaApiService
  protected _disposers: (() => void)[] = []
  protected _patientName: string // todo refactor

  protected _userId: string

  examId: string | null = null
  avRoomName: string | null = null
  accessCode: string | null = null
  examEntryRequest: ExamEntryRequest | null = null
  isReviewingAuscultation = false
  isRecordingAuscultation = false
  patientProfile: PatientProfile
  healthcareProvider: HealthcareProvider
  virtualExamId: string
  participants = observable<Participant>([])

  protected constructor(
    userId: string,
    virtualExamId: string,
    firebaseLiveExamService: FirebaseVirtualExamService,
    medaicaApiService: MedaicaApiService
  ) {
    makeObservable(this, {
      examId: observable,
      avRoomName: observable,
      isReviewingAuscultation: observable,
      isRecordingAuscultation: observable,
      patientProfile: observable,
      healthcareProvider: observable,
      accessCode: observable,
      examEntryRequest: observable,
      participants: observable,
    })
    this._userId = userId
    this.virtualExamId = virtualExamId
    this._firebaseVirtualExamService = firebaseLiveExamService
    this._medaicaApiService = medaicaApiService

    this._wireUpFirebaseListeners()
  }

  protected _getVirtualExam = async (): Promise<VirtualExam> => {
    const virtualExam = await this._medaicaApiService.virtualExams.getVirtualExam(this.virtualExamId, [
      "healthcareProvider",
      "patientProfile",
      "exam",
      "exam.auscultations",
    ])
    if (!virtualExam) {
      throw Error(`No virtual exam for ${this.virtualExamId}`)
    }
    return virtualExam
  }

  protected _wireUpFirebaseListeners(): void {
    this._firebaseVirtualExamService.participantLogRef.on(
      "value",
      action((snapshot) => {
        const participantLog = snapshot.val()
        if (participantLog) {
          // eslint-disable-next-line max-len
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument,@typescript-eslint/no-unnecessary-type-assertion
          const participantLogValues = Object.values(participantLog) as ParticipantLogItem[]
          this.participants.replace(participantLogValues.filter((item) => !item.loggedOut))
        }
      })
    )

    this._firebaseVirtualExamService.avRoomIdRef.on(
      "value",
      action((snapshot) => {
        this.avRoomName = snapshot.val() as string
      })
    )

    this._firebaseVirtualExamService.examEntryRequestRef.on(
      "value",
      action((snapshot) => {
        this.examEntryRequest = snapshot.val() as ExamEntryRequest
      })
    )
  }

  setIsReviewingAuscultation(isReviewingAuscultation: boolean): void {
    this._firebaseVirtualExamService.isReviewingAuscultationRef
      .set(isReviewingAuscultation)
      .then(() => {
        this.isReviewingAuscultation = isReviewingAuscultation
      })
      .catch(logError)
  }

  registerPresence(user: Participant): void {
    this._firebaseVirtualExamService.registerPresence(user)
  }

  protected async getOrCreateExam(): Promise<string> {
    if (!this.examId) {
      const exam = await this._medaicaApiService.virtualExams.getExamForVirtualExam(this.virtualExamId)
      if (exam) {
        this.examId = exam.id
      }
    }
    if (!this.examId) {
      const exam = await this._medaicaApiService.virtualExams.createExamForVirtualExam(this.virtualExamId)
      this.examId = exam.id
    }
    return this.examId
  }

  dispose(): void {
    this._firebaseVirtualExamService.deregisterPresence()
    this._disposers.forEach((disposer) => disposer())
    this._firebaseVirtualExamService.dispose()
  }
}

export default VirtualExamStore
export type { Participant }

/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement } from "react"
import Bowser from "bowser"

const index = (): ReactElement => {
  // todo branch mac/win for app download
  // todo if not windows/macos do browser
  const browser = Bowser.getParser(window.navigator.userAgent)

  return (
    <>
      <div>
        <a href={`/assets/live-exam/${browser.getOSName() === "Windows" ? "windows" : "macos"}/app`}>
          Download the app
        </a>
      </div>
      <div>If you have trouble downloading the app, you can use</div>
    </>
  )
}

export default index

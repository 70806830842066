/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import resolveConfig from "tailwindcss/resolveConfig"
import tailwindConfig from "@medaica/common/tailwind.config"
import { createTheme, Theme } from "@mui/material/styles"
import { TypeText } from "@mui/material"

const twConfig = resolveConfig(tailwindConfig)

declare module "@mui/material/styles" {
  interface TypeText {
    light?: string
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    filledTonal: true
    filled: true
  }
}

declare module "@mui/material/styles/createPalette" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface Palette {
    blue: {
      light: {
        1: string
        2: string
        3: string
      }
      medium: string
      dark: string
    }
    green: {
      light: string
      medium: string
    }
    dark: {
      1: string
      2: string
    }
    gray: {
      light: string
    }
    red: {
      medium: string
    }
    orange: {
      medium: string
    }
    border: { main: string }
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  export interface PaletteOptions {
    border: {
      main: string
    }
    blue: {
      light: {
        1: string
        2: string
        3: string
      }
      medium: string
      dark: string
    }
    green: {
      light: string
      medium: string
    }
    dark: {
      1: string
      2: string
    }
    gray: {
      light: string
    }
    red: {
      medium: string
    }
    orange: {
      medium: string
    }
    text?: Partial<TypeText>
  }
}

const theme = createTheme({
  palette: {
    primary: {
      main: "#3B3AEE",
      dark: "#1A1990",
    },
    blue: {
      light: {
        1: "#F6F6FB",
        2: "#F0F0FB",
        3: "#DDDDF2",
      },
      medium: "#3B3AEE",
      dark: "#1A1990",
    },
    secondary: {
      main: "#68DFBE",
      light: "#c5ece6",
    },
    green: {
      light: "#c5ece6",
      medium: "#68DFBE",
    },
    dark: {
      1: "#222222",
      2: "#757575",
    },
    gray: {
      light: "#B7B7B7",
    },
    text: {
      primary: "#222222",
      secondary: "#757575",
      light: "#B7B7B7",
    },
    background: {
      paper: "#ffffff",
    },
    red: {
      medium: "#F27F7F",
    },
    orange: {
      medium: "#F7BA28",
    },
    border: {
      main: "#F0F0FB",
    },
    // old
    neutral: {
      main: twConfig.theme.colors.gray["500"],
      contrastText: "#fff",
    },
  },
  typography: {
    fontFamily: ["Inter", "'Helvetica Neue'", "Arial", "sans-serif"].join(","),
  },
  components: {
    MuiDivider: {
      styleOverrides: {
        root: {
          borderBottomColor: "#F0F0FB",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          boxShadow: "none",
          textTransform: "none",
          fontSize: "1rem",
          padding: "0 14px",
          height: "32px",
          "&: hover": {
            boxShadow: "none",
          },
        },
        startIcon: {
          marginLeft: "-1px",
          marginRight: "6px",
        },
      },
    },
    MUIDataTable: {
      styleOverrides: {
        root: {
          backgroundColor: "transparent",
        },
        tableRoot: {
          borderWidth: "1px",
          borderColor: "#F0F0FB",
          borderRadius: "4px",
          borderSpacing: "0",
          borderCollapse: "separate",
          overflow: "hidden",
        },
      },
    },
    MUIDataTableHead: {
      styleOverrides: {
        main: {
          borderBottomColor: "#f0f0fb",
        },
      },
    },
    MUIDataTableHeadCell: {
      styleOverrides: {
        root: {
          backgroundColor: "#ffffff",
          color: "#757575",
          fontWeight: "500",
          paddingTop: "16px",
          paddingBottom: "16px",
        },
      },
    },
    MUIDataTableBodyCell: {
      styleOverrides: {
        stackedParent: {
          borderTopWidth: "1px",
          borderTopColor: "#f0f0fb",
        },
        root: {
          backgroundColor: "#fbfbfd",
        },
      },
    },
    MUIDataTablePagination: {
      styleOverrides: {
        tableCellContainer: {
          borderBottomWidth: "0",
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontSize: "1em",
          borderBottomWidth: "0px",
          padding: "24px",
        },
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        label: {
          fontSize: "1rem",
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: ({ theme }) => ({
          marginBottom: "4px",
          fontSize: "12px",
          fontWeight: theme.typography.fontWeightMedium,
        }),
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: () => ({
          fontSize: "11px",
          marginTop: "2px",
          marginLeft: "3px",
          fontWeight: theme.typography.fontWeightMedium,
        }),
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          display: "flex",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: ({ theme }) => ({
          display: "flex",
          borderRadius: "6px",
          fontSize: "16px",
          "&.Mui-focused": {
            "& .MuiOutlinedInput-notchedOutline": {
              borderWidth: "1px",
              borderColor: `${theme.palette.primary.main} !important`,
            },
          },
          "&:hover": {
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "#babade",
            },
          },
          "&.Mui-error": {
            "&:hover": {
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: theme.palette.error.main,
              },
            },
          },
        }),
        input: {
          backgroundColor: "rgba(240, 240, 251, .22)",
        },
        notchedOutline: {
          borderColor: "#C9C9E5",
        },
      },
    },
  },
})

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

export default theme

/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import firebase from "firebase"
import { getConfig } from "@medaica/common/services/util"

class FirebaseClient {
  private static _instance: FirebaseClient

  private constructor() {
    if (!firebase.apps.length) {
      const firebaseConfig = {
        apiKey: getConfig("FIREBASE_API_KEY"),
        authDomain: `${getConfig("FIREBASE_PROJECT_ID")}.firebaseapp.com`,
        databaseUrl: getConfig("FIREBASE_URL"),
        projectId: getConfig("FIREBASE_PROJECT_ID"),
        storageBucket: `${getConfig("FIREBASE_PROJECT_ID")}.appspot.com`,
        messagingSenderId: getConfig("FIREBASE_SENDER_ID"),
        appId: getConfig("FIREBASE_APP_ID"),
        measurementId: `G-${getConfig("FIREBASE_MEASUREMENT_ID")}`,
      }
      firebase.initializeApp(firebaseConfig)
    }
  }

  public static get instance(): FirebaseClient {
    if (!FirebaseClient._instance) {
      FirebaseClient._instance = new FirebaseClient()
    }

    return FirebaseClient._instance
  }

  public async authenticate(accessToken: string): Promise<void> {
    await firebase.auth().signInWithCustomToken(accessToken)
  }
}

/**
 * We have to go through this firstRead contortion since a child_added event is fired on the first read of any
 * children. Thus if we come into a virtual exam, for example, with an existing collection of auscultationRequests, by
 *  default each existing auscultationRequest would fire off a new auscultation request. However, we only want an
 * auscultation request to fire when the HCP has asked for one, not when we first enter the exam. So, by using
 * firstRead, we only fire off auscultation requests once one has been added after we've joined the virtual exam.
 */
const handleChildAdded = (
  ref: firebase.database.Reference,
  callback:
    | ((snapshot: firebase.database.DataSnapshot) => Promise<void>)
    | ((snapshot: firebase.database.DataSnapshot) => void)
): void => {
  let firstRead = true
  void ref.get().then((snapshot) => {
    if (!snapshot.exists()) {
      firstRead = false
    }
  })
  ref.limitToLast(1).on("child_added", async (snapshot) => {
    if (firstRead) {
      firstRead = false
    } else {
      await callback(snapshot)
    }
  })
}

export default FirebaseClient
export { handleChildAdded }

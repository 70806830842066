/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { useCallback, useRef } from "react"
import { ACTIONTYPE } from "../AppStateProvider"
import { PreflightTest, runPreflight } from "twilio-video"
import useGlobalContext from "@medaica/common/hooks/global-context"

const usePreflightTest = (
  dispatch: React.Dispatch<ACTIONTYPE>
): { startPreflightTest: () => Promise<void> | undefined } => {
  const preflightTestRef = useRef<PreflightTest>()
  const { medaicaApiService } = useGlobalContext()

  const startPreflightTest = useCallback(() => {
    // Don't start a new preflight test if one is already running
    if (preflightTestRef.current) {
      return
    }

    dispatch({ type: "preflight-started" })

    return medaicaApiService.twilio
      .getCompatibilityTestAccessToken()
      .then((token) => {
        const preflightTest = runPreflight(token)

        preflightTestRef.current = preflightTest

        preflightTest.on("progress", (progress) => {
          dispatch({ type: "preflight-progress", progress })
        })

        preflightTest.on("completed", (report) => {
          dispatch({ type: "preflight-completed", report })
          dispatch({ type: "preflight-finished" })
        })

        preflightTest.on("failed", (error) => {
          dispatch({ type: "preflight-failed", error })
          dispatch({ type: "preflight-finished" })
        })
      })
      .catch((error) => {
        console.error("Error running the preflight test", error)
        dispatch({ type: "preflight-token-failed", error })
        dispatch({ type: "preflight-finished" })
      })
  }, [dispatch, medaicaApiService])

  return { startPreflightTest } as const
}

export default usePreflightTest

/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import MedaicaApiService from "@medaica/common/services/medaica-api-service"
import { Auscultation, AuscultationPoint, Exam } from "@medaica/common/types"
import { action, makeObservable, observable } from "mobx"
import M1MediaDeviceStore from "@medaica/common/services/m1-media-device-store"
import { RecordingData } from "@medaica/common/services/recorder"
import { ExamType } from "@medaica/common/const"

class SelfInitiatedSelfExamStore {
  private _medaicaApiService: MedaicaApiService
  auscultations: Auscultation[] = []
  private _exam: Exam
  private _mediaDeviceManager: M1MediaDeviceStore
  private readonly _patientId: string

  constructor(patientId: string, medaicaApiService: MedaicaApiService, mediaDeviceManager: M1MediaDeviceStore) {
    makeObservable(this, {
      auscultations: observable,
      addAuscultation: action,
      deleteAuscultation: action,
    })
    this._patientId = patientId
    this._mediaDeviceManager = mediaDeviceManager
    this._medaicaApiService = medaicaApiService

    void this._mediaDeviceManager.start()
  }

  protected async getOrCreateExamId(): Promise<string> {
    if (!this._exam) {
      this._exam = await this._medaicaApiService.exams.createExam({
        examType: ExamType.SelfPatientInitiated,
        patientId: this._patientId,
      })
    }
    return this._exam.id
  }

  async addAuscultation(
    recordingData: RecordingData,
    auscultationPoint: AuscultationPoint,
    uploadProgressCallback: (number) => void
  ): Promise<Auscultation | undefined> {
    const examId = await this.getOrCreateExamId()

    const auscultation = await this._medaicaApiService.exams.addAuscultationToExam({
      examId,
      auscultationPoint,
      recording: recordingData.data,
      noiseQualityRecord: {
        unit: 1000,
        record: [0],
      },
      deviceLabel: recordingData.deviceLabel,
      uploadProgressCallback,
    })

    this.auscultations.push(auscultation)

    return auscultation
  }

  getAuscultationRecording(
    examId: string,
    auscultationId: string,
    downloadProgressCallback: (number) => void
  ): Promise<Blob> {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this._medaicaApiService.auscultations.getAuscultationRecordingNormalizedDataStandard(
      auscultationId,
      downloadProgressCallback
    )
  }

  async deleteAuscultation(auscultationId: string): Promise<void> {
    await this._medaicaApiService.auscultations.deleteAuscultation(auscultationId)
    this.auscultations = this.auscultations.filter((auscultation) => auscultation.id !== auscultationId)
  }

  async shareExam(emailAddress: string): Promise<void> {
    await this._medaicaApiService.exams.shareExam(emailAddress, this._exam.id)
  }
}

export default SelfInitiatedSelfExamStore

/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement, ReactNode, useEffect, useRef } from "react"
import clsx from "clsx"
import Video from "twilio-video"

import { ActivePane, useAppStateContext } from "../AppStateProvider/AppStateProvider"
import { ArrowDown } from "../../icons/ArrowDown"
import { ArrowUp } from "../../icons/ArrowUp"
import { AudioTest } from "../panes/AudioTest/AudioTest"
import { BrowserTest } from "../panes/BrowserTest/BrowserTest"
import { CameraTest } from "../panes/CameraTest/CameraTest"
import { CheckPermissions } from "../panes/DeviceSetup/CheckPermissions/CheckPermissions"
import { Connectivity } from "../panes/Connectivity/Connectivity"
import { GetStarted } from "../panes/GetStarted/GetStarted"
import { PermissionError } from "../panes/DeviceSetup/PermissionError/PermissionError"
import { Quality } from "../panes/Quality/Quality"
import { Results } from "../panes/Results/Results"
import { Snackbar } from "../Snackbar/Snackbar"
import { StethoscopeConnection } from "../panes/StethoscopeConnection/StethoscopeConnection"
import { StethoscopeTest } from "../panes/StethoscopeTest/StethoscopeTest"
import Button from "@mui/material/Button"
import { Box } from "@mui/material"

export const Item = ({
  children,
  isActive,
  onClick,
  isHidden,
}: {
  children: ReactNode
  isActive: boolean
  onClick: () => void
  isHidden: boolean
}): ReactElement => {
  const styles = {
    item: {
      transition: "all 0.75s ease",
      padding: "3em 0",
    },
    hideItem: {
      visibility: "hidden",
      position: "fixed",
    },
  }

  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (isActive) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const el = ref.current!
      const offset = el.offsetTop + el.offsetHeight * 0.5
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      el.parentElement!.style.transform = `translateY(calc(50vh - ${offset}px))` // if header subtract half its height
    }
  })

  return (
    <Box
      ref={ref}
      sx={{ ...styles.item, ...(isHidden && styles.hideItem) }}
      className={clsx("contentItem", { inactive: !isActive, active: isActive })}
      onClick={!isActive ? onClick : undefined}
      aria-hidden={!isActive}
      data-testid={`item-container`}
    >
      {children}
    </Box>
  )
}

const content = [
  { pane: ActivePane.GetStarted, component: <GetStarted /> },
  { pane: ActivePane.BrowserTest, component: <BrowserTest /> },
  { pane: ActivePane.DeviceCheck, component: <CheckPermissions /> },
  { pane: ActivePane.DeviceError, component: <PermissionError /> },
  { pane: ActivePane.CameraTest, component: <CameraTest /> },
  { pane: ActivePane.AudioTest, component: <AudioTest /> },
  { pane: ActivePane.StethoscopeConnection, component: <StethoscopeConnection /> },
  { pane: ActivePane.StethoscopeTest, component: <StethoscopeTest /> },
  { pane: ActivePane.Connectivity, component: <Connectivity /> },
  { pane: ActivePane.Quality, component: <Quality /> },
  { pane: ActivePane.Results, component: <Results /> },
]

export const MainContent = (): ReactElement => {
  const { state, dispatch, nextPane } = useAppStateContext()

  const styles = {
    contentContainer: {
      position: "absolute",
      top: "0",
      left: "50%",
      transform: "translateX(-50%)",
      width: "950px",
    },
    scrollContainer: {
      transition: "all 1s ease",
      position: "relative",
      transform: "translateY(50vh)",
      "& .inactive": {
        opacity: 0.2,
        userSelect: "none",
        cursor: "pointer",
        "& > *": {
          pointerEvents: "none",
        },
      },
    },
    hideAll: {
      "& .inactive": {
        opacity: 0,
        visibility: "hidden",
      },
    },
    hideAfter: {
      "& .active ~ .contentItem": {
        opacity: 0,
        visibility: "hidden",
      },
    },
    item: {
      transition: "all 0.75s ease",
      padding: "3em 0",
    },
    hideItem: {
      visibility: "hidden",
      position: "fixed",
    },
    buttonContainer: {
      position: "absolute",
      bottom: 30,
      right: 30,
      display: "flex",
      flexDirection: "column",
      "& button": {
        minWidth: 0,
        padding: "0.6em 0.9em",
        background: "white",
        "&:first-child": {
          marginBottom: "1.5em",
        },
        "&[disabled]": {
          visibility: "hidden",
        },
      },
    },
  }

  const testsInProgress = state.preflightTestInProgress || state.bitrateTestInProgress
  const onLoadingScreen = state.activePane === ActivePane.Connectivity && testsInProgress
  const preflightErrors = state.preflightTest.tokenError !== null || state.preflightTest.error !== null

  const deviceTestErrors =
    !!state.audioInputTestReport?.errors.length ||
    !!state.audioOutputTestReport?.errors.length ||
    !!state.videoInputTestReport?.errors.length
  const isSnackbarOpen =
    deviceTestErrors && (state.activePane === ActivePane.CameraTest || state.activePane === ActivePane.AudioTest)

  return (
    <div>
      <Snackbar open={isSnackbarOpen} />
      <Box sx={styles.contentContainer}>
        <Box
          sx={{
            ...styles.scrollContainer,
            ...((state.activePane === 0 || isSnackbarOpen) && styles.hideAll),
            ...((state.activePane === ActivePane.DeviceCheck ||
              state.activePane === ActivePane.DeviceError ||
              state.activePane === ActivePane.CameraTest ||
              state.activePane === ActivePane.AudioTest ||
              state.activePane === ActivePane.StethoscopeConnection ||
              state.activePane === ActivePane.StethoscopeTest ||
              onLoadingScreen ||
              (state.activePane === ActivePane.Connectivity && preflightErrors) ||
              (state.activePane === ActivePane.BrowserTest && (testsInProgress || !Video.isSupported))) &&
              styles.hideAfter),
          }}
        >
          {content.map((pane, i) => {
            return (
              <Item
                key={i}
                isActive={state.activePane === pane.pane}
                onClick={() => dispatch({ type: "set-active-pane", newActivePane: pane.pane })}
                isHidden={
                  (pane.pane === ActivePane.DeviceError && !state.deviceError) ||
                  (pane.pane === ActivePane.StethoscopeConnection && state.skipStethoscope) ||
                  (pane.pane === ActivePane.StethoscopeConnectionError && state.stethoscopeConnectionError) ||
                  (pane.pane === ActivePane.StethoscopeTest && state.skipStethoscope)
                }
              >
                {pane.component}
              </Item>
            )
          })}
        </Box>
      </Box>
      <Box sx={styles.buttonContainer}>
        <Button
          variant="outlined"
          onClick={() => dispatch({ type: "previous-pane" })}
          disabled={!ActivePane[state.activePane - 1] || isSnackbarOpen}
        >
          <ArrowUp />
        </Button>
        <Button variant="outlined" onClick={nextPane} disabled={state.downButtonDisabled}>
          <ArrowDown />
        </Button>
      </Box>
    </div>
  )
}

/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import { useEffect, useState } from "react"
import Bowser from "bowser"
import { logError } from "@medaica/common/services/util"

const browser = Bowser.getParser(window.navigator.userAgent)

type UADataValues = {
  brands: {
    brand: string
    version: string
  }[]
  platform: string
  platformVersion: string
  browserName: string
}

const useUserAgentData = (): UADataValues | undefined => {
  const [userAgentData, setUserAgentData] = useState<UADataValues>()

  useEffect(() => {
    if (navigator["userAgentData"]) {
      navigator["userAgentData"]
        .getHighEntropyValues(["platform", "platformVersion", "architecture"])
        .then((data) => {
          const browserName = data.brands.length > 0 ? data.brands[0].brand : "your browser"
          setUserAgentData({ browserName, ...data } as UADataValues)
        })
        .catch(logError)
    } else {
      setUserAgentData({
        brands: [{ brand: browser.getBrowserName(), version: browser.getBrowserVersion() }],
        platform: browser.getOSName(),
        platformVersion: browser.getOSVersion(),
        browserName: browser.getBrowserName(),
      })
    }
  }, [])

  return userAgentData
}

export default useUserAgentData

/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement, useContext } from "react"
import { classNames } from "@medaica/common/services/util"
import { CheckIcon } from "@heroicons/react/solid"
import { observer } from "mobx-react-lite"
import { useParams } from "react-router-dom"
import RequestedSelfExamContext from "views/exam/requested-self-exam/requested-self-exam-context"

const Progress = observer((): ReactElement => {
  const { selfExamRequestId } = useParams<{ selfExamRequestId: string }>()
  const { requestedSelfExamStore } = useContext(RequestedSelfExamContext)

  if (!selfExamRequestId) {
    throw new Error("Missing selfExamRequestId url param")
  }

  return (
    <div className="flex-shrink-0 w-64 border-r border-gray-300 rounded-l-md bg-gray-50">
      <nav className="mt-14 mb-6 mx-1" aria-label="Progress">
        <ol className="mt-4 overflow-hidden space-y-2">
          {requestedSelfExamStore.steps.map((step, idx) => {
            return (
              <li
                key={idx}
                className={classNames(
                  "flex items-center py-2 px-3 rounded-sm",
                  step.isComplete || step.isHead ? "cursor-pointer" : "",
                  step.isCurrent ? "bg-gray-200" : ""
                )}
                {...((step.isComplete || step.isHead) && {
                  onClick: () => requestedSelfExamStore.goToStep(idx),
                  // navigate(
                  //   URI.joinPaths(Paths.selfExam, selfExamRequestId, step.path).search(location.search).valueOf()
                  // ),
                })}
              >
                <span
                  className={classNames(
                    "inline-flex items-center justify-center rounded-full",
                    step.isComplete ? "bg-green-500" : step.isCurrent || step.isHead ? "bg-primary-500" : "bg-gray-200"
                  )}
                  style={{ height: "1.6rem", width: "1.6rem" }}
                >
                  <span
                    className={classNames(
                      "text-sm font-medium leading-none",
                      step.isCurrent || step.isComplete || step.isHead ? "text-white" : "text-gray-400"
                    )}
                  >
                    {step.isComplete ? <CheckIcon className="text-white h-4" /> : idx + 1}
                  </span>
                </span>
                <span
                  className={classNames(
                    "ml-3",
                    step.isCurrent
                      ? "text-primary-700"
                      : step.isComplete
                      ? "text-gray-700"
                      : step.isHead
                      ? "text-gray-800"
                      : "text-gray-400"
                  )}
                >
                  {step.label}
                </span>
              </li>
            )
          })}
        </ol>
      </nav>
    </div>
  )
})

export default Progress

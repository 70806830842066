/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

/* eslint-disable max-len */
import React, { ReactElement, useEffect, useState } from "react"
import { FailureReason } from "@medaica/common/components/compatibility-check"
import Bowser, { BROWSER_MAP, OS_MAP } from "bowser"
import Switch, { Case } from "react-switch-case"
import URI from "urijs"
import useUserAgentData from "@medaica/common/hooks/user-agent-data"
import { IconButton, InputAdornment, Link, TextField } from "@mui/material"
import { CopyIcon } from "@medaica/common/views/compatibility-test/icons/CopyIcon"

const origin = new URI(document.location.href).origin()
const browser = Bowser.getParser(window.navigator.userAgent)

const UserDeniedMessage = () => {
  const buildHelpLink = (linkText: string) => {
    switch (browser.getBrowserName()) {
      case BROWSER_MAP.chrome:
        return (
          <a
            className="underline text-blue-600"
            href="https://support.google.com/chrome/answer/2693767?hl=en-GB&co=GENIE.Platform%3DDesktop"
            target="_blank"
            rel="noreferrer"
          >
            {linkText}
          </a>
        )
      case BROWSER_MAP.safari:
        return (
          <a
            className="underline text-blue-600"
            href="https://support.apple.com/guide/safari/websites-ibrwe2159f50/mac"
            target="_blank"
            rel="noreferrer"
          >
            {linkText}
          </a>
        )
      case BROWSER_MAP.firefox:
        return (
          <a
            className="underline text-blue-600"
            href="https://support.mozilla.org/en-US/kb/how-manage-your-camera-and-microphone-permissions"
            target="_blank"
            rel="noreferrer"
          >
            {linkText}
          </a>
        )
      case BROWSER_MAP.edge:
        return (
          <a
            className="underline text-blue-600"
            href="https://support.microsoft.com/en-us/windows/manage-app-permissions-for-your-camera-in-windows-87ebc757-1f87-7bbf-84b5-0686afb6ca6b#:~:text=Here's%20how%3A,able%20to%20access%20the%20camera."
            target="_blank"
            rel="noreferrer"
          >
            {linkText}
          </a>
        )
      default:
        return linkText
    }
  }
  return (
    <>
      <p>
        The exam could not be started because it needs to use your microphone, and currently your browser settings
        prevent it from doing so. In order to proceed with the exam, you must{" "}
        {buildHelpLink("change your browser settings")} to allow {origin} to access your microphone.
      </p>
      <ul className="my-4">
        <li>{buildHelpLink("View instructions on changing your browser settings")}</li>
      </ul>
      <p>Once you have changed your browser settings, try to enter the exam again.</p>
    </>
  )
}

const SystemDeniedMessage = () => {
  const [nameOfPrefs, setNameOfPrefs] = useState<string>("")
  const [nameOfBrowser, setNameOfBrowser] = useState<string>("")
  const [linkToPrefs, setLinkToPrefs] = useState<string>("")
  const [linkToInstructions, setLinkToInstructions] = useState<string>("")
  const [loaded, setLoaded] = useState(false)

  const uaData = useUserAgentData()

  useEffect(() => {
    if (!uaData) {
      return
    }

    const { platform, platformVersion, browserName } = uaData
    if (platform === OS_MAP.MacOS) {
      setLinkToPrefs("x-apple.systempreferences:com.apple.preference.security?Privacy_Microphone")
      setLinkToInstructions(
        "https://support.apple.com/guide/mac-help/control-access-to-the-microphone-on-mac-mchla1b1e1fe/mac"
      )
      if (parseFloat(platformVersion) >= 13) {
        setNameOfPrefs("System Settings")
      } else {
        setNameOfPrefs("System Preferences")
      }
    } else if (platform === OS_MAP.Windows) {
      setLinkToPrefs("ms-settings:privacy-camera")
      setLinkToInstructions(
        "https://support.microsoft.com/en-us/windows/windows-camera-microphone-and-privacy-a83257bc-e990-d54a-d212-b5e41beba857"
      )
      setNameOfPrefs("Settings")
    } else {
      setNameOfPrefs("operation system settings")
    }
    setNameOfBrowser(browserName || "your browser")
    setLoaded(true)
  }, [uaData])

  if (!loaded) {
    return <></>
  }

  return (
    <>
      <p>
        The exam could not be started because it needs to use your microphone, and currently your operating system has
        denied {nameOfBrowser} access to your microphone. In order to proceed with the exam, you must change your{" "}
        {nameOfPrefs} to allow {nameOfBrowser} to access your microphone.
      </p>
      <ul className="my-4">
        <li>
          &#8226;&nbsp;
          <a className="underline text-blue-600" href={linkToInstructions} target="_blank" rel="noreferrer">
            View instructions on changing your {nameOfPrefs}
          </a>
        </li>
        <li className="mt-2">
          &#8226;&nbsp;
          <a className="underline text-blue-600" href={linkToPrefs} target="_blank" rel="noreferrer">
            Open {nameOfPrefs}
          </a>
        </li>
      </ul>
      <p>Once you have given {nameOfBrowser} access to your microphone, try to enter the exam again.</p>
    </>
  )
}

const TrackErrorMessage = () => {
  return (
    <p>
      The exam could not be started because your webcam is already in use. Please quit any applications and close any
      tabs which are using your webcam and try to enter the exam again.
    </p>
  )
}

const PlatformUnsupportedMessage = (): ReactElement => {
  const appUrl = window.location.href

  const copyAppLink = () => {
    void navigator.clipboard.writeText(appUrl)
  }

  return (
    <>
      <p>
        The exam could not be started because it is not compatible with your browser. If you haven't already done so,
        download and install a new browser from the list and copy the URL below and paste it into your new browser to
        restart the exam.
      </p>

      <div className="mt-4">
        <TextField
          fullWidth={true}
          id="read-only-app-link"
          defaultValue={appUrl}
          variant="outlined"
          size="small"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <div>
                  <IconButton edge="end" onClick={copyAppLink}>
                    <CopyIcon />
                  </IconButton>
                </div>
              </InputAdornment>
            ),
            readOnly: true,
          }}
        />
      </div>

      <div className="mt-4">
        <strong>Supported browsers</strong>
        <ul>
          <li>
            <Link
              color="inherit"
              underline="always"
              target="_blank"
              rel="noopener"
              href="https://www.google.com/chrome/"
            >
              Chrome
            </Link>
          </li>
          <li>
            <Link
              color="inherit"
              underline="always"
              target="_blank"
              rel="noopener"
              href="https://www.microsoft.com/en-us/edge"
            >
              Edge
            </Link>
          </li>
        </ul>
      </div>
    </>
  )
}

const UnknownErrorMessage = () => {
  return (
    <p>
      The exam could not be started because of a system error. Please{" "}
      <a href="/support" className="underline text-blue-600">
        contact Medaica support
      </a>
      .
    </p>
  )
}

const CompatibilityCheckFailureMessage = ({ failureReason }: { failureReason: FailureReason }): ReactElement => {
  return (
    <Switch condition={failureReason}>
      <Case value={FailureReason.userDenied}>
        <UserDeniedMessage />
      </Case>
      <Case value={FailureReason.systemDenied}>
        <SystemDeniedMessage />
      </Case>
      <Case value={FailureReason.trackError}>
        <TrackErrorMessage />
      </Case>
      <Case value={FailureReason.platformUnsupported}>
        <PlatformUnsupportedMessage />
      </Case>
      <Case value={FailureReason.unknownError}>
        <UnknownErrorMessage />
      </Case>
    </Switch>
  )
}

export default CompatibilityCheckFailureMessage

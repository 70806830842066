/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement, useContext, useEffect, useRef, useState } from "react"
import { Box } from "@mui/material"
import Target from "@medaica/common/components/icons/target"
import VirtualExamContext from "views/exam/virtual-exam/virtual-exam-context"
import { observer } from "mobx-react-lite"

const targetHeight = 51
const targetWidth = 51

const TargetOverlay = observer((): ReactElement => {
  const [coordinates, setCoordinates] = useState({ x: 0, y: 0 })
  const boundingElRef = useRef<HTMLDivElement>(null)
  const targetElRef = useRef<HTMLDivElement>(null)
  const { avStore } = useContext(VirtualExamContext)

  useEffect(() => {
    if (avStore.remoteDataTrack) {
      avStore.remoteDataTrack?.on("message", (message) => {
        const payload = JSON.parse(message as string)
        if (payload.type === "targetPos" && boundingElRef.current) {
          const [percentX, percentY] = payload.detail
          const boundingElClientRect = boundingElRef.current.getBoundingClientRect()
          // Since it's mirrored, we need to flip the x coordinate
          setCoordinates({
            x: (1 - percentX) * boundingElClientRect.width,
            y: percentY * boundingElClientRect.height,
          })
        }
      })
    }
  }, [avStore.remoteDataTrack])

  return (
    <Box ref={boundingElRef} sx={{ position: "absolute", height: "100%", width: "100%" }}>
      <Box
        ref={targetElRef}
        sx={{
          height: `${targetHeight}px`,
          width: `${targetWidth}px`,
          position: "absolute",
          left: coordinates.x - targetWidth / 2,
          top: coordinates.y - targetHeight / 2,
          touchAction: "none",
          borderRadius: "50%",
          zIndex: 100,
        }}
      >
        <Target />
      </Box>
      <Box
        sx={{
          position: "absolute",
          backgroundColor: "white",
          height: "1px",
          width: "100%",
          top: coordinates.y - 0.5,
          left: 0,
          opacity: 0.4,
          zIndex: 10,
        }}
      />
      <Box
        sx={{
          position: "absolute",
          backgroundColor: "white",
          height: "100%",
          width: "1px",
          top: 0,
          left: coordinates.x - 0.5,
          opacity: 0.4,
          zIndex: 10,
        }}
      />
    </Box>
  )
})

export default TargetOverlay

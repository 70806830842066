/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import {
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Box,
} from "@mui/material"
import { ErrorStatus, SuccessStatus, WarningStatus } from "../../../../icons/StatusIcons"
import { TwilioStatus, TwilioAPIStatus } from "../../../AppStateProvider/AppStateProvider"
import React, { ReactElement } from "react"
import { Close as CloseIcon } from "@mui/icons-material"
import { styled } from "@mui/material/styles"

const PREFIX = "twilioStatusRow"

const classes = {
  modal: `${PREFIX}-modal`,
  content: `${PREFIX}-content`,
  closeButton: `${PREFIX}-closeButton`,
  iconContainer: `${PREFIX}-iconContainer`,
}

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(() => ({
  [`& .${classes.modal}`]: {
    width: "100%",
    maxWidth: "600px",
  },

  [`& .${classes.content}`]: (theme) => ({
    padding: "3em",
    [theme.breakpoints.down("sm")]: {
      padding: "2em",
    },
  }),

  [`& .${classes.closeButton}`]: (theme) => ({
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  }),

  [`& .${classes.iconContainer}`]: {
    display: "flex",
    alignItems: "center",
    "& svg": {
      marginRight: "0.3em",
    },
  },
}))

const serviceStatusObj = {
  operational: { status: "Up", icon: <SuccessStatus /> },
  major_outage: { status: "Major Outage", icon: <ErrorStatus /> },
  partial_outage: { status: "Partial Outage", icon: <WarningStatus /> },
  degraded_performance: { status: "Degraded", icon: <WarningStatus /> },
}

export const TwilioStatusRow = ({
  status,
  serviceName,
}: {
  status?: TwilioAPIStatus
  serviceName: string
}): ReactElement => {
  const serviceStatus = status ? serviceStatusObj[status] : null

  return (
    <TableRow>
      <TableCell>
        <Box sx={{ display: "flex", alignItems: "center", "& svg": { marginRight: "0.3em" }, width: "230px" }}>
          {serviceStatus?.icon}
          <Typography variant="body1" style={{ display: "inline" }}>
            <strong>{serviceStatus?.status}</strong>
          </Typography>
        </Box>
      </TableCell>
      <TableCell>
        <Typography variant="body1">{serviceName}</Typography>
      </TableCell>
    </TableRow>
  )
}

interface ConnectionModalProps {
  isModalOpen: boolean
  setIsModalOpen: (isModalOpen: boolean) => void
  serviceStatuses: TwilioStatus | null
  signalingGateway: string
  turnServers: string
}

export const ConnectionModal = ({
  isModalOpen,
  setIsModalOpen,
  serviceStatuses,
  signalingGateway,
  turnServers,
}: ConnectionModalProps): ReactElement => {
  return (
    <Root>
      <Dialog open={isModalOpen} onClose={() => setIsModalOpen(false)} classes={{ paper: classes.modal }}>
        <DialogTitle>
          Detailed connection information
          <IconButton
            aria-label="close"
            sx={(theme) => ({
              position: "absolute",
              right: theme.spacing(1),
              top: theme.spacing(1),
              color: theme.palette.grey[500],
            })}
            onClick={() => setIsModalOpen(false)}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers className={classes.content}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography variant="body1">
                    <strong>Status</strong>
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body1">
                    <strong>Type</strong>
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TwilioStatusRow status={serviceStatuses?.["Compositions"]} serviceName="Compositions" />
              <TwilioStatusRow status={serviceStatuses?.["Go Rooms"]} serviceName="Go Rooms" />
              <TwilioStatusRow status={serviceStatuses?.["Group Rooms"]} serviceName="Group Rooms" />
              <TwilioStatusRow
                status={serviceStatuses?.["Network Traversal Service"]}
                serviceName="Network Traversal"
              />
              <TwilioStatusRow status={serviceStatuses?.["Peer-to-Peer Rooms"]} serviceName="Peer-to-Peer Rooms" />
              <TwilioStatusRow status={serviceStatuses?.["Recordings"]} serviceName="Recordings" />
              <TableRow>
                <TableCell>
                  <Box
                    sx={{ display: "flex", alignItems: "center", "& svg": { marginRight: "0.3em" }, width: "230px" }}
                  >
                    {signalingGateway === "Reachable" ? <SuccessStatus /> : <ErrorStatus />}
                    <Typography variant="body1" style={{ display: "inline" }}>
                      <strong>{signalingGateway}</strong>
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell>
                  <Typography variant="body1">Signaling Gateway</Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Box
                    sx={{ display: "flex", alignItems: "center", "& svg": { marginRight: "0.3em" }, width: "230px" }}
                  >
                    {turnServers === "Reachable" ? <SuccessStatus /> : <ErrorStatus />}
                    <Typography variant="body1" style={{ display: "inline" }}>
                      <strong>{turnServers}</strong>
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell>
                  <Typography variant="body1">TURN Servers</Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </DialogContent>
      </Dialog>
    </Root>
  )
}

/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement } from "react"
import { XIcon } from "@heroicons/react/solid"

const DialogCloseButton = ({ onClick }: { onClick: () => void }): ReactElement => {
  return (
    <div className="hidden sm:block absolute top-3 right-3">
      <button
        type="button"
        className="p-1.5 text-gray-400 focus focus-secondary hover:bg-primary-125 hover:text-gray-500 rounded-full"
        onClick={onClick}
      >
        <span className="sr-only">Close</span>
        <XIcon className="h-5 w-5" aria-hidden="true" />
      </button>
    </div>
  )
}

export default DialogCloseButton

/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement, useRef, useEffect, ReactNode, useState } from "react"
import { AudioTrack, VideoTrack } from "twilio-video"
import logger from "@medaica/common/services/logging"
import { CircularProgress } from "@mui/material"

const Video = ({
  videoTrack,
  audioTrack,
  isLocal,
  isMuted,
  isLoading,
  overlay,
}: {
  videoTrack: VideoTrack | null | undefined
  audioTrack: AudioTrack | null | undefined
  isLocal: boolean
  isMuted?: boolean
  isLoading?: boolean
  overlay?: ReactNode
}): ReactElement => {
  const videoElRef = useRef<HTMLVideoElement>(null)
  const audioElRef = useRef<HTMLAudioElement>(null)
  const [isVideoLoaded, setIsVideoLoaded] = useState(false)

  useEffect(() => {
    setIsVideoLoaded(false)
  }, [isLoading])

  useEffect(() => {
    if (videoTrack) {
      setIsVideoLoaded(false)
      logger.debug("Attaching video track", videoTrack)
      const currentVideoRef = videoElRef.current
      if (currentVideoRef) {
        currentVideoRef.defaultMuted = true
        currentVideoRef.muted = true
        videoTrack.attach(currentVideoRef)
      }
      setTimeout(() => {
        if (!videoTrack.isStarted) {
          // There was a bug where the video wasn't starting. We want to know if this is still happening. Twilio
          // wasn't throwing an error, so the only way we know if it is happening is to log it.
          logger.error("Video track not started after 5 seconds.", videoTrack)
        }
      }, 5000)
    } else {
      logger.debug("Detaching video track", videoTrack)
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      videoElRef.current!.srcObject = null
    }
    return () => {
      videoTrack?.detach()
    }
  }, [videoTrack])

  useEffect(() => {
    if (audioTrack) {
      logger.debug("Attaching audio track", audioTrack)
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      audioTrack.attach(audioElRef.current!)
    }
    return () => {
      audioTrack?.detach()
    }
  }, [audioTrack])

  return (
    <div className={isLocal ? "mirrored" : ""} style={{ width: "100%", height: "100%" }}>
      {isLoading && !isVideoLoaded && (
        <div className="absolute flex w-full h-full justify-center items-center">
          <CircularProgress style={{ color: "rgba(156,163,175)" }} />
        </div>
      )}
      <audio ref={audioElRef} autoPlay={true} muted={isLocal} />
      <video
        ref={videoElRef}
        autoPlay={true}
        playsInline
        style={{
          width: "100%",
          height: "100%",
          display: isMuted ? "none" : "block",
        }}
        onLoadedData={() => {
          logger.debug("Video track loaded", videoTrack)
          setIsVideoLoaded(true)
        }}
      />
      {isVideoLoaded && <>{overlay}</>}
    </div>
  )
}

export default Video

/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import MedaicaApiService from "@medaica/common/services/medaica-api-service"
import {
  Auscultation,
  HealthcareProvider,
  SelfExamRequest,
  SelfExamRequestAuscultationRequest,
} from "@medaica/common/types"
import { action, computed, makeObservable, observable, runInAction } from "mobx"
import M1MediaDeviceStore from "@medaica/common/services/m1-media-device-store"
import { RecordingData } from "@medaica/common/services/recorder"

class UploadTracker {
  percentUploaded = 0

  constructor() {
    makeObservable(this, {
      percentUploaded: observable,
    })
  }

  handleUploadProgressReported(percentUploaded: number) {
    runInAction(() => {
      this.percentUploaded = percentUploaded
    })
  }
}

type Step = {
  label: string
  path: string
  isCurrent: boolean
  isComplete: boolean
  isHead: boolean
}

class RequestedSelfExamStore {
  private _medaicaApiService: MedaicaApiService
  private readonly _selfExamRequestId: string
  selfExamRequest: SelfExamRequest
  healthCareProvider: HealthcareProvider
  _uploadTrackers: UploadTracker[] = []
  steps: Step[]
  private readonly _navigate: (segment: string) => void
  private _currentStep = 0
  _totalPercentUploaded = 0
  isComplete = false
  private _mediaDeviceManager: M1MediaDeviceStore

  constructor(
    selfExamRequestId: string,
    navigate: (segment) => void,
    medaicaApiService: MedaicaApiService,
    mediaDeviceManager: M1MediaDeviceStore
  ) {
    makeObservable(this, {
      goToNextStep: action,
      goToPreviousStep: action,
      goToStep: action,
      steps: observable,
      _totalPercentUploaded: observable,
      totalPercentUploaded: computed,
      isUploading: computed,
      isComplete: observable,
    })
    this._selfExamRequestId = selfExamRequestId
    this._medaicaApiService = medaicaApiService
    this._navigate = navigate
    this._mediaDeviceManager = mediaDeviceManager

    //void this.loadData()
  }

  init = async (): Promise<void> => {
    // noinspection ES6MissingAwait
    await this._mediaDeviceManager.start()

    this.selfExamRequest = await this._medaicaApiService.selfExamRequests.getSelfExamRequest(this._selfExamRequestId)

    this.isComplete = this.selfExamRequest.examId !== null

    this.healthCareProvider = await this._medaicaApiService.healthcareProviders.getHealthcareProvider(
      this.selfExamRequest.healthcareProviderId
    )

    this.steps = [
      {
        label: "Getting Started",
        path: "getting-started",
        isComplete: this.selfExamRequest.auscultationRequests[0].auscultation !== null,
        isHead: true,
        isCurrent: true,
      },
      ...this.selfExamRequest.auscultationRequests.map((auscultationRequest, idx) => {
        return {
          label: `Position ${idx + 1}`,
          path: (idx + 1).toString(),
          isComplete: auscultationRequest.auscultation !== null,
          isHead: false,
          isCurrent: false,
        }
      }),
      {
        label: "Review",
        path: "review",
        isComplete: false,
        isHead: false,
        isCurrent: false,
      },
    ]

    for (let i = 0; i < this.steps.length; i++) {
      if (!this.steps[i].isComplete) {
        this.goToStep(i)
        break
      }
    }
  }

  get isUploading(): boolean {
    return this.totalPercentUploaded < 100
  }

  get totalPercentUploaded(): number {
    if (this._uploadTrackers.length > 0) {
      const percentageSum = this._uploadTrackers.reduce((acc, item) => {
        acc += item.percentUploaded
        return acc
      }, 0)
      return percentageSum / this._uploadTrackers.length
    } else {
      return 100
    }
  }

  goToNextStep(): void {
    this.steps[this._currentStep].isComplete = true
    this.goToStep(this._currentStep + 1)
  }

  goToPreviousStep(): void {
    this.goToStep(this._currentStep - 1)
  }

  goToStep(step: number): void {
    this._currentStep = step
    this._navigate(this.steps[this._currentStep].path)
    for (let i = 0; i < this.steps.length; i++) {
      this.steps[i].isCurrent = this._currentStep === i
      this.steps[i].isHead = !this.steps[i].isComplete && (i === 0 || this.steps[i - 1].isComplete)
    }
  }

  async finalizeSelfExam(): Promise<void> {
    await this._medaicaApiService.selfExamRequests.finalizeSelfExamRequest(this._selfExamRequestId)
    this.isComplete = true
  }

  getRecordingContent(
    examId: string,
    auscultationId: string,
    downloadProgressCallback?: (number) => void
  ): Promise<Blob> {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this._medaicaApiService.auscultations.getAuscultationRecordingNormalizedDataStandard(
      auscultationId,
      downloadProgressCallback
    )
  }

  async addOrUpdateAuscultation(
    recording: RecordingData,
    auscultationRequest: SelfExamRequestAuscultationRequest
  ): Promise<Auscultation | undefined> {
    const uploadTracker = new UploadTracker()
    this._uploadTrackers.push(uploadTracker)

    const auscultation = await this._medaicaApiService.selfExamRequests.addAuscultationToAuscultationRequest({
      selfExamRequestId: this._selfExamRequestId,
      auscultationRequestId: auscultationRequest.id,
      auscultationPoint: auscultationRequest.auscultationPoint,
      recording: recording.data,
      deviceLabel: recording.deviceLabel,
      uploadProgressCallback: (percentDownloaded: number) => {
        uploadTracker.handleUploadProgressReported(percentDownloaded)
      },
    })

    auscultationRequest.auscultation = auscultation

    return auscultation
  }
}

export default RequestedSelfExamStore

/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ChangeEvent, ReactElement, useRef } from "react"
import SearchIcon from "@mui/icons-material/Search"

const SearchInput = ({
  placeholder,
  handleSearch,
}: {
  placeholder?: string
  handleSearch: (string) => void
}): ReactElement => {
  const inputRef = useRef<HTMLInputElement>(null)

  const onChange = (event: ChangeEvent<HTMLInputElement>): void => {
    handleSearch(event.target.value)
  }

  const onKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (!inputRef.current) return

    if (event.key !== undefined && event.key === "Escape") {
      inputRef.current.value = ""
      inputRef.current.blur()
      handleSearch("")
    }
  }

  return (
    <div className="mt-1 mb-2 relative rounded-md shadow-sm" style={{ marginLeft: "-20px" }}>
      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
        <SearchIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
      </div>
      <form>
        <input
          type="text"
          ref={inputRef}
          autoComplete="off"
          placeholder={placeholder}
          className="input pl-10 w-full"
          onKeyUp={onKeyUp}
          onChange={onChange}
        />
      </form>
    </div>
  )
}

export default SearchInput

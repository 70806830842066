/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

class M1DeviceInfoUtil {
  private readonly altStethoscopeId: string | undefined | null

  private allowedDeviceLabels = [
    // "USB MIC",
    // "PCP-USB",
    "Medaica",
    // "USB audio",
    // "Default - Microphone \\(PCP-USB\\)",
    // "Microphone \\(PCP-USB\\)",
  ]
  private allowFuzzyMatch = true

  private allowedDeviceRegexs = this.allowedDeviceLabels.map((label) => {
    return this.allowFuzzyMatch ? new RegExp(`^.*${label}( (.*))?$`) : new RegExp(`^$.*{label}$`)
  })

  /**
   * @param altStethoscopeId This is if we want to use a non-M1 device as a stethoscope.
   */
  constructor(altStethoscopeId?: string | null) {
    this.altStethoscopeId = altStethoscopeId
  }

  public deviceInfosIncludeM1(availableAudioDevices: MediaDeviceInfo[]): boolean {
    return this.findM1MediaDeviceInfo(availableAudioDevices) !== null
  }

  public findM1MediaDeviceInfo(availableAudioDevices: MediaDeviceInfo[]): MediaDeviceInfo | null {
    return (
      availableAudioDevices.find((deviceInfo) => {
        if (deviceInfo.kind !== "audioinput") {
          return false
        }

        return this.isM1DeviceInfo(deviceInfo)
      }) ?? null
    )
  }

  public isM1DeviceInfo(deviceInfo: MediaDeviceInfo): boolean {
    if (this.altStethoscopeId) {
      return deviceInfo.deviceId === this.altStethoscopeId
    } else {
      return this.allowedDeviceRegexs.some((regex) => regex.test(deviceInfo.label))
    }
  }
}

export default M1DeviceInfoUtil

/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import { Container, Grid, Typography, Paper, Box } from "@mui/material"
import SettingsIllustration from "./SettingsIllustration.png"
import { useAppStateContext } from "../../../AppStateProvider/AppStateProvider"
import React, { ReactElement, useEffect, useState } from "react"
import LoadingButton from "@mui/lab/LoadingButton"
import { requestMediaPermissions } from "mic-check"
import URI from "urijs"

export const CheckPermissions = (): ReactElement => {
  const { dispatch, state } = useAppStateContext()
  const [message, setMessage] = useState("")
  const [instructions, setInstructions] = useState<string | null>(null)
  const [buttonText, setButtonText] = useState("")
  const [loading, setLoading] = useState(false)

  const triggerGetUserMedia = async () => {
    setLoading(true)
    try {
      await requestMediaPermissions()
      setTimeout(() => navigator.mediaDevices.dispatchEvent(new Event("devicechange")), 500)
      dispatch({ type: "next-pane" })
    } catch (error) {
      dispatch({ type: "set-device-error", error })
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    void (async () => {
      if (state.audioGranted && state.videoGranted) {
        setMessage("We need to check that your system has granted permissions to access your camera and microphone.")
        setButtonText("Check permissions")
      } else {
        // If we can check permissions, we check to see if there will be a prompt
        try {
          const microphonePermission = await navigator.permissions.query({ name: "microphone" as PermissionName })
          const cameraPermission = await navigator.permissions.query({ name: "camera" as PermissionName })
          if (microphonePermission.state === "prompt" || cameraPermission.state === "prompt") {
            const uri = new URI(document.location.href)
            setMessage(
              // eslint-disable-next-line max-len
              `If you haven't already, you'll see a pop-up to grant ${uri.origin()} permissions to access your camera and microphone.`
            )
            setInstructions("Allow all permissions and refresh this page.")
            setButtonText("Request permissions")
          } else {
            setMessage(
              "We need to check that your system has granted permissions to access your camera and microphone."
            )
            setButtonText("Check permissions")
          }
        } catch (error) {
          // If there is no permissions object (Safari) or there is an error getting permissions (FF)
          // We don't know if there will be a prompt or not
          const uri = new URI(document.location.href)
          setMessage(
            // eslint-disable-next-line max-len
            `We need to check that your system has granted permissions to access your camera and microphone. You may see a pop-up to grant ${uri.origin()} permissions to access your camera and microphone.`
          )
          setInstructions("If prompted, allow all permissions and refresh this page.")
          setButtonText("Request permissions")
        }
      }
    })()
  }, [state])

  return (
    <Container>
      <Grid container sx={{ justifyContent: "space-between" }}>
        <Grid item lg={5} sx={{ float: "left" }}>
          <Typography component="h1" variant="h3" gutterBottom>
            Check permissions
          </Typography>
          <Typography variant="body1" gutterBottom>
            {message}
          </Typography>
          {instructions && (
            <Typography variant="body1" gutterBottom>
              <strong>{instructions}</strong>
            </Typography>
          )}

          <Box mt={3}>
            <LoadingButton
              loading={loading}
              variant="contained"
              color="primary"
              onClick={() => void triggerGetUserMedia()}
            >
              {buttonText}
            </LoadingButton>
          </Box>
        </Grid>

        <Grid item lg={5} sx={{ float: "right", marginRight: "1em" }}>
          <Paper sx={{ display: "inline-block", padding: "20px", borderRadius: "8px" }}>
            <Typography variant="body1" sx={{ fontSize: "16px", paddingBottom: "16px" }}>
              <strong>Grant permissions</strong>
            </Typography>
            <img
              src={SettingsIllustration}
              alt="Settings Illustration"
              style={{ maxHeight: "174px", maxWidth: "337px" }}
            />
          </Paper>
        </Grid>
      </Grid>
    </Container>
  )
}

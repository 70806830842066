/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement, SVGProps, useRef } from "react"
import { AuscultationPoint } from "@medaica/common/types"
import { v4 as uuidv4 } from "uuid"
import { AuscultationPointType } from "@medaica/common/const"

enum Mode {
  heart = "Heart",
  lungs = "Lungs",
  none = "None",
}

type Props = {
  mode?: Mode
  highlightedPoint?: AuscultationPoint
  highlightPointColor?: string
  basePointColor?: string
  title?: string
} & SVGProps<SVGSVGElement>

const AuscultationPointImage = ({
  mode,
  highlightedPoint,
  highlightPointColor = "#3B3AEE",
  basePointColor = "#ffffff",
  title,
  ...props
}: Props): ReactElement => {
  const titleId = useRef(uuidv4())

  if (!mode) {
    if (highlightedPoint) {
      mode = {
        [AuscultationPointType.heart]: Mode.heart,
        [AuscultationPointType.lungs]: Mode.lungs,
        [AuscultationPointType.other]: Mode.none,
      }[highlightedPoint.type]
    } else {
      mode = Mode.none
    }
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="svg146"
      version="1.1"
      fill="none"
      viewBox="0 0 62 62"
      height="62"
      width="62"
      role="img"
      aria-labelledby={titleId.current}
      {...props}
    >
      <title id={titleId.current}>{title ?? highlightedPoint?.description ?? "Torso diagram"}</title>
      <g transform="translate(0.24050295)" id="g1035">
        <path
          opacity="0.326316"
          fillRule="evenodd"
          clipRule="evenodd"
          d="m 23.4917,4.5406089 c 1.8093,2.7998218 3.2799,6.9024441 1.9419,8.6306011 -1.3379,1.728056
          -9.792,3.672786 -13.8939,8.857157 -4.10191,5.18427 -8.24493,32.068927 -8.24493,32.068927 L
          2.02045,62 H 9.31484 L 15.5534,43.422915 17.2007,54.097294 15.5534,62 H 30.8438 V 0 h -9.238 c 0,0
          0.0765,1.7407872 1.8859,4.5406089 z"
          fill="#91A0B4"
          id="path120"
          style={{ fill: "#91a0b4" }}
        />
        <path
          opacity="0.326316"
          fillRule="evenodd"
          clipRule="evenodd"
          d="m 38.1939,4.5406089 c -1.8094,2.7998218 -3.2799,6.9024441 -1.942,8.6306011 1.3379,1.728056
          9.792,3.672786 13.8939,8.857157 4.102,5.18427 8.245,32.068927 8.245,32.068927 L 59.6651,62 H
          52.3707 L 46.1321,43.422915 44.4848,54.097294 46.1321,62 H 30.8418 V 0 h 9.2379 c 0,0 -0.0764,
          1.7407872 -1.8858,4.5406089 z"
          fill="#91a0b4"
          id="path122"
        />
        <path
          d="m 30.8447,21.9824 c -1.0869,0 -7.4631,0 -11.2308,0 -3.0142,0 -3.5514,4.2411 -3.3054,5.3961
          0.4106,1.9272 2.0519,5.9191 5.7327,8.3517 3.1105,2.0557 6.7585,2.0557 8.8035,2.0557"
          stroke="#ffffff"
          id="path124"
        />
        <path
          d="m 30.8409,21.9824 c 1.0868,0 7.4631,0 11.2308,0 3.0142,0 3.5514,4.2411 3.3053,5.3961 -0.4106,
          1.9272 -2.0519,5.9191 -5.7327,8.3517 -3.1105,2.0557 -6.7584,2.0557 -8.8034,2.0557"
          stroke="#ffffff"
          id="path126"
        />
        <path
          d="m 42.101181,62 c 0,-11.875825 -5.018224,-21.503181 -11.208481,-21.503181 -6.190157,0 -11.208381,
          9.627356 -11.208381,21.503181"
          stroke="#ffffff"
          id="path128"
        />
        <path fillRule="evenodd" clipRule="evenodd" d="M 30.8476,1 V 62.6069 Z" fill="#ffffff" id="path130" />
        <path
          d="M 30.759497,0.49755099 V 61.502455"
          stroke="#ffffff"
          strokeLinecap="square"
          id="path132"
          style={{ strokeWidth: 1 }}
        />
        <path fillRule="evenodd" clipRule="evenodd" d="M 0.0421941,39.4372 H 61.4768 Z" fill="#ffffff" id="path134" />
        {mode === Mode.heart && (
          <>
            <ellipse
              id="h1"
              ry="3.0803399"
              rx="3.0804999"
              cx="26.239998"
              cy="22.880341"
              fill={highlightedPoint?.label === "h1" ? highlightPointColor : basePointColor}
            />
            <ellipse
              id="h2"
              ry="3.0803399"
              rx="3.0804999"
              cx="35.278996"
              cy="22.880341"
              fill={highlightedPoint?.label === "h2" ? highlightPointColor : basePointColor}
            />
            <ellipse
              id="h3"
              ry="3.0803399"
              rx="3.0804999"
              cx="34.063995"
              cy="34.444340"
              fill={highlightedPoint?.label === "h3" ? highlightPointColor : basePointColor}
            />
            <ellipse
              id="h4"
              ry="3.0803399"
              rx="3.0804999"
              cx="39.943996"
              cy="39.364342"
              fill={highlightedPoint?.label === "h4" ? highlightPointColor : basePointColor}
            />
          </>
        )}
        {mode === Mode.lungs && (
          <>
            <ellipse
              id="l1"
              cy="22.880341"
              cx="23.403997"
              rx="3.0804999"
              ry="3.0803399"
              fill={highlightedPoint?.label === "l1" ? highlightPointColor : basePointColor}
            />
            <ellipse
              id="l2"
              cy="22.880341"
              cx="37.661999"
              rx="3.0804999"
              ry="3.0803399"
              fill={highlightedPoint?.label === "l2" ? highlightPointColor : basePointColor}
            />
            <ellipse
              id="l3"
              cy="42.950554"
              cx="43.255417"
              rx="3.0804999"
              ry="3.0803399"
              fill={highlightedPoint?.label === "l3" ? highlightPointColor : basePointColor}
            />
            <ellipse
              id="l4"
              ry="3.0803399"
              rx="3.0804999"
              cx="18.263578"
              cy="42.950554"
              fill={highlightedPoint?.label === "l4" ? highlightPointColor : basePointColor}
            />
          </>
        )}
      </g>
    </svg>
  )
}

export default AuscultationPointImage
export { Mode }

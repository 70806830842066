/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import { Button, Container, Grid, Paper, Typography, Box } from "@mui/material"
import { useAppStateContext } from "../../AppStateProvider/AppStateProvider"
import { useStethoscopeConnection } from "./useStethoscopeConnection/useStethoscopeConnection"
import SettingsIllustration from "../DeviceSetup/CheckPermissions/SettingsIllustration.png"
import React, { ReactElement } from "react"

export const StethoscopeConnection = (): ReactElement => {
  const { dispatch } = useAppStateContext()
  const { isM1Connected } = useStethoscopeConnection()

  // todo skip if plugged in
  const checkForStethoscope = async () => {
    if (await isM1Connected()) {
      dispatch({ type: "next-pane" })
    } else {
      // do
      alert("noo")
    }
  }

  return (
    <Container>
      <Grid container sx={{ justifyContent: "space-between" }}>
        <Grid item lg={5} sx={{ xs: "initial", md: "left" }}>
          <Typography component="h1" variant="h3" gutterBottom>
            Connect your Stethoscope
          </Typography>
          <Typography variant="body1" gutterBottom>
            Plug your Medaica M1 Stethoscope into your computer's USB port. If the stethoscope's plug does not fit in
            the USB port, use one of the adaptors which were included in the box the stethoscope came in.
          </Typography>

          <Box mt={3}>
            <Typography variant="body1" gutterBottom>
              <strong>Is your stethoscope plugged in?</strong>
            </Typography>
            <Box mt={1.5}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => void checkForStethoscope()}
                style={{ marginRight: "1.5em" }}
              >
                Yes
              </Button>
              <Button color="primary" onClick={() => dispatch({ type: "next-pane" })}>
                Skip for now
              </Button>
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          lg={5}
          sx={(theme) => ({
            float: "right",
            marginRight: "1em",
            [theme.breakpoints.down("md")]: {
              float: "initial",
              display: "flex",
              justifyContent: "center",
              margin: "0 0 2em 0",
            },
          })}
        >
          <Paper
            sx={(theme) => ({
              padding: "2em",
              borderRadius: "8px",
              maxWidth: "450px",
              [theme.breakpoints.down("md")]: {
                width: "100%",
              },
            })}
          >
            <Typography
              variant="body1"
              sx={{
                fontSize: "16px",
                paddingBottom: "16px",
              }}
            >
              <strong>Connect stethoscope</strong>
            </Typography>
            <Box
              component={"img"}
              src={SettingsIllustration}
              alt="Settings Illustration"
              sx={(theme) => ({
                maxHeight: "174px",
                maxWidth: "337px",
                [theme.breakpoints.down("sm")]: {
                  width: "100%",
                },
              })}
            />
          </Paper>
        </Grid>
      </Grid>
    </Container>
  )
}

/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement, useState } from "react"
import { Field, Form, Formik } from "formik"
import * as Yup from "yup"
import { Alert, LoadingButton } from "@mui/lab"
import { ConnectedFocusError } from "focus-formik-error"
import { TextField } from "formik-mui"
import useGlobalContext from "@medaica/common/hooks/global-context"
import { getConfig, logError } from "@medaica/common/services/util"
import ActionPanelBody from "@medaica/common/views/exam/virtual-exam/lobby/action-panel-body"
import ActionPanelTitle from "@medaica/common/views/exam/virtual-exam/lobby/action-panel-title"
import ActionPanel from "@medaica/common/views/exam/virtual-exam/lobby/action-panel"
import ActionPanelActions from "@medaica/common/views/exam/virtual-exam/lobby/action-panel-actions"
import { Link } from "@mui/material"
import axios, { AxiosError } from "axios"

const InitialEntryPanel = ({
  onEnterExam,
}: {
  onEnterExam: (patientName: string, virtualExamId: string) => void
}): ReactElement => {
  const [errorMessage, setErrorMessage] = useState<string>()
  const { medaicaApiService } = useGlobalContext()
  const [patientName] = useState<string>("")

  const handleSubmitButtonClicked = async (
    { name, accessCode }: { name: string; accessCode: string },
    { setSubmitting }
  ) => {
    // todo make sure all this is as secure as possible
    try {
      const virtualExam = await medaicaApiService.virtualExams.getVirtualExamByAccessCode(accessCode.trim())
      onEnterExam(name, virtualExam.id)
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError
        if (axiosError.response?.status === 404) {
          // Handle 404 error
          setErrorMessage("The access code is invalid. Please double check your access code and try again.")
        } else {
          // Handle other errors
          logError(error)
          setErrorMessage(`Unknown error occurred. Error code: ${axiosError.response?.status}`)
        }
      } else {
        logError(error)
        setErrorMessage(
          "An unexpected error occurred. Our team has been notified and is looking into it. Please try again later."
        )
      }
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <ActionPanel>
      <ActionPanelTitle>Ready to enter the exam?</ActionPanelTitle>
      <Formik
        initialValues={{
          name: patientName,
          accessCode: "",
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().required("Please enter your name."),
          accessCode: Yup.string().required("Please enter an access code."),
        })}
        onSubmit={handleSubmitButtonClicked}
      >
        {({ isSubmitting }) => (
          <Form className="w-full">
            <ConnectedFocusError />
            <ActionPanelBody>
              <p>Enter your name and the six character exam access code you received from your healthcare provider.</p>
              {!!errorMessage && (
                <Alert sx={{ mt: 3 }} severity="error">
                  {errorMessage}
                </Alert>
              )}
              <div className="mt-8 flex flex-col space-y-4">
                <Field component={TextField} name="name" size="small" label="Your name" />
                <Field
                  component={TextField}
                  name="accessCode"
                  label="Exam access code"
                  size="small"
                  // render={({ field }) => (
                  //   <MaskedInput
                  //     {...field}
                  //     mask={() => {
                  //       return _.range(6).map(() => /[a-zA-Z0-9_]/)
                  //     }}
                  //     guide={true}
                  //     type="text"
                  //   />
                  // )}
                />
              </div>
            </ActionPanelBody>
            <ActionPanelActions>
              <LoadingButton loading={isSubmitting} type="submit" variant="contained">
                Ask to enter the exam
              </LoadingButton>
            </ActionPanelActions>
          </Form>
        )}
      </Formik>
      <div className="mt-10 text-md">
        Are you a healthcare provider?{" "}
        <Link href={getConfig("HEALTH_CARE_PROVIDER_PORTAL_URL")}>
          Enter exams through the healthcare provider portal
        </Link>
      </div>
    </ActionPanel>
  )
}

export default InitialEntryPanel

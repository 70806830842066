/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import MedaicaApiService from "./"

class EmsApi {
  private _apiService: MedaicaApiService

  constructor(apiService: MedaicaApiService) {
    this._apiService = apiService
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async getDeviceUnits(): Promise<any> {
    const apiClient = await this._apiService.apiClient(false)
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return (await apiClient.get("/ems/log")).data
  }
}

export default EmsApi

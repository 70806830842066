/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import { action, computed, makeObservable, observable } from "mobx"
import { logError } from "@medaica/common/services/util"
import { AnyError } from "@medaica/common/types"
import { auscultationMicSampleRateInHz } from "@medaica/common/const"
import { IMediaRecorder, MediaRecorder, register } from "extendable-media-recorder"
import { connect } from "extendable-media-recorder-wav-encoder"

let _wavEncoderRegistered = false

type RecordingData = {
  data: Blob
  mimeType: string
  deviceLabel: string
}

enum RecorderState {
  readyToRecord,
  recording,
}

class Recorder {
  private mediaRecorder: IMediaRecorder
  private cancelled = false
  isRecording = false
  readonly sampleRate = auscultationMicSampleRateInHz
  private audioContext: AudioContext | null = null

  onError: (error: AnyError) => void
  onRecordingComplete: (data: Blob, mimeType: string) => void

  constructor() {
    makeObservable(this, {
      start: action,
      stop: action,
      isRecording: observable,
      state: computed,
    })
  }

  get state(): "inactive" | "recording" | "paused" {
    return this.mediaRecorder ? this.mediaRecorder.state : "inactive"
  }

  private handleError(error: AnyError): void {
    logError(error)
    this.cancel()
    this.onError(error)
  }

  async start(mediaStream: MediaStream): Promise<void> {
    this.cancelled = false

    if (!_wavEncoderRegistered) {
      await register(await connect())
      _wavEncoderRegistered = true
    }

    this.audioContext = new (window.AudioContext || window["webkitAudioContext"])({ sampleRate: this.sampleRate })
    const mediaStreamAudioSourceNode = new MediaStreamAudioSourceNode(this.audioContext, { mediaStream })
    const mediaStreamAudioDestinationNode = new MediaStreamAudioDestinationNode(this.audioContext)
    mediaStreamAudioSourceNode.connect(mediaStreamAudioDestinationNode)
    const mediaRecorder = new MediaRecorder(mediaStreamAudioDestinationNode.stream, { mimeType: "audio/wav" })

    const mediaParts: Blob[] = []

    mediaRecorder.ondataavailable = ({ data }) => {
      mediaParts.push(data)
    }

    mediaRecorder.onerror = (error) => {
      this.handleError(error)
    }

    mediaRecorder.onstop = () => {
      this.isRecording = false
      if (this.cancelled) {
        return
      }
      const recording = new Blob(mediaParts, {
        type: mediaRecorder.mimeType,
      })
      try {
        this.onRecordingComplete(recording, mediaRecorder.mimeType)
      } catch (error) {
        this.handleError(error)
      }
    }

    this.mediaRecorder = mediaRecorder
    this.isRecording = true
    this.mediaRecorder.start()
  }

  cancel(): void {
    if (this.mediaRecorder && this.mediaRecorder.state === "recording") {
      this.cancelled = true
      this.mediaRecorder.stop()
    }
  }

  stop(): void {
    if (this.mediaRecorder && this.mediaRecorder.state === "recording") {
      this.mediaRecorder.stop()
    }
  }

  async cleanup(): Promise<void> {
    if (this.audioContext) {
      await this.audioContext.close().then(() => {
        this.audioContext = null
      })
    }
  }

  dispose(): void {
    this.cancel()
    void this.cleanup()
  }
}

export default Recorder
export { RecorderState }
export type { RecordingData }

/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactNode, useEffect } from "react"
import { RouteObject } from "react-router"
import Loading from "@medaica/common/components/loading"
import { Auth } from "@medaica/common/services/auth"
import { getCurrentUrlFullPath } from "@medaica/common/services/util"

const Protect = ({ useAuth, children }: { useAuth: Auth; children: ReactNode }) => {
  const { login, isLoading, isAuthenticated } = useAuth()

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      void login({
        appState: { returnTo: getCurrentUrlFullPath() },
      })
    }
  }, [isAuthenticated, isLoading, login])

  return isLoading || !isAuthenticated ? <Loading /> : <>{children}</>
}

const protect = (useAuth: Auth, ...children: RouteObject[]): RouteObject[] => {
  return children.map((child) => ({
    ...child,
    element: <Protect useAuth={useAuth}>{child.element}</Protect>,
  }))
}

const withLayout = (layout: ReactNode, path: string, ...children: RouteObject[]): RouteObject => {
  return {
    element: layout,
    path,
    children,
  }
}

export { protect, withLayout }

/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement, SVGProps } from "react"

const StopIcon = (props: SVGProps<SVGSVGElement>): ReactElement => {
  return (
    <svg {...props} fill="currentColor" height="24px" viewBox="0 0 24 24">
      <path d="M0 0h24v24H0z" fill="none" />
      <rect width="12" height="12" x="6" y="6" />
    </svg>
  )
}

export default StopIcon

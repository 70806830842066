/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import { Button, Typography, Container, Grid, Box, Paper } from "@mui/material"
import { useAppStateContext } from "../../AppStateProvider/AppStateProvider"
import React, { ReactElement } from "react"
import { useStethoscopeConnection } from "@medaica/common/views/compatibility-test/components/panes/StethoscopeConnection/useStethoscopeConnection/useStethoscopeConnection"
import { styled } from "@mui/material/styles"

const PREFIX = "stethoscopeTest"

const classes = {
  mainContainer: `${PREFIX}-mainContainer`,
  meters: `${PREFIX}-meters`,
  paper: `${PREFIX}-paper`,
  paperContainer: `${PREFIX}-paperContainer`,
  metersContainer: `${PREFIX}-metersContainer`,
  header: `${PREFIX}-header`,
}

const StyledContainer = styled(Container)(() => ({
  [`& .${classes.mainContainer}`]: {
    justifyContent: "space-between",
  },

  [`& .${classes.meters}`]: {
    fontSize: "16px",
    paddingBottom: "16px",
  },

  [`& .${classes.paper}`]: (theme) => ({
    padding: "2em",
    borderRadius: "8px",
    maxWidth: "450px",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  }),

  [`& .${classes.paperContainer}`]: (theme) => ({
    marginRight: "1em",
    [theme.breakpoints.down("md")]: {
      float: "initial",
      display: "flex",
      justifyContent: "center",
      margin: "0 0 2.5em 0",
    },
  }),

  [`& .${classes.metersContainer}`]: (theme) => ({
    float: "right",
    marginRight: "1em",
    [theme.breakpoints.down("md")]: {
      float: "initial",
      display: "flex",
      justifyContent: "center",
      margin: "0 0 2em 0",
    },
  }),

  [`& .${classes.header}`]: (theme) => ({
    float: "left",
    [theme.breakpoints.down("md")]: {
      float: "initial",
    },
  }),
}))

export const StethoscopeTest = (): ReactElement => {
  const { dispatch } = useAppStateContext()
  const { isM1Connected } = useStethoscopeConnection()

  // todo skip if plugged in
  const checkForStethoscope = async () => {
    if (await isM1Connected()) {
      dispatch({ type: "next-pane" })
    } else {
      // do
      alert("noo")
    }
  }

  return (
    <StyledContainer>
      <Grid container className={classes.mainContainer}>
        <Grid item lg={5} className={classes.header}>
          <Typography component="h1" variant="h3" gutterBottom>
            Test your stethoscope
          </Typography>
          <Typography variant="body1" gutterBottom>
            With your stethoscope plugged in, make some noise. Check that the audio auscultation mic meter and room mic
            meter are both registering the noise.
          </Typography>

          <Box mt={3}>
            <Typography variant="body1" gutterBottom>
              <strong>Are the meters registering the noise?</strong>
            </Typography>
            <Box mt={1.5}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => void checkForStethoscope()}
                style={{ marginRight: "1.5em" }}
              >
                Yes
              </Button>
              <Button color="primary" onClick={() => dispatch({ type: "next-pane" })}>
                Skip for now
              </Button>
            </Box>
          </Box>
        </Grid>
        <Grid item lg={5} className={classes.metersContainer}>
          <Paper className={classes.paper}>
            <Typography variant="body1" className={classes.meters}>
              <strong>[Meters here]</strong>
            </Typography>
          </Paper>
        </Grid>
      </Grid>
    </StyledContainer>
  )
}

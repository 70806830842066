/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import MedaicaApiService, { JoinedLoads, pagination, QuerySpec } from "./"
import {
  Auscultation,
  AuscultationPoint,
  LiveExamAnonAccessToken,
  Page,
  SelfExamAccessTokenData,
  SelfExamRequest,
} from "@medaica/common/types"
import { plainToInstance } from "class-transformer"
import { CancelTokenSource } from "axios"

class SelfExamRequestsApi {
  private _apiService: MedaicaApiService

  constructor(apiService: MedaicaApiService) {
    this._apiService = apiService
  }

  async getSelfExamRequests(query: QuerySpec): Promise<Page<SelfExamRequest>> {
    const apiClient = await this._apiService.apiClient()
    const response = await apiClient.get<SelfExamRequest[]>(
      this._apiService.withQuery("/search/self-exam-requests", {
        q: JSON.stringify(query),
      })
    )

    return {
      items: plainToInstance(SelfExamRequest, response.data),
      ...pagination(response),
    }
  }

  async createSelfExamAccessToken(selfExamRequestId: string, accessCode: string): Promise<SelfExamAccessTokenData> {
    const apiClient = await this._apiService.apiClient(false)
    const response = await apiClient.post<SelfExamAccessTokenData>("self-exam-requests/auth", {
      accessCode,
      selfExamRequestId,
    })
    return response.data
  }

  async addSelfExamAccessCode(selfExamRequestId: string): Promise<LiveExamAnonAccessToken> {
    const apiClient = await this._apiService.apiClient(false)
    const response = await apiClient.post<LiveExamAnonAccessToken>(
      `/self-exam-requests/${selfExamRequestId}/access-codes`
    )
    return response.data
  }

  async getSelfExamRequestFinalized(selfExamRequestId: string): Promise<boolean> {
    const apiClient = await this._apiService.apiClient(false)
    const response = await apiClient.get<{ finalized: boolean }>(`/self-exam-requests/${selfExamRequestId}/finalized`)
    return response.data.finalized
  }

  async finalizeSelfExamRequest(selfExamRequestId: string): Promise<SelfExamRequest> {
    const apiClient = await this._apiService.apiClient()
    const response = await apiClient.post(`/self-exam-requests/${selfExamRequestId}/finalized`)
    return plainToInstance(SelfExamRequest, response.data)
  }

  async addAuscultationToAuscultationRequest({
    selfExamRequestId,
    auscultationRequestId,
    auscultationPoint,
    recording,
    deviceLabel,
    uploadProgressCallback,
    cancelToken,
  }: {
    selfExamRequestId: string
    auscultationRequestId: string
    auscultationPoint: AuscultationPoint
    recording: Blob
    deviceLabel: string
    uploadProgressCallback?: (number) => void
    cancelToken?: CancelTokenSource
  }): Promise<Auscultation> {
    const formData = new FormData()
    formData.append(
      "auscultation",
      JSON.stringify({
        auscultationPoint: auscultationPoint.label,
        deviceLabel,
      })
    )
    formData.append("recordingData", recording)

    const config = {
      ...(cancelToken && { cancelToken: cancelToken.token }),
      ...(uploadProgressCallback && {
        onUploadProgress: (progressEvent: ProgressEvent) => {
          const percentCompleted = Math.floor((progressEvent.loaded / progressEvent.total) * 100)
          uploadProgressCallback(percentCompleted)
        },
      }),
    }

    const apiClient = await this._apiService.apiClient()
    const response = await apiClient.put<Auscultation>(
      `/self-exam-requests/${selfExamRequestId}/auscultation-requests/${auscultationRequestId}/auscultation`,
      formData,
      config
    )

    return plainToInstance(Auscultation, response.data)
  }

  async getSelfExamRequest(selfExamRequestId: string, joinedLoads?: JoinedLoads): Promise<SelfExamRequest> {
    const apiClient = await this._apiService.apiClient()
    const response = await apiClient.get<SelfExamRequest>(
      this._apiService.withQuery(`/self-exam-requests/${selfExamRequestId}`, {
        q: JSON.stringify({ joinedLoads }),
      })
    )
    return plainToInstance(SelfExamRequest, response.data)
  }
}

export default SelfExamRequestsApi

/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

class AuscultationWaveformProvider {
  private analyserNode: AnalyserNode
  waveformChunks: number[] = []

  constructor(analyserNode: AnalyserNode) {
    this.analyserNode = analyserNode
  }

  reset(): void {
    this.waveformChunks = []
  }

  getWaveform(): number[] {
    const waveform = new Float32Array(this.analyserNode.frequencyBinCount)
    this.analyserNode.getFloatTimeDomainData(waveform)
    let total = 0
    for (const value of waveform) {
      total += value
    }
    const avg = total / waveform.length
    this.waveformChunks.push(avg)
    let waveformsToDraw: number[]
    if (this.waveformChunks.length > 300) {
      const cutFrom = this.waveformChunks.length - 300
      waveformsToDraw = this.waveformChunks.slice(cutFrom)
    } else {
      waveformsToDraw = this.waveformChunks
    }
    return waveformsToDraw
  }
}

export default AuscultationWaveformProvider

/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement, useEffect } from "react"
import useGlobalContext from "@medaica/common/hooks/global-context"
import { useParams } from "react-router-dom"
import { useSnackbar } from "notistack"
import MinimalLayout from "@medaica/common/layouts/minimal-layout"

const UnauthenticatedView = (): ReactElement => {
  const { medaicaApiService } = useGlobalContext()
  const { selfExamRequestId } = useParams<{ selfExamRequestId: string }>()
  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    void medaicaApiService.selfExamRequests.addSelfExamAccessCode(selfExamRequestId)
  }, [medaicaApiService, selfExamRequestId])

  const handleResendEmailButtonClicked = async () => {
    await medaicaApiService.selfExamRequests.addSelfExamAccessCode(selfExamRequestId)
    enqueueSnackbar("The email has been sent")
  }

  return (
    <MinimalLayout>
      <div style={{ width: "340px" }}>
        <div className="sm:mx-auto mb-8">
          <h2 className="text-center text-l font-bold mb-4 text-gray-700">Action Required: Check Your Email</h2>
          <p className="text-gray-600">
            In order to protect your privacy, we have just sent you an email with a new link to access your self exam.
            Please click the link in the email to continue to your self exam.
          </p>
          <p className="mt-8 text-gray-600">
            Didn't get the email? <button onClick={() => void handleResendEmailButtonClicked()}>Resend Email</button>
          </p>
        </div>
      </div>
    </MinimalLayout>
  )
}

export default UnauthenticatedView

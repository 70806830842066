/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import { AudioDevice } from "@medaica/common/services/media-device-store"
import { LocalAudioTrack } from "twilio-video"
import AVStore from "@medaica/common/views/exam/virtual-exam/stores/av-store"
import { auscultationMicSampleRateInHz } from "@medaica/common/const"

class M1 {
  private _patientMonitorCtx: AudioContext
  private _recordingCtx: AudioContext
  private _inputMonitorCtx: AudioContext
  private _HCPRoomMonitorCtx: AudioContext
  private _HCPAuscMonitorCtx: AudioContext
  private _roomVolAnalyser: AnalyserNode
  private _patientRoomGain: GainNode
  private _patientAuscGain: GainNode
  private _recordingRoomGain: GainNode
  private _recordingAuscGain: GainNode
  private _inputRoomGain: GainNode
  private readonly sourceStream: MediaStream
  track: LocalAudioTrack
  auscultationAudioTrack: LocalAudioTrack

  streams: MediaStream[] = []
  patientM1RoomStream: MediaStream
  patientM1AuscStream: MediaStream
  recordingStream: MediaStream
  ausVolAnalyser: AnalyserNode
  isConnected: boolean
  label: string
  id: string
  muted: boolean
  private readonly sampleRate = auscultationMicSampleRateInHz

  constructor(mediaStream: MediaStream | null, id: string, label: string, allStreams = true) {
    this.id = id
    this.label = label

    if (!mediaStream) {
      throw new Error("Null media stream")
    }
    if (mediaStream.getTracks().length === 0) {
      throw new Error("Insufficient tracks")
    }
    this.sourceStream = mediaStream
    this.streams.push(this.sourceStream)

    this._setupPatientMonitorStream()
    this._setupRecordingStream()
    this._setupInputMonitorStream()
    if (allStreams) {
      this._setupHCPMonitorStream()
      this._setupPhonocardiogramStream()
    }
  }

  setMuted(muted: boolean): void {
    this.track.enable(!muted)
    this.auscultationAudioTrack.enable(!muted)
    this.muted = muted
  }

  get tracks(): LocalAudioTrack[] {
    return [this.track, this.auscultationAudioTrack]
  }

  //patient monitor stream
  private _setupPatientMonitorStream(): void {
    this._patientMonitorCtx = new (window.AudioContext || window["webkitAudioContext"])({ sampleRate: this.sampleRate })
    const patientMonitorSrc = this._patientMonitorCtx.createMediaStreamSource(this.sourceStream)

    //splitter 0 is the room mic
    //splitter 1 is the M1
    const splitter = this._patientMonitorCtx.createChannelSplitter(2)
    patientMonitorSrc.connect(splitter)
    const merger = this._patientMonitorCtx.createChannelMerger(2)

    this._patientRoomGain = this._patientMonitorCtx.createGain()
    this._patientRoomGain.gain.setValueAtTime(0.0, this._patientMonitorCtx.currentTime)
    splitter.connect(this._patientRoomGain, 1)

    this._patientAuscGain = this._patientMonitorCtx.createGain()
    this._patientAuscGain.gain.setValueAtTime(0.0, this._patientMonitorCtx.currentTime)
    splitter.connect(this._patientAuscGain, 0)

    this._patientAuscGain.connect(merger, 0, 0)
    this._patientRoomGain.connect(merger, 0, 0)

    this._patientAuscGain.connect(merger, 0, 1)
    this._patientRoomGain.connect(merger, 0, 1)

    merger.connect(this._patientMonitorCtx.destination)

    this._patientAuscGain.gain.setValueAtTime(1, this._patientMonitorCtx.currentTime)
  }

  //HCP monitor stream
  private _setupHCPMonitorStream(): void {
    //set up room stream
    //set up ausc stream
    this._HCPRoomMonitorCtx = new (window.AudioContext || window["webkitAudioContext"])({ sampleRate: this.sampleRate })
    const roomDestination = this._HCPRoomMonitorCtx.createMediaStreamDestination()

    const hcpMonitorRoomSrcStreamClone = this.sourceStream.clone()
    this.streams.push(hcpMonitorRoomSrcStreamClone)

    const HCPMonitorRoomSrc = this._HCPRoomMonitorCtx.createMediaStreamSource(hcpMonitorRoomSrcStreamClone)
    //splitter 0 is the room mic
    //splitter 1 is the M1
    const roomSplitter = this._HCPRoomMonitorCtx.createChannelSplitter(2)
    HCPMonitorRoomSrc.connect(roomSplitter)
    const roomMerger = this._HCPRoomMonitorCtx.createChannelMerger(1)
    roomSplitter.connect(roomMerger, 1, 0)
    roomMerger.connect(roomDestination)
    this.patientM1RoomStream = roomDestination.stream
    this.streams.push(this.patientM1RoomStream)
    this.track = new LocalAudioTrack(this.patientM1RoomStream.getAudioTracks()[0], {
      name: AVStore.genTrackName("m1RoomMicAudio"),
      logLevel: "off",
    })

    //set up ausc stream
    this._HCPAuscMonitorCtx = new (window.AudioContext || window["webkitAudioContext"])({ sampleRate: this.sampleRate })
    const auscDestination = this._HCPAuscMonitorCtx.createMediaStreamDestination()

    const hcpMonitorAuscSrcStreamClone = this.sourceStream.clone()
    this.streams.push(hcpMonitorAuscSrcStreamClone)

    const HCPMonitorAuscSrc = this._HCPAuscMonitorCtx.createMediaStreamSource(hcpMonitorAuscSrcStreamClone)
    //splitter 0 is the room mic
    //splitter 1 is the M1
    const auscSplitter = this._HCPAuscMonitorCtx.createChannelSplitter(2)
    HCPMonitorAuscSrc.connect(auscSplitter)
    const auscMerger = this._HCPAuscMonitorCtx.createChannelMerger(1)
    auscSplitter.connect(auscMerger, 0, 0)
    auscMerger.connect(auscDestination)
    this.patientM1AuscStream = auscDestination.stream
    this.streams.push(this.patientM1AuscStream)
    this.auscultationAudioTrack = new LocalAudioTrack(this.patientM1AuscStream.getAudioTracks()[0], {
      name: AVStore.genTrackName("m1AuscMicAudio"),
      logLevel: "off",
    })
  }

  private _setupRecordingStream(): void {
    this._recordingCtx = new (window.AudioContext || window["webkitAudioContext"])({ sampleRate: this.sampleRate })
    const recordingSrc = this._recordingCtx.createMediaStreamSource(this.sourceStream)

    //splitter 0 is the room mic
    //splitter 1 is the M1
    const splitter = this._recordingCtx.createChannelSplitter(2)
    recordingSrc.connect(splitter)

    this._recordingAuscGain = this._recordingCtx.createGain()
    this._recordingAuscGain.gain.setValueAtTime(1, this._recordingCtx.currentTime)
    splitter.connect(this._recordingAuscGain, 0)

    const mediaStreamDestination = new MediaStreamAudioDestinationNode(this._recordingCtx)

    this._recordingAuscGain.connect(mediaStreamDestination)
    this.recordingStream = mediaStreamDestination.stream
    this.streams.push(this.recordingStream)
  }

  enableRoomMicOnRecording(): void {
    if (this._recordingRoomGain) {
      this._recordingRoomGain.gain.setValueAtTime(0.7, this._recordingCtx.currentTime)
    }
  }

  disableRoomMicOnRecording(): void {
    if (this._recordingRoomGain) {
      this._recordingRoomGain.gain.setValueAtTime(0, this._recordingCtx.currentTime)
    }
  }

  setRecordingInputGain(gain: number): void {
    this._recordingAuscGain.gain.setValueAtTime(gain, this._recordingCtx.currentTime)
  }

  private _setupInputMonitorStream(): void {
    this._inputMonitorCtx = new (window.AudioContext || window["webkitAudioContext"])({ sampleRate: this.sampleRate })
    const inputMonitorSrc = this._inputMonitorCtx.createMediaStreamSource(this.sourceStream)

    //splitter 0 is the room mic
    //splitter 1 is the M1
    const splitter = this._inputMonitorCtx.createChannelSplitter(2)
    inputMonitorSrc.connect(splitter)

    this._inputRoomGain = this._inputMonitorCtx.createGain()
    this._inputRoomGain.gain.setValueAtTime(1.0, this._inputMonitorCtx.currentTime)
    splitter.connect(this._inputRoomGain, 1)
    splitter.connect(this._inputRoomGain, 0)
    this._roomVolAnalyser = this._inputMonitorCtx.createAnalyser()
    this._roomVolAnalyser.smoothingTimeConstant = 0.1
    this._roomVolAnalyser.maxDecibels = -0
    this._roomVolAnalyser.minDecibels = -10
    this._roomVolAnalyser.fftSize = 2048

    this._inputRoomGain.connect(this._roomVolAnalyser)
  }

  private _setupPhonocardiogramStream(): void {
    const phonocradiogramMonitorCtx = new (window.AudioContext || window["webkitAudioContext"])({
      sampleRate: this.sampleRate,
    })
    const phonoCradiogramSrc = phonocradiogramMonitorCtx.createMediaStreamSource(this.sourceStream)

    //splitter 0 is the room mic
    //splitter 1 is the M1
    const splitter = phonocradiogramMonitorCtx.createChannelSplitter(2)

    const audioFilterLowPass = phonocradiogramMonitorCtx.createBiquadFilter()
    audioFilterLowPass.type = "lowpass"
    audioFilterLowPass.frequency.value = 800

    const inputAuscGain = phonocradiogramMonitorCtx.createGain()
    inputAuscGain.gain.setValueAtTime(0.9, phonocradiogramMonitorCtx.currentTime)

    this.ausVolAnalyser = phonocradiogramMonitorCtx.createAnalyser()
    this.ausVolAnalyser.smoothingTimeConstant = 0.9
    this.ausVolAnalyser.maxDecibels = -0
    this.ausVolAnalyser.minDecibels = -10
    this.ausVolAnalyser.fftSize = 8192

    splitter.connect(audioFilterLowPass, 0)
    audioFilterLowPass.connect(inputAuscGain)
    inputAuscGain.connect(this.ausVolAnalyser)
    phonoCradiogramSrc.connect(splitter)
  }

  getVolume(): number {
    let avgPowerDecibels = 0
    // let ausVol = 0
    if (this._roomVolAnalyser) {
      //const roomBufferLength =
      const sampleBuffer = new Float32Array(this._roomVolAnalyser.fftSize)
      this._roomVolAnalyser.getFloatTimeDomainData(sampleBuffer)

      let sumOfSquares = 0
      for (const value of sampleBuffer) {
        sumOfSquares += value ** 2
      }
      avgPowerDecibels = 10 * Math.log10(sumOfSquares / sampleBuffer.length)
    }

    return avgPowerDecibels
  }

  dispose(): void {
    this.streams.forEach((stream) => {
      stream.getTracks().forEach((track) => {
        track.stop()
      })
    })
  }

  enableRoomMicOnLocalSpeakers(): void {
    if (this._patientRoomGain) {
      this._patientRoomGain.gain.setValueAtTime(1, this._patientMonitorCtx.currentTime)
    }
  }

  disableRoomMicOnLocalSpeakers(): void {
    if (this._patientRoomGain) {
      this._patientRoomGain.gain.setValueAtTime(0, this._patientMonitorCtx.currentTime)
    }
  }

  enableAuscultationMicOnLocalSpeakers(): void {
    if (this._patientAuscGain) {
      this._patientAuscGain.gain.setValueAtTime(1, this._patientMonitorCtx.currentTime)
    }
  }

  disableAuscultationMicOnLocalSpeakers(): void {
    if (this._patientAuscGain) {
      this._patientAuscGain.gain.setValueAtTime(0, this._patientMonitorCtx.currentTime)
    }
  }
}

const deviceIsM1 = (device: AudioDevice | null): device is M1 => {
  return device instanceof M1
}

export { deviceIsM1, M1 }

/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement, useContext, useState } from "react"
import { observer } from "mobx-react-lite"
import { when } from "mobx"
import RequestedSelfExamContext from "views/exam/requested-self-exam/requested-self-exam-context"
import { Button, Dialog, DialogContent, DialogTitle } from "@mui/material"
import { LoadingButton } from "@mui/lab"
import { ChevronLeft as ChevronLeftIcon } from "@mui/icons-material"
import { logError } from "@medaica/common/services/util"

const Review = observer(({ step }: { step: number }): ReactElement => {
  const { requestedSelfExamStore } = useContext(RequestedSelfExamContext)
  const [showFinalizingProgressModal, setShowFinalizingProgressModal] = useState(false)
  const [finalizing, setFinalizing] = useState(false)

  const completeExamButtonClicked = () => {
    setFinalizing(true)
    if (requestedSelfExamStore.totalPercentUploaded < 100) {
      setShowFinalizingProgressModal(true)
      when(
        () => requestedSelfExamStore.totalPercentUploaded < 100,
        () => {
          requestedSelfExamStore.finalizeSelfExam().catch(logError)
        }
      )
    } else {
      requestedSelfExamStore.finalizeSelfExam().catch(logError)
    }
  }

  return (
    <>
      <Dialog
        disableEscapeKeyDown
        onClose={(_, reason) => {
          if (reason === "backdropClick") {
            return
          }
        }}
        open={showFinalizingProgressModal}
      >
        <DialogTitle>Finalizing Exam</DialogTitle>
        <DialogContent>
          <div className="flex flex-col items-center mt-2 mr-2">
            <div className="bg-gray-200 h-4 w-full rounded-sm">
              <div
                className="bg-gray-400 h-full rounded-l-sm"
                style={{ width: `${requestedSelfExamStore.totalPercentUploaded}%` }}
              />
            </div>
            <div className="mt-3 text-sm">Please do not close the window while the exam is finalizing.</div>
          </div>
        </DialogContent>
      </Dialog>
      <div>
        <h2 className="text-2xl header-color font-semibold">Review</h2>
        <p className="mt-6">
          If you would like to re-record any of your recordings, click the position on the left you would like to
          re-record. Otherwise, click <strong>Finalize Exam</strong> to finalize your exam and your healthcare provider
          will be notified that your exam is complete.
        </p>
        <div className="mt-8 flex justify-center">
          <LoadingButton variant="contained" onClick={completeExamButtonClicked} loading={finalizing}>
            {finalizing ? "Finalizing" : "Finalize Exam"}
          </LoadingButton>
        </div>
        <div className="mt-5">
          <Button
            color="neutral"
            startIcon={<ChevronLeftIcon />}
            onClick={() => requestedSelfExamStore.goToPreviousStep()}
          >
            Back
          </Button>
        </div>
      </div>
    </>
  )
})

export default Review

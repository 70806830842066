/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import { TextField, Container, Grid, Typography, Paper, InputAdornment, IconButton } from "@mui/material"
import { CopyIcon } from "../../../../icons/CopyIcon"
import { ErrorIcon } from "../../../../icons/ErrorIcon"
import { SupportedList } from "./SupportedList"
import React, { ReactElement } from "react"
import { styled } from "@mui/material/styles"

const PREFIX = "unsupportedBrowser"

const classes = {
  mainContainer: `${PREFIX}mainContainer`,
  paper: `${PREFIX}paper`,
  copyButton: `${PREFIX}copyButton`,
  caption: `${PREFIX}caption`,
  heading: `${PREFIX}heading`,
  header: `${PREFIX}header`,
  urlTextBox: `${PREFIX}urlTextBox`,
  paperContainer: `${PREFIX}paperContainer`,
}

const Root = styled("div")(() => ({
  [`& .${classes.mainContainer}`]: {
    justifyContent: "space-between",
  },

  [`& .${classes.paper}`]: (theme) => ({
    padding: "1.5em",
    borderRadius: "8px",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  }),

  [`& .${classes.copyButton}`]: {
    borderLeft: "solid 1px #E1E3EA",
    maxHeight: "2.5em",
    display: "flex",
  },

  [`& .${classes.caption}`]: {
    marginTop: "1.3em",
  },

  [`& .${classes.heading}`]: {
    position: "relative",
  },

  [`& .${classes.header}`]: (theme) => ({
    float: "left",
    [theme.breakpoints.down("md")]: {
      float: "initial",
    },
  }),

  [`& .${classes.urlTextBox}`]: (theme) => ({
    clear: "left",
    [theme.breakpoints.down("md")]: {
      clear: "initial",
      marginBottom: "2em",
    },
  }),

  [`& .${classes.paperContainer}`]: (theme) => ({
    marginRight: "1em",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      float: "initial",
      display: "flex",
      justifyContent: "center",
      margin: "0 0 2.5em 0",
    },
  }),
}))

export const UnsupportedBrowser = (): ReactElement => {
  const appURL = window.location.href

  const copyAppLink = () => {
    void navigator.clipboard.writeText(appURL)
  }

  return (
    <Root>
      <Container>
        <Grid container className={classes.mainContainer}>
          <Grid item lg={5} className={classes.header}>
            <Typography component="h1" variant="h3" gutterBottom className={classes.heading}>
              <ErrorIcon />
              New browser needed
            </Typography>
            <Typography variant="body1" gutterBottom>
              Oh no, your browser isn't supported! If you haven't already done so, download and install a new one from
              the list and copy the url below and paste it into your new browser to restart the test.
            </Typography>

            <TextField
              fullWidth={true}
              id="read-only-app-link"
              defaultValue={appURL}
              variant="outlined"
              size="small"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <div className={classes.copyButton}>
                      <IconButton edge="end" onClick={copyAppLink}>
                        <CopyIcon />
                      </IconButton>
                    </div>
                  </InputAdornment>
                ),
                readOnly: true,
              }}
            />
          </Grid>

          <Grid item lg={5} className={classes.paperContainer}>
            <Paper className={classes.paper}>
              <SupportedList />
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </Root>
  )
}

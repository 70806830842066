/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import { useCallback, useEffect, useRef } from "react"
import { useNavigate, NavigateOptions, To } from "react-router-dom"

// https://github.com/remix-run/react-router/issues/7634
const useNav = (): ((location: To, options?: NavigateOptions) => void) => {
  const navigate = useNavigate()
  const navigateRef = useRef({ navigate })
  useEffect(() => {
    navigateRef.current.navigate = navigate
  }, [navigate])
  return useCallback((to: To, options?: NavigateOptions) => {
    navigateRef.current.navigate(to, options)
  }, [])
}

export default useNav

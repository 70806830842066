/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement } from "react"
import "./styles.css"

const Hipaa = (): ReactElement => {
  return (
    <div className="main-centered">
      <h1 className="header h1-header">HIPAA</h1>
      <p className="justified-text">
        Data security is of utmost importance to us at Medaica. We have implemented policies, procedures, and technical
        safeguards to ensure the security of patient and customer data while adhering to HIPAA compliance standards. Our
        commitment to data security includes the following measures:
      </p>
      <h3 className="header h3-header">Policies and Procedures</h3>
      <p className="justified-text">
        We maintain industry-recommended HIPAA-compliant policies and procedures to safeguard patient data effectively.
      </p>
      <h3 className="header h3-header">Encryption</h3>
      <p className="justified-text">
        We utilize HIPAA-compliant and NIST-recommended 256-bit AES encryption with managed keys for securing patient
        data. Additionally, encrypted backups are created on a daily basis to ensure data protection.
      </p>
      <h3 className="header h3-header">Backups</h3>
      <p className="justified-text">
        We conduct nightly backups of our data and regularly test these backups to ensure data recoverability in case of
        any unforeseen issues.
      </p>
      <h3 className="header h3-header">BAA Agreements</h3>
      <p className="justified-text">
        Medaica has established Business Associate Agreement (BAA) agreements with our subcontractors and hosting
        providers to ensure they also comply with HIPAA regulations.
      </p>
      <h3 className="header h3-header">Security Contact</h3>
      <p className="justified-text">
        To report any security issues or concerns, please contact us at{" "}
        <a href="mailto:info@medaica.com" className="mailto-link">
          info@medaica.com
        </a>
        . We take security matters seriously and encourage prompt reporting of any potential vulnerabilities or
        incidents.
      </p>
    </div>
  )
}

export default Hipaa

/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import MedaicaApiService from "./"
import { FirebaseAccessToken } from "@medaica/common/types"

class FirebaseApi {
  private _apiService: MedaicaApiService

  constructor(apiService: MedaicaApiService) {
    this._apiService = apiService
  }

  async getFirebaseAccessTokenForAuthorizedParticipant(liveExamId: string): Promise<FirebaseAccessToken> {
    const apiClient = await this._apiService.apiClient(true)
    const response = await apiClient.post<FirebaseAccessToken>(
      `/firebase/auth/live-exam/${liveExamId}/authorized-participant`
    )
    return response.data
  }

  async getFirebaseAccessTokenForPotentialParticipant(liveExamId: string): Promise<FirebaseAccessToken> {
    const apiClient = await this._apiService.apiClient(false)
    const response = await apiClient.post<FirebaseAccessToken>(
      `/firebase/auth/live-exam/${liveExamId}/potential-participant`
    )
    return response.data
  }
}

export default FirebaseApi

/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement } from "react"
import { CogIcon } from "@heroicons/react/outline"
import Popup from "@medaica/common/components/popup/popup"
import { bindToggle, usePopupState } from "material-ui-popup-state/hooks"

const SettingsButton = ({
  buttonClassName = "video-button",
  showLabel = true,
  children,
}: {
  buttonClassName?: string
  showLabel?: boolean
  children: ReactElement
}): ReactElement => {
  const popupState = usePopupState({ variant: "popper", popupId: `settingsPopup` })

  return (
    <>
      <Popup placement="top" style={{ zIndex: 200 }} popupState={popupState}>
        {children}
        {/*<SettingsPanel mediaDeviceManager={mediaDeviceManager} />*/}
      </Popup>
      <button {...bindToggle(popupState)}>
        <div className="flex flex-col items-center">
          <div className={buttonClassName}>
            <CogIcon className="h-7 stroke-2" />
          </div>
          {showLabel && <div className="font-sm text-gray-500">Settings</div>}
        </div>
      </button>
    </>
  )
}

export default SettingsButton

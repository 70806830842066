/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

function findM1MediaDeviceInfo(availableAudioDevices: MediaDeviceInfo[]): MediaDeviceInfo | null {
  /* So far it seems that the label is:
    - Chrome: USB MIC (0c76:26c7)
    - FF: USB MIC
    - Safari: USB MIC
    Thus, to match in all browsers we need to fuzzy match
  */
  const allowFuzzyMatch = true

  //restrict for production
  const allowedDeviceLabels = [
    "USB MIC",
    "PCP-USB",
    "Medaica",
    "USB audio",
    "Default - Microphone \\(PCP-USB\\)",
    "Microphone \\(PCP-USB\\)",
  ]

  const allowedDeviceRegexs = allowedDeviceLabels.map((label) => {
    return allowFuzzyMatch ? new RegExp(`^.*${label}( (.*))?$`) : new RegExp(`^$.*{label}$`)
  })

  return (
    availableAudioDevices.find((device) => {
      if (device.kind !== "audioinput") {
        return false
      }

      return allowedDeviceRegexs.find((regex) => regex.exec(device.label))
    }) ?? null
  )
}

const sleep = (delay: number): Promise<void> => new Promise((r) => setTimeout(r, delay))

export const useStethoscopeConnection = (): { isM1Connected } => {
  const isM1Connected = async (): Promise<boolean> => {
    const tries = 16
    for (let i = 0; i < tries; i++) {
      const deviceInfos = await navigator.mediaDevices.enumerateDevices()
      if (findM1MediaDeviceInfo(deviceInfos) != null) {
        return true
      } else {
        await sleep(500)
      }
    }
    return false
  }

  return { isM1Connected } as const
}

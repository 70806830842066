/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import { v4 as uuidv4 } from "uuid"
import Bowser from "bowser"
import {
  connect,
  Logger,
  Room,
  LocalVideoTrack,
  LocalAudioTrack,
  LocalAudioTrackPublication,
  LocalDataTrack,
  RemoteDataTrack,
  RemoteParticipant,
} from "twilio-video"
import logger from "@medaica/common/services/logging"
import { autorun, makeObservable, observable, runInAction } from "mobx"
import RemoteParticipantStore from "./remote-participant-store"
import { VideoRoomMonitor } from "@twilio/video-room-monitor"
import MediaDeviceStore from "@medaica/common/services/media-device-store"

/**
 * This allows us to start/stop the twilio room monitor from the browser's console
 */
// @ts-ignore
window.startRoomMonitor = () => {
  // @ts-ignore
  VideoRoomMonitor.registerVideoRoom(global.twilioRoom as Room)
  VideoRoomMonitor.openMonitor()
}

// @ts-ignore
window.stopRoomMonitor = () => {
  VideoRoomMonitor.closeMonitor()
}

type TrackName = "m1RoomMicAudio" | "m1AuscMicAudio" | "defaultAudio" | "defaultVideo"

const browser = Bowser.getParser(window.navigator.userAgent)

class AVStore {
  static compatibleBrowsers = {
    chrome: ">=0",
    chromium: ">=0",
    electron: ">=0",
    edge: ">=0",
  }
  protected _disposers: (() => void)[] = []
  remoteParticipant: RemoteParticipantStore | null = null
  room: Room | null
  localDataTrack: LocalDataTrack | null = null
  remoteDataTrack: RemoteDataTrack | null = null

  constructor(mediaDeviceStore: MediaDeviceStore) {
    makeObservable(this, {
      remoteParticipant: observable,
      remoteDataTrack: observable,
      room: observable,
    })

    this._disposers.push(
      autorun(() => {
        if (mediaDeviceStore.audioDevice && this.room) {
          void this.publishLocalAudioTrack(mediaDeviceStore.audioDevice.track)
        }
      })
    )

    this._disposers.push(
      autorun(() => {
        if (mediaDeviceStore.videoDevice && this.room) {
          void this.publishLocalVideoTrack(mediaDeviceStore.videoDevice.track)
        }
      })
    )

    this._disposers.push(
      autorun(() => {
        if (mediaDeviceStore.disconnectedAudioDevice && this.room) {
          this.unpublishLocalAudioTracks(mediaDeviceStore.disconnectedAudioDevice.tracks)
        }
      })
    )

    this._disposers.push(
      autorun(() => {
        if (mediaDeviceStore.disconnectedVideoDevice && this.room) {
          this.unpublishLocalVideoTracks(mediaDeviceStore.disconnectedVideoDevice.tracks)
        }
      })
    )
  }

  static isPlatformSupported(): boolean {
    const s = browser.satisfies({
      desktop: AVStore.compatibleBrowsers,
    })
    return !!s // && Video.isSupported // Twilio doesn't officially support Electron, so we can't use this.
  }

  public localAudioTracksInclude(trackName: TrackName): boolean {
    if (!this.room) return false
    const p = this.room.localParticipant.audioTracks.values()
    return !!Array.from(p).find((publication: LocalAudioTrackPublication) =>
      publication.trackName.startsWith(trackName)
    )
  }

  // A workaround for https://github.com/twilio/twilio-video.js/issues/1227
  public static genTrackName(prefix: TrackName): string {
    return `${prefix}-${uuidv4()}`
  }

  async joinRoom(token: string, roomName: string): Promise<void> {
    logger.debug("joining room", roomName)

    const twilioLogger = Logger.getLogger("twilio-video")
    twilioLogger.setLevel("silent")

    this.localDataTrack = new LocalDataTrack()

    const room = await connect(token, {
      automaticSubscription: true,
      name: roomName,
      audio: false,
      video: false,
      insights: false, // fixes https://github.com/twilio/twilio-video.js/issues/1968
      tracks: [this.localDataTrack],
    })

    logger.debug(`Connected to room SID: ${room.sid}`)
    // We make the room global, so we can use
    // https://github.com/twilio/twilio-video-room-monitor.js from console
    // @ts-ignore
    window.twilioRoom = room

    const disconnect = () => this.room?.disconnect()

    room.once("disconnected", () => {
      window.removeEventListener("beforeunload", disconnect)
    })

    //Listen to the "beforeunload" event on a window to leave the Room
    //when the tab/browser is being closed.
    window.addEventListener("beforeunload", disconnect)

    room.participants.forEach((participant) => {
      this.handleRemoteParticipantConnected(participant)
    })

    room.on("participantConnected", (participant) => {
      this.handleRemoteParticipantConnected(participant)
    })

    room.on("participantDisconnected", (participant) => {
      runInAction(() => {
        this.remoteParticipant = null
      })
    })

    room.on("participantReconnecting", (participant) => {
      runInAction(() => {
        this.remoteParticipant?.setReconnecting(true)
      })
    })

    room.on("participantReconnected", (participant) => {
      runInAction(() => {
        this.remoteParticipant?.setReconnecting(false)
      })
    })

    room.on("trackSubscribed", (track) => {
      if (track.kind === "data") {
        runInAction(() => {
          this.remoteDataTrack = track
        })
        // console.log("data track attached")
        // track.on("message", function (message) {
        //   console.log(message)
        // })
      }
    })

    runInAction(() => {
      this.room = room
    })
  }

  private handleRemoteParticipantConnected(participant: RemoteParticipant) {
    runInAction(() => {
      logger.debug("New remote participant", participant)
      this.remoteParticipant = new RemoteParticipantStore(participant)
    })
  }

  async publishLocalVideoTrack(localVideoTrack: LocalVideoTrack): Promise<void> {
    if (this.room) {
      try {
        logger.debug("Publishing local video track", localVideoTrack)
        await this.room.localParticipant.publishTrack(localVideoTrack)
      } catch (error) {
        logger.error(error)
      }
    }
  }

  unpublishLocalVideoTracks(localVideoTracks: LocalVideoTrack[]): void {
    this.room?.localParticipant?.videoTracks.forEach((trackPublication) => {
      if (localVideoTracks.includes(trackPublication.track)) {
        logger.debug("Unpublishing", trackPublication)
        trackPublication.unpublish()
      }
    })
  }

  async publishLocalAudioTrack(localAudioTrack: LocalAudioTrack): Promise<void> {
    if (this.room) {
      try {
        logger.debug("Publishing local audio track", localAudioTrack)
        await this.room.localParticipant.publishTrack(localAudioTrack)
      } catch (error) {
        logger.error(error)
      }
    }
  }

  unpublishLocalAudioTracks(localAudioTracks: LocalAudioTrack[]): void {
    this.room?.localParticipant?.audioTracks.forEach((trackPublication) => {
      if (localAudioTracks.includes(trackPublication.track)) {
        logger.debug("Unpublishing", trackPublication)
        trackPublication.unpublish()
      }
    })
  }

  unpublishLocalAudioTrack(localAudioTrack: LocalAudioTrack): void {
    this.unpublishLocalAudioTracks([localAudioTrack])
  }

  disconnect(): void {
    if (this.room) {
      logger.debug("Disconnecting from AV Room")
      this.room.disconnect()
    }
  }

  dispose(): void {
    logger.debug("Disposing AbstractAVManager")
    this._disposers.forEach((disposer) => disposer())
    this.disconnect()
  }
}

export default AVStore
export type { TrackName }

/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import {
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Tooltip,
  Box,
} from "@mui/material"
import { ErrorStatus, SuccessStatus, WarningStatus } from "../../../../icons/StatusIcons"
import { QualityScore } from "../Quality"
import { toolTipContent } from "./ToolTipContent"
import { InfoIcon } from "../../../../icons/InfoIcon"
import { Close as CloseIcon } from "@mui/icons-material"
import React, { ReactElement } from "react"
import { styled } from "@mui/material/styles"
import { useTheme } from "@mui/styles"

const PREFIX = "qualityModal"

const classes = {
  modal: `${PREFIX}-modal`,
  content: `${PREFIX}-content`,
  closeButton: `${PREFIX}-closeButton`,
}

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(() => ({
  [`& .${classes.modal}`]: {
    width: "100%",
    maxWidth: "600px",
  },

  [`& .${classes.content}`]: (theme) => ({
    padding: "3em",
    [theme.breakpoints.down("sm")]: {
      padding: "2em",
    },
  }),
}))

interface QualityModalProps {
  isModalOpen: boolean
  setIsModalOpen: (isModalOpen: boolean) => void
  jitter: { average?: string; max?: string; qualityScore: QualityScore }
  latency: { average?: string; max?: string; qualityScore: QualityScore }
  packetLoss: { average?: string; max?: string; qualityScore: QualityScore }
  bitrate: { average?: string; max?: string; min?: string; qualityScore: QualityScore }
}

export const QualityModal = ({
  isModalOpen,
  setIsModalOpen,
  latency,
  jitter,
  packetLoss,
  bitrate,
}: QualityModalProps): ReactElement => {
  const statusIcons = {
    [QualityScore.Excellent]: <SuccessStatus />,
    [QualityScore.Good]: <SuccessStatus />,
    [QualityScore.Suboptimal]: <WarningStatus />,
    [QualityScore.Poor]: <ErrorStatus />,
  }

  const theme = useTheme()

  const styles = {
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    statusInfoContainer: {
      display: "flex",
      alignItems: "center",
      "& div": { display: "flex", alignItems: "center" },
      "& svg": { marginRight: "0.3em" },
    },
  }

  return (
    <Root>
      <Dialog open={isModalOpen} onClose={() => setIsModalOpen(false)} classes={{ paper: classes.modal }}>
        <DialogTitle>
          Detailed quality information
          <IconButton aria-label="close" sx={styles.closeButton} onClick={() => setIsModalOpen(false)}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers className={classes.content}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography variant="body1">
                    <strong>Network Info</strong>
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body1">
                    <strong>Score</strong>
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  <Box sx={styles.statusInfoContainer}>
                    <Tooltip
                      title={toolTipContent.roundTripTime}
                      leaveDelay={250}
                      leaveTouchDelay={15000}
                      enterTouchDelay={0}
                    >
                      <div>
                        <InfoIcon />
                      </div>
                    </Tooltip>
                    <Typography variant="body1">
                      <strong>RTT (ms) avg/max</strong>
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box sx={styles.statusInfoContainer}>
                    {statusIcons[latency.qualityScore]}
                    <Typography variant="body1">{`${latency.average} / ${latency.max} (${
                      QualityScore[latency.qualityScore]
                    })`}</Typography>
                  </Box>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Box sx={styles.statusInfoContainer}>
                    <Tooltip title={toolTipContent.jitter} leaveDelay={250} leaveTouchDelay={15000} enterTouchDelay={0}>
                      <div>
                        <InfoIcon />
                      </div>
                    </Tooltip>
                    <Typography variant="body1">
                      <strong>Jitter (s) avg/max</strong>
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box sx={styles.statusInfoContainer}>
                    {statusIcons[jitter.qualityScore]}
                    <Typography variant="body1">{`${jitter.average} / ${jitter.max} (${
                      QualityScore[jitter.qualityScore]
                    })`}</Typography>
                  </Box>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Box sx={styles.statusInfoContainer}>
                    <Tooltip
                      title={toolTipContent.packetLoss}
                      leaveDelay={250}
                      leaveTouchDelay={15000}
                      enterTouchDelay={0}
                    >
                      <div>
                        <InfoIcon />
                      </div>
                    </Tooltip>
                    <Typography variant="body1">
                      <strong>Packet loss avg/max</strong>
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box sx={styles.statusInfoContainer}>
                    {statusIcons[packetLoss.qualityScore]}
                    <Typography variant="body1">{`${packetLoss.average}% / ${packetLoss.max}% (${
                      QualityScore[packetLoss.qualityScore]
                    })`}</Typography>
                  </Box>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Box sx={styles.statusInfoContainer}>
                    <Tooltip
                      title={toolTipContent.bitrate}
                      leaveDelay={250}
                      leaveTouchDelay={15000}
                      enterTouchDelay={0}
                    >
                      <div>
                        <InfoIcon />
                      </div>
                    </Tooltip>
                    <Typography variant="body1">
                      <strong>Bitrate (kbps) avg/max</strong>
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box sx={styles.statusInfoContainer}>
                    {statusIcons[bitrate.qualityScore]}
                    <Typography variant="body1">{`${bitrate.average} / ${bitrate.max} (${
                      QualityScore[bitrate.qualityScore]
                    })`}</Typography>
                  </Box>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </DialogContent>
      </Dialog>
    </Root>
  )
}

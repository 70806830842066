/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import MedaicaApiService from "./"
import { TurnCredential, TwilioAccessToken } from "@medaica/common/types"

class TwilioApi {
  private _apiService: MedaicaApiService

  constructor(apiService: MedaicaApiService) {
    this._apiService = apiService
  }

  async createTwilioRoom(): Promise<{ roomName: string; accessToken: TwilioAccessToken }> {
    const apiClient = await this._apiService.apiClient()
    const response = await apiClient.post<{ roomName: string; accessToken: TwilioAccessToken }>("/twilio/rooms")
    return response.data
  }

  async getTwilioRoomToken(roomName: string): Promise<TwilioAccessToken> {
    const apiClient = await this._apiService.apiClient()
    const response = await apiClient.post<TwilioAccessToken>(`/twilio/rooms/${roomName}/tokens`)
    return response.data
  }

  async getTwilioTurnCredentials(): Promise<{
    iceServers: [TurnCredential]
  }> {
    const apiClient = await this._apiService.apiClient(false)
    const response = await apiClient.get<{ iceServers: [TurnCredential] }>("/twilio/turn-credentials")
    return response.data
  }

  async getCompatibilityTestAccessToken(): Promise<string> {
    const apiClient = await this._apiService.apiClient(false)
    const response = await apiClient.get<{ token: string }>("/twilio/compatibility-test/token")
    return response.data.token
  }
}

export default TwilioApi

/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import { observer } from "mobx-react-lite"
import React, { ReactElement } from "react"
import VideoOnIcon from "@medaica/common/components/icons/video-on-icon"
import VideoOffIcon from "@medaica/common/components/icons/video-off-icon"
import MicOnIcon from "@medaica/common/components/icons/mic-on-icon"
import MicOffIcon from "@medaica/common/components/icons/mic-off-icon"
import MediaDeviceStore from "services/media-device-store"

const VideoControls = observer(
  ({
    mediaDeviceStore,
    showLabels = true,
    buttonClassName = "video-button",
    buttonOffClassName = "video-button bg-red-500 text-white",
  }: {
    mediaDeviceStore: MediaDeviceStore
    showLabels?: boolean
    buttonClassName?: string
    buttonOffClassName?: string
  }): ReactElement => {
    return (
      <>
        {!mediaDeviceStore.videoDeviceIsMuted ? (
          <button
            onClick={() => {
              mediaDeviceStore.setVideoDeviceMuted(true)
            }}
          >
            <div className={buttonClassName}>
              <VideoOnIcon className="h-7 w-7" />
            </div>
            {showLabels && <div className="font-sm text-gray-500">Cam</div>}
          </button>
        ) : (
          <button
            onClick={() => {
              mediaDeviceStore.setVideoDeviceMuted(false)
            }}
          >
            <div className={buttonOffClassName}>
              <VideoOffIcon className="h-7 w-7 stroke-2" />
            </div>
            {showLabels && <div className="font-sm text-gray-500">Cam</div>}
          </button>
        )}
        {!mediaDeviceStore.audioDeviceIsMuted ? (
          <button
            onClick={() => {
              mediaDeviceStore.setAudioDeviceMuted(true)
            }}
          >
            <div className={buttonClassName}>
              <MicOnIcon className="h-6 stroke-2" />
            </div>
            {showLabels && <div className="font-sm text-gray-500">Mic</div>}
          </button>
        ) : (
          <button
            onClick={() => {
              mediaDeviceStore.setAudioDeviceMuted(false)
            }}
          >
            <div className={buttonOffClassName}>
              <MicOffIcon className="h-6 stroke-2" />
            </div>
            {showLabels && <div className="font-sm text-gray-500">Mic</div>}
          </button>
        )}
      </>
    )
  }
)

export default VideoControls

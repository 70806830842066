/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { forwardRef, HTMLProps, ReactElement, useCallback, useEffect, useImperativeHandle, useRef } from "react"

type Props = {
  shouldDraw: boolean
  waveformProvider: (() => number[]) | null
  framerate?: number
} & HTMLProps<HTMLCanvasElement>

type PhonocardiogramHandle = {
  reset: () => void
}

const Phonocardiogram = forwardRef<PhonocardiogramHandle, Props>(
  ({ shouldDraw, waveformProvider, framerate = 15, ...props }: Props, ref): ReactElement => {
    const displayRef = useRef<HTMLCanvasElement>(null)
    const animationFrameIdRef = useRef<number | null>(null)
    const requestAnimationFrame =
      window.requestAnimationFrame ||
      window["mozRequestAnimationFrame"] ||
      window["webkitRequestAnimationFrame"] ||
      window["msRequestAnimationFrame"]
    const cnvWidth = 300
    const cnvHeight = 150

    useImperativeHandle(ref, () => ({
      reset: () => {
        if (displayRef.current) {
          resetCanvas()
        }
      },
    }))

    const drawBackground = (drawingContext: CanvasRenderingContext2D) => {
      drawingContext.clearRect(0, 0, cnvWidth, cnvHeight)
      drawingContext.fillStyle = "#000000"
      drawingContext.fillRect(0, 0, cnvWidth, cnvHeight)

      drawingContext.beginPath()
      drawingContext.moveTo(0, 75)
      drawingContext.lineTo(300, 75)
      drawingContext.lineWidth = 3
      drawingContext.strokeStyle = "gray"
      drawingContext.stroke()
    }

    const resetCanvas = () => {
      const canvas = displayRef.current
      if (canvas) {
        const drawingContext = canvas.getContext("2d")
        if (drawingContext) {
          drawBackground(drawingContext)
        }
      }
    }

    const startDrawingWaveform = useCallback(
      (waveformProvider, canvas: HTMLCanvasElement) => {
        const drawingContext = canvas.getContext("2d")

        const drawWaveform = () => {
          const waveformsToDraw = waveformProvider()

          if (waveformsToDraw !== null && drawingContext) {
            drawBackground(drawingContext)

            drawingContext.lineWidth = 2
            drawingContext.strokeStyle = "#00ff66"

            drawingContext.beginPath()

            for (let i = 0; i < waveformsToDraw.length; i++) {
              const x = i
              const y = (0.5 + (waveformsToDraw[i] / 2) * 5) * cnvHeight
              if (i === 0) {
                drawingContext.moveTo(x, y)
              } else {
                drawingContext.lineTo(x, y)
              }
            }
            drawingContext.stroke()
          }

          animationFrameIdRef.current = requestAnimationFrame(drawWaveform)
        }
        drawWaveform()
      },
      [requestAnimationFrame]
    )

    const stopDrawingWaveform = () => {
      if (animationFrameIdRef.current) {
        cancelAnimationFrame(animationFrameIdRef.current)
      }
    }

    useEffect(() => {
      if (waveformProvider && displayRef.current) {
        startDrawingWaveform(waveformProvider, displayRef.current)
      } else {
        stopDrawingWaveform()
      }
      return () => {
        stopDrawingWaveform()
      }
    }, [waveformProvider, startDrawingWaveform])

    return <canvas {...props} ref={displayRef} />
  }
)

export default Phonocardiogram
export type { PhonocardiogramHandle }

/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import MedaicaApiService, { JoinedLoads, QuerySpec } from "./"
import { Exam, LiveExamAnonAccessToken, VirtualExam } from "@medaica/common/types"
import { plainToInstance } from "class-transformer"
import { addSeconds } from "date-fns"
import moment from "moment-timezone"

class VirtualExamsApi {
  private _apiService: MedaicaApiService

  constructor(apiService: MedaicaApiService) {
    this._apiService = apiService
  }

  async getVirtualExamByAccessCode(accessCode: string): Promise<VirtualExam> {
    const apiClient = await this._apiService.apiClient(false)
    const response = await apiClient.get<VirtualExam>(`virtual-exams/access-code/${accessCode}`)
    return plainToInstance(VirtualExam, response.data)
  }

  async createVirtualExamAccessToken(liveExamId: string, userName: string): Promise<LiveExamAnonAccessToken> {
    const apiClient = await this._apiService.apiClient(false)
    const response = await apiClient.post<{
      liveExamId: string
      userName: string
      value: string
      tokenType: string
      expiresIn: number
      userId: string
    }>(`/virtual-exams/${liveExamId}/auth`, {
      userName,
    })

    const { expiresIn, ...rest } = response.data
    const expiresAt = addSeconds(new Date(), expiresIn)

    return { ...rest, expiresAt }
  }

  async createVirtualExam(
    patientProfileId: string,
    healthcareProviderId: string,
    fromDate?: Date,
    toDate?: Date
  ): Promise<VirtualExam> {
    const apiClient = await this._apiService.apiClient()
    const response = await apiClient.post<VirtualExam>("/virtual-exams", {
      healthcareProviderId,
      patientProfileId,
      scheduledStartDate: fromDate,
      scheduledEndDate: toDate,
      timeZone: moment.tz.guess(),
    })
    return plainToInstance(VirtualExam, response.data)
  }

  async getVirtualExam(virtualExamId: string, joinedLoads?: JoinedLoads): Promise<VirtualExam | null> {
    const apiClient = await this._apiService.apiClient()
    const response = await apiClient.get<VirtualExam>(
      this._apiService.withQuery(`/virtual-exams/${virtualExamId}`, {
        q: JSON.stringify({ joinedLoads }),
      })
    )
    return plainToInstance(VirtualExam, response.data)
  }

  async getVirtualExams(query: QuerySpec): Promise<VirtualExam[]> {
    const apiClient = await this._apiService.apiClient()
    const response = await apiClient.get<VirtualExam[]>(
      this._apiService.withQuery("/search/virtual-exams", {
        q: JSON.stringify(query),
      })
    )
    return plainToInstance(VirtualExam, response.data)
  }

  async createExamForVirtualExam(liveExamId: string): Promise<Exam> {
    const apiClient = await this._apiService.apiClient()
    const response = await apiClient.put<Exam>(`/virtual-exams/${liveExamId}/exam`)
    return plainToInstance(Exam, response.data)
  }

  async getExamForVirtualExam(liveExamId: string): Promise<Exam | null> {
    const apiClient = await this._apiService.apiClient()
    const response = await apiClient.get<Exam>(`/virtual-exams/${liveExamId}/exam`)
    return plainToInstance(Exam, response.data)
  }

  async sendInvitation(liveExamId: string, recipientEmailAddress: string): Promise<void> {
    const apiClient = await this._apiService.apiClient()
    await apiClient.post<Exam>(`/virtual-exams/${liveExamId}/invitations`, {
      recipientEmailAddress,
    })
  }
}

export default VirtualExamsApi

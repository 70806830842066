/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement } from "react"
import { NavigationItem } from "@medaica/common/types/ui"
import LinkList from "@medaica/common/components/link-list"
import GlobalLayout from "@medaica/common/layouts/global-layout"

const MinimalLayout = ({
  useHomeLink = true,
  footerNavigation,
  children,
}: {
  useHomeLink?: boolean
  footerNavigation?: NavigationItem[]
  children
}): ReactElement => {
  return (
    <GlobalLayout useHomeLink={useHomeLink}>
      <div className="flex flex-1 flex-col relative w-full overflow-auto">
        <main className="main-centered flex-1">{children}</main>
        {footerNavigation && (
          <div className="flex flex-shrink-0 p-2 mx-4 my-auto border-t border-gray-200 justify-center">
            <LinkList links={footerNavigation} ulClassname="flex space-x-5" aClassname="link" />
          </div>
        )}
      </div>
    </GlobalLayout>
  )
}

export default MinimalLayout

/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import { SupportedBrowser } from "./SupportedBrowser/SupportedBrowser"
import { UnsupportedBrowser } from "./UnsupportedBrowser/UnsupportedBrowser"
import React, { ReactElement } from "react"
import AVStore from "@medaica/common/views/exam/virtual-exam/stores/av-store"

export const BrowserTest = (): ReactElement => (
  <>{AVStore.isPlatformSupported() ? <SupportedBrowser /> : <UnsupportedBrowser />}</>
)

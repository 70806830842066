/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement } from "react"
import Avatar from "@mui/material/Avatar"
import MicOffIcon from "@mui/icons-material/MicOff"

const AudioMutedIcon = (): ReactElement => {
  return (
    <Avatar sx={{ width: 26, height: 26, position: "absolute", backgroundColor: "rgba(0, 0, 0, 0.4)" }}>
      <MicOffIcon fontSize="small" />
    </Avatar>
  )
}

export default AudioMutedIcon

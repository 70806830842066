/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement } from "react"
import "./styles.css"

const Terms = (): ReactElement => {
  return (
    <div className="main-centered">
      <h1 className="header h1-header">Medaica Terms of Service</h1>
      <p>Updated August 1, 2023</p>
      <h2 className="header h2-header">1.0 INTRODUCTION</h2>
      <p className="justified-text">
        Thank you for choosing to use Medaica’s web services, and/or Medaica’s Mobile Application (collectively
        “Devices”), These Terms of Service are a legal contract between You (“you/your” or “User”) and Medaica, inc. and
        govern your use of the Services and all of the text, data, information, software, graphics, photographs,
        functionality, and more (all of which We refer to as “Materials”) that We may make available to You through the
        software, whether you access the software through our website at{" "}
        <a href="https://www.medaica.com">https://www.medaica.com</a> or our mobile application. The Services are owned
        by Medaica, inc. and its subsidiaries or afﬁliates involved in providing and supporting the Services
        (collectively, “We”, “Us” or “Medaica”). The Device and the Application are all referred to as the “Services” in
        these Terms.
        <br />
      </p>
      <h3 className="header h3-header">1.1 Acceptance of Terms</h3>
      <p className="justified-text">
        Please read these terms of service carefully. By clicking “accept” or accessing the service, you agree to be
        bound by the terms of service. If you do not wish to be bound by these terms of service, you may not access or
        use the service. By accepting these terms or by using the service, you acknowledge that you have read,
        understood, and agree to be bound by the following terms and services, including the Medaica privacy policy
        (together, the “Terms”).
      </p>
      <h3 className="header h3-header">1.2 Agreement to Terms</h3>
      <p className="justified-text">
        By continuing to use the Services, you agree as follows:
        <ul className="child-overflow">
          <li className="custom-li">
            Any information that We collect through your use of the Services is subject to the Medaica Privacy Policy,
            which is part of these Terms of Service.
          </li>
          <li className="custom-li">You are at least 18 years old</li>
          <li className="custom-li">
            You understand and intend that this Agreement is a legally binding agreement and the equivalent of a signed
            written contract
          </li>
          <li className="custom-li">
            You will use the Services in a manner consistent with applicable laws and regulations and these Terms of
            Service, as they may be amended by Medaica from time to time
          </li>
          <li className="custom-li">
            You are adhering to all international, national, and/or state laws/regulations, including data protection
            laws that govern your use and transmission of personal information including health data to Medaica, and
          </li>
          <li className="custom-li">
            You understand, accept, and have received these Terms, and acknowledge and demonstrate that you can access
            these Terms at will
          </li>
        </ul>
      </p>
      <br />
      <p className="justified-text">
        If you are not eligible or do not agree to the terms, then you do not have our permission to use the service. Do
        not log in to the system and immediately delete all installed files, if any, of the accompanying services and
        materials from your computer or mobile device.
      </p>
      <br />
      <p className="justified-text">
        The web services, Medaica M1 Telehealth Stethoscope, and mobile applications (hereafter, the “application”) are
        available to users in the United States of America (USA). The application is intended is intended to be used by
        professional users in a clinical environment or by lay users in a nonclinical environment.
      </p>
      <br />
      <p className="justified-text">
        <strong>Arbitration notice:</strong> except if you opt-out and except for certain types of disputes described in
        the arbitration section below, you agree that disputes between you and Medaica will be resolved by a binding,
        individual arbitration. You waive your right to participate in a class action lawsuit or class-wide arbitration.
        You can opt out of the agreement to arbitrate by contacting{" "}
        <a href="mailto:info@medaica.com" className="mailto-link">
          info@medaica.com
        </a>{" "}
        within 30 days of accepting these terms.
      </p>
      <h2 className="header h2-header">2.0 PURPOSE OF THE SERVICES</h2>
      <p className="justified-text">
        By using our software, you agree to be bound by these Terms and any other agreements made between you and
        Medaica.
        <br />
        <br />
        For healthcare providers, our software is intended to serve the following purposes:
        <ul className="custom-ul">
          <li className="custom-li">Transmit a patient's auscultation sounds to the patient's healthcare provider.</li>
          <li className="custom-li">
            Enable healthcare providers to access the transmitted data and utilize Medaica's wireless, mobile, and
            web-based management systems and technologies.
          </li>
        </ul>
        <br />
        Please note that Medaica is not a healthcare provider, and we do not offer any healthcare services.
        Additionally, we are not a health insurance company, and we are not licensed to sell health insurance. Our role
        is solely to provide software and technology solutions to facilitate communication and data exchange between
        patients and their healthcare providers.
      </p>
      <h2 className="header h2-header">3.0 NO MEDICAL ADVICE</h2>
      <p className="justified-text">
        Our Services offer an online platform for providers and patients to communicate about non-serious or
        non-life-threatening medical conditions that do not require emergency attention. It is not intended for active
        patient monitoring that requires immediate clinical action or continuous monitoring by a healthcare provider or
        the patient.
        <br /> <br />
        Please note that the Services are not designed to replace the provider-patient relationship or address serious,
        emergency, or life-threatening medical conditions. Patients should always seek advice from their healthcare
        provider(s) for diagnosis and treatment, including information regarding appropriate drugs, therapies, or other
        treatments.
        <br /> <br />
        Medaica is not liable for any malpractice or substandard treatment provided by a healthcare provider. You use
        the Services at your own risk.
        <br /> <br />
        Any content provided or accessed through the Services, including information provided by Medaica's staff in
        response to questions, is for informational purposes only and may not cover all possible uses, directions,
        precautions, drug interactions, or adverse effects. For medical advice about a specific condition, drug, diet,
        or treatment, consult your doctor or qualified healthcare provider. Never ignore, avoid, or delay seeking
        medical advice from a qualified healthcare provider due to something posted on our Services.
        <br /> <br />
        Medaica does not endorse or recommend any specific healthcare provider, and we do not verify the credentials of
        providers. We do not control the medical advice given by providers, and we do not access or use any of that
        advice. It is your responsibility to independently verify that a healthcare provider is properly licensed and
        qualified to provide medical services.
      </p>
      <h2 className="header h2-header">4.0 FOR PROVIDERS</h2>
      <p className="justified-text">
        As a Provider using our Services, you acknowledge and agree that you are responsible for obtaining appropriate
        consent from your patients to collect their personal data or personal information through the Services, as
        required by each applicable law. The consent you obtain from patients should, at a minimum, include the
        following:
        <ul className="custom-ul">
          <li className="custom-li">A clear description of the Devices and the Services being used. </li>
          <li className="custom-li">
            A detailed explanation of the information you will collect from patients through the Services.{" "}
          </li>
          <li className="custom-li">
            Notification that Medaica will have access to the patient's information for the purpose of providing the
            Services.{" "}
          </li>
          <li className="custom-li">
            Disclosure that the patient's information may be transferred outside of their home country for processing
            purposes.
          </li>
          <li className="custom-li">
            A weblink or physical copy to Medaica's Privacy Notice, which outlines how patient data will be handled and
            protected.
          </li>
        </ul>
        <br />
        By obtaining proper consent, you ensure that patients are fully aware of how their personal data will be
        utilized and shared when using our Services. It is crucial to comply with the relevant legal requirements and
        maintain transparency in the data collection process to protect the privacy and rights of your patients.
      </p>
      <h2 className="header h2-header">5.0 REGISTRATION; TERM OF REGISTRATION</h2>
      <h3 className="header h3-header">5.1. Registration through the Application</h3>
      <p className="justified-text">
        To use the Services provided by Medaica, you must be a registered user. You can register either through the
        Medaica Mobile Application or the Medaica website. By providing the required information during registration,
        you can access and use the Services to view certain data related to you, as made available by Medaica.
        <br />
        By registering for an account, you agree to the following:
        <ul className="custom-ul">
          <li className="custom-li">Your registration data must be accurate, current, and complete.</li>
          <li className="custom-li">You will update your registration data as necessary to ensure its accuracy.</li>
          <li className="custom-li">You meet all eligibility requirements specified below.</li>
          <li className="custom-li">
            You have the authorization to create an account, either for yourself or on behalf of another person.
          </li>
        </ul>
        <br />
        Do not use the Services where prohibited by law. Please be aware that using the Services may involve
        transmitting significant amounts of data. You are responsible for all data charges that may be applied by your
        wireless carrier or internet service provider, or that may arise from your use of the Services.
      </p>
      <h3 className="header h3-header">5.2. Modification and Termination of Services</h3>
      <p className="justified-text">
        Medaica reserves the right to terminate user accounts for reasons it deems appropriate, including but not
        limited to situations where it believes that a user's conduct or use of the Services violates applicable laws or
        is detrimental to the interests of Medaica or other users. Additionally, Medaica may limit, modify, suspend, or
        terminate the Services as a whole and may suspend or terminate your use of the Services if you fail to comply
        with the terms of this Agreement or Medaica's internal policies. Such suspension or termination may result in
        the deletion of your information, files, and other content previously available to you.
        <br />
        <br />
        Furthermore, Medaica retains the right to modify, discontinue, or terminate the Services or modify these Terms
        at any time without prior notice to users. If there are any changes to these Terms, Medaica will post the
        updated Terms on its website or Medaica Mobile Application. By continuing to use the Services after being
        informed of the changes, you agree to be bound by the updated Terms. If you find the changes unacceptable, you
        should cease using the Services immediately.
        <br />
        <br />
        Certain features of the Services may be subject to different terms and conditions. If you choose to use those
        features, you agree to be bound by the additional terms of use related to those specific features. In the event
        of any conflict between these Terms and the additional terms governing specific features, the additional terms
        will take precedence for those features.
      </p>
      <h3 className="header h3-header">5.3 Eligibility</h3>
      <p className="justified-text">
        To use the Service, you must be at least 18 years of age. By agreeing to these Terms, you confirm and guarantee
        the following:
        <ol className="custom-ol">
          <li className="custom-li">You are at least 18 years old</li>
          <li className="custom-li">You have not been previously suspended or removed from the Service, and</li>
          <li className="custom-li">
            Your registration and use of the Service comply with all relevant laws and regulations in your local
            jurisdiction. If you are using the Service on behalf of an entity, organization, or company, you declare and
            assure that you have the authority to bind that organization to these Terms, and you agree to be bound by
            these Terms on behalf of that organization.
          </li>
        </ol>
      </p>
      <h3 className="header h3-header">5.4 Notices </h3>
      <p className="justified-text">
        All notices from Medaica intended for receipt by You shall be deemed delivered and effective when sent to the
        email address provided by You during the registration process or as otherwise provided in these Terms or our (in
        either case, “Notice”). If You change your email address, you must update Your account information. All notices
        from Medaica intended for receipt by you will be considered delivered and effective when sent to the email
        address provided by you during the registration process or as otherwise indicated in these Terms or our Privacy
        Policy ("Notice"). If you decide to change your email address, you must promptly update your account information
        to ensure that you receive important communications from Medaica.
      </p>
      <h3 className="header h3-header">5.5 Keeping Your Information Secure</h3>
      <p className="justified-text">
        To access and use the Services provided by Medaica, you must provide a valid and functioning email address.
        During the registration process, you will create a password for your account, and this, along with any assigned
        codes, will be considered your "User Information." By creating an account, you certify that the information you
        provide is accurate, complete, and up-to-date. You must adhere to the following guidelines:
        <ol className="custom-ol">
          <li className="custom-li">Keep your User Information confidential.</li>
          <li className="custom-li">Do not allow anyone else to use your User Information to access the Services.</li>
          <li className="custom-li">Do not permit anyone else to use your Device(s).</li>
        </ol>
        <br />
        To safeguard your confidential healthcare information, it is recommended to enable touch ID, fingerprint ID,
        Face ID, and/or a passcode on your smartphone.
        <br />
        <br />
        If you fail to comply with the above guidelines, resulting in damages to Medaica, you will be held accountable
        for those damages. In case of any unauthorized use of your User Information or any breach of security, you are
        obligated to notify Medaica promptly in writing via email.
        <br />
        <br />
        All communications made through the Services may be monitored, captured, recorded, and transmitted to government
        authorities if deemed necessary by Medaica, and such actions may occur without prior notice to you.
        <br />
        <br />
        Certain features of the Services may be subject to distinct terms and conditions. If you use these features, you
        agree to be bound by the additional terms applicable to them. In the event of any inconsistency between these
        Terms and the additional terms governing specific features, the additional terms shall govern for those
        features.
      </p>
      <h2 className="header h2-header">6.0 YOUR USE OF THE SERVICES</h2>
      <h3 className="header h3-header">6.1 General</h3>
      <p className="justified-text">
        You are required to use the Application and Website strictly in accordance with the following:
        <ol className="custom-ol">
          <li className="custom-li">These Terms</li>
          <li className="custom-li">
            Any additional instructions, guidelines, or policies issued by Medaica, which may be posted within the
            Medaica Mobile Application or on the website
          </li>
          <li className="custom-li">All applicable laws, rules, and regulations (referred to as "Laws")</li>
        </ol>
      </p>
      <h3 className="header h3-header">6.2 Use Prohibitions</h3>
      <p className="justified-text">
        You agree to use the Website and Application solely for their intended purpose and in compliance with all
        applicable laws, including privacy, data protection, and intellectual property laws. While using the Services,
        you must not:
        <ol className="custom-ol">
          <li className="custom-li">
            Post, upload, publish, submit, or transmit any content that you do not have the right to make available
          </li>
          <li className="custom-li">
            Use, display, mirror, frame, or exploit the Services, Medaica's name, trademarks, logos, or proprietary
            information without Medaica's express written consent
          </li>
          <li className="custom-li">
            Attempt to access non-public areas of the Services or Medaica's computer systems or tamper with technical
            delivery systems
          </li>
          <li className="custom-li">
            Attempt to probe, scan, or test the vulnerability of any Medaica system or network or breach any security
            measures
          </li>
          <li className="custom-li">
            Circumvent or bypass any technological measures implemented by Medaica or any third party to protect the
            Services
          </li>
          <li className="custom-li">
            Use automated tools or software, other than those provided by Medaica or generally available third-party web
            browsers, to access or search the Services or download materials
          </li>
          <li className="custom-li">
            Send unsolicited advertising, promotional materials, spam, chain letters, or any form of solicitation
          </li>
          <li className="custom-li">
            Use Medaica's trademarks, logos, URLs, or product names without Medaica's written consent
          </li>
          <li className="custom-li">
            Use the Services or materials for commercial purposes, research purposes, or the benefit of any third party,
            or in any manner not permitted by these Terms
          </li>
          <li className="custom-li">
            Forge or alter any source-identifying information, such as email headers, in any communication through the
            Services
          </li>
          <li className="custom-li">
            Attempt to reverse engineer, decompile, or disassemble any software used to provide the Services
          </li>
          <li className="custom-li">
            Interfere with the access of any user, host, or network, including sending viruses, overloading, flooding,
            spamming, or mail-bombing the Services
          </li>
          <li className="custom-li">
            Collect or store personally identifiable information of others (except your patients, if you are a provider)
            from the Services
          </li>
          <li className="custom-li">Impersonate or misrepresent your affiliation with any person or entity</li>
          <li className="custom-li">Violate any applicable law or regulation</li>
          <li className="custom-li">Encourage or enable others to engage in any of the above activities</li>
        </ol>
        <br />
        We take violations of these Terms seriously, and we may investigate and prosecute any such violations to the
        fullest extent of the law. We may also cooperate with law enforcement authorities in prosecuting users who
        violate these Terms.
        <br />
        <br />
        While we do not have an obligation to monitor your access to the Services, we may do so for the purpose of
        operating the Services and ensuring your compliance with these Terms and the Privacy Policy. Additionally, we
        may monitor your access to comply with applicable laws or court orders or requirements from administrative
        agencies or other governmental bodies.
        <br />
        <br />
        We reserve the right, without prior notice, to remove or disable access to any materials on the Services that we
        determine to be in violation of these Terms, the Privacy Policy, or otherwise harmful to the Services.
      </p>
      <h3 className="header h3-header">6.3 Computer Equipment; Browser Access and Internet Services</h3>
      <p className="justified-text">
        You are solely responsible for obtaining, installing, maintaining, and operating all the necessary software,
        hardware, or other equipment (collectively, "Systems") required to access and use the Services, except for the
        Devices provided by us.
        <br />
        <br />
        This includes, but is not limited to, obtaining internet services, using up-to-date web browsers, and
        implementing the best commercially available encryption, antivirus, anti-spyware, and internet security
        software. It is essential to be aware that using open networks like the Internet carries inherent security and
        access availability risks, and you explicitly assume those risks.
        <br />
        <br />
        You are responsible for ensuring the data security of the Systems used to access the Services and for
        transmitting and receiving information through those Systems. Any errors or problems arising from the
        malfunction or failure of the Internet or your System are your responsibility, and we are not liable for such
        issues.
      </p>
      <h2 className="header h2-header">7.0 INTELLECTUAL PROPERTY RIGHTS; RESTRICTIONS ON USE</h2>
      <h3 className="header h3-header">7.1 Ownership of the Services and Related Data</h3>
      <p className="justified-text">
        The Services and all materials available on the Services are either owned or licensed by Medaica. We grant you a
        nonexclusive, limited, and revocable right to access and use the Services for your personal purposes only, as
        long as you comply with these Terms. You agree not to use the Services for any other purposes, including
        commercial purposes or research purposes, without our prior written consent. This means you cannot co-brand,
        frame, link, or resell any portion of the Services without our permission.
        <br />
        <br />
        You may access, download, or print the materials available on the Services for non-commercial purposes and
        within the scope allowable by these Terms. Any other use of the materials requires our express written
        permission. Unauthorized use of words or images from the Services may violate copyright laws, trademark laws,
        privacy and publicity laws, and other civil and criminal statutes.
        <br />
        <br />
        You are not allowed to use Medaica's name, trademarks, service marks, or logos, or those of third parties
        appearing on the Services in any advertising or publicity or to indicate Medaica's or such third party's
        sponsorship of or affiliation with any product or service without our express written permission or that of the
        respective third party.
        <br />
        <br />
        Regarding your personal information and any other content you post on or through the Services, you retain
        ownership. However, to provide you with the Services, you grant Medaica a perpetual, non-exclusive, fully paid,
        royalty-free, transferable, sublicensable, worldwide license to use your content solely for the purpose of
        providing the Services. Additionally, you agree to allow Medaica to de-identify and anonymize your content,
        including personal health information, and use or disclose such de-identified information for the purposes of
        Medaica's management, operations, or administration of its business and the Services, as described in the
        Privacy Policy.
      </p>
      <h3 className="header h3-header">7.2 The Medaica Findings and Reports</h3>
      <p className="justified-text">
        You may use the Application to generate reports of findings using the heart, lung, or body sound data. Any
        reports provided by Medaica do not suggest a diagnosis. The interpreted report is intended as information for
        You and to be used as a tool to provide proper diagnosis and treatment taking into account the patient’s
        complete medical history. Analysis and diagnosis based on the data can only be accomplished by a physician.
      </p>
      <h3 className="header h3-header">7.3 Regional Telemedicine Restrictions</h3>
      <p className="justified-text">
        Please note that depending on your location's telemedicine laws, the Application's use for telehealth purposes
        may be restricted. It is your responsibility to ensure that the Application complies with your local
        telemedicine laws since you use a mobile device to collect data, including patient data. This section
        specifically pertains to Medaica telemedicine purposes and does not cover Remote Patient Monitoring or other
        Services functions that are not subject to current telemedicine laws and regulations.
        <br />
        <br />
        You have the option to request a data recording from the Medaica device at any time. It's essential to consider
        that your heart, lungs, and body sound recordings can be influenced by various factors related to your health
        and activities. Medaica cannot guarantee the accuracy or clinical significance of the data interpretation.
        Please be aware that you provide explicit consent for sharing data with third-party electronic health record
        systems (EHRs) in accordance with the terms outlined in Medaica's Privacy Policy
      </p>
      <h3 className="header h3-header">7.4 Feedback</h3>
      <p className="justified-text">
        We welcome and encourage you to provide us with feedback, comments, and suggestions for improvements to the
        Services, Devices or materials (“Feedback”). You may submit Feedback by emailing us at{" "}
        <a href="mailto:info@medaica.com" className="mailto-link">
          info@medaica.com
        </a>
        . If you submit any Feedback to us, we will own all intellectual property rights in such Feedback and may use
        such Feedback for any lawful purpose.
      </p>
      <h2 className="header h2-header">8.0 PRIVACY</h2>
      <h3 className="header h3-header">8.1 Privacy Notice</h3>
      <p className="justified-text">
        Medaica respects the information You provide to us. Please see our Privacy Policy for an explanation of how we
        collect and handle your Personal Information. By clicking on the “I Agree” or “I Accept” button, accessing or
        using the Services, or downloading or uploading any content from or through the Services, you acknowledge and
        agree to the provisions of the Privacy Policy and affirm that the Privacy Policy is a part of these Terms.
      </p>
      <h2 className="header h2-header">9.0 REPRESENTATIONS, COVENANTS AND WARRANTIES</h2>
      <p className="justified-text">
        You acknowledge and agree that your use of the Services is at your own risk, and the Services are provided on an
        "as is" and "as available" basis. Medaica makes no representations, covenants, or warranties, and offers no
        other conditions, express or implied, regarding any matter, including the merchantability, suitability, fitness
        for a particular purpose, non-infringement, or results derived from using the Application, Website, or any
        related data service, software, hardware, deliverable, work product, or materials.
        <br />
        <br />
        Medaica does not guarantee the accuracy, completeness, or truthfulness of the information available on or
        transmitted by the Application or Website. You understand that Medaica is not responsible for any healthcare or
        related decisions made by you or your healthcare professional based on data collected, transmitted, or displayed
        by the Application or Website, regardless of its accuracy.
        <br />
        <br />
        Furthermore, Medaica does not represent, covenant, or warrant that access to or services provided by the
        Application or Website will be uninterrupted or error-free. You acknowledge and understand that there are
        inherent risks in transmitting and storing information on the internet, and Medaica is not liable for any losses
        of your data, confidentiality, or privacy in connection therewith.
      </p>
      <h2 className="header h2-header">10.0 LIABILITY</h2>
      <h3 className="header h3-header">10.1 Limitation of liability</h3>
      <p className="justified-text">
        To the maximum extent permitted by applicable law, neither Medaica nor any other person or entity involved in
        creating, producing, or delivering the Services or materials shall be liable to you for any direct, indirect,
        special, incidental, punitive, or consequential damages arising out of or resulting from your use of the
        Services. This includes any damages based upon any breach of express or implied warranty, breach of contract
        (including these Terms and any additional instructions, guidelines, or policies issued by Medaica), negligence,
        tort, or any other legal theory (collectively, the "Excluded Damages"). The Excluded Damages also include,
        without limitation, loss of savings or revenue, loss of profit, loss of use, loss of life or health, claims of
        third parties, and any cost of substitute equipment or services.
        <br />
        <br />
        If you are not satisfied with the Services, the materials, or the Terms, your only remedy is to discontinue
        using them. However, because some states do not allow the exclusion or limitation of liability for consequential
        or incidental damages, some of these limitations may not apply to you. Any claim arising from the usage of the
        Services or materials must be brought within one (1) year of the event from which the claim arose.
        <br />
        <br />
      </p>
      <h3 className="header h3-header">10.2 Remedies</h3>
      <p className="justified-text">
        At its option, Medaica may seek all remedies available to it under law and in equity, including seeking
        injunctive relief in the form of specific performance to enforce these Terms, as well as actions for damages.
        This includes enforcing any additional instructions, guidelines, or policies issued by Medaica, including those
        posted in the Application or on the Website.
      </p>
      <h2 className="header h2-header">11.0 INDEMNITIES</h2>
      <p className="justified-text">
        You agree to indemnify, defend, and hold harmless Medaica, its clients, and its suppliers, as well as their
        respective affiliates, employees, officers, directors, agents, servants, and representatives from any liability,
        loss, claim, suit, damage, and expense (including reasonable attorneys' fees and expenses) arising out of or in
        any way connected with your access to or use of the Services or Medaica's materials, your violation of these
        Terms, or any negligent or wrongful conduct by you or any other person accessing the Services or Medaica
        materials through your account.
      </p>
      <h2 className="header h2-header">12.0 DISPUTE RESOLUTION</h2>
      <h3 className="header h3-header">12.1 Disputes</h3>
      <p className="justified-text">
        If you or Medaica have any dispute regarding these Terms, including but not limited to any alleged breach of
        these Terms, the parties will submit the dispute to binding arbitration in the State of New York before a single
        arbitrator, following the rules and procedures of the American Arbitration Association. The arbitrator may, at
        their discretion, award legal fees, arbitrator's fees, costs, and other expenses incurred by the party that does
        not prevail in the dispute. Any arbitration will be kept strictly confidential, and neither party will disclose
        the existence of the dispute or any aspect of it to any person, except as necessary to carry out the
        arbitration.
      </p>
      <h3 className="header h3-header">12.2 Governing Law</h3>
      <p className="justified-text">
        These Terms are governed by the laws of the State of New York, without regard to conflict of law principles, to
        the fullest extent permitted by applicable law.
      </p>
      <h2 className="header h2-header">13.0 GENERAL</h2>
      <h3 className="header h3-header">13.1 Governing LawGeneral Legal Provisions</h3>
      <p className="justified-text">
        If any provision of this Agreement is found to be invalid, illegal, or unenforceable, the remaining provisions
        of the Agreement will remain in full force, provided that the essential terms and conditions of this Agreement
        remain valid, binding, and enforceable, and the economic and legal substance of the transactions contemplated by
        the Agreement are materially preserved.
        <br />
        <br />
        You agree to comply with the United States export control laws, which regulate the export and re-export of
        technology originating in the United States, including the electronic transmission of information and software
        to foreign countries and certain foreign nationals.
        <br />
        <br />
        This Agreement does not create an agency, partnership, or joint venture between the parties. Failure to enforce
        any provision of this Agreement will not constitute a waiver of that provision.
      </p>
      <h3 className="header h3-header">13.2 Assignment</h3>
      <p className="justified-text">
        You are not allowed to assign these Terms (or any rights, benefits, or obligations hereunder) by operation of
        law or otherwise without the prior written consent of Medaica, which may be withheld at Medaica's sole
        discretion. Any attempted assignment by you that does not comply with the terms of this Section will be null and
        void. Medaica, on the other hand, may assign these Terms and Conditions, in whole or in part, to any third party
        at its sole discretion.
      </p>
      <h3 className="header h3-header">13.3 Contact</h3>
      <p className="justified-text">
        The Service is offered by Medaica, inc. You may contact us by emailing us at{" "}
        <a href="mailto:info@medaica.com" className="mailto-link">
          info@medaica.com
        </a>
        .
        <br />
        <blockquote>
          <br />
          Medaica, inc.
          <br />
          170 S. Green Valley Parkway, Suite 300
          <br />
          Henderson NV 89012
          <br />
          USA
          <br />
          +1 917 480 9995
        </blockquote>
        <br />
      </p>
    </div>
  )
}

export default Terms

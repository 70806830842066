/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { Fragment, ReactElement } from "react"
import { Menu, Transition } from "@headlessui/react"
import { Link, Outlet } from "react-router-dom"
import { classNames } from "@medaica/common/services/util"
import Sidebar from "./sidebar"
import { NavigationItem, PrimaryNavigationItem } from "@medaica/common/types/ui"
import LinkList from "@medaica/common/components/link-list"
import UserCircleIcon from "@medaica/common/components/icons/user-circle-icon"
import GlobalLayout from "@medaica/common/layouts/global-layout"

const DashboardLayout = ({
  primaryNavigation,
  userNavigation,
  footerNavigation,
}: {
  primaryNavigation: PrimaryNavigationItem[]
  userNavigation: NavigationItem[]
  footerNavigation: NavigationItem[]
}): ReactElement => {
  return (
    <GlobalLayout
      header={
        <div className="ml-4 flex items-center md:ml-6">
          {/*<button*/}
          {/*  className="bg-white p-1 rounded-full text-gray-500 hover:text-gray-600*/}
          {/*focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"*/}
          {/*>*/}
          {/*  <span className="sr-only">View notifications</span>*/}
          {/*  <BellIcon className="h-6 w-6" aria-hidden="true" />*/}
          {/*</button>*/}

          {/* Profile dropdown */}
          <Menu as="div" className="ml-3 relative">
            {({ open }) => (
              <>
                <div>
                  <Menu.Button
                    className="max-w-xs text-gray-500 hover:text-gray-600 bg-white flex
                  items-center rounded-full focus:outline-none"
                  >
                    <UserCircleIcon className="h-7 w-7 stroke-icon" />
                    <span className="sr-only">View profile options</span>
                  </Menu.Button>
                </div>
                <Transition
                  show={open}
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items
                    static
                    className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1
                    bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-50"
                  >
                    {userNavigation.map((item) => (
                      <Menu.Item key={item.name}>
                        {({ active }) =>
                          item.href ? (
                            <Link
                              to={item.href}
                              className={classNames(active ? "bg-gray-100" : "", "block px-4 py-2 text-gray-700")}
                            >
                              {item.name}
                            </Link>
                          ) : (
                            <span>{item.name}</span>
                          )
                        }
                      </Menu.Item>
                    ))}
                  </Menu.Items>
                </Transition>
              </>
            )}
          </Menu>
        </div>
      }
    >
      <div className="overflow-hidden flex flex-grow">
        {/* Sidebar  bg-gradient-to-b from-primary-700 to-secondary */}
        <div className="flex border-r bg-gray-50 px-1">
          <div className="flex flex-col w-72">
            {/* Sidebar component, swap this element with another sidebar if you like */}
            <Sidebar navigation={primaryNavigation} />
          </div>
        </div>

        {/* Main content */}
        <div className="flex flex-col w-full overflow-auto">
          <div className="flex-1 flex">
            <main className="flex-1 relative overflow-y-auto focus:outline-none">
              <Outlet />
            </main>
          </div>
          <div className="p-3 mx-4 my-auto border-t border-gray-200 flex justify-center">
            <LinkList links={footerNavigation} ulClassname="flex space-x-5" aClassname="text-gray-500" />
          </div>
        </div>
      </div>
    </GlobalLayout>
  )
}

export default DashboardLayout

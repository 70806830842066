/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement, useId, useMemo } from "react"
import { observer } from "mobx-react-lite"
import MediaDeviceStore from "@medaica/common/services/media-device-store"
import PreferencesStore from "@medaica/common/views/exam/virtual-exam/stores/preferences-store"
import M1DeviceInfoUtil from "@medaica/common/services/m1-device-info-util"

const SettingsPanel = observer(
  ({
    mediaDeviceStore,
    preferencesStore,
    disableAudioSources = false,
  }: {
    mediaDeviceStore: MediaDeviceStore
    preferencesStore: PreferencesStore
    disableAudioSources?: boolean
  }): ReactElement => {
    const m1DeviceInfoUtil = useMemo(() => new M1DeviceInfoUtil(), [])
    const videoSourcesId = useId()
    const audioSourcesId = useId()

    const handleVideoInputSelectChange = (event) => {
      const videoDeviceId = event.target.value as string
      if (videoDeviceId) {
        void mediaDeviceStore.connectToVideoDevice(videoDeviceId)
        preferencesStore.setVideoDeviceId(videoDeviceId)
      }
    }

    const handleAudioInputSelectChange = (event) => {
      const audioDeviceId = event.target.value as string
      if (audioDeviceId) {
        void mediaDeviceStore.connectToAudioDevice(audioDeviceId)
        preferencesStore.setAudioDeviceId(audioDeviceId)
      }
    }

    return (
      <div style={{ minWidth: "200px" }}>
        <div className="bg-white p-5 text-left">
          <div className="mb-3">
            <h3 className="panel-title">Settings</h3>
            <div>
              <label htmlFor={videoSourcesId} className="">
                Video source
              </label>
              <select
                value={mediaDeviceStore.videoDevice?.id}
                onChange={handleVideoInputSelectChange}
                id={videoSourcesId}
                className="mt-1 block input"
              >
                {mediaDeviceStore.availableVideoDevices.map((device) => (
                  <option key={device.deviceId} value={device.deviceId}>
                    {device.label}
                  </option>
                ))}
              </select>
            </div>
            <div className="mt-6">
              <label htmlFor={audioSourcesId}>Audio source</label>
              <select
                value={mediaDeviceStore.audioDevice?.id}
                onChange={handleAudioInputSelectChange}
                id={audioSourcesId}
                className="mt-1 block input"
                disabled={disableAudioSources}
                aria-label="Audio sources"
              >
                {mediaDeviceStore.availableAudioDevices
                  .filter((deviceInfo) => !m1DeviceInfoUtil.isM1DeviceInfo(deviceInfo))
                  .map((deviceInfo) => (
                    <option key={deviceInfo.deviceId} value={deviceInfo.deviceId}>
                      {deviceInfo.label}
                    </option>
                  ))}
              </select>
            </div>
          </div>
        </div>
      </div>
    )
  }
)

export default SettingsPanel

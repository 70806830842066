/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import MedaicaApiService, { JoinedLoads, pagination, QuerySpec } from "./"
import { Auscultation, AuscultationPoint, Exam, Page } from "@medaica/common/types"
import { plainToInstance } from "class-transformer"
import { ExamType } from "@medaica/common/const"
import { CancelTokenSource, AxiosRequestConfig } from "axios"
import { NoiseQualityRecord } from "../../components/audio-player/noise-quality-recorder"

class ExamsApi {
  private _apiService: MedaicaApiService

  constructor(apiService: MedaicaApiService) {
    this._apiService = apiService
  }

  async createExam({
    healthcareProviderId,
    patientProfileId,
    patientId,
    examType,
  }: {
    healthcareProviderId?: string
    patientProfileId?: string
    patientId?: string
    examType: ExamType
  }): Promise<Exam> {
    const apiClient = await this._apiService.apiClient()
    const response = await apiClient.post<Exam>("/exams", {
      type: examType,
      healthcareProviderId,
      patientProfileId,
      patientId,
    })
    return plainToInstance(Exam, response.data)
  }

  async addAuscultationToExam({
    examId,
    auscultationPoint,
    recording,
    noiseQualityRecord,
    deviceLabel,
    uploadProgressCallback,
    virtualExamInfo,
    cancelToken,
  }: {
    examId: string
    auscultationPoint: AuscultationPoint
    patientProfileId?: string
    deviceLabel: string
    recording: Blob
    noiseQualityRecord: NoiseQualityRecord
    uploadProgressCallback?: (number) => void
    virtualExamInfo?: {
      virtualExamId: string
      auscultationRequestId: string
    }
    cancelToken?: CancelTokenSource
  }): Promise<Auscultation> {
    const apiClient = await this._apiService.apiClient()

    const path = this._apiService.withQuery(`/exams/${examId}/auscultations`, {
      ...(virtualExamInfo && { ...virtualExamInfo }),
    })

    const formData = new FormData()
    formData.append(
      "auscultation",
      JSON.stringify({
        auscultationPoint: auscultationPoint.label,
        deviceLabel,
        noiseQualityRecord,
      })
    )
    formData.append("recordingData", recording)

    const config = {
      ...(cancelToken && { cancelToken: cancelToken.token }),
      ...(uploadProgressCallback && {
        onUploadProgress: (progressEvent: ProgressEvent) => {
          const percentCompleted = Math.floor((progressEvent.loaded / progressEvent.total) * 100)
          uploadProgressCallback(percentCompleted)
        },
      }),
      headers: {
        "Content-Type": "multipart/form-data",
      },
    } as AxiosRequestConfig

    const response = await apiClient.post<Auscultation>(path, formData, config)

    return plainToInstance(Auscultation, response.data)
  }

  async getExamById(examId: string, joinedLoads?: JoinedLoads): Promise<Exam> {
    const apiClient = await this._apiService.apiClient(true)
    const response = await apiClient.get(
      this._apiService.withQuery(`/exams/${examId}`, {
        q: JSON.stringify({ joinedLoads }),
      })
    )
    return plainToInstance(Exam, response.data)
  }

  private mapExamsPath = (isCouncil: boolean, isOrganization: boolean): string => {
    if (isCouncil) {
      return "/search/council-exams"
    } else if (isOrganization) {
      return "/search/organization-exams"
    } else {
      return "/search/exams"
    }
  }

  async getExams(query: QuerySpec, isCouncilExams = false, isOrganizationExams = false): Promise<Page<Exam>> {
    const path = this.mapExamsPath(isCouncilExams, isOrganizationExams)
    const apiClient = await this._apiService.apiClient()
    const response = await apiClient.get<Exam[]>(
      this._apiService.withQuery(path, {
        q: JSON.stringify(query),
      })
    )
    const exams = plainToInstance(Exam, response.data)
    return {
      items: exams,
      ...pagination(response),
    }
  }

  async updateReviewed(examId: string, reviewed: boolean): Promise<void> {
    const apiClient = await this._apiService.apiClient()
    await apiClient.put(`/exams/${examId}/reviewed`, {
      value: reviewed,
    })
  }

  async updateNotes(examId: string, notesValue: string): Promise<void> {
    const apiClient = await this._apiService.apiClient()
    await apiClient.put(`/exams/${examId}/notes`, {
      value: notesValue,
    })
  }

  async updateEnhancedNotes(examId: string, enhancedNotesValue: string[]): Promise<void> {
    const apiClient = await this._apiService.apiClient()
    await apiClient.put(`/exams/${examId}/enhanced-notes`, {
      value: enhancedNotesValue,
    })
  }

  async shareExam(emailAddress: string, examId: string): Promise<void> {
    const apiClient = await this._apiService.apiClient()
    await apiClient.post(`/exams/${examId}/shares`, { emailAddress })
  }
}

export default ExamsApi

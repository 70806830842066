/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement, useCallback, useEffect, useRef, useState } from "react"
import { MediaPermissionsError, MediaPermissionsErrorType, requestMediaPermissions } from "mic-check"
import Bowser from "bowser"
import Switch, { Case } from "react-switch-case"
import { logError } from "@medaica/common/services/util"
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material"
import LoadingButton from "@mui/lab/LoadingButton"
import logger from "@medaica/common/services/logging"
import AVStore from "@medaica/common/views/exam/virtual-exam/stores/av-store"
import URI from "urijs"
import { ExamType } from "@medaica/common/const"

const browser = Bowser.getParser(window.navigator.userAgent)
const origin = new URI(document.location.href).origin()

enum DialogType {
  platformUnsupported = 1,
  explanation,
  systemDenied,
  userDenied,
  trackError,
  unknownError,
}

enum FailureReason {
  platformUnsupported = 1,
  systemDenied,
  userDenied,
  trackError,
  unknownError,
}

type CheckMediaPermissions = () => void

const TryAgainButton = ({ checkMediaPermissions }: { checkMediaPermissions: CheckMediaPermissions }) => {
  return (
    <LoadingButton
      onClick={() => {
        if (browser.getBrowserName() === "Safari") {
          // If on Safari, rechecking permissions results in glitches so just refresh the page
          window.location.reload()
        } else {
          checkMediaPermissions()
        }
      }}
    >
      Retry
    </LoadingButton>
  )
}

const Explanation = ({ onClose, examType }: { onClose: () => void; examType: ExamType }) => {
  const device = examType === ExamType.Virtual ? "camera and microphone" : "microphone"
  return (
    <>
      <DialogTitle>Allow {device} access</DialogTitle>
      <DialogContent>
        The exam needs access to your {device}. Please allow {origin} to use your {device}.
      </DialogContent>
      <DialogActions>
        <LoadingButton onClick={onClose}>Dismiss</LoadingButton>
      </DialogActions>
    </>
  )
}

const SystemDenied = ({
  onCancel,
  checkMediaPermissions,
  examType,
}: {
  onCancel: (failureReason: FailureReason) => void
  checkMediaPermissions: () => void
  examType: ExamType
}) => {
  // todo test windows
  const settingsDataByOS = {
    macOS: {
      name: "System Preferences",
      link: "x-apple.systempreferences:com.apple.preference.security?Privacy_Camera",
    },
    windows: {
      name: "Settings",
      link: "ms-settings:privacy-webcam",
    },
  }

  const device = examType === ExamType.Virtual ? "camera or microphone" : "microphone"

  return (
    <>
      <DialogTitle>{`Can't use your ${device}`}</DialogTitle>
      <DialogContent>
        {`Your browser might not have access to your ${device}. To fix this problem, open `}
        {settingsDataByOS[browser.getOSName()] ? (
          <a className="link" href={settingsDataByOS[browser.getOSName()].link} target="_blank" rel="noreferrer">
            {settingsDataByOS[browser.getOSName()].name}
          </a>
        ) : (
          "Settings"
        )}
        .
      </DialogContent>
      <DialogActions>
        <LoadingButton onClick={() => onCancel(FailureReason.systemDenied)}>Close</LoadingButton>
        <TryAgainButton checkMediaPermissions={checkMediaPermissions} />
      </DialogActions>
    </>
  )
}

const UserDenied = ({
  onCancel,
  examType,
}: {
  onCancel: (failureReason: FailureReason) => void
  examType: ExamType
}) => {
  const title = examType === ExamType.Virtual ? "Camera and microphone are blocked" : "Microphone is blocked"

  return (
    <>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {examType === ExamType.Virtual ? (
          <>
            Access to your camera or microphone are blocked. Click the camera blocked icon in your browser's address bar
            and allow {origin} to access your camera and microphone.
          </>
        ) : (
          <>
            Access to your microphone is blocked. Click the microphone blocked icon in your browser's address bar and
            allow {origin} to access your microphone.
          </>
        )}
      </DialogContent>
      <DialogActions>
        <LoadingButton onClick={() => onCancel(FailureReason.userDenied)}>Close</LoadingButton>
      </DialogActions>
    </>
  )
}

const TrackError = ({
  onCancel,
  checkMediaPermissions,
  examType,
}: {
  onCancel: (failureReason: FailureReason) => void
  checkMediaPermissions: () => void
  examType: ExamType
}) => {
  return (
    <>
      <DialogTitle>Can't start your microphone{examType === ExamType.Virtual && " or camera"}</DialogTitle>
      <DialogContent>
        Another application (Zoom, Webex) or browser tab (Google Meet, Messenger Video) might already be using your
        webcam. Please quit other applications using your webcam before proceeding.
      </DialogContent>
      <DialogActions>
        <LoadingButton onClick={() => onCancel(FailureReason.trackError)}>Close</LoadingButton>
        <TryAgainButton checkMediaPermissions={checkMediaPermissions} />
      </DialogActions>
    </>
  )
}

const Generic = ({
  onCancel,
  checkMediaPermissions,
  examType,
}: {
  onCancel: (failureReason: FailureReason) => void
  checkMediaPermissions: () => void
  examType: ExamType
}) => {
  const device = examType === ExamType.Virtual ? "camera or microphone" : "microphone"

  return (
    <>
      <DialogTitle>Can't access your {device}</DialogTitle>
      <DialogContent>
        {examType === ExamType.Virtual ? (
          <>
            Access to your camera and microphone are blocked. Please ensure that your camera and microphone are enabled
            and that {origin} has been granted permission to access them.
          </>
        ) : (
          <>
            Access to your microphone is blocked. Please ensure that your microphone is enabled and that {origin} has
            been granted permission to access it.
          </>
        )}
      </DialogContent>
      <DialogActions>
        <LoadingButton onClick={() => onCancel(FailureReason.unknownError)}>Close</LoadingButton>
        <TryAgainButton checkMediaPermissions={checkMediaPermissions} />
      </DialogActions>
    </>
  )
}

const PlatformUnsupported = ({ onClose }: { onClose: () => void }) => {
  return (
    <>
      <DialogTitle>Your platform is unsupported</DialogTitle>
      <DialogContent>A virtual exam requires Google Chrome or Microsoft Edge on a desktop computer.</DialogContent>
      <DialogActions>
        <LoadingButton onClick={onClose}>Dismiss</LoadingButton>
      </DialogActions>
    </>
  )
}

const CompatibilityCheck = ({
  onComplete,
  examType,
}: {
  onComplete: (passed: boolean, failureReason?: FailureReason) => void
  examType: ExamType
}): ReactElement => {
  const [dialogType, setDialogType] = useState<DialogType | null>(null)

  const dialogTypeRef = useRef(dialogType)
  dialogTypeRef.current = dialogType

  const checkForExplanationDialog = useCallback(async (checkMediaPermissions: () => Promise<void>) => {
    const microphonePermission = await navigator.permissions.query({ name: "microphone" as PermissionName })
    const cameraPermission = await navigator.permissions.query({ name: "camera" as PermissionName })

    const handlePermissionChanged = (permissionStatus: PermissionStatus): void => {
      if (permissionStatus.state === "granted") {
        if (dialogTypeRef.current === DialogType.explanation) {
          setDialogType(null)
        }
        if (dialogTypeRef.current === DialogType.userDenied) {
          setDialogType(null)
          void checkMediaPermissions()
        }
      }
    }

    cameraPermission.onchange = () => handlePermissionChanged(cameraPermission)
    microphonePermission.onchange = () => handlePermissionChanged(microphonePermission)

    if (microphonePermission.state === "prompt" || cameraPermission.state === "prompt") {
      setDialogType(DialogType.explanation)
    }
  }, [])

  /**
   * This won't work for FF and Safari. For them consider using a timeout like
   * https://github.com/gtechnologies/mic-check/blob/main/examples/react-example/src/components/MediaOnboardingDialog/MediaOnboardingDialog.tsx
   */
  const checkMediaPermissions = useCallback(async () => {
    setDialogType(null)
    await checkForExplanationDialog(checkMediaPermissions)
    try {
      await requestMediaPermissions({
        audio: true,
        ...(examType === ExamType.Virtual && { video: true }),
      })
      setDialogType(null)
      onComplete(true)
    } catch (error) {
      if (error as MediaPermissionsError) {
        if (error.type === MediaPermissionsErrorType.SystemPermissionDenied) {
          setDialogType(DialogType.systemDenied)
        } else if (error.type === MediaPermissionsErrorType.UserPermissionDenied) {
          setDialogType(DialogType.userDenied)
        } else if (error.type === MediaPermissionsErrorType.CouldNotStartVideoSource) {
          setDialogType(DialogType.trackError)
        }
      } else {
        setDialogType(DialogType.unknownError)
        logError(error)
      }
    }
  }, [checkForExplanationDialog, examType, onComplete])

  const checkPlatform = useCallback((): boolean => {
    if (!AVStore.isPlatformSupported()) {
      setDialogType(DialogType.platformUnsupported)
      return false
    }
    return true
  }, [])

  const handleCancel = (failureReason: FailureReason) => {
    setDialogType(null)
    onComplete(false, failureReason)
  }

  useEffect(() => {
    logger.debug("Running compatibility check")
    if (examType === ExamType.InPerson || checkPlatform()) {
      logger.debug("Checking media permissions")
      void checkMediaPermissions()
    }
  }, [checkMediaPermissions, examType, checkPlatform])

  return (
    <Dialog
      open={!!dialogType}
      disableEscapeKeyDown
      onClose={(_, reason) => {
        if (reason !== "backdropClick") {
          setDialogType(null)
        }
      }}
    >
      <Switch condition={dialogType}>
        <Case value={DialogType.platformUnsupported}>
          <PlatformUnsupported
            onClose={() => {
              setDialogType(null)
              onComplete(false, FailureReason.platformUnsupported)
            }}
          />
        </Case>
        <Case value={DialogType.systemDenied}>
          <SystemDenied
            examType={examType}
            onCancel={handleCancel}
            checkMediaPermissions={() => void checkMediaPermissions()}
          />
        </Case>
        <Case value={DialogType.userDenied}>
          <UserDenied examType={examType} onCancel={handleCancel} />
        </Case>
        <Case value={DialogType.trackError}>
          <TrackError
            examType={examType}
            onCancel={handleCancel}
            checkMediaPermissions={() => void checkMediaPermissions()}
          />
        </Case>
        <Case value={DialogType.explanation}>
          <Explanation examType={examType} onClose={() => setDialogType(null)} />
        </Case>
        <Case value={DialogType.unknownError}>
          <Generic
            examType={examType}
            onCancel={handleCancel}
            checkMediaPermissions={() => void checkMediaPermissions()}
          />
        </Case>
      </Switch>
    </Dialog>
  )
}

export default CompatibilityCheck
export { FailureReason }

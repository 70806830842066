/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement, ReactNode, useCallback, useEffect, useState } from "react"
import {
  Auth0Provider,
  User as Auth0User,
  GetTokenSilentlyOptions,
  RedirectLoginOptions,
  LogoutOptions,
  useAuth0,
} from "@auth0/auth0-react"
import URI from "urijs"
import { getConfig, logError } from "@medaica/common/services/util"
import useNav from "@medaica/common/hooks/nav"
import MedaicaApiService from "@medaica/common/services/medaica-api-service"
import Loading from "@medaica/common/components/loading"

type User = {
  auth0UserId: string
  id: string
  email: string
}

type AuthContextInterface<T> = {
  isLoading: boolean
  isAuthenticated: boolean
  logout: (options?: LogoutOptions) => void
  login: (options?: RedirectLoginOptions) => Promise<void>
  userMaybe: T | undefined
  user: T
  getAccessToken: (options?: GetTokenSilentlyOptions) => Promise<string>
}

type Auth = () => AuthContextInterface<User>

const MedaicaAuthContextProvider = <T, P extends React.Context<AuthContextInterface<T>>>({
  userBuilder,
  context,
  children,
}: {
  userBuilder: (baseUser: User, user: Auth0User) => T
  context: P
  children: ReactNode
}): ReactElement => {
  const {
    user: auth0User,
    isLoading: isAuth0Loading,
    isAuthenticated,
    logout,
    getAccessTokenSilently,
    loginWithRedirect,
  } = useAuth0()
  const [isLoading, setIsLoading] = useState(true)
  const [user, setUser] = useState<T | undefined>(undefined)

  const login = useCallback(
    async (options?: RedirectLoginOptions) => {
      await loginWithRedirect({
        apiUrl: MedaicaApiService.apiUrl(),
        ...options,
      })
    },
    [loginWithRedirect]
  )

  useEffect(() => {
    if (!isAuth0Loading) {
      if (auth0User) {
        const baseUser = {
          auth0UserId: auth0User["sub"] as string,
          id: auth0User["https://medaica.com/id"] as string,
          email: auth0User["email"] as string,
        }
        setUser(userBuilder(baseUser, auth0User))
      }
      setIsLoading(false)
    }
  }, [isAuth0Loading, auth0User, userBuilder])

  return !isLoading ? (
    <context.Provider
      value={{
        isLoading,
        isAuthenticated,
        logout,
        login,
        // We use this user prop when we aren't sure at runtime whether the user has been set or not
        userMaybe: user,
        // We use this user prop when we can assume the user has been set and are fine with a NPE exception being
        // thrown if for some reason it hasn't been. This keeps us from having to litter the code with linting
        // exceptions or null checks
        get user(): T {
          return user as T
        },
        getAccessToken: getAccessTokenSilently,
      }}
    >
      {children}
    </context.Provider>
  ) : (
    <Loading />
  )
}

const AuthContextProvider = <T, P extends React.Context<AuthContextInterface<T>>>({
  userBuilder,
  context,
  children,
}: {
  userBuilder: (baseUser: User | undefined, auth0User: Auth0User | undefined) => T
  context: P
  children: ReactNode
}): ReactElement => {
  const navigate = useNav()

  const onRedirectCallback = useCallback(
    (appState) => {
      try {
        navigate("/auth-callback", {
          state: { returnTo: appState?.returnTo || "/" },
        })
      } catch (error) {
        logError(error)
      }
    },
    [navigate]
  )

  return (
    <Auth0Provider
      domain={getConfig("AUTH0_DOMAIN")}
      clientId={getConfig("AUTH0_CLIENT_ID")}
      audience={getConfig("AUDIENCE")}
      scope="openid profile email api"
      responseType="token id_token"
      redirectUri={URI(window.location.href).pathname("/auth-callback").valueOf()}
      onRedirectCallback={onRedirectCallback}
    >
      <MedaicaAuthContextProvider context={context} userBuilder={userBuilder}>
        {children}
      </MedaicaAuthContextProvider>
    </Auth0Provider>
  )
}

export type { AuthContextInterface, User, Auth }
export { AuthContextProvider }

/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement } from "react"

const Target = (): ReactElement => (
  <svg width="51" height="51" viewBox="0 0 51 51" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.33">
      <rect x="12" y="12" width="27" height="27" rx="13.5" fill="#3B3AEE" />
      <rect x="15.8572" y="15.8564" width="19.2857" height="19.2857" rx="9.64286" fill="#3B3AEE" />
    </g>
    <g opacity="0.33">
      <rect x="6" y="6" width="39" height="39" rx="19.5" fill="#3B3AEE" />
      <rect x="11.5714" y="11.5693" width="27.8571" height="27.8571" rx="13.9286" fill="#3B3AEE" />
    </g>
    <g opacity="0.33">
      <rect width="51" height="51" rx="25.5" fill="#3B3AEE" />
    </g>
    <rect x="21" y="21" width="9" height="9" rx="4.5" fill="white" />
    <rect x="22.2863" y="22.2853" width="6.42857" height="6.42857" rx="3.21429" fill="white" />
    <g opacity="0.44">
      <rect x="17" y="17" width="17" height="17" rx="8.5" fill="white" />
      <rect x="19.4294" y="19.4283" width="12.1429" height="12.1429" rx="6.07143" fill="white" />
    </g>
  </svg>
)
export default Target

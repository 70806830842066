/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import { Auscultation, AuscultationPoint } from "@medaica/common/types"
import { AudioFile, RemoteAudioFile } from "@medaica/common/components/audio-player"
import MedaicaApiService from "@medaica/common/services/medaica-api-service"

enum AlgorithmType {
  Raw = "Raw",
  Algo1 = "Algo1",
  Algo2 = "Algo2",
  Silence = "Silence",
}

const buildAudioFileFromAuscultation = (
  auscultation: Auscultation,
  medaicaApiService: MedaicaApiService,
  algorithmType: AlgorithmType = AlgorithmType.Silence
): AudioFile | RemoteAudioFile => {
  if (auscultation.recording.data) {
    return {
      filename: auscultation.fileName,
      data: auscultation.recording.data,
    } as AudioFile
  } else {
    return {
      filename: auscultation.fileName,
      duration: auscultation.recording.duration,
      getData: async (downloadProgressCallback): Promise<Blob> => {
        switch (algorithmType) {
          case AlgorithmType.Raw:
            return await medaicaApiService.auscultations.getAuscultationRecordingRawData(
              auscultation.id,
              downloadProgressCallback
            )
          case AlgorithmType.Algo1:
            return await medaicaApiService.auscultations.getAuscultationRecordingNormalizedDataWaveletFiltering(
              auscultation.id,
              downloadProgressCallback
            )
          case AlgorithmType.Algo2:
            return await medaicaApiService.auscultations.getAuscultationRecordingNormalizedDataStandard(
              auscultation.id,
              downloadProgressCallback
            )
          case AlgorithmType.Silence:
            return await medaicaApiService.auscultations.getAuscultationRecordingNormalizedDataSilenced(
              auscultation.id,
              downloadProgressCallback
            )
        }
      },
    }
  }
}

const buildAuscultationFileName = (
  auscultationPoint: AuscultationPoint,
  dateCreated: Date,
  mimeType: string
): string => {
  return `Medaica_${auscultationPoint.label}_${dateCreated.toISOString().split(".")[0]}.wav`
}

export { buildAudioFileFromAuscultation, buildAuscultationFileName, AlgorithmType }

/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement, SVGProps } from "react"

const PlayIcon = (props: SVGProps<SVGSVGElement>): ReactElement => {
  return (
    <svg {...props} fill="currentColor" viewBox="0 0 16 16">
      <path
        d="m11.596 8.697-6.363 3.692c-.54.313-1.233-.066-1.233-.697V4.308c0-.63.692-1.01
      1.233-.696l6.363 3.692a.802.802 0 0 1 0 1.393z"
      />
    </svg>
  )
}

export default PlayIcon

/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement, useContext, useEffect, useState } from "react"
import { Field, Form, Formik } from "formik"
import * as Yup from "yup"
import { Alert, LoadingButton } from "@mui/lab"
import { ConnectedFocusError } from "focus-formik-error"
import { TextField } from "formik-mui"
import useGlobalContext from "@medaica/common/hooks/global-context"
import useAuth from "services/auth"
import LoadingWrapper from "@medaica/common/components/loading-wrapper"
import VirtualExamContext from "views/exam/virtual-exam/virtual-exam-context"
import ActionPanel from "@medaica/common/views/exam/virtual-exam/lobby/action-panel"
import ActionPanelTitle from "@medaica/common/views/exam/virtual-exam/lobby/action-panel-title"
import { Link } from "@mui/material"
import { getConfig } from "@medaica/common/services/util"
import URI from "urijs"

const InitialEntryExamKnown = ({
  onEnterExam,
  name,
}: {
  onEnterExam: (patientName: string, virtualExamId: string) => void
  name: string
}): ReactElement => {
  const [errorMessage, setErrorMessage] = useState<string>()
  const { medaicaApiService } = useGlobalContext()
  const { isAuthenticated, user } = useAuth()
  const [patientName, setPatientName] = useState<string>(name)
  const [loading, setLoading] = useState(true)
  const { uiStore } = useContext(VirtualExamContext)
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const virtualExamId = URI(window.location.href).segment(1)!

  useEffect(() => {
    const fetchData = async () => {
      const patient = await medaicaApiService.patients.getPatient(user.id)
      setPatientName(`${patient.firstName} ${patient.lastName}`)
      setLoading(false)
    }
    if (isAuthenticated) {
      void fetchData()
    } else {
      setLoading(false)
    }
  }, [medaicaApiService, isAuthenticated, user])

  useEffect(() => {
    if (uiStore.viewArgs?.redirectReason === "entryError") {
      setErrorMessage("A system error occurred while entering the exam. Please try again.")
    }
  }, [uiStore])

  return (
    <LoadingWrapper loading={loading}>
      <ActionPanel>
        <ActionPanelTitle>Ready to enter the exam?</ActionPanelTitle>
        <Formik
          initialValues={{
            name: patientName,
          }}
          validationSchema={Yup.object().shape({
            name: Yup.string().required("Please enter your name."),
          })}
          onSubmit={(values: { name: string }) => onEnterExam(values.name, virtualExamId)}
        >
          {() => (
            <Form className="w-full">
              <ConnectedFocusError />
              {!!errorMessage && (
                <Alert sx={{ mt: 3 }} severity="error">
                  {errorMessage}
                </Alert>
              )}
              <div className="mt-8 flex flex-col space-y-4">
                <Field component={TextField} name="name" size="small" label="Your name" />
              </div>
              {/*<div className="mt-8 text-center">Waiting for Nick to join the exam</div>*/}
              <div className="flex mt-8 justify-center">
                <LoadingButton type="submit" variant="contained">
                  Ask to enter the exam
                </LoadingButton>
              </div>
            </Form>
          )}
        </Formik>
        <div className="mt-10 text-md">
          Are you a healthcare provider?{" "}
          <Link href={getConfig("HEALTH_CARE_PROVIDER_PORTAL_URL")}>
            Enter exams through the healthcare provider portal
          </Link>
        </div>
      </ActionPanel>
    </LoadingWrapper>
  )
}

export default InitialEntryExamKnown

/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import MedaicaApiService from "./"
import { Auscultation } from "@medaica/common/types"
import { plainToInstance } from "class-transformer"
import { runInAction } from "mobx"

class AuscultationsApi {
  private _apiService: MedaicaApiService

  constructor(apiService: MedaicaApiService) {
    this._apiService = apiService
  }

  async getAuscultation(auscultationId: string): Promise<Auscultation> {
    const apiClient = await this._apiService.apiClient()
    const response = await apiClient.get<Auscultation>(`/auscultations/${auscultationId}`)
    return plainToInstance(Auscultation, response.data)
  }

  private async getAuscultationRecordingData(url: string, downloadProgressCallback?: (number) => void): Promise<Blob> {
    const apiClient = await this._apiService.apiClient()
    const response = await apiClient.get<Blob>(url, {
      responseType: "blob",
      onDownloadProgress: (progressEvent) => {
        const percentCompleted = progressEvent.total
          ? Math.floor((progressEvent.loaded / progressEvent.total) * 100)
          : 0
        if (downloadProgressCallback) {
          runInAction(() => {
            downloadProgressCallback(percentCompleted)
          })
        }
      },
    })
    return response.data
  }

  async getAuscultationRecordingRawData(
    auscultationId: string,
    downloadProgressCallback?: (number) => void
  ): Promise<Blob> {
    return this.getAuscultationRecordingData(
      `/auscultations/${auscultationId}/recording/data`,
      downloadProgressCallback
    )
  }

  async getAuscultationRecordingNormalizedDataSilenced(
    auscultationId: string,
    downloadProgressCallback?: (number) => void
  ): Promise<Blob> {
    return this.getAuscultationRecordingData(
      `/auscultations/${auscultationId}/recording/normalized-data-silenced`,
      downloadProgressCallback
    )
  }

  async getAuscultationRecordingNormalizedDataStandard(
    auscultationId: string,
    downloadProgressCallback?: (number) => void
  ): Promise<Blob> {
    return this.getAuscultationRecordingData(
      `/auscultations/${auscultationId}/recording/normalized-data-std`,
      downloadProgressCallback
    )
  }

  async getAuscultationRecordingNormalizedDataWaveletFiltering(
    auscultationId: string,
    downloadProgressCallback?: (number) => void
  ): Promise<Blob> {
    return this.getAuscultationRecordingData(
      `/auscultations/${auscultationId}/recording/normalized-data-sg`,
      downloadProgressCallback
    )
  }

  async deleteAuscultation(auscultationId: string): Promise<void> {
    const apiClient = await this._apiService.apiClient()
    await apiClient.delete(`/auscultations/${auscultationId}`)
  }
}

export default AuscultationsApi

/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement, ReactNode } from "react"
import MedaicaLogo from "@medaica/common/components/medaica-logo"
import { Link } from "react-router-dom"

const GlobalLayout = ({
  header,
  useHomeLink = true,
  children,
}: {
  header?: ReactNode
  useHomeLink?: boolean
  children: ReactNode
}): ReactElement => {
  return (
    <div className="overflow-hidden h-screen flex flex-col bg-white">
      <div className="relative flex-shrink-0 flex justify-between h-16 px-4 border-b">
        <div className="flex items-center">
          {useHomeLink ? (
            <Link to="/">
              <MedaicaLogo className="text-primary h-6" />
            </Link>
          ) : (
            <MedaicaLogo className="text-primary h-6" />
          )}
        </div>
        {header}
      </div>
      {children}
    </div>
  )
}

export default GlobalLayout

/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement, useContext } from "react"
import VideoControls from "@medaica/common/components/video-controls"
import SettingsButton from "@medaica/common/views/exam/virtual-exam/components/settings-button"
import ExitButton from "@medaica/common/views/exam/virtual-exam/exam-room/components/exit-button"
import VirtualExamContext from "views/exam/virtual-exam/virtual-exam-context"
import SettingsPanel from "@medaica/common/views/exam/virtual-exam/components/settings-panel"
import { observer } from "mobx-react-lite"

const PageControls = observer(({ onExitEvent }: { onExitEvent: () => void }): ReactElement => {
  const { virtualExamStore, mediaDeviceStore, uiStore, preferencesStore } = useContext(VirtualExamContext)

  const handleExitButtonClicked = () => {
    onExitEvent()
    uiStore.navigate("lobby", { redirectReason: "exited" })
  }

  return (
    <div className="flex flex-col mt-2 gap-y-2 w-full">
      <div className="mt-2 flex flex-row gap-x-3 w-full justify-center">
        <VideoControls
          mediaDeviceStore={{
            ...mediaDeviceStore,
            setAudioDeviceMuted(muted: boolean) {
              virtualExamStore.setAudioDeviceMutedByUser(muted)
            },
            setVideoDeviceMuted(muted: boolean) {
              virtualExamStore.setVideoDeviceMutedByUser(muted)
            },
          }}
        />
        {/* TODO: OTIS-225 - help button is disabled for now */}
        {/* <HelpButton /> */}
        <SettingsButton>
          <SettingsPanel
            disableAudioSources={virtualExamStore.inListeningMode}
            preferencesStore={preferencesStore}
            mediaDeviceStore={mediaDeviceStore}
          />
        </SettingsButton>
        <ExitButton onExit={handleExitButtonClicked} />
      </div>
    </div>
  )
})

export default PageControls

/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement, ReactNode, useEffect } from "react"
import { ErrorBoundary, useErrorHandler } from "react-error-boundary"
import { logError } from "@medaica/common/services/util"

/**
 * Captures all unhandled exceptions and throws them to the nearest Error Boundary. Since it attaches functions to the
 * global error handlers, it only makes sense to create one of these. In other words, it's pointless to nest them.
 */
const GlobalErrorCatcher = ({ children }: { children: ReactNode }): ReactElement => {
  const handleError = useErrorHandler()

  useEffect(() => {
    window.onunhandledrejection = function (event: PromiseRejectionEvent) {
      if (event.type === "unhandledrejection") {
        handleError(event.reason)
      }
    }

    window.onerror = function (message, source, lineno, colno, error) {
      handleError(error)
    }
  }, [handleError])

  return <>{children}</>
}

const LoggingErrorBoundary = ({
  children,
  FallbackComponent,
}: {
  children: ReactNode
  FallbackComponent: React.FunctionComponent<{ error: Error }>
}): ReactElement => {
  const handleError = (error: Error, errorInfo: { componentStack: string }) => {
    logError(error, { extra: errorInfo })
  }

  return (
    <ErrorBoundary onError={handleError} FallbackComponent={FallbackComponent}>
      {children}
    </ErrorBoundary>
  )
}

export default LoggingErrorBoundary
export { GlobalErrorCatcher }

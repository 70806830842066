/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement, useEffect, useState } from "react"
import { useParams, useLocation } from "react-router-dom"
import MedaicaApiService, { isMedaicaApiError, MedaicaApiErrorType } from "@medaica/common/services/medaica-api-service"
import Loading from "@medaica/common/components/loading"
import { MedaicaApiAccessToken } from "@medaica/common/types"
import useGlobalContext from "@medaica/common/hooks/global-context"
import { addSeconds, addHours } from "date-fns"
import UnauthenticatedView from "views/exam/requested-self-exam/views/unauthenticated"
import Switch, { Case, Default } from "react-switch-case"
import { RequestedSelfExamContextProvider } from "views/exam/requested-self-exam/requested-self-exam-context"
import AccessTokenStorage from "@medaica/common/services/access-token-storage"
import { logError } from "@medaica/common/services/util"
import RequestedSelfExamView from "views/exam/requested-self-exam/views/exam"
import ExamCompleteView from "views/exam/requested-self-exam/views/complete"

const getValidAccessToken = async (
  medaicaApiService: MedaicaApiService,
  selfExamRequestId: string,
  accessCode: string | null
): Promise<MedaicaApiAccessToken | null> => {
  const accessTokenStorage = new AccessTokenStorage("SELF_EXAM_ACCESS_TOKENS")
  // Check local storage for an anon self exam access token and if it's valid for this exam return it
  const accessTokenData = accessTokenStorage.get(selfExamRequestId)
  if (accessTokenData) {
    // We subtract an hour from the expiration so we force the user to get a new token if they enter the exam with
    // just an hour to go before the token expires. We don't want the token expiring in the middle of the exam.
    // This way, they have an hour to complete the exam without the token expiring.
    const expiresAt = addHours(new Date(accessTokenData.expiresAt), -1)
    if (new Date() < expiresAt) {
      return {
        value: accessTokenData.value,
        expiresAt: accessTokenData.expiresAt,
      }
    }
  }

  if (!accessCode) {
    return null
  }

  // If there's no stored access token for this self exam, we need to create one, so we send the access code to
  // the API to create one.
  try {
    const accessTokenData = await medaicaApiService.selfExamRequests.createSelfExamAccessToken(
      selfExamRequestId,
      accessCode
    )
    const expiresAt = addSeconds(new Date(), accessTokenData.expiresIn)

    // We save it to local storage
    accessTokenStorage.set(selfExamRequestId, {
      value: accessTokenData.value,
      expiresAt,
      userId: accessTokenData.userId,
    })

    return {
      value: accessTokenData.value,
      expiresAt,
    }
  } catch (error) {
    // If the access code has expired we don't have a new access token to store. We send the user to a page to get
    // a new access code.
    if (isMedaicaApiError(error) && error.type === MedaicaApiErrorType.EXPIRED_ACCESS_CODE) {
      return null
    } else {
      // If there's an error other than EXPIRED_ACCESS_CODE we want to log it.
      logError(error)
      return null
    }
  }
}

// In order to access a self exam a patient needs an access token. An access token is valid for 24 hours. The
// patient receives an access token by exchanging a valid access code for an access token. An access code is stored
// on the server, has a short duration, and is associated with an email address. When the patient first hits this
// page, we check to see if the access token is in local storage and is unexpired. If so, we continue. If not, we check
// for the access code in the query of the URL. If there is one, we attempt to exchange it for an access token. If
// it hasn't expired, the API will reply with a valid access token. If it has expired or there isn't one in the
// query string, we tell the user we will send a new link (which will include a valid access code) to the email
// address associated with this self exam (we don't expose the email address for security/HIPAA reasons.) Once they
// hit this page with the new link, it will hopefully be within the access code's short valid life span and they
// will continue to the page. If not, the process will repeat.
const Factory = (): ReactElement => {
  const { selfExamRequestId } = useParams<{ selfExamRequestId: string }>()
  const { medaicaApiService } = useGlobalContext()
  const accessCode = new URLSearchParams(useLocation().search).get("ac")
  const [view, setView] = useState<"exam" | "complete" | "unauthenticated" | "loading">("loading")
  const [accessToken, setAccessToken] = useState<MedaicaApiAccessToken | null>(null)

  useEffect(() => {
    void (async () => {
      const isComplete = await medaicaApiService.selfExamRequests.getSelfExamRequestFinalized(selfExamRequestId)
      if (isComplete) {
        setView("complete")
      } else {
        const accessToken = await getValidAccessToken(medaicaApiService, selfExamRequestId, accessCode)
        if (!accessToken) {
          setView("unauthenticated")
        } else {
          setAccessToken(accessToken)
          setView("exam")
        }
      }
    })()
  }, [accessCode, medaicaApiService, selfExamRequestId])

  return (
    <Switch condition={view}>
      <Case value="exam">
        {accessToken && (
          <RequestedSelfExamContextProvider accessToken={accessToken}>
            <RequestedSelfExamView />
          </RequestedSelfExamContextProvider>
        )}
      </Case>
      <Case value="complete">
        <ExamCompleteView />
      </Case>
      <Case value="unauthenticated">
        <UnauthenticatedView />
      </Case>
      <Default>
        <Loading />
      </Default>
    </Switch>
  )
}

export default Factory

/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import ContentMiddle from "@medaica/common/layouts/minimal-layout/content-middle"
import React, { ReactElement, useEffect, useRef } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import MinimalLayout from "@medaica/common/layouts/minimal-layout"

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ErrorBoundary = ({ resetErrorBoundary }: { error: any; resetErrorBoundary: () => void }): ReactElement => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const originalPathname = useRef(pathname)

  const goHomeButtonClick = () => {
    resetErrorBoundary()
    navigate("/")
  }

  useEffect(() => {
    if (pathname !== originalPathname.current) {
      resetErrorBoundary()
    }
  }, [pathname, resetErrorBoundary])
  return (
    <MinimalLayout>
      <ContentMiddle>
        <h2 className="text-5xl font-bold">An error occurred.</h2>
        <p className="mt-3 text-base text-gray-500">The error has been logged and our team will be looking into it.</p>
        <button className="mt-7 link font-medium text-base" onClick={goHomeButtonClick}>
          Go back home <span aria-hidden="true">&rarr;</span>
        </button>
      </ContentMiddle>
    </MinimalLayout>
  )
}

export default ErrorBoundary

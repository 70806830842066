/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement } from "react"
import "./styles.css"

const Support = (): ReactElement => {
  return (
    <div className="main-centered">
      <h1 className="header h1-header">Support</h1>
      <p className="justified-text">
        To receive support send an email to{" "}
        <a href="mailto:support@medaica.com" className="mailto-link">
          support@medaica.com
        </a>
      </p>
    </div>
  )
}

export default Support

/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement, useRef, useState } from "react"
import { useIdleTimer } from "react-idle-timer"
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material"
import Button from "@mui/material/Button"
import URI from "urijs"
import { Auth } from "@medaica/common/services/auth"
import { getConfigNumber, getCurrentUrlFullPath } from "@medaica/common/services/util"

const IdleTimer = ({ useAuth, loggedOutPath }: { useAuth: Auth; loggedOutPath: string }): ReactElement => {
  const [isOpen, setIsOpen] = useState(false)
  const logoutTimeout = useRef<NodeJS.Timeout | null>(null)
  const allowedIdleTimeInSeconds = getConfigNumber("ALLOWED_IDLE_TIME_IN_SECONDS")
  const gracePeriodInSeconds = getConfigNumber("ALLOWED_IDLE_GRACE_PERIOD_IN_SECONDS")
  const { logout, isAuthenticated } = useAuth()

  useIdleTimer({
    timeout: allowedIdleTimeInSeconds * 1000,
    onIdle: () => {
      if (isAuthenticated) {
        setIsOpen(true)
        logoutTimeout.current = setTimeout(handleTimeout, gracePeriodInSeconds * 1000)
      }
    },
    debounce: 500,
  })

  const handleTimeout = () => {
    close()
    logout({
      returnTo: URI(window.location.href)
        .pathname(loggedOutPath)
        .search({ reason: "inactivity", returnTo: getCurrentUrlFullPath() })
        .valueOf(),
    })
  }

  const stayLoggedInButtonClicked = () => {
    if (logoutTimeout.current) {
      clearInterval(logoutTimeout.current)
    }
    close()
  }

  const signOutButtonClicked = () => {
    logout()
  }

  const close = () => {
    setIsOpen(false)
  }

  return (
    <Dialog
      open={isOpen}
      disableEscapeKeyDown
      onClose={(_, reason) => {
        if (reason !== "backdropClick") {
          close()
        }
      }}
    >
      <DialogTitle>Your session is about to end</DialogTitle>
      <DialogContent>
        For your security, you will be signed out shortly. If you wish to stay signed in, click{" "}
        <strong>Stay signed in</strong>.
      </DialogContent>
      <DialogActions>
        <Button onClick={signOutButtonClicked}>Sign out</Button>
        <Button ref={(el) => el?.focus()} onClick={stayLoggedInButtonClicked}>
          Stay signed in
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default IdleTimer

/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement, useState } from "react"
import { auscultationPoints } from "@medaica/common/const"
import AuscultationPointImage from "@medaica/common/components/images/auscultation-point"
import { AuscultationPoint } from "@medaica/common/types"
import { classNames } from "@medaica/common/services/util"

const ListItem = ({
  auscultationPoint,
  boxSize,
  selectedAuscultationPoint,
  onClick,
}: {
  auscultationPoint: AuscultationPoint
  boxSize?: string
  selectedAuscultationPoint?: AuscultationPoint | null
  onClick: (auscultationPoint: AuscultationPoint) => void
}): ReactElement => {
  return (
    <li key={auscultationPoint.label}>
      <button
        onClick={() => onClick(auscultationPoint)}
        className={classNames(
          auscultationPoint === selectedAuscultationPoint ? "ring-2 ring-primary-300" : "ring-0",
          "border bg-gray-50 hover:ring-2 hover:ring-primary-300 rounded-md"
        )}
        {...(boxSize && { style: { width: boxSize, height: boxSize } })}
      >
        <AuscultationPointImage height="100%" width="100%" highlightedPoint={auscultationPoint} />
      </button>
    </li>
  )
}

const AuscultationPointMenu = ({
  boxSize,
  selectedAuscultationPoint,
  onAuscultationPointSelected,
}: {
  boxSize?: string
  selectedAuscultationPoint?: AuscultationPoint
  onAuscultationPointSelected: (auscultationPoint: AuscultationPoint) => void
}): ReactElement => {
  const [clickedAuscultationPoint, setClickedAuscultationPoint] = useState<AuscultationPoint | undefined>(
    selectedAuscultationPoint
  )
  const handleAuscultationPointButtonClicked = (auscultationPoint: AuscultationPoint) => {
    setClickedAuscultationPoint(auscultationPoint)
    onAuscultationPointSelected(auscultationPoint)
  }
  return (
    <dl>
      <dt className="text mt-6">Heart</dt>
      <dd>
        <ul className="flex space-x-2">
          {auscultationPoints.heart.map((auscultationPoint) => (
            <ListItem
              key={auscultationPoint.label}
              auscultationPoint={auscultationPoint}
              boxSize={boxSize}
              selectedAuscultationPoint={clickedAuscultationPoint}
              onClick={handleAuscultationPointButtonClicked}
            />
          ))}
        </ul>
      </dd>
      <dt className="mt-3">Lungs</dt>
      <dd>
        <ul className="flex space-x-2">
          {auscultationPoints.lungs.map((auscultationPoint) => (
            <ListItem
              key={auscultationPoint.label}
              auscultationPoint={auscultationPoint}
              boxSize={boxSize}
              selectedAuscultationPoint={clickedAuscultationPoint}
              onClick={handleAuscultationPointButtonClicked}
            />
          ))}
        </ul>
      </dd>
      <dt className="mt-3">Other</dt>
      <dd>
        <ul className="flex space-x-2">
          <ListItem
            key={auscultationPoints.other.label}
            auscultationPoint={auscultationPoints.other}
            boxSize={boxSize}
            selectedAuscultationPoint={clickedAuscultationPoint}
            onClick={handleAuscultationPointButtonClicked}
          />
        </ul>
      </dd>
    </dl>
  )
}

export default AuscultationPointMenu

/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement, useContext, useEffect } from "react"
import "firebase/database"
import { observer } from "mobx-react-lite"
import MedaicaLogo from "@medaica/common/components/medaica-logo"
import Progress from "views/exam/requested-self-exam/views/exam/components/progress"
import useWarnBeforeUnload from "@medaica/common/hooks/warn-before-unload"
import Routes from "views/exam/requested-self-exam/views/exam/components/router"
import { useLocation } from "react-router-dom"
import Complete from "views/exam/requested-self-exam/views/exam/views/complete"
import RequestedSelfExamContext from "views/exam/requested-self-exam/requested-self-exam-context"

const RequestedSelfExamView = observer((): ReactElement => {
  const { requestedSelfExamStore, mediaDeviceManager } = useContext(RequestedSelfExamContext)
  const location = useLocation()

  useWarnBeforeUnload(() => !requestedSelfExamStore.isComplete)

  useEffect(() => {
    return () => {
      mediaDeviceManager.dispose()
    }
  }, [mediaDeviceManager, requestedSelfExamStore])

  return requestedSelfExamStore.isComplete ? (
    <Complete />
  ) : (
    <div className="h-screen bg-gray-100 flex flex-col items-center pb-5">
      <main className="py-3 h-full flex flex-col" style={{ width: "64rem" }}>
        <MedaicaLogo className="flex-shrink-0 text-primary-700 h-6 mt-1 ml-1 self-start" />
        <div className="my-5 bg-white flex flex-row border border-gray-300 rounded-md">
          <Progress />
          <Routes
            auscultationRequests={requestedSelfExamStore.selfExamRequest.auscultationRequests}
            query={location.search}
          />
        </div>
      </main>
    </div>
  )
})

export default RequestedSelfExamView
